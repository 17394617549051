import React, { useEffect } from "react";
import { useState } from "react";
import Images from "./Images";
import styles from '../pages/Home/landingpage.module.css';
import MyStories_stories from './MyStories_stories'
// import MyStories_Ingredients from '../component/MyStories_Ingredients'
import MyStoriesNext from "./MyStories_Ingredients"
import { useNavigate } from "react-router-dom";
import NoDataFile from "./NoDataFile";

function MyStories({Storys,Ingridient,userinfo}) {
const navigate=useNavigate();
//   btnclickcondition
const [active, setActive] = useState(false)
//   btnclickcondition
const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

useEffect(()=>{
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
},[])

  return (
    <div className={styles.MyStories}>
        <div className="container">
            <div className={styles.backbtn} onClick={()=>{navigate(-1)}} style={{cursor:"pointer"}}>
                <a onClick={()=>{navigate(-1)}}><img src={Images.navBannerleft} /></a>
            </div>
            <h4>My Stories</h4>
            <div className={styles.storybtn}>
                <button className={active? `${styles.activeEffect} ${styles.btnFill}  ${styles.btnFillLeft}`: `${styles.btnFill}  ${styles.btnFillLeft}`}  onClick={()=>setActive(true)}>Stories</button>
                <button style={{marginLeft: "50px"}} className={!active?`${styles.activeEffect} ${styles.btnFill} ${styles.submissionNewRightBtn} `: `${styles.btnFill} ${styles.submissionNewRightBtn}`}  onClick={()=>setActive(false)}>Story Tools</button>
            </div>
           {active && Storys.length==10 &&<>
           <h3>Here is a list of your published stories</h3>
            <p>You can create as many stories as you want and save it in draft and <br /> submit maximum 10 stories.</p>
            </>}
            
            <>
                {active && Storys.length!=0 &&  <MyStories_stories Storys={Storys} /> }
                {active && Storys.length==0 &&  <NoDataFile/> }
                {!active && <MyStoriesNext matches={matches} hide={true} Ingridient={Ingridient} userinfo={userinfo} /> }
            </>
            <div className={styles.StoryFlowInfoBtn} onClick={()=>{navigate("/GuppyAppDownload");}}>
                <button className={styles.btnFill}><img src={Images.Recordimg} />Record a <br /> new story </button>
            </div>
        </div>
    </div>
  );
}

export default MyStories;
