import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import { useNavigate } from "react-router-dom";

function BannerCommon(){
    const navigate = useNavigate();
    return(
        <section className={BsCustom.BannerCommon}>
            <img className={BsCustom.BannerCommonImg} src={Images.BAnnewAnotherPageBlur} />
            <img className={BsCustom.MobileCommonBannerNew} src={Images.MobileCommonBanner} />
            <img className={BsCustom.BackBtnBSCommonNew} src={Images.BackstageBackBtn} style={{cursor:"pointer"}}  onClick={() => {
            navigate(-1);
          }} />
        </section>
    );
}

export default BannerCommon;