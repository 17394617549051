import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";

function GuppyStoriesBackstage({ home, navigate }) {
  return (
    <div className={BsCustom.GuppyStoriesBackstageSection}>
      <div className="container-fluid">
        <h2 className={BsCustom.BsH2}>Guppy Stories</h2>
        <div
          className={`row ${BsCustom.Row} ${BsCustom.GuppyStoriesBackstageSectionTop}`}
        >
          <div className={`col-md-6 ${BsCustom.GuppyStoriesBackstageLeft}`}>
            <img src={Images.LandingPAgeGuppyStoryImgNew1} />
          </div>
          <div className={`col-md-6 ${BsCustom.GuppyStoriesBackstageRight}`}>
            <p className={BsCustom.BsCommaonP}>{home?.guppy_stories_text}</p>
            <a
              href="/GuppyStoriesIp"
              style={{ textDecoration: "none", color: "#fff" }}
              className={BsCustom.BsComBtn}
              onClick={() => {
                "https://guppytheatre.com/";
              }}
            >
              Visit Guppy Stories
            </a>
          </div>
        </div>
        <div className={`row ${BsCustom.GuppyStoriesBackstageSectionBottom}`}>
          <div className={`col-md-6 ${BsCustom.GuppyStoriesBackstageSoical}`}>
            <h3>
              Kids are centre stage, <br /> actively flexing their <br />{" "}
              imaginations.
            </h3>
            <div className={BsCustom.GuppyStoriesBackstageSoicalMain}>
              <span>Available on: </span>
              <div>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.guppytheatre.app"
                >
                  <img src={Images.googleIcon} />
                </a>
                <a href="https://apps.apple.com/in/app/guppy-stories/id6450250856">
                  <img src={Images.appledownload} />
                </a>
              </div>
            </div>
          </div>
          <div
            className={`col-md-6 ${BsCustom.GuppyStoriesBackstageImgMobile}`}
          >
            <img src={Images.BackstageMobile1} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuppyStoriesBackstage;
