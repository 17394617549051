import React, { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import Images from "./Images";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { useNavigate } from "react-router-dom";
import styles from "../pages/Home/landingpage.module.css";
const RagisterFirstModal = forwardRef(({}, ref) => {
  const [showmodal, setshowmodal] = useState(false);
  const navigate =useNavigate();

  useImperativeHandle(ref, () => ({
    show: () => {
      setshowmodal(true);
    },
    hide: () => {
      setshowmodal(false);
    },
  }));

  return (
    <Modal
    ariaHideApp={false}
      style={{
        content: {
          display: "flex",
          backgroundColor: "#0000",
          borderWidth: 0,
          flexDirection: "column",
          flex: 1,
          position: "unset",
          overflow: "auto",
          height: "100%",
          padding: "40px 20px 20px",
          justifyContent: "space-around"
        },
      }}
      overlayClassName={Modalstyle.modal}
      isOpen={showmodal}
      onRequestClose={()=>{setshowmodal(false)}}
      contentLabel="Example Modal"
    >
      <div className="container">
        <div className={Modalstyle.modalLoginRegister}>
          <h2>
          To Continue playing please <br />  Log in or Register!
          </h2>
          <button className={styles.btnFill} onClick={()=>{navigate('/login')}}>LOG-IN</button>
          <button className={styles.btnFill}  onClick={()=>{navigate('/signUp')}}>SIGN-UP</button>
        </div>
      </div>
    </Modal>
  );
});

export default RagisterFirstModal;
