import axios from "axios";
import { getLocal } from "../otherexports";

export const baseURL = "https://manage.guppytheatre.com/api/upload";

export default async (dataSend, comment,toke) => {
  // const Token = getLocal("User");
  const form = new FormData();
  form.append("files", dataSend[0]);
  // let header;
  // if(toke==undefined){
  //   header = {
  //     Accept: "application/json",
  //     "Content-Type": "multipart/form-data",
  //     Authorization: "bearer " + Token.jwt,
  //   };
  // }else{
  //   header = {
  //     Accept: "application/json",
  //     "Content-Type": "multipart/form-data"
  //   };
  // }
  // let header = {
  //   Accept: "application/json",
  //   "Content-Type": "multipart/form-data",
  //   Authorization: "bearer " + Token,
  // };

  return new Promise((resolve, reject) => {
    console.log(baseURL,form);
    // fetch(baseURL, {
    //   method: "post",
    //   headers: header,
    //   body: form,
    // })
    //   .then((response) => response.json())
 
    axios.post(baseURL, form).then((res) => {
        // if (comment != undefined) {
        //   console.log("=================",dataSend[0],"=================" + comment + "=================");
        //   console.log(res, "=================" + comment + "=================");
        // }

        resolve(res);
      })
      .catch((error) => {
        // console.log(error, "=================" + comment + "=================",dataSend[0]);
        reject(error);
      });
  });
};
