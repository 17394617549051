import React, { useState } from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import BackStageMaharaja from "../Component/BackStageMaharaja";
import BSHyperLinkPriviousNext from "../Component/BSHyperLinkPriviousNext";
import StoryInformationBackstage from "../Component/StoryInformationBackstage";
import BshyperLinkStoryTitle from "../Component/BshyperLinkStoryTitle";
import Credit from "../Component/Credit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBackstagewhatwodo, useSettergetter } from "../../otherexports";
import MetaTags from 'react-meta-tags';
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports"; 

function IpInside(){
    const location = useLocation();
    const { Singleitem } = useSettergetter(location?.state?.item);
    const [item, setitem] = useState(Singleitem);
    const {isLoading,home} =useBackstagehome();
    if(!isLoading){
        return(
            <section className={BsCustom.WrapperIpInside}>
                <MetaTags>
                    <title>Maharaja of Mastipur - Guppy Theater</title>
                    <meta name="description" content="The Maharaja of Mastipur is a prankster" />
                    <meta name="keywords" content="Maharaja, Mastipur, Prank, story, children, animation, games, books, audiobooks, theatre" />
                </MetaTags>
                <HeaderBackstageNew />
                <div className={BsCustom.BshyperLinkStoryTitleMain}>
                    <div className="container">
                        <div className={BsCustom.BSHyperLinkPriviousNextMain}>
                            <a href="/LandingPageBs" className="ReadMoreCmn"><img style={{marginRight: "15px"}} src={Images.CurveArrowLeft} /><span>Previous IP</span></a>
                            <a href="#"><h1 className={BsCustom.BsH1}>The Maharaja of Mastipur </h1></a>
                            <a href="/MalluAndPi" className="ReadMoreCmn"><span>Next IP</span><img style={{marginLeft: "15px"}} src={Images.CurveArrowRight} /></a>
                        </div>
                    </div>
                </div>
                <StoryInformationBackstage item={item} />
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <a href="#"><img src={Images.HeistheKingofPranks}/></a>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 class={BsCustom.BsH3}>He is the King of Pranks </h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.HeistheKingofPranks}/>
                                <p className={BsCustom.BsCommaonP}>He can pop up anywhere, anytime in a new avatar together with his family and other animals, in different mediums. Check out the transformation of ‘The Maharaja of Mastipur’ in theatre, play, book series, audiobooks, animation series, mini stories (App), NFT’s and card games!</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 class={BsCustom.BsH3}>Books & Audiobooks</h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.BOOKSAUDIOVIDEO}/>
                                <p className={BsCustom.BsCommaonP}>The original series about The Maharaja of Mastipur and his family is about a well loved and respected ruler who is a family man AND a prankster. Each book in the series is a unique event in the life of a unique Maharaja.</p>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <a href="https://www.amazon.in/Maharaja-Mastipur-Palace-Pranks-ebook/dp/B07Z87JNQP/ref=sr_1_1?crid=3N9VB0IKELASQ&keywords=the+palace+of+pranks&qid=1673109299&s=books&sprefix=the+palace+of+pranks%2Cstripbooks%2C191&sr=1-1
                                https://www.amazon.in/Maharaja-Mastipur-Pet-Tiger-Books/dp/8194758505/ref=sr_1_7?crid=2TGV8X4B128NM&keywords=the+pet+tiger&qid=1673109347&s=books&sprefix=the+pet+tiger%2Cstripbooks%2C188&sr=1-7
                                "><img src={Images.BOOKSAUDIOVIDEO}/></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <a href="#"><img src={Images.LIVETHEATRE}/></a>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 class={BsCustom.BsH3}>Live Theatre </h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.LIVETHEATRE}/>
                                <p className={BsCustom.BsCommaonP}>The Maharaja of Mastipur is bold and mad enough to play a prank on the King & Queen of England and invites children on stage to participate in the planning.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 class={BsCustom.BsH3}>Animated TV Series</h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.ANIMATEDTVSERIES}/>
                                <p className={BsCustom.BsCommaonP}>The Maharaja, as a young boy (Miniraja) is unstoppable when it comes to playing mischievous pranks on important international guests of the Palace of Mastipur, perpetually putting his companion-cum-caregiver in trouble.</p>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <a href="#"><img src={Images.ANIMATEDTVSERIES}/></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <a href="#"><img src={Images.Cardgame}/></a>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 class={BsCustom.BsH3}>Card Game </h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.Cardgame}/>
                                <p className={BsCustom.BsCommaonP}>The Mastipur world of characters, locations, animals, props and objects form an interactive and engaging game in which the children use their imagination to invent new stories about The Maharaja of Mastipur.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 class={BsCustom.BsH3}>Guppy Stories App</h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.APP}/>
                                <p className={BsCustom.BsCommaonP}>The Maharaja and his family lend themselves to continuing storytelling by applying the ‘5 W’s and a HOW’ model to the Mastipur characters and submitting them for publication.</p>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <a href="#"><img src={Images.APP}/></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <a href="#"><img src={Images.NFTVOXEL}/></a>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 class={BsCustom.BsH3}>Voxel</h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.NFTVOXEL}/>
                                <p className={BsCustom.BsCommaonP}>Owning a variety of the unique characters of the Maharaja and his key family members as a fantastic art form to eventually play unthinkable pranks in the Metaverse.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 class={BsCustom.BsH3}>Social Media</h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.Socialmedia}/>
                                <p className={BsCustom.BsCommaonP}>Certain attributes of the Maharaja give rise to playful and participative calls to action bringing children together in small groups or workshops.</p>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <a href="#"><img src={Images.Socialmedia}/></a>
                            </div>
                        </div>
                    </div>
                </section>
                <Credit item={item} />
                <div className={BsCustom.ExtraParaIpinside}>
                    <div className="container-fluid">
                        <h5>STATUS / Enquiries</h5>
                        <p>Status - Seeking investors, co-producers, buyers & collaborators <br/>
                        <span style={{fontSize: "16px"}}>For screening materials  and other business enquiries contact : <a href="mailto:anil@guppytheatre.com" style={{textDecoration: "underline", color: "#000"}}> anil@guppytheatre.com </a></span> 
                        </p>
                    </div>
                </div>
                <BSHyperLinkPriviousNext/>
                <FooterBs/>
            </section>
        ); 
    }
    else 
    {return (<Loader />)}
}

export default IpInside;