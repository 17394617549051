import React from "react";
import { useState } from "react";
import Images from "./Images";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { getLocal, useAvtar } from "../otherexports";

const MuniaModal = forwardRef(({ data, birdshow }, ref) => {
  const userinfo = getLocal("User");

  //   console.log(JSON.stringify(data),"=======bird==data")
  const [modal, setmodal] = useState(false);
  useImperativeHandle(ref, () => ({
    visible: () => {
      return modal;
    },
    show: () => {
      setmodal(true);
      // return counts;
    },
    hide: () => {
      setmodal(false);
      // return counts;
    },
  }));

  return (
    <Modal
      style={{
        content: {
          display: "flex",
          backgroundColor: "#0000",
          borderWidth: 0,
          flexDirection: "column",
          flex: 1,
          position: "unset",
          overflow: "auto",
          height: "100%",
          zIndex: 90,
        },
      }}
      ariaHideApp={false}
      overlayClassName={Modalstyle.modal}
      isOpen={modal}
      onRequestClose={() => {
        birdshow();
        setmodal(false);
      }}
      contentLabel="Example Modal"
    >
      <div className="container">
        <div className={Modalstyle.modalmuniaModalMain}>
          <img
            className={Modalstyle.sharebtnimg}
            src={Images.backbtncross}
            onClick={() => {
              birdshow();
              setmodal(false);
            }}
          />
          <div className={Modalstyle.modalmuniaModal}>
            <div className={Modalstyle.modalmuniaModalTop}>
              <img
                src={
                  !!userinfo?.name
                    ? "https://manage.guppytheatre.com" +
                      data?.attributes?.guppy_avtar?.data?.attributes
                        ?.avtar_media?.data?.attributes?.url
                    : "https://manage.guppytheatre.com" +
                      data?.attributes?.avtar_media?.data?.attributes?.url
                }
              />
              <img
                className={Modalstyle.modalmuniaHelloImg}
                src={Images.MuniaMiddleModal}
              />
            </div>
            <h5>How to start a story adventure?</h5>
            <p>
              {/* You can create a story flow from these keywords by <br />{" "}
              navigating to other stories by selecting any one of <br /> the
              options provided or create your own. Watch this <br /> video to
              know in detail about the same. */}
              Swipe the story cards and start your story adventure by tapping on
              “Start a Story!” on a story that you like.
              <br />
              <br />
              If you are not sure how to start the adventure. Let me choose the
              first story for you.
            </p>
            <div
              onClick={() =>
                window.open(
                  "https://www.youtube.com/embed/gxknEqM9P14?start=5&end=14"
                )
              }
              className={Modalstyle.modalmuniaInner}
            >
              <p>
                Click here to watch <br /> the video tutorial!
              </p>
              <img src={Images.whiteArrow} />
              <img src={Images.youtube} />
            </div>
            {/* <div className={Modalstyle.modalmuniabtn}>
              <button>Pick a story</button>
            </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default MuniaModal;
