import React from 'react';
import Accordion from './Accordian';
import styles from '../pages/Home/landingpage.module.css';
import Images from '../component/Images';
import { useNavigate } from 'react-router-dom';



const Faq = ({data}) => {
  const navigate=useNavigate();
  const handleScrollToTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  return (
    <section className={styles.faqMain}>
        <div className='container'>
            <div className={styles.faqTop}>
                <h4>Frequently Asked Questions.</h4>
                <p>You have a question! We are here to help you.</p>
                <span> You can find your question and go through the tutorial/solution <br/> provided regarding your problem.</span>
            </div>
            <div className="accordion">
                {data.map((item,index) => (
                <Accordion title={item.attributes?.question} content={item.attributes?.question_details} videoUrl={item.attributes?.tutorial_media?.data?.attributes?.url} item={item} />
                ))}
            </div>
            <div classname={styles.faqBottom} onClick={handleScrollToTop}>
                <img src={Images.navleft} style={{transform: 'rotate(90deg)', width: '70px', cursor: "pointer"}} /><span>Back to top</span>
            </div>
        </div>
        <div className={styles.faqbgImg}>
            <img src={Images.faqBg} />
        </div>
    </section>
  );
};

export default Faq;