import React, { useEffect, useState, createRef } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import Logining from "../../component/Login";
import Images from "../../component/Images";
import api from "../../ApiService/Apicall";
import NoDraftModal from "../../component/NoDraftModal";
import SubmittedSuccessfullyModal from "../../component/SubmittedSuccessfullyModal";
import { useNavigate } from "react-router-dom";


const ForgetPassword = () => {
  const navigate=useNavigate();
  const [modaltext, setmodaltext] = useState("");
  const modalopentwo = createRef(null);
  const modalopen = createRef(null);
  const [loading, setloading] = useState(true);
  const [email, setemail] = useState("");
  useEffect(() => {
    setloading(false);
  }, []);

  const sendforgetPassowrd = async () => {

    let alluser = await api("get", "users");
    var user = alluser?.data?.filter((e) => e?.email == email);
    if (user?.length > 0) {
      // setloading(true);
      await api("post", "auth/forgot-password", { email: email }).then(
        (res) => {
          if (res.ok) {
            // alert('email send Susessfully !')
            // setloading(false);
            setmodaltext("email send Successfully !");
            modalopentwo.current.show();
          }
        },
        (err) => {
          console.log(err);
          // alert('Something went wrong..!')
          // setloading(false);
          setmodaltext("Something went wrong..!");
          modalopen.current.show();
        }
      );
    } else {
      // setloading(false);
      // alert("This email doesn't exists..!");
      setmodaltext("This email doesn't exists..!");
      modalopen?.current?.show();
    }
  };

  if (!loading)
    return (
      <div className={styles.body}>
        <Header />
        <div className={styles.loginBg}>
          <div className={styles.arrowImgMain}>
            <div className={styles.signLogRightLine}>
              <img src={Images.signLogRightLine} />
            </div>
            <div className="container">
              <div className={`row ${styles.signUpInfo}`}>
                <div className={`col-sm-12 col-md-6 ${styles.leftInfo}`}>
                  <img src={Images.MuniaMiddle} alt="" />
                  <h5>Forget Password</h5>
                  <p>Log in with one of the options below</p>
                  <div className={styles.socialIcon}>
                    <a href="#">
                      <img src={Images.googleSocial} />
                    </a>
                    {/* <a href="#">
                  <img src={Images.facebook} />
                </a> */}
                  </div>
                </div>
                <div className={`col-sm-12 col-md-6 ${styles.rightInfo}`}>
                  <form action="#">
                    <div className={styles.wrapdiv}>
                      <label for="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        onChange={(event) => {
                          console.log(event.target.value);
                          setemail(event.target.value);
                        }}
                        required
                      />
                    </div>
                    {/* <div className={styles.wrapdiv}>
                  <label for="psw">Password</label>
                  <input
                    type="password"
                    name="psw"
                    onChange={(event) => {
                      userlogin.password = event.target.value;
                      setuserlogin({ ...userlogin });
                    }}
                    required
                  />
                </div> */}
                    {/* <div className={styles.forgerPass}>
                  <a href="#">Forgot Password?</a>
                </div> */}
                    <div className={styles.btndiv}>
                      <button
                        type="submit"
                        className={styles.btnFill}
                        onClick={(e) => {
                          e.preventDefault();
                          var emailcheck =
                            /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

                          if (!emailcheck.test(email)) {
                            setmodaltext("Invalid Email Id");
                            modalopen.current.show();
                          } else {
                            sendforgetPassowrd();
                          }
                        }}
                      >
                        Send
                      </button>
                    </div>
                    {/* <div className={styles.againreg}>
                  <p>Don’t have an account?</p> <a href="/signUp"> Register</a>
                </div> */}
                  </form>
                </div>
              </div>
            </div>
            <div className={styles.signBgLeft}>
              <img src={Images.signupbg} />
            </div>
          </div>
        </div>
        <NoDraftModal ref={modalopen} text={modaltext} />
        <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} Click={()=>{navigate("/login")}} />
      </div>
    );
  else
    return (
      <div className={styles.body}>
        <div className={styles.LoadingMain}
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
        </div>
      </div>
    );
};

export default ForgetPassword;
