import { findByLabelText } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import StoryFlow from "../../component/StoryFlows";
import { useNavigate, useLocation } from "react-router-dom";
import Images from '../../component/Images';

export default () => {
  const location = useLocation();
  const navigate=useNavigate();
  const [loading,setloading]=useState(true)
  const [stroynum, setstroynum] = useState(0);
  const [stroyitem, setstroyitem] = useState({});
  const [insidedata, setinsidedata] = useState([]);
  useEffect(() => {
    fatch_data();
  }, [stroynum,stroyitem,insidedata,loading,location?.state?.item]);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
       navigate("/myAccount")
      } 
    }
  }, []);

  const fatch_data = async () => {
    // console.log(location?.state?.item,"======location?.state?.itemlocation?.state?.item")
    if (location?.state?.item!=undefined) {
      setstroyitem(location?.state?.item);
      setstroynum(location?.state?.index + 1);
      setinsidedata(location?.state?.item?.attributes?.story_flow);
    }
    setloading(false)
  };

  if (!loading)
    return (
      <div className={styles.body}>
        <Header />
        <StoryFlow stroynum={stroynum} stroyitem={stroyitem} insidedata={insidedata} setinsidedata={setinsidedata} setloading={setloading} />
      </div>
    );
  else
    return (
      <div className={styles.body}>
        <div className={styles.LoadingMain}
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
        </div>
      </div>
    );
};

// export default LandingPage;
