import React from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import { useNavigate } from "react-router-dom";

function MyAccount_com({ Userdata }) {
  const navigate = useNavigate();
  const static_data = [
    {
      title: "My Story Adventures",
      img: Images.account_1,
      click: () => {
        navigate("/MyStoryFlows");
      },
    },
    {
      title: "My Submitted Stories",
      img: Images.account_2,
      click: () => {
        navigate("/MySubmission");
      },
    },
    // {
    //   title: "My Published Stories",
    //   img: Images.account_3,
    //   click: () => {
    //     navigate("/MyStories");
    //   },
    // },
    {
      title: "My Story Packs",
      img: Images.account_4,
      click: () => {
        navigate("/MyStoryKits");
      },
    },
    {
      title: "Guppy Avatar",
      img: Images.Userimg,
      click: () => {
        navigate("/myavtar");
      },
    },
    {
      title: "Edit Profile",
      img: Images.account_6,
      click: () => {
        navigate("/Edit_Profile");
      },
    },
  ];
  const static_datatrwo = [
    {
      title: "My Story Adventures",
      img: Images.account_1,
      click: () => {
        navigate("/MyStoryFlows");
      },
    },
    {
      title: "My Submitted Stories",
      img: Images.account_2,
      click: () => {
        navigate("/MySubmission");
      },
    },
    {
      title: "My Published Stories",
      img: Images.account_3,
      click: () => {
        navigate("/MyStories");
      },
    },
    {
      title: "My Story Packs",
      img: Images.account_4,
      click: () => {
        navigate("/MyStoryKits");
      },
    },
    {
      title: "Guppy Avatar",
      img: Images.Userimg,
      click: () => {
        navigate("/myavtar");
      },
    },
  ];

  return (
    <div className="container">
      <div className={`row  ${styles.accountmain_bottom}`}>
        {Object.keys(Userdata).length != 0
          ? static_data.map((e, i) => {
              return (
                <div
                  className={`col-sm-12 col-md-12 col-lg-6 ${styles.account_bottom_info}`}
                  onClick={e.click}
                  key={i}
                >
                  <img src={e.img} />
                  <a href="#">
                    <span>{e.title}</span>
                  </a>
                </div>
              );
            })
          : static_datatrwo.map((e, i) => {
              return (
                <div
                  className={`col-sm-12 col-md-12 col-lg-6 ${styles.account_bottom_info}`}
                  onClick={e.click}
                  key={i}
                >
                  <img src={e.img} />
                  <a href="#">
                    <span>{e.title}</span>
                  </a>
                </div>
              );
            })}
      </div>
      <div src={styles.MenuCircularImgBottom}>
        <img
          src={Images.MenuCircularImgBottom}
          style={{
            position: "absolute",
            bottom: "50px",
            right: "0",
            zIndex: "-1",
          }}
        />
      </div>
      {Object.keys(Userdata)?.length != 0 && (
        <div
          className={styles.logout}
          onClick={async () => {
            localStorage.clear();
            navigate("/home");
          }}
        >
          <a>LOGOUT</a>
        </div>
      )}
    </div>
  );
}

export default MyAccount_com;
