import React from "react";
import Images from "./Images";
import styles from '../pages/Home/landingpage.module.css';
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { useNavigate } from "react-router-dom";


function ModalEditSubmission_Draft() {
  const navigate=useNavigate();
  return (
        <div className={Modalstyle.ModalEditSubmission_DraftInner}>
            <h2>Story 1</h2>
            <p>You can edit your stories/ingredients only in Guppy Mobile App. You can login to your profile in Guppy App to continue editing. <span> Or </span> Don’t have Guppy Mobile APP? <br/> Download Now!</p>
            <button onClick={()=>{navigate("/GuppyAppDownload");}}>Download the app</button>
        </div>
  );
}

export default ModalEditSubmission_Draft;
