import React from "react";
import { useState } from "react";
import Modal from 'react-modal';
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import Images from "./Images";
import styles from '../pages/Home/landingpage.module.css';
import ModalEditSubmission_Draft from './SubmissionModal';
import * as moment from 'moment'


function Submission_Submitted({type,item,index}) {

// modal
const [modalIsOpen, setIsOpen] = useState(false);
function openModal() {
setIsOpen(true);
}
function closeModal() {
setIsOpen(false);
}
// Modal

  return (
        <div className={styles.MySubmissionInfoDraft}>
           {type==undefined&&<div className={`${styles.MySubmissiondetail} ${index%2!=0&&styles.leftalign}`}>
                <a href="#" className={styles.MySubmissionInfoInner}>
                    <a className={styles.EditInfoSubmission} onClick={openModal}><img src={Images.editpencilBlue} /></a>
                    <h2>{index+1}</h2>
                    <span>LAST EDITED STORY</span>
                    <p>{moment(item.attributes.createdAt).format("Do MMMM,YYYY")}</p>
                    <div className={styles.progressdivsubmission}><p>IN PROGRESS...</p></div>
                </a>
            </div>}
            {type!=undefined&&<div className={`${styles.MySubmissiondetail} ${index%2!=0&&styles.leftalign}`}>
                <a href="#" className={styles.MySubmissionInfoInner}>
                    <a className={styles.EditInfoSubmission}  onClick={openModal}><img src={Images.editpencil} /></a>
                    <h2>{index+1}</h2>
                    <span>LAST EDITED STORY</span>
                    <p>{moment(item.attributes.createdAt).format("Do MMMM,YYYY")}</p>
                    <div className={styles.progressdivsubmission}><p>IN PROGRESS...</p></div>
                </a>
            </div>}
            {/* <div className={styles.MySubmissiondetail}>
                <a href="#" className={styles.MySubmissionInfoInner}>
                    <a className={styles.EditInfoSubmission} onClick={openModal}><img src={Images.editpencilBlue} /></a>
                    <h2>3</h2>
                    <span>LAST EDITED STORY</span>
                    <p>11th October, 2021</p>
                    <div className={styles.progressdivsubmission}><p>IN PROGRESS...</p></div>
                </a>
            </div> */}
            <Modal style={{ content:{display:"flex",backgroundColor:"#0000",borderWidth:0,flexDirection:"column",flex:1,position:"unset", height: "100%"}}} overlayClassName={Modalstyle.modal} isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
                <div className="container">
                    <div className={Modalstyle.ModalEditSubmission_Draft}>
                        <div className={styles.backbtn}>
                            <a href='#'><img src={Images.navBannerleft}  onClick={closeModal}/></a>
                        </div>
                        <ModalEditSubmission_Draft />
                    </div>
                </div>
            </Modal>
        </div>
  );
}

export default Submission_Submitted;
