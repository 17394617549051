import { findByLabelText } from "@testing-library/react";
import React, { useEffect } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import MyStories from "../../component/MyStories";
import Images from '../../component/Images';
import { api } from "../../ApiService/Apicall";
import { useQuery } from "@tanstack/react-query";
import { getLocal, useMystory } from "../../otherexports";
export default () => {
  const { data,filterStorys,isLoading,Ingridient,userinfo} = useMystory()
  if (!isLoading)
{ 
  
  // console.log(JSON.stringify(data),"===filterStorys=====")
    return (
      <div className={styles.body}>
        <Header/>
        <MyStories Storys={filterStorys} Ingridient={Ingridient}  userinfo={userinfo}/>
      </div>
    )}
  else
    return (
      <div className={styles.body}>
      <div className={styles.LoadingMain}
        style={{
          alignItems: "center",
          backgroundColor: "#F2DD4E",
          top: 0,
          left: 0,
          bottom: 0,
          position: "absolute",
          justifyContent: "center",
          flex: 1,
          flexDirection: "column",
          display: "flex",
          right: 0,
        }}
      >
        <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
      </div>
    </div>
    );
};

// export default LandingPage;
