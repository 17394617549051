import React from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import { useNavigate } from "react-router-dom";
function Nav({ hide }) {
  const navigate = useNavigate();
  console.log("styles", styles);

  return (
    <div className={styles.header_section}>
      <div className={styles.navheader}>
        <img className={styles.navheaderBgImg} src={Images.CurveBgheader} />
        <div className="container-fluid">
          <nav className="navbar">
            {hide == undefined && (
              <button
                className={styles.navbarToggler}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => {
                  navigate("/BackstageMenu");
                }}
                style={{ cursor: "pointer" }}
              >
                <span className={styles.navbarTogglerIcon}>
                  <img src={Images.hamburger} alt="hamburger" />
                </span>
              </button>
            )}
            {/* {hide != undefined && <div className={styles.headerGs}></div>} */}
            {/* <div className={`${styles.headerGs} ${styles.headerGsNew}`}>
              <a href="/home">
                <img src={Images.HeaderGsStories} />
              </a>
            </div> */}
            <a
              className={styles.navbarBrand}
              href="/home"
              style={{ margin: "auto" }}
            >
              <img src={Images.logoBackstageStories} alt="LOGO" />
            </a>
            {/* <div className={styles.headerGb}>
              <a href="/LandingPageBs">
                <img src={Images.BackstageHeaderActive} />
              </a>
            </div> */}
            {/* <div className={styles.headerContact}>
              <a
                onClick={() => {
                  navigate("/myAccount");
                }}
              >
                <img src={Images.contact} alt="contact" />
              </a>
            </div> */}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Nav;
