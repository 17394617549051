import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import BannerBs from "../Component/BannerBs";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import CurrentIps from "../Component/CurrentIps";
import NewsLetterBS from "../Component/NewsLetterBS";
import GuppyStoriesBackstage from "../Component/GuppyStoriesBackstage";
import FooterBs from "../Component/FooterBs";
import { useBackstagehome } from "../../otherexports";
import Loader from "../Component/Loader";
import { useNavigate } from "react-router-dom";
import MetaTags from "react-meta-tags";

function LandingPageBs() {
  const { isLoading, home } = useBackstagehome();
  const navigate = useNavigate();
  if (!isLoading) {
    console.log(
      JSON.stringify(home?.attributes?.banner),
      "==========home====home"
    );
    return (
      <section className={BsCustom.WrapperMain}>
        <MetaTags>
          <title>Backstage Home - Guppy Theater</title>
          <meta
            name="description"
            content="Guppy Theatre is a Transmedia storytelling company that develops IPs with children that entertain as well as foster creativity and collaboration"
          />
          <meta
            name="keywords"
            content="animation, games, IP development, story world, children, transmedia story telling"
          />
        </MetaTags>
        <HeaderBackstageNew />
        <BannerBs data={home?.attributes?.banner} />
        <section className={BsCustom.WTGBSection}>
          <div className="container-fluid">
            <h2 className={BsCustom.BsH2}>
              <span>Children are centre stage</span>
            </h2>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <p className={BsCustom.BsCommaonP}>
                  {home?.attributes?.welcome_text}
                </p>
                <a className={BsCustom.ReadMoreCmn} href="/WhoWeAre">
                  <span>Read More </span>
                  <img src={Images.ReadMoreImg} />
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <img
                  src={
                    "https://manage.guppytheatre.com" +
                    home?.attributes?.welcome_media?.data?.attributes?.url
                  }
                />
              </div>
            </div>
          </div>
        </section>
        <section className={BsCustom.WhoWeAreSection}>
          <div className="container-fluid">
            <h2 className={BsCustom.BsH2}>Our IP's & Storyscapes</h2>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WhoWeAreleft}`}>
                <img
                  src={
                    "https://manage.guppytheatre.com" +
                    home?.attributes?.who_we_are_media?.data?.attributes?.url
                  }
                />
              </div>
              <div className={`col-md-6 ${BsCustom.WhoWeAreRight}`}>
                <p className={BsCustom.BsCommaonP}>
                  {home?.attributes?.who_we_are_text}
                </p>
                <a className={BsCustom.ReadMoreCmn} href="/WhatWeDo">
                  <span>Read More </span>
                  <img src={Images.ReadMoreImg} />
                </a>
              </div>
            </div>
          </div>
        </section>
        <CurrentIps />
        <NewsLetterBS />
        {/* <section className={BsCustom.NewsSection}>
                    <div className="container-fluid">
                        <h2 className={BsCustom.BsH2}>News</h2>
                        <div className="row">
                            <div className={`col-12 ${BsCustom.NewsSectionLeft}`}>
                                <p className={BsCustom.BsCommaonP}>{home?.attributes?.news_text}</p>
                                <button className={BsCustom.BsComBtn} onClick={()=>{navigate('/News')}}>See all news</button>
                            </div>
                        </div>
                    </div>
                </section> */}
        <GuppyStoriesBackstage home={home?.attributes} navigate={navigate} />
        <FooterBs />
      </section>
    );
  } else {
    return <Loader />;
  }
}

export default LandingPageBs;
