import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import GuppyStoriesBackstage from "../Component/GuppyStoriesBackstage";
import BannerCommon from "../Component/BannerCommon";
import SellProductBs from "../Component/SellProductBs";
import { useBackstagewhatwesell } from "../../otherexports";
import Loader from "../Component/Loader";


function WhatWeSell(){
    const {data,isLoading}=useBackstagewhatwesell();
    console.log(JSON.stringify(data),"=======psk===")
    if(!isLoading){
        return(
            <section className={BsCustom.WrapperWhatWeSell}>
                <HeaderBackstageNew />
                <BannerCommon/>
                <div className={BsCustom.TeamLightBCGroung}>
                    <div className="container-fluid">
                        <h1 className={BsCustom.BsH1}>What We Sell</h1>
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-12 ${BsCustom.WhoWeAreRight}`}>
                                <p className={BsCustom.BsCommaonP}>
                                    {data?.attributes?.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <SellProductBs/>
                <GuppyStoriesBackstage/>
                <FooterBs/>
            </section>
        ); 
    }
    else 
    {return (<Loader />)}
}

export default WhatWeSell;