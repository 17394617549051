import React, { useEffect, useState } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import Logining from "../../component/Login";
import Images from '../../component/Images';

// import { firebaseConfig } from "../../ApiService/firebase";
// import { initializeApp } from "firebase/app";
// import { GoogleLogin } from 'react-google-login';

const Login = () => {
const [loading,setloading]=useState(true)
  useEffect(() => {
      setloading(false);
  },[]);

  if (!loading)
  return (
    <div className={styles.body}>
      <Header />
      <Logining />
    </div>
  );
  else
  return (
    <div className={styles.body}>
    <div className={styles.LoadingMain}
      style={{
        alignItems: "center",
        backgroundColor: "#F2DD4E",
        top: 0,
        left: 0,
        bottom: 0,
        position: "absolute",
        justifyContent: "center",
        flex: 1,
        flexDirection: "column",
        display: "flex",
        right: 0,
      }}
    >
     <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
    </div>
  </div>
  );
};

export default Login;
