import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../pages/Home/landingpage.module.css";
import { logout } from "../redux/userdata/user.actions";
import Images from "./Images";
import MenuMobileNotLogin from "./MenuMobileNotLogin";
import Story from "./Story";

function Menu({ Userdata,setIsOpen2 }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logouting=()=>{
    dispatch(
      logout({
        onSuccess: (res) => {
          navigate("/home");
        }
      }))
  }

  return (
    <div className="row" style={{ margin: "0",overflowY:"scroll",height:"100vh" }} id={styles.MainMenu}>
      <div style={{ padding: 0,backgroundColor:"#00000066", display: "flex", flexDirection: "row" }} className="col-sm-12 col-md-12">
        <div className={styles.menuMain} style={{width:"75%"}} >
          <div
            className="container-fluid"
            style={!matches ? { overflow: "hidden", paddingTop: 15 } : {}}
          >
            <div className={styles.backbtn} onClick={()=>{setIsOpen2(false)}} style={{cursor:"pointer",zIndex:999}}>
              <a >
                <img src={Images.backbtncross} />
              </a>
            </div>
            <div className={styles.menuMainHead}>
             {Object.keys(Userdata)?.length == 0 && <h3 style={{cursor:"pointer"}} onClick={()=>{navigate('/LandingPageBs')}}>
                Guppy Theatre Backstage <img src={Images.uppperarrow} />
              </h3>}

              <div className="container">
                {Object.keys(Userdata).length != 0 ? (
                  <div className={styles.menuMainhead_inner}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <img src={Images.avtarMain} />
                      <span>
                        <div className={styles.menunewdivmid}
                          style={{ maxWidth: "45%", display: "inline-block" }}
                        >
                          <strong>Hi! {Userdata.username}, </strong>
                        </div>{" "}
                        {Userdata.age} years old | <b>User ID: {Userdata.id}</b>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={styles.menuNoLogin}>
                    {matches && <Story logindata={Userdata} hide={true} />}
                  </div>
                )}
              </div>
            </div>
            {!matches && Object.keys(Userdata).length == 0 && (
              <div style={{ width: "100%" }}>
                <MenuMobileNotLogin matches={matches} hide={true} navigate={navigate} />
              </div>
            )}
            <img
              className={styles.MAinMEnudashedLineYellow}
              style={{ width: "100%", marginTop: "-150px" }}
              src={Images.dashedLineYellow}
            />
            <div className={styles.menuMainInner}>
              <div className={styles.menuMainInnerImg}>
                <img
                  src={Images.storyAdventure}
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={Images.rans}
                  onClick={() => {
                    navigate("/CreateStory");
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <a href="/myAccount">
                My Account <img src={Images.contactMenu} />
              </a>
              <div className={styles.menubtn}>
                <a href="/FaqMain">
                  FAQs <img src={Images.menuQestion} />
                </a>
                <a href="/contact">
                  Contact us <img src={Images.menuPhone} />
                </a>
              </div>
            </div>
            {Object.keys(Userdata)?.length != 0 && (
              <>
                <div className={styles.menuMainHead}>
                  <h3 style={{cursor: "pointer"}}  onClick={() => {
                    navigate("/LandingPageBs");
                  }}>
                    Guppy Theatre Backstage <img src={Images.uppperarrow} />
                  </h3>
                </div>

                <a href="#" className={styles.logoutMenu} onClick={()=>{logouting();}}>
                  LOGOUT
                </a>
                </>
            )}
                <div className={styles.logoutMenuBottomNew}>
                  <a className={styles.logoutMenuBottoma} href="/PrivacyPolicy">
                    Privacy Policy
                  </a>
                  <a
                    className={styles.logoutMenuBottoma}
                    href="/TermsConditions"
                  >
                    Terms and Conditions
                  </a>
                </div>
             
          </div>
        </div>
        <div style={{width:"25%", background: "#0000"}} onClick={()=>{setIsOpen2(false)}}></div>
      </div>
      {/* <div style={{ padding: 0 }} className="col-sm-12 col-md-3">
        <div style={{ background: "rgba(0, 0, 0, 0.6)", height: "100%" }}></div>
      </div> */}
    </div>
  );
}

export default Menu;
