import React, { useState, useMemo, useRef, useEffect, createRef } from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";
import TinderCard from "react-tinder-card";
import Header from "./Header";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import Carousel from "better-react-carousel";
import { getLocal, useAvtar, useGetdata } from "../otherexports";
import MuniaModal from "./MuniaModal";
import moment from "moment/moment";
import api from "../ApiService/Apicall";
import _ from "lodash";
import NoDraftModal from "./NoDraftModal";
import SubmittedSuccessfullyModal from "./SubmittedSuccessfullyModal";
import { useNavigate } from "react-router-dom";
import RagisterFirstModal from "./RagisterFirstModal";
function CreateaStory() {
  const Registermodal = createRef(null);
  const navigate = useNavigate();
  const userinfo = getLocal("User");
  const [modaltext, setmodaltext] = useState("");
  const modalopentwo = createRef(null);
  const modalopen = createRef(null);
  const { isLoading: otherloading, data: Avtardata } = useAvtar();
  const { isLoading, Packagelist, ingridentlist } = useGetdata();
  const birdmodal = createRef(null);
  const [birdvisiblity, setbirdvisiblity] = useState(true);
  const [rotateting, setrotateting] = useState(0);
  const [selectedpack, setselectedpack] = useState([1, 2, 3]);
  const [static_data, setstatic_data] = useState([
    {
      img: Images.SelectIngredients,
      title: <img src={Images.StoryDashed} />,
      btn: "Find ingredient",
      topBtn: "Who",
      des: "Who is in your story?",
      selected: null,
      index: 0,
    },
    {
      img: Images.SelectIngredients,
      title: <img src={Images.StoryDashed} />,
      btn: "Find ingredient",
      topBtn: "Where",
      des: `Where is your story taking\n place?`,
      selected: null,
      index: 1,
    },
    {
      img: Images.SelectIngredients,
      title: <img src={Images.StoryDashed} />,
      btn: "Find ingredient",
      topBtn: "When",
      des: `When does your story\n happen?`,
      selected: null,
      index: 2,
    },
    {
      img: Images.SelectIngredients,
      title: <img src={Images.StoryDashed} />,
      btn: "Find ingredient",
      topBtn: "What",
      des: "What is in your story?",
      selected: null,
      index: 3,
    },
  ]);
  const [currentindi, setcurrentindi] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(Packagelist?.length - 1);
  const currentIndexRef = useRef(currentIndex);
  const childRefs = useMemo(
    () =>
      Array(Packagelist?.length)
        .fill(0)
        .map((i) => React.createRef()),
    [Packagelist]
  );
  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex >= 0;
  const swiped = async (direction, nameToDelete, index) => {
    updateCurrentIndex(index - 1);
  };

  const outOfFrame = (name, idx) => {
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    if (idx === 0) {
      for (let i = 0; i <= Packagelist.length; i++) {
        childRefs[i].current.restoreCard();
      }
    }
  };

  const swipe = async (dir) => {
    if (currentIndex == -1) {
      setCurrentIndex(Packagelist?.length - 2);
      await childRefs[Packagelist?.length - 1]?.current?.swipe(dir);
    }
    if (canSwipe && currentIndex < Packagelist?.length) {
      await childRefs[currentIndex]?.current?.swipe(dir);
    }
  };

  const rotating = (i) => {
    if (i == Packagelist.length - 1) {
      return "0deg";
    }
    if (i % 2 === 0) {
      return "10deg";
    } else {
      return "1deg";
    }
  };
  const translate = (i) => {
    if (i === Packagelist.length - 1) {
      return "0";
    }
    if (i % 2 === 0) {
      return "8px";
    } else {
      return "0px";
    }
  };

  // modal
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // Modal

  const packageitemchange = (i) => {
    let info = selectedpack.filter((e, n) => i != n);
    info.unshift(2);
    setselectedpack([...info]);
  };

  const filteringridinet = (Ingidient, text) => {
    const selectedindi = selectedpack
      .filter((n) => typeof n == "object")
      .map((e) => {
        return e.attributes.ingredients.data.map((n) => {
          return n.id;
        });
      })
      ?.join("")
      ?.split(",");
    let info;
    if (text != undefined) {
      info = text;
    } else {
      info = currentindi?.topBtn;
    }
    return Ingidient?.filter(
      (e) =>
        e?.attributes?.ingredient_type_id?.data?.attributes?.TypeName ===
          info && selectedindi?.includes(e?.id?.toString())
    );
  };

  const postdraft = async (data) => {
    let info = {
      Title: userinfo?.name + "_Story_" + moment().format("DD-MM-YY:HH:MM"),
      how_text: "",
      Status: "Draft",
      users_id: userinfo?.id?.toString(),
      packages: selectedpack
        .filter((e) => typeof e == "object")
        .map((e) => {
          return e.id;
        }),
    };

    if (data?.filter((e) => e.topBtn == "What")[0]?.selected?.id != undefined) {
      info.what_ingredient = data
        .filter((e) => e.topBtn == "What")[0]
        .selected.id.toString();
    }
    if (data?.filter((e) => e?.topBtn == "Who")[0]?.selected?.id != undefined) {
      info.who_ingredient = data
        .filter((e) => e.topBtn == "Who")[0]
        .selected.id.toString();
    }
    if (data?.filter((e) => e.topBtn == "When")[0]?.selected?.id != undefined) {
      info.when_ingredient = data
        .filter((e) => e.topBtn == "When")[0]
        .selected.id.toString();
    }
    if (
      data?.filter((e) => e.topBtn == "Where")[0]?.selected?.id != undefined
    ) {
      info.where_ingredient = data
        .filter((e) => e.topBtn == "Where")[0]
        .selected.id.toString();
    }
    if (
      data?.filter((e) => e?.topBtn == "How/Why")[0]?.selected?.id != undefined
    ) {
      info.how_why_ingredient = data
        .filter((e) => e.topBtn == "How/Why")[0]
        .selected.id.toString();
    }
    let infotwo = { data: info };
    //   console.log(JSON.stringify(infotwo))
    const response = await api("post", "story-submissions", infotwo, "psk");
    if (response.data != null) {
      // alert("Your story saved successfully!");
      setmodaltext("Story saved as draft ");
      modalopentwo.current.show();
    } else {
      setmodaltext("Something went wrong !");
      modalopen.current.show();
      // alert("Something went wrong !");
    }
  };

  if (!isLoading && !otherloading) {
    return (
      <section className={styles.createStorySection}>
        <Header />
        <div className={styles.createStoryTop}>
          {birdvisiblity && (
            <img
              className={styles.MuniaMiddle}
              style={{ zIndex: 999 }}
              src={
                !!userinfo?.name
                  ? "https://manage.guppytheatre.com" +
                    Avtardata?.attributes?.guppy_avtar?.data?.attributes
                      ?.avtar_media.data?.attributes?.url
                  : "https://manage.guppytheatre.com" +
                    Avtardata?.attributes?.avtar_media.data?.attributes?.url
              }
              onClick={() => {
                setbirdvisiblity(false);
                birdmodal.current.show();
              }}
            />
          )}
          <div className="container">
            <div className={styles.createStoryTopMaintext}>
              <h2>Create New story</h2>
              <p style={{ fontWeight: "bold" }}>
                {"1. Select a Pack for \n your Story"}
              </p>
            </div>
            <div className={styles.createStorySwiperInfo}>
              <div
                className={styles.swiperSectionInfo_inner}
                style={{
                  margin: "auto 30%",
                  height: "30em",
                }}
              >
                {!!Packagelist &&
                  Packagelist?.map((cardData, index) => {
                    return (
                      <div
                        className={styles.swiperSection_InfoDe}
                        style={{
                          zIndex: index,
                          position: "absolute",
                          rotate: rotating(index),
                          translate: translate(index),
                        }}
                        key={index}
                      >
                        <TinderCard
                          ref={childRefs[index]}
                          className="swipe"
                          key={cardData?.id}
                          onSwipe={(dir) => swiped(dir, cardData?.id, index)}
                          onCardLeftScreen={() =>
                            outOfFrame(cardData?.id, index)
                          }
                          swipeThreshold={900}
                          swipeRequirementType={"velocity"}
                          preventSwipe={["up", "down"]}
                        >
                          <div className={styles.swiperSlide}>
                            <div
                              className={styles.cardNew}
                              style={{ border: 0 }}
                            >
                              <div className={styles.cardHeader}>
                                <img
                                  src={
                                    "https://manage.guppytheatre.com" +
                                    cardData?.attributes?.PackageImage?.data
                                      ?.attributes?.url
                                  }
                                  alt="image"
                                />
                              </div>
                              <div className={styles.cardFooter}>
                                <span
                                  className={styles.cardfooterName}
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {cardData?.attributes?.Name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </TinderCard>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.swiperNav}>
                <button
                  className={styles.swiperPrev}
                  onClick={() => swipe("left")}
                  style={{ outline: "none", cursor: "pointer" }}
                >
                  <img src={Images.backbtn} alt="" />
                </button>
                <button
                  className={styles.swiperNext}
                  onClick={() => swipe("right")}
                  style={{ outline: "none", cursor: "pointer" }}
                >
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    src={Images.backbtn}
                    alt=""
                  />
                </button>
              </div>
              <div className={styles.swiperNavtwo}>
                <button
                  className={styles.swiperPrev}
                  onClick={() => swipe("left")}
                  style={{ outline: "none", cursor: "pointer" }}
                >
                  <img src={Images.backbtn} alt="" />
                </button>
                <button
                  className={styles.swiperNext}
                  onClick={() => swipe("right")}
                  style={{ outline: "none", cursor: "pointer" }}
                >
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    src={Images.backbtn}
                    alt=""
                  />
                </button>
              </div>
            </div>
            <button
              className={styles.btnFill}
              style={{ padding: "8px 25px" }}
              onClick={(event) => {
                event.preventDefault();
                if (
                  selectedpack.filter((n) => typeof n == "object").length != 3
                ) {
                  if (currentIndex == -1) {
                    if (
                      selectedpack.filter(
                        (e) => e?.id == Packagelist[Packagelist.length - 1]?.id
                      ).length != 0
                    ) {
                      setmodaltext("Already selected package !");
                      modalopen.current.show();
                    } else {
                      selectedpack.shift();
                      selectedpack.push(Packagelist[Packagelist.length - 1]);
                      setselectedpack([...selectedpack]);
                      swipe("left");
                    }
                  } else {
                    if (
                      selectedpack.filter(
                        (e) => e?.id == Packagelist[currentIndex].id
                      ).length != 0
                    ) {
                      // alert("Already selected package !");
                      setmodaltext("Already selected package !");
                      modalopen.current.show();
                    } else {
                      selectedpack.shift();
                      selectedpack.push(Packagelist[currentIndex]);
                      setselectedpack([...selectedpack]);
                      swipe("left");
                    }
                  }
                } else {
                  // alert("Max 3 Package can selected");
                  setmodaltext("Max 3 Package can selected");
                  modalopen.current.show();
                }

                // console.log(selectedpack);
              }}
            >
              Pick this Package
            </button>
            <div className={styles.moreStory}>
              {selectedpack
                .map((e, i) => {
                  if (typeof e != "object") {
                    return (
                      <div className={styles.moreStoryInner} key={i}>
                        <img
                          className={styles.moreStoryInnerMainNew}
                          src={Images.EmptyBoxStory}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div className={styles.moreStoryInner} key={i}>
                        <img
                          className={styles.moreStoryInnerMainNew}
                          src={
                            "https://manage.guppytheatre.com" +
                            e?.attributes?.PackageImage?.data?.attributes?.url
                          }
                          style={{
                            borderRadius: 80,
                            width: 214,
                            height: 214,
                            borderRadius: 40,
                          }}
                        />
                        <img
                          className={styles.RedCross}
                          src={Images.RedCross}
                          onClick={() => {
                            packageitemchange(i);
                          }}
                        />
                      </div>
                    );
                  }
                })
                .reverse()}
            </div>
          </div>
        </div>
        <div className={styles.createStoryMid}>
          <div className={styles.dashedLine}>
            <img src={Images.dashedLine} alt="" />
          </div>
          <p
            className={styles.newparaCreateastory}
            style={{
              fontWeight: "bold",
              fontSize: "36px",
              lineHeight: "54px",
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            {"2) Now let’s build your story!"}
          </p>
          <div className="container">
            <div className={styles.createStoryMidInner}>
              {static_data.map((e, i) => {
                return (
                  <div className={styles.createMidInnerInfo}>
                    <button className={styles.storyBtn}>{e.topBtn}</button>
                    <p>{e.des}</p>
                    {e.selected == null && (
                      <>
                        <img
                          className={styles.createMidInnerMainImg}
                          src={e.img}
                        />
                        <span style={{ paddingBottom: "6%" }}>{e.title}</span>
                      </>
                    )}
                    {e.selected != null && (
                      <>
                        <img
                          className={styles.createMidInnerMainImg}
                          src={
                            "https://manage.guppytheatre.com" +
                            e?.selected?.attributes?.IngredientImage?.data
                              ?.attributes?.url
                          }
                        />
                        <span>{e?.selected?.attributes?.IngredientName}</span>
                      </>
                    )}
                    <div className={styles.findIngredients}>
                      <img
                        src={Images.RefreashStory}
                        onClick={() => {
                          if (
                            selectedpack.filter((n) => typeof n == "object")
                              .length > 0
                          ) {
                            setrotateting(i);
                            // setTimeout(()=>{},1000)
                            const datalist = filteringridinet(
                              ingridentlist,
                              e?.topBtn
                            );
                            static_data[i].selected =
                              datalist[_.random(0, datalist?.length - 1)];
                            setstatic_data([...static_data]);
                          } else {
                            setmodaltext("Please select package..!");
                            modalopen.current.show();
                          }
                        }}
                        // className={[rotateting==i &&styles.rotating]}
                        // style={[rotateting==i &&{transform:[{rotate:"360deg"}]}]}
                      />
                      <button
                        onClick={() => {
                          if (
                            selectedpack.filter((n) => typeof n == "object")
                              .length > 0
                          ) {
                            setcurrentindi(e);
                            openModal();
                          } else {
                            // alert("Please select package..!");
                            setmodaltext("Please select package..!");
                            modalopen.current.show();
                          }
                        }}
                        className={styles.btnFill}
                      >
                        {e.btn}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.createStoryBottom}>
          <div className={styles.dashedLine}>
            <img src={Images.dashedLine} alt="" />
          </div>
          <div className="container">
            <p>
              {/* Customize your own 4 W’s with <br /> different customization
              options! */}
              Save your 4 W’s and download the app <br /> to record your story!
            </p>
            <div className={styles.createStoryBottomBtn}>
              <button
                className={styles.btnUnfill}
                onClick={() => {
                  if (userinfo.name == undefined) {
                    Registermodal?.current?.show();
                  } else {
                    if (
                      selectedpack.filter((n) => typeof n == "object").length ==
                      0
                    ) {
                      // alert("Please select a\n Kit to add an\n ingredient.")
                      setmodaltext(
                        "Please select a\n Kit to add an\n ingredient."
                      );
                      modalopen.current.show();
                    } else if (
                      static_data?.filter((e) => e.selected != null).length < 4
                    ) {
                      // alert("Please select empty ingredients !");
                      setmodaltext("Please select empty ingredients !");
                      modalopen.current.show();
                    } else {
                      postdraft(static_data?.filter((e) => e.selected != null));
                    }
                  }
                }}
              >
                Save
              </button>
              {/* <button className={styles.btnFill}>Download the app</button> */}
            </div>
            <div className="Container">
              <p style={{ fontWeight: "bold", marginBottom: "15px" }}>
                Download the app
              </p>
            </div>
            <div className={styles.createStoryBottomShare}>
              <span>Available on</span>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.guppytheatre.app"
                  target="_blank"
                >
                  <img src={Images.googleIcon} />
                </a>
                <a href="https://apps.apple.com/in/app/guppy-stories/id6450250856">
                  <img src={Images.appledownload} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <NoDraftModal ref={modalopen} text={modaltext} />
        <SubmittedSuccessfullyModal
          ref={modalopentwo}
          text={modaltext}
          Click={() => {
            navigate(-1);
          }}
        />
        <RagisterFirstModal ref={Registermodal} />
        <MuniaModal
          ref={birdmodal}
          birdshow={() => {
            setbirdvisiblity(true);
          }}
          data={Avtardata}
        />
        <Modal
          style={{
            content: {
              display: "flex",
              backgroundColor: "#0000",
              borderWidth: 0,
              flexDirection: "column",
              flex: 1,
              position: "unset",
              height: "100%",
              overflow: "auto",
            },
          }}
          overlayClassName={Modalstyle.modal}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <div className="container">
            <div className={Modalstyle.ModalStoryPicker}>
              <div className={Modalstyle.ModalStoryPickerTop}>
                <div className="container">
                  <div className={styles.backbtn}>
                    <a href="#">
                      <img src={Images.navBannerleft} onClick={closeModal} />
                    </a>
                  </div>
                  <p>Selected Package</p>
                  <div className={Modalstyle.ModalStoryPickerTopInner}>
                    {selectedpack
                      .filter((n) => typeof n == "object")
                      .map((e) => {
                        return (
                          <div
                            className={styles.createMidInnerInfo}
                            style={{ margin: "0px 16px" }}
                          >
                            <img
                              src={
                                "https://manage.guppytheatre.com" +
                                e?.attributes?.PackageImage?.data?.attributes
                                  ?.url
                              }
                              style={{
                                borderRadius: 80,
                                width: 214,
                                height: 214,
                                borderRadius: 40,
                              }}
                            />
                            <p>{e?.attributes?.Name}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className={Modalstyle.dashedLine}>
                <img src={Images.dashedLine} alt="" />
              </div>
              <div className={Modalstyle.ModalStoryPickerBottom}>
                <div className="container">
                  <button className={Modalstyle.storyBtn}>
                    {currentindi?.topBtn}
                  </button>
                  <p>
                    If you need more ingredients, read more stories or <br />{" "}
                    Download the app the create new ingredients!
                  </p>
                  <Carousel cols={4} rows={1} gap={10} loop={true}>
                    {filteringridinet(ingridentlist).map((e) => {
                      return (
                        <Carousel.Item>
                          <img
                            style={{ width: "192px" }}
                            src={
                              "https://manage.guppytheatre.com" +
                              e?.attributes?.IngredientImage?.data?.attributes
                                ?.url
                            }
                            onClick={() => {
                              const index = static_data.findIndex(
                                (e) => e?.topBtn == currentindi?.topBtn
                              );
                              static_data[index].selected = e;
                              setstatic_data([...static_data]);
                              closeModal();
                            }}
                          />
                          <p
                            style={{ fontWeight: "700", margin: "0" }}
                            onClick={() => {
                              const index = static_data.findIndex(
                                (e) => e?.topBtn == currentindi?.topBtn
                              );
                              static_data[index].selected = e;
                              setstatic_data([...static_data]);
                              closeModal();
                            }}
                          >
                            {e?.attributes?.IngredientName}
                          </p>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                  <button className={styles.btnFill} onClick={closeModal}>
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    );
  } else {
    return (
      <div className={styles.body}>
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{ width: "500px" }} src={Images.LoaderGIFweb} />
        </div>
      </div>
    );
  }
}

export default CreateaStory;
