import React, { createRef, useEffect, useState } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import { useNavigate } from "react-router-dom";
import { getLocal } from "../otherexports";
import RagisterFirstModal from "./RagisterFirstModal";
import NoDataFile from "./NoDataFile";

function MyStoryFlow({ data }) {
  const Registermodal= createRef(null);
  const navigate = useNavigate();
  const [storyflowdata, setstoryflowdata] = useState([]);
  const userinfo = getLocal("User");
  const getStory = () => {
    // let info = [];
    // for (let i = 0; i <= data?.length; i++) {
    //   if (
    //     data[
    //       i
    //     ]?.attributes?.users_permissions_user?.data?.id?.toString() ===
    //     userinfo?.id.toString()
    //   ) {
    //     info.push(data?.data[i]);
    //   }
    // }
    // console.log(JSON.stringify(data),"===data====data")
    setstoryflowdata([...data]);
  };

  useEffect(() => {
    if(userinfo.name==undefined){
      Registermodal.current.show();
    }else{
      getStory();
    }
  }, []);

  return (
    <div className={styles.MyStoryFlowMain}>
      <div className="container">
        <div
          className={styles.backbtn}
          // onClick={() => {
          //   navigate('/MyStoryFlows');
          // }}
        >
          <a
            href="/myAccount"
            // onClick={() => {
            //   navigate('/MyStoryFlows');
            // }}
          >
            <img src={Images.backbtn} />
          </a>
        </div>
        <h4>My Story Adventures</h4>
        <div className={styles.MyStoryFlowMainExpand}>
          {storyflowdata.length!=0 && storyflowdata?.map((item, index) => {
            return (
              <div
                className={styles.StoryFlowTop}
                onClick={() => {
                  navigate("/StoryFlow", {
                    state: {
                      item: item,
                      index: index,
                    },
                  });
                }}
              >
                <a
                  // href="#"
                  className={styles.MyStoryFlowInfo}
                  onClick={() => {
                    navigate("/StoryFlow", {
                      state: {
                        item: item,
                        index: index,
                      },
                    });
                  }}
                >
                  <div className={styles.MyStoryFlowInner}>
                    <h5>
                      {item?.attributes?.Story_Flow_Name == null ||
                      item?.attributes?.Story_Flow_Name == ""
                        ? "Story Flow " + (index + 1)
                        : item?.attributes?.Story_Flow_Name}
                    </h5>
                    <p>
                      Author Name:{" "}
                      {
                        item?.attributes?.users_permissions_user?.data
                          ?.attributes?.username
                      }
                    </p>
                  </div>
                  <img src={Images.StoryAroowYellowLight} />
                </a>
              </div>
            );
          })}
          {storyflowdata.length==0  && <NoDataFile />}
          <div className={styles.StoryFlowInfoBtn} onClick={()=>{navigate("/home")}}>
            <button className={styles.btnFill} onClick={()=>{navigate("/home")}}>
              <img src={Images.roadImg} />
              Start a new <br /> adventure!
            </button>
          </div>
        </div>
      </div>
    <RagisterFirstModal ref={Registermodal} />
    </div>
  );
}

export default MyStoryFlow;
