import api from "../../ApiService/Apicall";
import {
  Home_Data_Request,
  Home_Data_SUCCESS,
  Home_Data_FAILURE,
  Story_lits_Request,
  Story_lits_FAILURE,
  Story_lits_SUCCESS,
} from "./home.types";

export const home_reuest = () => {
  return {
    type: Home_Data_Request,
  };
};
export const Home_SUCCESS = (payload) => {
  return {
    type: Home_Data_SUCCESS,
    payload,
  };
};
export const Home_FAILURE = (error) => {
  return {
    type: Home_Data_FAILURE,
    payload: error,
  };
};


export const Story_Request = () => {
  return {
    type: Story_lits_Request,
  };
};
export const Story_SUCCESS = (payload) => {
  return {
    type: Story_lits_SUCCESS,
    payload,
  };
};
export const Story_FAILURE = (error) => {
  return {
    type: Story_lits_FAILURE,
    payload: error,
  };
};



//===========home_api FUNCTION=========================
export const home_api = () => {
  return async (dispatch) => {
    dispatch(home_reuest());
    try {
      const data = await api("get", "Home-apis?populate=*");
      dispatch(Home_SUCCESS(data?.data?.data));
    } catch (error) {
      dispatch(Home_FAILURE("Some Thing went wrong"));
    }
  };
};


//===========Story_list_api FUNCTION=========================

export const Story_list_api = () => {
  return async (dispatch) => {
    dispatch(Story_Request());
    try {
      const data = await api("get", "Home-apis?populate=*");
      dispatch(Story_SUCCESS(data?.data?.data));
    } catch (error) {
      dispatch(Story_FAILURE("Some Thing went wrong"));
    }
  };
};
