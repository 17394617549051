const Images = {
  logoHeader: require("../assets/images/header--logo.png"),
  hamburger: require("../assets/images/hamburger.png"),
  contact: require("../assets/images/contact-header.png"),
  Insta: require("../assets/images/insta-1.png"),
  facebook: require("../assets/images/facebook-1.png"),
  youtube: require("../assets/images/youtube-1.png"),
  footerLogo: require("../assets/images/logo.png"),
  inputRightArrow: require("../assets/images/right-arrow-1.png"),
  whtspp: require("../assets/images/whtspp-1.png"),
  inbox: require("../assets/images/inbox.png"),
  googleIcon: require("../assets/images/google-icon.png"),
  appleIcon: require("../assets/images/apple-icon.png"),
  dashedLine: require("../assets/images/dashed-line.png"),
  rans: require("../assets/images/r-a-n-s.png"),
  redCir: require("../assets/images/red-cir-2.png"),
  googleAds: require("../assets/images/Google-Ads.png"),
  disablebtn: require("../assets/images/disabled_btn.png"),
  charactername: require("../assets/images/character-name.png"),
  storytitle: require("../assets/images/story-title.png"),
  navright: require("../assets/images/nav-right.png"),
  navleft: require("../assets/images/nav-left.png"),
  bludebg2: require("../assets/images/blue-bg-2.png"),
  MuniaMiddle: require("../assets/images/Munia-Middle.png"),
  ModalMuniaBird: require("../assets/images/modalMuniaBird.png"),
  StoryPageArrow: require("../assets/images/story-page-arrow.png"),
  Sample: require("../assets/images/sample.png"),
  navBannerleft: require("../assets/images/rightarrow.png"),
  BannerBg: require("../assets/images/bg.png"),
  whtsNew: require("../assets/images/whatsnew.png"),
  signupbg: require("../assets/images/sign-up-img-bg.png"),
  googleSocial: require("../assets/images/google-icon-social.png"),
  signLogRightLine: require("../assets/images/sign-up-right-img.png"),
  myavtar: require("../assets/images/myavtar.png"),
  backbtn: require("../assets/images/backbtn.png"),
  avtarMain: require("../assets/images/avtar-main.png"),
  account_1: require("../assets/images/account-1.png"),
  account_2: require("../assets/images/account-2.png"),
  account_3: require("../assets/images/account-3.png"),
  account_4: require("../assets/images/account-4.png"),
  account_6: require("../assets/images/account-6.png"),
  Userimg: require("../assets/images/userimg.png"),
  editprofile: require("../assets/images/edit-profile-top.png"),
  accordianArrow: require("../assets/images/accordian-arrow.png"),
  faqBg: require("../assets/images/faq-bg.png"),
  DownloadApp: require("../assets/images/download-guppy-app-img.png"),
  storyAdventure: require("../assets/images/menu-story-adventure.png"),
  contactMenu: require("../assets/images/contactMenu.png"),
  menuPhone: require("../assets/images/menuPhone.png"),
  uppperarrow: require("../assets/images/uppperarrow.png"),
  dashedLineYellow: require("../assets/images/dashed-line-yellow.png"),
  menuQestion: require("../assets/images/menuQestion.png"),
  menuQestion: require("../assets/images/menuQestion.png"),
  backbtncross: require("../assets/images/backbtncross.png"),
  rightArrowStory: require("../assets/images/right-arrow-story.png"),
  roadImg: require("../assets/images/roadImg.png"),
  StoryFlow1: require("../assets/images/storyFlow1.png"),
  StoryFlow2: require("../assets/images/storyFlow2.png"),
  StoryFlow3: require("../assets/images/storyFlow3.png"),
  StoryFlow4: require("../assets/images/storyFlow4.png"),
  Sharebtn: require("../assets/images/storysharebtn.png"),
  editpencil: require("../assets/images/edit-pencil.png"),
  editpencilBlue: require("../assets/images/edit-pencil-blue.png"),
  BadalLeft: require("../assets/images/badal-left.png"),
  BadalRight: require("../assets/images/badal-right.png"),
  TheEnd: require("../assets/images/TheEnd.png"),
  EnterArrow: require("../assets/images/EnterArrow.png"),
  Eyeimg: require("../assets/images/Eyeimg.png"),
  BlueRightImgArrow: require("../assets/images/blueRightImgArrow.png"),
  StoryPackage1: require("../assets/images/package1.png"),
  StoryPackage2: require("../assets/images/package2.png"),
  MuniaPackage: require("../assets/images/MuniaPackage.png"),
  Sharecopylink: require("../assets/images/sharecopylink.png"),
  Sharewhtspp: require("../assets/images/sharewhtspp.png"),
  Sharemessage: require("../assets/images/sharemessage.png"),
  ShareSnap: require("../assets/images/shareSnap.png"),
  Recordimg: require("../assets/images/recordimg.png"),
  CameraImg: require("../assets/images/cameraImg.png"),
  StorypageNavFill: require("../assets/images/pagignation-fill.png"),
  StorypageNavActive: require("../assets/images/pagignation-active.png"),
  StorypageNavUnFill: require("../assets/images/pagignation-unfill.png"),
  StorypageNavline: require("../assets/images/pagignation-line.png"),
  StorypageNavHover: require("../assets/images/pagignation-hover.png"),
  Continuebtn: require("../assets/images/continuebtn.png"),
  Storypage1: require("../assets/images/storypage1.png"),
  Storypage2: require("../assets/images/storypage2.png"),
  Where: require("../assets/images/where.png"),
  Who: require("../assets/images/who.png"),
  Palaceimg: require("../assets/images/storyPage.png"),
  Audible: require("../assets/images/audible.png"),
  Uppperarrowwhite: require("../assets/images/uppperarrowwhite.png"),
  CurveBgheader: require("../assets/images/curve-bg-header.png"),
  HeartYellow: require("../assets/images/heart-yellow-bg.png"),
  blueSingleTick: require("../assets/images/blue-single-tick.png"),
  blueDoubleTick: require("../assets/images/blue-double-tick.png"),
  yellowSingleTick: require("../assets/images/yellow-single-tick.png"),
  whiteArrow: require("../assets/images/whiteArrow.png"),
  whiteBorderImage: require("../assets/images/white-border-image.png"),
  MuniaMiddleModal: require("../assets/images/MuniaMiddleModal.png"),
  ConfirmedFrame: require("../assets/images/ConfirmedFrame.png"),
  NoDraftFrame: require("../assets/images/NoDraftFrame.png"),
  NoDataFound: require("../assets/images/NoDataFound.png"),
  CrossblueFrame: require("../assets/images/CrossblueFrame.png"),
  FrameConfirmedBlue: require("../assets/images/FrameConfirmedBlue.png"),
  CreateStoryDashed: require("../assets/images/CreateStoryDashed.png"),
  RedCross: require("../assets/images/RedCross.png"),
  SelectIngredients: require("../assets/images/SelectIngredients.png"),
  RefreashStory: require("../assets/images/RefreashStory.png"),
  EmptyBoxStory: require("../assets/images/EmptyBoxStory.png"),
  JungleCreate: require("../assets/images/JungleCreate.png"),
  FunStory: require("../assets/images/FunStory.png"),
  StoryDashed: require("../assets/images/StoryDashed.png"),
  Lignbluecircle: require("../assets/images/lignbluecircle.png"),
  Lighbluezigzag: require("../assets/images/lighbluezigzag.png"),
  redCir1: require("../assets/images/red-cir-1.png"),
  RedZigZagHomeStory: require("../assets/images/red-zig-zag-story-home.png"),
  Ingredientstop: require("../assets/images/ingredientstop.png"),
  MenuCircularImgBottom: require("../assets/images/MenuCircularImgBottom.png"),
  YellowShareBtnStory: require("../assets/images/YellowShareBtnStory.png"),
  StoryAroowYellowLight: require("../assets/images/StoryAroowYellowLight.png"),
  ZidZagDesktopStory: require("../assets/images/ZidZagDesktopStory.png"),
  HeaderGsStories: require("../assets/images/HeaderGsStories.png"),
  HeaderGsStoriesActive: require("../assets/images/HeaderGsStoriesActive.png"),
  BackstageHeader: require("../assets/images/BackstageHeader.png"),
  BackstageHeaderActive: require("../assets/images/BackstageHeaderActive.png"),
  DownloadShareNew: require("../assets/images/Download-share-new.png"),
  facebookShareNew: require("../assets/images/facebook-share-new.png"),
  logoNewGuppy: require("../assets/images/logo-new-guppy.png"),
  New: require("../assets/images/new.png"),
  PasswordNewSuccess: require("../assets/images/PasswordNewSuccess.png"),
  QRScanner: require("../assets/images/QRScanner.png"),
  LoaderGIFweb: require("../assets/images/LoaderGIFweb.gif"),
  logoBackstageStories: require("../assets/images/logoBackstageStories.png"),

  // backstage
  BackstageBackBtn: require("../assets/images/BackstageBackBtn.png"),
  MenuBSBGMain: require("../assets/images/MenuBSBGMain.png"),
  BsComBtnImg: require("../assets/images/BsComBtnImg.png"),
  BackstageMenuMainNav: require("../assets/images/BackstageMenuMainNav.png"),
  BackstageMenuMainNavHover: require("../assets/images/BackstageMenuMainNavHover.png"),
  BannerBackstage: require("../assets/images/BannerBackstage.png"),
  CommonBackstageImg: require("../assets/images/CommonBackstageImg.png"),
  ReadMoreImg: require("../assets/images/ReadMoreImg.png"),
  BecomingNarad: require("../assets/images/BecomingNarad.png"),
  MalluandPi: require("../assets/images/MalluandPi.png"),
  GuppyStoriesBackstage: require("../assets/images/GuppyStoriesBackstage.png"),
  BackstageMobile: require("../assets/images/BackstageMobile.png"),
  BackstageMobile1: require("../assets/images/backstagemobile1.png"),
  BsYoutube: require("../assets/images/BsYoutube.png"),
  BsWhatsapp: require("../assets/images/BsWhatsapp.png"),
  BsFacebook: require("../assets/images/BsFacebook.png"),
  BsInstagram: require("../assets/images/BsInstagram.png"),
  BsMessage: require("../assets/images/BsMessage.png"),
  MobileBannerBackstage: require("../assets/images/MobileBannerBackstage.png"),
  BAnnewAnotherPageBlur: require("../assets/images/BAnnewAnotherPageBlur.png"),
  GuppyBSCutImage: require("../assets/images/GuppyBSCutImage.png"),
  MobileCommonBanner: require("../assets/images/MobileCommonBanner.png"),
  DownloadConsentForm: require("../assets/images/DownloadConsentForm.png"),
  MaharajaImage: require("../assets/images/MaharajaImage.png"),
  MalluNewLeftImg: require("../assets/images/MalluNewLeftImg.png"),
  CurveArrowLeft: require("../assets/images/CurveArrowLeft.png"),
  CurveArrowRight: require("../assets/images/CurveArrowRight.png"),
  MobileMaharajaImage: require("../assets/images/MobileMaharajaImage.png"),
  MobileIntroducingImg: require("../assets/images/MobileIntroducingImg.png"),
  MaharajaOfMAstipurImgNew: require("../assets/images/MaharajaOfMAstipurImgNew.png"),
  BecomingNaradNew: require("../assets/images/BecomingNaradNew.png"),
  GuppyShortStories: require("../assets/images/GuppyShortStories.png"),
  StoryImageBannerLeft: require("../assets/images/StoryImageBannerLeft.png"),
  BSStoryTitleLogo: require("../assets/images/BSStoryTitleLogo.png"),
  Books: require("../assets/images/Books.png"),
  Games: require("../assets/images/Games.png"),
  ThePalaceStory: require("../assets/images/ThePalaceStory.png"),
  AllStoryOneFame: require("../assets/images/AllStoryOneFame.png"),
  ThePetTiger: require("../assets/images/ThePetTiger.png"),
  PlusBSSign: require("../assets/images/PlusBSSign.png"),
  ShareSignBS: require("../assets/images/ShareSignBS.png"),
  Checksign: require("../assets/images/Checksign.png"),
  Unchecksign: require("../assets/images/Unchecksign.png"),
  LilMaharajabg: require("../assets/images/LilMaharajabg.png"),
  BecomingNaradbg: require("../assets/images/BecomingNaradbg.png"),
  MalluPibg: require("../assets/images/Mallu&Pibg.png"),
  IPSmaharaja: require("../assets/images/IPSmaharaja.png"),
  WhoweareheaderBanner: require("../assets/images/WhoweareheaderBanner.png"),
  WWAWhoisguppy: require("../assets/images/WWAWhoisguppy.png"),
  WhatwedoheaderBanner: require("../assets/images/WhatwedoheaderBanner.png"),
  Neil_Chakravarti: require("../assets/images/Neil_Chakravarti.png"),
  Srividya: require("../assets/images/Srividya.png"),
  NeeruNanda: require("../assets/images/NeeruNanda.png"),
  TinsleyAGalyean: require("../assets/images/TinsleyAGalyean.png"),
  Anil_PIC2: require("../assets/images/Anil_PIC2.png"),
  Cyril1: require("../assets/images/Cyril1.png"),
  IshanRaina: require("../assets/images/IshanRaina.png"),
  GuppyStoriesBehindTheScene: require("../assets/images/GuppyStoriesBehindTheScene.png"),
  IPinsidebannerMaharaja: require("../assets/images/IPinsidebannerMaharaja.png"),
  ANIMATEDTVSERIES: require("../assets/images/ANIMATEDTVSERIES.png"),
  IPInsidelogo: require("../assets/images/IPInsidelogo.png"),
  BeforeMaharajacredits: require("../assets/images/BeforeMaharajacredits.png"),
  BOOKSAUDIOVIDEO: require("../assets/images/BOOKSAUDIOVIDEO.png"),
  Cardgame: require("../assets/images/Cardgame.png"),
  APP: require("../assets/images/APP.png"),
  HeistheKingofPranks: require("../assets/images/HeistheKingofPranks.png"),
  Socialmedia: require("../assets/images/Socialmedia.png"),
  NFTVOXEL: require("../assets/images/NFTVOXEL.png"),
  LIVETHEATRE: require("../assets/images/LIVETHEATRE.png"),
  Sectionbreaker2BN: require("../assets/images/Sectionbreaker2BN.png"),
  Sectionbreaker1BN: require("../assets/images/Sectionbreaker1BN.png"),
  IPInsideLOGOBN: require("../assets/images/IPInsideLOGOBN.png"),
  IPInsidebannerBN: require("../assets/images/IPInsidebannerBN.png"),
  SynopsisBN: require("../assets/images/SynopsisBN.png"),
  IPInsidebannerMalluPi: require("../assets/images/IPInsidebannerMalluPi.png"),
  IPInsideLOGOMalluPi: require("../assets/images/IPInsideLOGOMalluPi.png"),
  SynopsisMalluPi: require("../assets/images/SynopsisMalluPi.png"),
  SectionbreakerMalluPi: require("../assets/images/SectionbreakerMalluPi.png"),
  IPInsidebannerMiniraja: require("../assets/images/IPInsidebannerMiniraja.jpg"),
  IPInsideLOGOMiniraja: require("../assets/images/IPInsideLOGOMiniraja.png"),
  SectionbreakerMiniraja: require("../assets/images/SectionbreakerMiniraja.jpg"),
  SynopsisMiniraja: require("../assets/images/SynopsisMiniraja.jpg"),
  StudioRedfrog: require("../assets/images/StudioRedfrog.png"),
  BackstageBannerContact: require("../assets/images/BackstageBannerContact.png"),
  HitendraMarchent: require("../assets/images/HitendraMarchent.png"),
  GuppyFinalBannersBottom: require("../assets/images/GuppyFinalBannersBottom.png"),
  GuppyFinalBannersLast: require("../assets/images/GuppyFinalBannersLast.png"),
  GuppyFinalBannersMidileCut: require("../assets/images/GuppyFinalBannersMidileCut.png"),
  GuppyFinalBannersTop: require("../assets/images/GuppyFinalBannersTop.png"),
  GuppyPlaystore_BannersFinal: require("../assets/images/GuppyPlaystore_BannersFinal.png"),
  GuppystorieslogoIp: require("../assets/images/GuppystorieslogoIp.png"),
  StudioTeam: require("../assets/images/StudioTeam.png"),
  TheaterTeam: require("../assets/images/TheaterTeam.png"),
  AgencyTeam: require("../assets/images/AgencyTeam.png"),
  WriterTeam: require("../assets/images/WriterTeam.png"),
  LinkedInLogoTeam: require("../assets/images/LinkedInLogoTeam.png"),
  OurStoryBanner: require("../assets/images/OurStoryBanner.png"),
  OurStoryBottom: require("../assets/images/OurStoryBottom.png"),
  OurStoryTop: require("../assets/images/OurStoryTop.png"),
  OurStoryMiddileBreaker: require("../assets/images/OurStoryMiddileBreaker.png"),
  EyeimgNewWhat: require("../assets/images/EyeimgNewWhat.png"),
  TranmediaTop: require("../assets/images/TranmediaTop.png"),
  TransmediaBanner: require("../assets/images/TransmediaBanner.png"),
  TransmediaBottom: require("../assets/images/TransmediaBottom.png"),
  TransmediaBreaker: require("../assets/images/TransmediaBreaker.png"),
  LandingPAgeGuppyStoryImgNew: require("../assets/images/LandingPAgeGuppyStoryImgNew.png"),
  LandingPAgeGuppyStoryImgNew1: require("../assets/images/LandingPAgeGuppyStoryImgNew1.webp"),
  ContactBannerMobile: require("../assets/images/ContactBannerMobile.png"),
  MobileBreakerGuppyIPStroies: require("../assets/images/MobileBreakerGuppyIPStroies.png"),
  MobileBreakerMinirajaIP: require("../assets/images/MobileBreakerMinirajaIP.png"),
  MobileBreakerMalliandPiIP: require("../assets/images/MobileBreakerMalliandPiIP.png"),
  MobileBreakerBecomingNarad: require("../assets/images/MobileBreakerBecomingNarad.png"),
  TopSectionbreaker1BN: require("../assets/images/TopSectionbreaker1BN.png"),
  WhatWeDoBannerNewMobile: require("../assets/images/WhatWeDoBannerNewMobile.png"),
  TranmediaBannerMobileNew: require("../assets/images/TranmediaBannerMobileNew.png"),
  TranmediaBreakerImgNew: require("../assets/images/TranmediaBreakerImgNew.png"),
  OurStoryBreakerNewMobile: require("../assets/images/OurStoryBreakerNewMobile.png"),
  OurStoryNewMobileBanner: require("../assets/images/OurStoryNewMobileBanner.png"),
  WhoWeAreBreakerNewMobile: require("../assets/images/WhoWeAreBreakerNewMobile.png"),
  WhoWeAreBannewMobile: require("../assets/images/WhoWeAreBannewMobile.png"),
  AskGuppyYellowBg: require("../assets/images/ask-guppy-yellow-bg.png"),
  Askguppyborder: require("../assets/images/askguppyborder.png"),
  appledownload: require("../assets/images/appstorelogo.png"),
  qrcode: require("../assets/images/qrcode.png"),
};

export default Images;
