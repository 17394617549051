import React from 'react'
import Images from './Images';
import styles from '../pages/Home/landingpage.module.css';
import Header from './Header';
import { useNavigate } from 'react-router-dom';


function PasswordUpdatedScreen(){
    const navigate=useNavigate();
    return (
        <div>
            <Header/>
            <section className={styles.PasswordUpdatedScreenMain}>
                <div className={styles.signLogRightLine}>
                    <img src={Images.signLogRightLine} />
                </div>
                <div className='container'>
                    <div className={styles.PasswordUpdatedTop}>
                        <img src={Images.MuniaMiddle} />
                    </div>
                    <div className={styles.PasswordUpdatedBottom}>
                        <img src={Images.PasswordNewSuccess} />
                        <p className={styles.PasswordNewSuccesspara1}>You password have been updated <br/> successfully!</p>
                        <p className={styles.PasswordNewSuccesspara2}>You can now login to Guppy Stories with <br/> your new password</p>
                        <button className={styles.btnFill} onClick={()=>{ navigate("/login")}}>
                         Go Back To Login
                        </button>
                    </div>
                </div>
                <div className={styles.signBgLeft}>
                    <img src={Images.signupbg} />
                </div>
            </section>
        </div>
    )
}


export default PasswordUpdatedScreen;

