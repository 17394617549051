import {
  mystorydata_SUCCESS,
  mystorydata_FAILURE,
  mystorydata_Request,
} from "./mystorydata.types";

const INITIAL_STATE = {
  mystoryflow: { loading: false, error: "", data:{} },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    //=================User Data================
    case mystorydata_Request: {
      return {
        ...state,
        mystoryflow: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case mystorydata_SUCCESS: {
      return {
        ...state,
        mystoryflow: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case mystorydata_FAILURE: {
      return {
        mystoryflow: {
          loading: false,
          data:{},
          error: action.payload,
        },
      };
    }
    //=================User Data================


    default:
      return state;
  }
};

export default reducer;
