import React, { useState } from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import BackStageMaharaja from "../Component/BackStageMaharaja";
import BSHyperLinkPriviousNext from "../Component/BSHyperLinkPriviousNext";
import StoryInformationBackstage from "../Component/StoryInformationBackstage";
import BshyperLinkStoryTitle from "../Component/BshyperLinkStoryTitle";
import Credit from "../Component/Credit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBackstagewhatwodo, useSettergetter } from "../../otherexports";
import MetaTags from "react-meta-tags";
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";

function Miniraja() {
  const location = useLocation();
  const { Singleitem } = useSettergetter(location?.state?.item);
  const [item, setitem] = useState(Singleitem);
  window.scrollTo({ top: 0 });
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { isLoading, home } = useBackstagehome();
  if (!isLoading) {
    return (
      <section className={BsCustom.WrapperIpInside}>
        <MetaTags>
          <title>Miniraja - Guppy Theater</title>
          <meta
            name="description"
            content="Miniraja  is a buddy cartoon comedy"
          />
          <meta
            name="keywords"
            content="cartoon, comedy, children, animation series, 6 year old"
          />
        </MetaTags>
        <HeaderBackstageNew />
        <div className={BsCustom.BshyperLinkStoryTitleMain}>
          <div className="container">
            <div className={BsCustom.BSHyperLinkPriviousNextMain}>
              <a href="/MalluAndPi" className="ReadMoreCmn">
                <img
                  style={{ marginRight: "15px" }}
                  src={Images.CurveArrowLeft}
                />
                <span>Previous IP</span>
              </a>
              <a href="#">
                <h1 className={BsCustom.BsH1}>Miniraja </h1>
              </a>
              <a href="/BecomingNarad" className="ReadMoreCmn">
                <span>Next IP</span>
                <img
                  style={{ marginLeft: "15px" }}
                  src={Images.CurveArrowRight}
                />
              </a>
            </div>
          </div>
        </div>

        <div className={BsCustom.StoryInformationBackstageSection}>
          <div className="container-fluid">
            <div className={`row`}>
              <div
                className={`col-sm-12 col-md-8 ${BsCustom.StoryInformationBackstageLeft}`}
              >
                <div className={BsCustom.CopyrightTextNew}>
                  <img src={Images.IPInsidebannerMiniraja} />
                  {/* <span>
                    &#169; 2024 Crossover Media and Design Pvt. Ltd. , all
                    rights reserved
                  </span> */}
                </div>
                <div
                  className={BsCustom.StoryInformationMobileBottom}
                  style={{ textAlign: "center" }}
                >
                  <span className={BsCustom.ImageBottomInfoSpan}>
                    Miniraja, Gauri & Kulfi
                  </span>
                </div>
              </div>
              <div
                className={`col-sm-12 col-md-4 ${BsCustom.StoryInformationBackstageRight}`}
              >
                <img
                  style={{ width: "173px" }}
                  src={Images.IPInsideLOGOMiniraja}
                />
                <p className={BsCustom.BsCommaonP}>
                  <strong>Format: </strong>
                  <br />
                  78 x 7’ 2D Animated TV series
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Genre: </strong>
                  <br />
                  Slapstick buddy comedy
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Target Audience: </strong>
                  <br />
                  <p className={BsCustom.BsCommaonPS}>6+ and family</p>
                  <br />
                  <p className={BsCustom.BsCommaonPM}>
                    Official Selection (by the MIA Selection Committee) at MIA |
                    International Audiovisual Market & Pitching Forum 2023 -
                    ROME
                  </p>
                </p>
                {/* <p className={BsCustom.BsCommaonP}><strong>Production Status: </strong><br/>Pitch bible, short teaser (in colour) and pilot episode animatic ready</p> */}
                {/* <p className={BsCustom.BsCommaonP}><strong>Status: </strong><br/>Seeking primary broadcasters/OTT,  co-production and distribution partners</p> */}
                {/* <p className={BsCustom.BsCommaonP}><strong>Co-production partner: </strong><br/>STUDIO REDFROG, France	</p> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`container-fluid ${BsCustom.StoryInformationDesktopBottom}`}
        >
          <span className={BsCustom.ImageBottomInfoSpan}>
            Miniraja, Gauri & Kulfi
          </span>
        </div>

        <section
          className={`${BsCustom.WTGBSection} ${BsCustom.IPInsideFirstMAinPara}`}
        >
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <div className={BsCustom.CopyrightTextNew}>
                  <a href="#">
                    <img
                      style={{ objectFit: "cover", objectPosition: "left" }}
                      src={Images.SynopsisMiniraja}
                    />
                  </a>
                  {/* <span>
                    &#169; 2024 Crossover Media and Design Pvt. Ltd. , all
                    rights reserved
                  </span> */}
                </div>
                <span className={BsCustom.ImageBottomInfoSpan}>Miniraja</span>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <h3 class={BsCustom.BsH3}>Synopsis</h3>
                <div className={BsCustom.CopyrightTextNew}>
                  <img
                    className={BsCustom.MainWWANewExtraImg}
                    src={Images.SynopsisMiniraja}
                  />
                  {/* <span className={BsCustom.CopyrightTextNewMobile}>
                    &#169; 2024 Crossover Media and Design Pvt. Ltd. , all
                    rights reserved
                  </span> */}
                </div>
                <span className={BsCustom.ImageBottomInfoSpan}>Miniraja</span>
                <p
                  className={BsCustom.BsCommaonP}
                  style={{ marginBottom: "15px" }}
                >
                  This buddy comedy is about MINIRAJA, a young prince and future
                  Maharaja of Mastipur, who has a strong appetite for mischief
                  and pranks. Kulfi, a Royal Bengal Tiger from the Royal Guards,
                  is entrusted with the task of ‘Nanny’ who watches over him as
                  if he were his own cub. With Miniraja’s tendency to head
                  straight towards danger Kulfi definitely has his work clawed
                  out for him!!!{" "}
                </p>
                <p className={BsCustom.BsCommaonP}>
                  The series draws on funny intercultural situations as the
                  Royal Family hosts several international guests from all
                  around the world in their splendid ancestral palace.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className={BsCustom.NewGuppyIPStoryPAra}>
          <img
            style={{ width: "100%" }}
            className={BsCustom.desktopBreakerImg}
            src={Images.SectionbreakerMiniraja}
          />
          <img
            style={{ width: "100%" }}
            className={BsCustom.MobileBreakerImg}
            src={Images.MobileBreakerMinirajaIP}
          />
          <div className="container-fluid">
            <span
              style={{ marginBottom: "60px" }}
              className={BsCustom.ImageBottomInfoSpan}
            >
              Miniraja, Gauri & Kulfi - best buddies!
            </span>
          </div>
          <div className={BsCustom.CreditSection}>
            <div className="container-fluid">
              <h5>CREDITS</h5>
              <p>
                Co-development & Co-production partner - MondoTV Studios, Spain
              </p>
              <p>Teaser development partner - Mondo TV Studios, Spain/Italy</p>
            </div>
          </div>
        </div>

        <div className={BsCustom.ExtraParaIpinside}>
          <div className="container-fluid">
            <h5>STATUS / Enquiries</h5>
            <p>
              Status - seeking primary buyers/broadcasters/OTT/commissioners{" "}
              <br />
              For co-productions, pre-sales, pitch bible, screening materials
              and other business enquiries <br />
              contact :{" "}
              <a
                href="mailto:anil@guppytheatre.com"
                style={{ textDecoration: "underline", color: "#000" }}
              >
                {" "}
                anil@guppytheatre.com
              </a>
            </p>
          </div>
          <div className="container-fluid" style={{ marginTop: "30px" }}>
            <h5>Production Status:</h5>
            <p>
              Pitch bible, short teaser (in colour), pilot episode Animatic and
              <br />
              26 episodic synopsis available for screening
            </p>
          </div>
        </div>
        <div className={BsCustom.BSHyperLinkPriviousNextWrapper}>
          <div className="container">
            <div className={BsCustom.BSHyperLinkPriviousNextMain}>
              <a href="/MalluAndPi" className="ReadMoreCmn">
                <img
                  style={{ marginRight: "15px" }}
                  src={Images.CurveArrowLeft}
                />
                <span>Previous IP</span>
              </a>
              <button
                href="#"
                onClick={handleScrollToTop}
                className="ReadMoreCmn"
                style={{
                  color: "#0D5268",
                  textDecorationLine: "underline",
                  border: "0",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                Back to Top
              </button>
              <a href="/BecomingNarad" className="ReadMoreCmn">
                <span>Next IP</span>
                <img
                  style={{ marginLeft: "15px" }}
                  src={Images.CurveArrowRight}
                />
              </a>
            </div>
          </div>
        </div>
        <FooterBs />
      </section>
    );
  } else {
    return <Loader />;
  }
}

export default Miniraja;
