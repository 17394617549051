import React, { useEffect, useMemo, useRef } from "react";
import StoryPageComp from "../../component/StoryPageComp";
import Header from "../../component/Header";
import styles from "../Home/landingpage.module.css";
import StoryPageNavigation from "../../component/StoryPageNavigation";
import Images from "../../component/Images";
import Palacecom from "../../component/Palacecom";
import GoogleAds from "../../component/GoogleAds";
import Modal from "react-modal";
import Modalstyle from "../StoryPage/storypage.module.css";
import { useState } from "react";
import MuniaModal from "../../component/MuniaModal";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getLocal, useAvtar, useSettergetter } from "../../otherexports";
import { useQuery } from "@tanstack/react-query";
import api from "../../ApiService/Apicall";
import MetaTags from 'react-meta-tags';
import TinderCard from "react-tinder-card";
import _ from "lodash";
import { createRef } from "react";
import RagisterFirstModal from "../../component/RagisterFirstModal";
import NoDraftModal from "../../component/NoDraftModal";
export default function StoryPage() {
  const modalopen = createRef(null);
  const [modaltext, setmodaltext] = useState("");
  const Registermodal= createRef(null);
  const userinfo = getLocal("User");
  const { isLoading: otherloading, data: Avtardata } = useAvtar();
  const navigate=useNavigate();
  const location = useLocation();
  const modalIsOpen = createRef(null);
  const [birdshow, setbirdshow] = useState(true);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [point, setpoint] = useState([]);
  const { Singleitem } = useSettergetter(location?.state?.item);
  const [item, setitem] = useState(Singleitem);
  const [loder, setloader] = useState(true);
  const [key, setkey] = useState(null);
  const [storyflowdata, setstoryflowdata] = useState([]);
  const { data: Storydata, isLoading: otherisLoading } = useQuery(
    ["Stroydatabystory"],
    async () => {
      const results = await api(
        "get",
        "guppy-stories?populate[0]=Sections&populate[1]=Sections.StoryImage&populate[2]=Sections.keyword&populate[3]=Sections.keyword.guppy_stories&populate[4]=Sections.keyword.guppy_stories.Sections.StoryImage&populate[5]=Sections.keyword.ingredient_type&populate[6]=story_ingredients&populate[7]=story_ingredients.IngredientImage&populate[8]=story_ingredients.ingredient_type_id&populate[9]=story_ingredients.packages&populate[10]=story_ingredients.users_permissions_users&populate[11]=continue_btn&populate[12]=continue_btn.guppy_stories&populate[13]=continue_btn.guppy_stories.Sections.StoryImage&populate[14]=bottom_banner&populate[15]=bottom_banner.banner_image&populate[16]=user_ids&populate[17]=*"
      );
      return results.data.data;
    }
  );
  const [count, setcount] = useState(0);
  const [selectedstroy, setselectedstroy] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(selectedstroy?.length - 1);

  const gettingAPi = async (id) => {
    const results = await api(
      "get",
      `guppy-stories/${id}?populate[0]=Sections&populate[1]=Sections.StoryImage&populate[2]=Sections.keyword&populate[3]=Sections.keyword.guppy_stories&populate[4]=Sections.keyword.guppy_stories.Sections.StoryImage&populate[5]=Sections.keyword.ingredient_type&populate[6]=story_ingredients&populate[7]=story_ingredients.IngredientImage&populate[8]=story_ingredients.ingredient_type_id&populate[9]=story_ingredients.packages&populate[10]=story_ingredients.users_permissions_users&populate[11]=continue_btn&populate[12]=continue_btn.guppy_stories&populate[13]=continue_btn.guppy_stories.Sections.StoryImage&populate[14]=bottom_banner&populate[15]=bottom_banner.banner_image&populate[16]=user_ids&populate[17]=story_thumbnail&populate[18]=*`
    );
    setitem(results?.data?.data);
    setloader(false);
  };

  useEffect(() => {
    if (window?.location?.href?.split("=")[1] != undefined) {
      gettingAPi(window?.location?.href?.split("=")[1]);
    }
  }, []);

  useEffect(() => {
    if (modalIsOpen2) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
 }, [modalIsOpen2 ]);
//  useEffect(() => {
//   if (window.performance) {  
//     if (performance.navigation.type == 1) {
//      navigate("/")
//     } 
//   }
// }, []);

  const currentIndexRef = useRef(currentIndex);
  const childRefs = useMemo(
    () =>
      Array(selectedstroy?.length)
        .fill(0)
        .map((i) => React.createRef()),
    [selectedstroy]
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };
  const canSwipe = currentIndex >= 0;
  const swiped = async (direction, nameToDelete, index) => {
    updateCurrentIndex(index - 1);
  };
  const outOfFrame = (name, idx) => {
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    if (idx === 0) {
      for (let i = 0; i <= selectedstroy.length; i++) {
        childRefs[i].current.restoreCard();
        setCurrentIndex(selectedstroy?.length - 1)
      }
    }
  };
  const swipe = async (dir) => {
    if (canSwipe && currentIndex < Storydata?.length) {
      await childRefs[currentIndex].current.swipe(dir);
    }
  };

  const rotating = (i) => {
    if (i % 2 === 0) {
      return "10deg";
    }
    if (i % 3 === 0) {
      return "-10deg";
    }
  };

  useEffect(() => {
    if(point.length==0 && point.length<6){
      for (let i = 0; i < 5; i++) {
        if (i == 0) {
          point.push({ visible: true });
        } else {
          point.push({ visible: false });
        }
        // prevStory.push(item.id);
        setpoint(point);
        setloader(false);
      }
    }
  
  }, []);

  const chnageStory = () => {
    setloader(true);
    let indexing;
    if (point?.filter((e) => e?.visible)?.length == 1) {
      indexing = Math.abs(currentIndex);
    } else {
      indexing = currentIndex;
    }
    let selecteditem;
    if (key == null) {
      selecteditem = Storydata?.filter((e) => e?.id == item?.id)[0]?.attributes
        ?.continue_btn?.guppy_stories?.data[indexing];
    } else {
      selecteditem = key?.guppy_stories?.data[indexing];
    }
    let filtereditem = Storydata?.filter((e) => e?.id == selecteditem?.id)[0];
    let storyinfo = filtereditem?.attributes?.continue_btn?.guppy_stories?.data;
    point[point?.filter((e) => e?.visible)?.length].visible = true;
    // console.log(JSON.stringify(filtereditem),"==========",indexing,"=======count",count)
    setpoint([...point]);
    setitem({ ...filtereditem });
    setCurrentIndex(storyinfo.length - 1);
    setselectedstroy([...storyinfo]);
    setIsOpen2(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setloader(false);
  };

  const continuebtn = (item) => {
    setselectedstroy(item?.attributes?.continue_btn?.guppy_stories?.data);
    setCurrentIndex(
      item?.attributes?.continue_btn?.guppy_stories?.data?.length - 1
    );
    setcount(count + 1);
    setkey(null);
    setIsOpen2(true);
  };

  const finaldata=async()=>{
    storyflowdata.push({
      step: point?.filter((e) => e?.visible)?.length,
      fullview: false,
      flow_step: item,
    });
    if (userinfo?.id != undefined && userinfo?.id != null) {
      let infotwo = {
        data: {
          story_flow: storyflowdata,
          users_permissions_user: userinfo?.id?.toString(),
          Story_Flow_Name: "",
          createdBy: "",
          updatedBy: "",
        },
      };
      setloader(true)
     const data= await api('post',"guppy-story-flows", infotwo, "psk");
     console.log(JSON.stringify(data),"========Final api call===data==data")
     if(Object.keys(data).length!=0){
      navigate("/GuppyAppDownload");
     }
  
  }
}

  if (
    !loder &&
    !!Storydata &&
    item != undefined &&
    !otherisLoading &&
    !otherloading
  ) {
    return (
      <div style={styles.body}>
        <MetaTags>
          <title>Story Page - Guppy Theater</title>
          <meta name="description" content="Guppy Theatre is a Transmedia storytelling company that develops IPs with children that entertain as well as foster creativity and collaboration" />
          <meta property="og:title" content="animation, games, IP development, story world, children, transmedia story telling" />
        </MetaTags>
        <Header />
        <div className={styles.storyPageMain}>
          {birdshow && (
            <div className={styles.birdTopText}>
            <img
              className={styles.MuniaMiddle}
              style={{ zIndex: 999 }}
              src={
                !!userinfo?.name
                  ? "https://manage.guppytheatre.com" +
                    Avtardata?.attributes?.guppy_avtar?.data?.attributes
                      ?.avtar_media.data?.attributes?.url
                  : "https://manage.guppytheatre.com" +
                    Avtardata?.attributes?.avtar_media.data?.attributes?.url
              }
              
            />
            <span><img src={Images.Askguppyborder} onClick={() => {
                setbirdshow(false);
                modalIsOpen.current.show();
              }} /></span>
            </div>
          )}
          <div className="container">
            {/* {console.log({point})} */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {point?.map((e, i) => {
                return (
                  <StoryPageNavigation
                    data={e}
                    index={i}
                    last={point.length - 1}
                    current={point?.filter((e) => e?.visible)?.length - 1}
                  />
                );
              })}
            </div>

            {!!item && <span className={`${styles.storyPAgeMainTitle}`}>{item?.attributes?.title}</span>}
            <div>
              {!!item &&
                item?.attributes?.Sections?.map((e, i) => {
                  let Alldes;
                  if (
                    e?.keyword != undefined &&
                    e?.keyword[0]?.keyword != undefined
                  ) {
                    let key = e?.keyword[0]?.keyword;
                    if (e?.StoryText?.split(key).length != 1) {
                      Alldes = {
                        ...e,
                        StoryText: e?.StoryText?.split(key).join(
                          ` ##${key}## `
                        ),
                      };
                    } else if (e?.StoryText?.split(` ${key} `).length != 1) {
                      Alldes = {
                        ...e,
                        StoryText: e?.StoryText?.split(` ${key}.`)
                          .join(` ##${key}##.`)
                          .split(` ${key}, `)
                          ?.join(` ##${key}##.`)
                          .split(`RIIING RIIING!`)
                          ?.join(` ##RIIING RIIING##!`),
                      };
                    } else {
                      Alldes = {
                        ...e,
                        StoryText: e?.StoryText?.split(` ${key}.`)
                          .join(` ##${key}##.`)
                          .split(` ${key}, `)
                          ?.join(` ##${key}##.`)
                          .split(`RIIING RIIING!`)
                          ?.join(` ##RIIING RIIING##!`)
                          .split(` ${key} `)
                          .join(` ##${key}## `)
                          .split(key)
                          .join(` ##${key}## `),
                      };
                    }
                  } else {
                    Alldes = e;
                  }
                  return (
                    <StoryPageComp
                      Storydata={Storydata}
                      item={item}
                      data={Alldes}
                      setselectedstroy={setselectedstroy}
                      setIsOpen2={(e) => {
                        if(point?.filter((e) => e?.visible).length<5){
                          setcount(count + 1);
                          setIsOpen2(e);
                        }else{
                          setmodaltext("you have reached your limit !");
                          modalopen.current.show();
                        }
                      }}
                      index={i}
                      setCurrentIndex={setCurrentIndex}
                      setkey={setkey}
                    />
                  );
                })}
            </div>
            {point.length != point?.filter((e) => e?.visible)?.length && (
              <button
                className={styles.btnFill}
                onClick={() => {
                  continuebtn(item);
                }}
              >
                Continue <img src={Images.Continuebtn} />
              </button>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {point?.map((e, i) => {
                return (
                  <StoryPageNavigation
                    data={e}
                    index={i}
                    last={point.length - 1}
                    current={point?.filter((e) => e?.visible)?.length - 1}
                  />
                );
              })}
            </div>

            {point.length == point?.filter((e) => e?.visible)?.length && (
              <div
                style={{ background: "#F5FEF0" }}
                className={styles.celebrationBox}
              >
                <div className="container">
                  <div className={`row ${styles.celebrationBoxInner}`}>
                    <div className="col-sm-12 col-md-4">
                      <img
                        style={{ width: "322px" }}
                        src={Images.FrameConfirmedBlue}
                      />
                    </div>
                    <div className="col-sm-12 col-md-8">
                      <p>
                        Yay! You've reached the top <br /> of the story
                        mountain! Ready <br /> for another adventure?
                      </p>
                    </div>
                  </div>
                  <div
                    className={styles.celebrationBoxBtn}
                    onClick={() => {
                      // Navigate("/GuppyAppDownload");
                   
                      if(userinfo?.name==undefined){
                        Registermodal?.current?.show();
                      }else{
                        finaldata()
                      }
                    }}
                  >
                    <a
                      // href="#"
                      // onClick={() => {
                      //   // Navigate("/GuppyAppDownload");
                      // }}
                     style={{cursor:"pointer"}}>
                      <img style={{ width: "175px" }} src={Images.account_1} />
                      View My
                      <br /> Story Adventures
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {item?.attributes?.story_ingredients?.data?.length > 0 &&
          item?.attributes?.story_ingredients?.data != null && (
            <div className={styles.storyPageMainMid}>
              <img
                className={styles.imgafterstoryPageLeft}
                style={{ position: "absolute", top: "0", right: "0" }}
                src={Images.Lignbluecircle}
              />
              <div className="container">
                <p>
                  You've won 2 new <br /> story ingredients!{" "}
                </p>
                <span>I wonder what you will do with them! </span>
                <div className={styles.storyPageMidimg}>
                  {item?.attributes?.story_ingredients?.data?.map((e,i) => {
                    return (
                      <div
                        style={{ margin: "0 20px" }}
                        className={styles.storyimgInner}
                      key={i}>
                        {!!e?.attributes?.IngredientImage?.data?.attributes
                          ?.url && (
                          <img
                            src={
                              "https://manage.guppytheatre.com" +
                              e?.attributes?.IngredientImage?.data?.attributes
                                ?.url
                            }
                          />
                        )}
                        <div
                          style={{ position: "relative", marginTop: "-15px" }}
                          className={styles.whereText}
                        >
                          <img src={Images.Where} />
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              height: "100%",
                              justifyContent: "center",
                              left: 0,
                              margin: "auto",
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}
                          >
                            <span>{e?.attributes?.ingredient_type_id?.data?.attributes?.TypeName}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className={styles.storyimgInner}>
                <img src={"https://manage.guppytheatre.com" +
                      item?.attributes?.IngredientImage?.data?.attributes?.url} />
                <img src={Images.Who} />
              </div> */}
                </div>
              </div>
              <img
                className={styles.imgafterstoryPageRight}
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  zIndex: "-1",
                }}
                src={Images.Lighbluezigzag}
              />
            </div>
          )}
        <div className={styles.storyPageMainBottom}>
          <div className="container">
            {!!item?.attributes?.credits && (
              <p>
                <strong>Credits:</strong> {item?.attributes?.credits}
              </p>
            )}
            {!!item?.attributes?.bottom_banner?.banner_image?.data?.attributes
              ?.url && <Palacecom item={item} />}
            {/* <GoogleAds /> */}
          </div>
        </div>
        <MuniaModal
          ref={modalIsOpen}
          birdshow={() => {
            setbirdshow(true);
          }}
          data={Avtardata}
        />
        <Modal
          style={{
            content: {
              display: "flex",
              backgroundColor: "#0000",
              borderWidth: 0,
              flexDirection: "column",
              flex: 1,
              position: "unset",
              overflow: "auto",
              height: "100%",
              zIndex: 999,
              marginTop: "-45px"
            },
          }}
          ariaHideApp={false}
          overlayClassName={Modalstyle.modal}
          isOpen={modalIsOpen2}
          onRequestClose={() => {
            setIsOpen2(false);
          }}
          contentLabel="Example Modal"
        >
          <div className="container">
            {/* <img src={Images.dashedLine} /> */}
            <div className={Modalstyle.ModalStoryPage}>
              <img
                className={Modalstyle.sharebtnimg}
                src={Images.backbtncross}
                onClick={() => {
                  setIsOpen2(false);
                }}
              />
              <h1>{item?.attributes?.title}</h1>
              {!!key ? (
                <p>{key?.Description}</p>
              ):<p>{item?.attributes?.continue_btn?.Description}</p>}
              <div className={Modalstyle.NewCardMAinStoryModal}
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "20em",
                  position: "relative"
                }}
              >
                {selectedstroy?.map((cardData, index) => (
                  <div className={`${Modalstyle.swiperSection_InfoDe}`}
                    style={{
                      zIndex: index,
                      position: "absolute",
                      rotate: rotating(index),
                      maxWidth: "80%",
                    }}
                    key={index}
                  >
                    <TinderCard
                      ref={childRefs[index]}
                      className="swipe"
                      key={cardData?.attributes?.title}
                      onSwipe={(dir) =>
                        swiped(dir, cardData?.attributes?.title, index)
                      }
                      onCardLeftScreen={() =>
                        outOfFrame(cardData?.attributes?.title, index)
                      }
                      swipeThreshold={900}
                      swipeRequirementType={"velocity"}
                      preventSwipe={["up", "down"]}
                    >
                      <div className={styles.swiperSlide}>
                        <div className={`${styles.cardNew} ${Modalstyle.cardNew}`}>
                          <div className={styles.cardHeader}>
                            <img
                              src={
                                "https://manage.guppytheatre.com" +
                                cardData?.attributes?.Sections[0]?.StoryImage
                                  ?.data?.attributes?.url
                              }
                              alt="image"
                            />
                          </div>
                          <div className={`${styles.cardFooter} ${Modalstyle.cardFooter}`}>
                            <span
                              className={styles.cardfooterName}
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                display: "inherit",
                                fontSize: "25px",
                                lineHeight: "30px",
                                marginTop: "0"
                              }}
                            >
                              {cardData?.attributes?.title}
                            </span>
                          </div>
                        </div>
                      </div>
                    </TinderCard>
                  </div>
                ))}
                <div className={`${styles.swiperNavBannertwo} ${Modalstyle.swiperNavBannertwo}`} style={{top:!!key?"40%":"45%"}}>
            <button
              className={styles.swiperPrev}
              onClick={() => {swipe("left")}}
              style={{outline:"none",cursor:"pointer"}}>
              <img src={Images.navBannerleft} alt="" />
            </button>
            <button
              className={styles.swiperNext}
              onClick={() => {swipe("right")}}
            style={{outline:"none",cursor:"pointer",position:"absolute",zIndex:99,right:0}} >
              <img src={Images.navBannerleft} alt="" />
            </button>
          </div>
              </div>
              <button
                className={styles.btnFill}
                onClick={() => {
                  chnageStory();
                  storyflowdata.push({
                    step: point?.filter((e) => e?.visible)?.length,
                    fullview: false,
                    flow_step: item,
                  });
                  setstoryflowdata([...storyflowdata]);
                }}
              >
                Pick this card
              </button>
              <div className={Modalstyle.ModalStoryPageInner}>
                <p>
                  {" "}
                  <span>OR</span>
                  Customise your own 4 W’s with different customization options.
                </p>
                <button className={styles.btnFill} onClick={()=>{ navigate("/GuppyAppDownload");}}>Download GUPPY APP</button>
              </div>
            </div>
          </div>
        </Modal>
        <RagisterFirstModal ref={Registermodal} />
        <NoDraftModal ref={modalopen} text={modaltext} />
      </div>
    )
  } else {
    return (
      <div className={styles.body}>
        <div className={styles.LoadingMain}
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
        </div>
      </div>
    );
  }
}
