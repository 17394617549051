import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import LatestNewsBS from "../Component/LatestNewsBS";
import { useNavigate } from "react-router-dom";
import { useBackstagenews } from "../../otherexports";
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";

function News() {
  const navigate = useNavigate();
  
  const { isLoading, data } = useBackstagenews();
  // console.log(JSON.stringify(data?.attributes?.backstage_stories?.data), "==========data====data");
  if(!isLoading){
    return (
      <section className={BsCustom.WrapperNews}>
        <HeaderBackstageNew />
        <section className={BsCustom.BannerCommon}>
          <img
            className={BsCustom.BackBtnBSCommonNew}
            src={Images.BackstageBackBtn}
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className={BsCustom.BannerCommonText}>
            <h1 className={BsCustom.BsH1}>News</h1>
            <h4 className={BsCustom.BsH4}>Latest News</h4>
          </div>
        </section>
        <LatestNewsBS data={data} />
        <FooterBs />
      </section>
    );
  }
  else 
    {return (<Loader />)}
}

export default News;
