import React, { useState ,createRef} from 'react'
import styles from '../../pages/Home/landingpage.module.css';
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';

function BackStageContactFooter(){
    return (
        <div className={`${BsCustom.TeamDarkBCGroung} ${BsCustom.BackStageContactFooter}`}>
        <section className={`${styles.contactSection} ${BsCustom.contactSection}`}>
            <div className={`${styles.ContactTop} ${BsCustom.ContactTop}`} style={{background: 'transparent'}}>
                <div className='container'>
                    <div className={`${styles.contactTopInfo} ${BsCustom.contactTopInfo}`}>
                        <p>Choose the best way you would like to contact us. We look forward to hearing from you. </p>
                        <div className={`${styles.contactinfoMain} ${BsCustom.contactinfoMain}`}>
                            <div className={`${styles.mainInfo} ${BsCustom.mainInfo}`}>
                                <span>Get in touch</span>
                                <div className={`${styles.socialIcon} ${BsCustom.socialIcon}`} onClick={()=>{window.open('whatsapp://send?text=hello&phone=+918928110916', "_blank")}} style={{cursor:"pointer"}} >
                                    <img src={Images.whtspp} />
                                    <span>+918928110916</span>
                                </div>
                                <div className={`${styles.socialIcon} ${BsCustom.socialIcon}`} onClick={()=>{window.open('mailto:guppy@guppytheatre.com', "_blank")}} style={{cursor:"pointer"}} >
                                    <img src={Images.inbox} />
                                    <span>guppy@guppytheatre.com</span>
                                </div>
                            </div>
                            <div className={`${styles.mainInfo} ${BsCustom.mainInfo}`}>
                                <span>Social</span>
                                <div className={`${styles.socialIcon} ${BsCustom.socialIcon}`} onClick={()=>{window.open('https://www.facebook.com/GuppyTheatre1', "_blank")}} style={{cursor:"pointer"}}  >
                                    <img src={Images.facebook}  />
                                    <span>theguppytheatre</span>
                                </div>
                                <div className={`${styles.socialIcon} ${BsCustom.socialIcon}`} onClick={()=>{window.open('https://www.instagram.com/guppytheatre/', "_blank")}} style={{cursor:"pointer"}} >
                                    <img src={Images.Insta} />
                                    <span>@theguppytheatre</span>
                                </div>
                                <div className={`${styles.socialIcon} ${BsCustom.socialIcon}`} onClick={()=>{window.open('https://www.youtube.com/@guppytheatre5404', "_blank")}} style={{cursor:"pointer"}} >
                                    <img src={Images.youtube} />
                                    <span>theguppytheatre</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}


export default BackStageContactFooter;

