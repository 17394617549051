import { findByLabelText } from "@testing-library/react";
import React, { createRef, useEffect } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import Images from '../../component/Images';
import MyStoryFlows from "../../component/MyStoryFlow";
import StyleStory from "../MyStoryKits/MyStoryPackages.css";

// import { useQuery } from 'react-query'
// import { api } from "../../ApiService/Apicall";
import { useDispatch, useSelector } from "react-redux";
import { mystoryflow } from "../../redux/mystorydata/mystorydata.actions";
import { getLocal, useGetStoryFlow } from "../../otherexports";
export default () => {
  // const userinfo = getLocal("User");
  // const dispatch = useDispatch();
  // const {data, loading } = useSelector((state) => state.MyStoryflow.mystoryflow);
  const {data,isLoading}=useGetStoryFlow();
  // useEffect(() => {
  //   if(userinfo.name!=undefined){
  //     fatch_data();
  //   }
  // }, []);

  // const fatch_data = async () => {
  //   dispatch(mystoryflow());
  // };

  if (!isLoading){
    return (
      <div className={styles.body}>
        <Header hide={true} />
        <MyStoryFlows data={data}  />
      </div>
    );}
  else
    return (
      <div className={styles.body}>
      <div className={styles.LoadingMain}
        style={{
          alignItems: "center",
          backgroundColor: "#F2DD4E",
          top: 0,
          left: 0,
          bottom: 0,
          position: "absolute",
          justifyContent: "center",
          flex: 1,
          flexDirection: "column",
          display: "flex",
          right: 0,
        }}
      >
        <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
      </div>
    </div>
    );
};

// export default LandingPage;
