import React, { createRef, useEffect } from "react";
import { useState } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import Submission_Submitted from "./Submission_Submitted";
import Submission_Draft from "./Submission_Draft";
import { useNavigate } from "react-router-dom";
import { getLocal } from "../otherexports";
import RagisterFirstModal from "./RagisterFirstModal";
import NoDataFile from "./NoDataFile";

function MySubmission({ data }) {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={`${styles.MySubmission} ${styles.MyStories}`}>
      <div className="container">
        <div
          className={styles.backbtn}
          onClick={() => {
            navigate(-1);
          }}
        >
          <a
            href="#"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={Images.navBannerleft} />
          </a>
        </div>
        <h4>My Submissions</h4>
        <div className={styles.storybtn}>
          <button
            style={{ marginRight: "50px" }}
            className={
              active
                ? ` ${styles.activeEffect} ${styles.activeEffect} ${styles.btnFill} ${styles.btnFillRight} ${styles.submissionNewLEftBtn}`
                : ` ${styles.btnFill} ${styles.submissionNewLEftBtn}`
            }
            onClick={() => setActive(true)}
          >
            Submitted
          </button>
          <button
            className={
              !active
                ? `${styles.activeEffect} ${styles.btnFill}  ${styles.btnFillRight} `
                : `${styles.btnFill} ${styles.btnFillRight}`
            }
            onClick={() => setActive(false)}
          >
            Drafts
          </button>
        </div>
        {data?.filter((e) => e?.attributes?.Status == "Submitted").length >
          4 && (
          <>
            <h3>Here is a list of your published stories</h3>
            <p>
              You can submit maximum 5 stories. <br /> You can create as many
              stories as you want and save it in draft.
            </p>
          </>
        )}
        <>
          {active &&
            data
              ?.filter((e) => e?.attributes?.Status != "Draft")
              ?.filter((e) => e?.attributes?.ingredient_status != "Draft")
              ?.length != 0 &&
            data
              ?.filter((e) => e?.attributes?.Status != "Draft")
              ?.filter((e) => e?.attributes?.ingredient_status != "Draft")
              ?.reverse()
              ?.map((item, index) => {
                return (
                  <div key={index}>
                    <Submission_Submitted item={item} index={index} />
                  </div>
                );
              })}

          {active &&
            data
              ?.filter((e) => e?.attributes?.Status != "Draft")
              ?.filter((e) => e?.attributes?.ingredient_status != "Draft")
              ?.length == 0 && <NoDataFile />}

{!active && data?.filter(
                (e) =>
                  e?.attributes?.Status == "Draft" ||
                  e?.attributes?.ingredient_status == "Draft"
              )?.length == 0 && <NoDataFile />}
          {!active && data?.filter(
                (e) =>
                  e?.attributes?.Status == "Draft" ||
                  e?.attributes?.ingredient_status == "Draft"
              )?.length != 0 &&
            data
              ?.filter(
                (e) =>
                  e?.attributes?.Status == "Draft" ||
                  e?.attributes?.ingredient_status == "Draft"
              )
              ?.map((item, index) => {
                if (item.type === "ingredient") {
                  return (
                    <Submission_Draft
                      type={"ingredient"}
                      item={item}
                      index={index}
                    />
                  );
                } else {
                  return (
                    <Submission_Draft
                      type={undefined}
                      item={item}
                      index={index}
                    />
                  );
                }
              })}
        </>
      </div>
    </div>
  );
}

export default MySubmission;
