import React from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";
import { useEffect, useState, createRef } from "react";
import { signupapi } from "../redux/userdata/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../ApiService/Apicall";
import emailsend from "../ApiService/emailsend";
import NoDraftModal from './NoDraftModal';
import SubmittedSuccessfullyModal from './SubmittedSuccessfullyModal';
import Header from "./Header";
import { fontWeight } from "@mui/system";

function Signup({setloading}) {
  // const postEvent = () =>{
  //     const url = "https://manage.guppytheatre.com/api/"
  //     fetch(url)
  // }
  // const dispatch=useDispatch();
  const [modaltext, setmodaltext] = useState('');
  const modalopentwo=createRef(null);
  const modalopen=createRef(null);
  const navigate=useNavigate();
  const [uname, setUname] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
 async function postUser() {
  setloading(true)
    var emailcheck = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (
      email.length == 0 ||
      password.length == 0 ||
      uname.length == 0 ||
      age.length == 0
    ) {
      let message =
        uname.length == 0
          ? "Name is a required field"
          : age.length == 0
          ? "Age is a required field"
          : email.length == 0
          ? "Email is a required field"
          : !emailcheck.test(email)
          ? "Invalid Email Id"
          : password.length == 0
          ? "Password is a required field"
          : "";
      // setmodal({ type: message, show: true });
      // alert(message);
      setloading(false)
      setmodaltext(message)
      modalopen.current.show()
      // setloading(false);
    }else{

      let alluser = await api('get',"users");
      var user = alluser?.data?.filter((e) => e?.email == email);
      if (user?.length > 0) {
        // alert("This email is already linked to an existing account");
        setmodaltext("This email is already linked to an existing account")
        modalopen.current.show()
        // setmodal({ type: "This email is already linked to an existing account", show: true });
        setloading(false);
      }else{

        let sixnumber = 100000 + Math.floor(Math.random() * 900000);
        emailsend(
          {
            email: email,
            name: uname,
          },
          "psk",
          sixnumber
        );
        let data = {
          email: email,
          user_media: "110",
          password: password,
          username: email.split("@")[0],
          age: age,
          name: uname,
          penName: uname,
        };

        navigate("/Otp", {
          state: {
            data: data,
            otp: sixnumber,
          },
        }); 
     setloading(false);
      }
  
    }

 
    
  }

  return (
    <div>
        <Header/>
        <div className={`${styles.signUpBg} ${styles.signUpOtp}`}>
        <div className={styles.arrowImgMain}>
            <div className={styles.signLogRightLine}>
            <img src={Images.signLogRightLine} />
            </div>
            <div className="container">
            <div className={`row ${styles.signUpInfo}`}>
                <div className={`col-sm-12 col-md-12 ${styles.rightInfo}`}>
                    <p style={{
                        fontSize: '24px',
                        lineHeight: '34px',
                        fontWeight: '600'
                    }}
                    >Please verify your account details,  <br/> in order to complete your  <br/> registration with Guppy Story.</p>
                    <div style={{margin: '20px 0'}}><img src={Images.dashedLine} /></div>
                    <p style={{
                        fontSize: '24px',
                        lineHeight: '34px',
                        fontWeight: '600'
                    }}>We have sent you an email with a <br/> copy of the filled-out consent form <br/> along with the OTP.</p>
                    <p style={{
                        fontSize: '24px',
                        lineHeight: '34px',
                        fontWeight: '600',
                        margin: '10px 0'
                    }}>01:00</p>
                    <a style={{
                        fontSize: '24px',
                        lineHeight: '34px',
                        fontWeight: '600',
                        color: '#000',
                        textDecoration: 'underline',
                        marginBottom: '30px',
                        display: 'block'
                    }} href="#">Resend OTP</a>
                <form action="#">
                    <div className={styles.wrapdiv}>
                    <input type="text"/>
                    </div>
                    <div className={styles.btndiv}>
                    <button
                        type="submit"
                        className={styles.btnFill}>
                        Verify
                    </button>

                    <div className={styles.againreg}>
                        <p style={{paddingTop: "30px", fontSize: '18px', lineHeight: '23px'}}>Already have an account? <a style={{color: 'var(--black)', display: 'inline-block'}} href="/login"> Login</a></p> 
                    </div>
                    </div>
                </form>
                </div>
            </div>
            </div>
            <div className={styles.signBgLeft}>
            <img src={Images.signupbg} />
            </div>
        </div>
        <NoDraftModal ref={modalopen} text={modaltext} />
        <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
        </div>
    </div>
  );
}

export default Signup;
