import React, { useEffect } from "react";
import "./App.css";
import LandingPage from "./pages/Home/LandingPage";
import SignUp from "./pages/Auth/SignUp";
import Login from "./pages/Auth/Login";
import Myavtar from "./pages/Myavtar";
import Contact from "./pages/Contact";
import MyAccount from "./pages/User/MyAccount";
import Editprofile from "./pages/User/Edit_Profile";
import MyStoryFlows from "./pages/MyStoryFlows";
import DownloadAppGuppy from "./pages/GuppyAppDownload";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import FaqMain from "./pages/FaqMain";
import MenuMain from "./pages/MenuMain";
import StoryFlow from "./pages/StoryFlow";
import MyStoryKits from "./pages/MyStoryKits";
import PackageIngredient from "./pages/MyStoryKits/MyStoryPackageIngredient";
import MyStories from "./pages/MyStories";
import MySubmission from "./pages/MySubmission";
import StoryPage from "./pages/StoryPage/StoryPage";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import Otp from "./pages/Auth/Otp";
import CreateaStory from "./component/CreateaStory";
import PasswordReset from "./pages/Auth/PasswordReset";
import ModalStoryIngrediaentsNew from "./component/ModalStoryIngrediaentsNew";
import MobileShareIcon from "./component/MobileShareIcon";
import ShareStoryFlowNew from "./component/ShareStoryFlowNew";
import SignupOtp from "./component/SignupOtp";
import HeaderBackstage from "./Backstage/Component/HeaderBackstage";
import BackstageMenu from "./Backstage/Component/BackstageMenu";
import LandingPageBs from "./Backstage/Screen/LandingPageBs";
import WhoWeAre from "./Backstage/Screen/WhoWeAre";
import OurTeam from "./Backstage/Screen/OurTeam";
import GuppyIntroduction from "./Backstage/Screen/GuppyIntroduction";
import TermsConditions from "./component/TermsConditions";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TemplatePage from "./Backstage/Screen/TemplatePage";
import PasswordUpdatedScreen from "./component/PasswordUpdatedScreen";
import WhatWeDo from "./Backstage/Screen/WhatWeDo";
import IpInside from "./Backstage/Screen/IpInside";
import WhatWeSell from "./Backstage/Screen/WhatWeSell";
import News from "./Backstage/Screen/News";
import ContactBackstage from "./Backstage/Screen/ContactBackstage";
import SignUPChild from "./component/SignUPChild";
import ParentConsentForm from "./component/ParentConsentForm";
import SignUpSuccessFully from "./component/SignUpSuccessFully";
// import ParentConsentFormNew from "./component/ParentConsentFormNew";
import ParentConsentFormMain from "./component/ParentConsentFormMain";
import StoryInformationBackstage from "./Backstage/Component/StoryInformationBackstage";
import BecomingNarad from "./Backstage/Screen/BecomingNarad";
import MalluAndPi from "./Backstage/Screen/MalluAndPi";
import Miniraja from "./Backstage/Screen/Miniraja";
import GuppyStoriesIp from "./Backstage/Screen/GuppyStoriesIp";
import OurStory from "./Backstage/Screen/OurStory";
import Transmedia from "./Backstage/Screen/Transmedia";

function App() {
  return (
    <div style={{ position: "absolute", width: "100%", height: "100%" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPageBs />} />
          <Route path="/home" element={<LandingPageBs />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/PasswordReset" element={<PasswordReset />} />
          <Route path="/Otp" element={<Otp />} />
          <Route path="/myavtar" element={<Myavtar />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/myAccount" element={<MyAccount />} />
          <Route path="/Edit_Profile" element={<Editprofile />} />
          <Route path="/FaqMain" element={<FaqMain />} />
          <Route path="/GuppyAppDownload" element={<DownloadAppGuppy />} />
          <Route path="/MenuMain" element={<MenuMain />} />
          <Route path="/MyStoryFlows" element={<MyStoryFlows />} />
          <Route path="/StoryFlow" element={<StoryFlow />} />
          <Route path="/MyStoryKits" element={<MyStoryKits />} />
          <Route
            path="/MyStoryKits/MyStoryPackageIngredient"
            element={<PackageIngredient />}
          />
          <Route path="/MyStories" element={<MyStories />} />
          <Route path="/MySubmission" element={<MySubmission />} />
          <Route path="/StoryPage" element={<StoryPage />} />
          <Route path="/CreateStory" element={<CreateaStory />} />
          <Route
            path="/ModalStoryIngrediaentsNew"
            element={<ModalStoryIngrediaentsNew />}
          />
          <Route path="/MobileShareIcon" element={<MobileShareIcon />} />
          <Route path="/ShareStoryFlowNew" element={<ShareStoryFlowNew />} />
          <Route path="/SignupOtp" element={<SignupOtp />} />
          <Route path="/BackstageMenu" element={<BackstageMenu />} />
          <Route path="/LandingPageBs" element={<LandingPageBs />} />
          <Route path="/WhoWeAre" element={<WhoWeAre />} />
          <Route path="/OurTeam" element={<OurTeam />} />
          <Route path="/GuppyIntroduction" element={<GuppyIntroduction />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TemplatePage" element={<TemplatePage />} />
          <Route
            path="/PasswordUpdatedScreen"
            element={<PasswordUpdatedScreen />}
          />
          <Route path="/WhatWeDo" element={<WhatWeDo />} />
          <Route path="/IpInside" element={<IpInside />} />
          <Route path="/ContactBackstage" element={<ContactBackstage />} />
          <Route path="/SignUPChild" element={<SignUPChild />} />
          <Route path="/ParentConsentForm" element={<ParentConsentForm />} />
          <Route path="/SignUpSuccessFully" element={<SignUpSuccessFully />} />
          <Route path="/user/consent-pdf" element={<ParentConsentFormMain />} />
          <Route
            path="/StoryInformationBackstage"
            element={<StoryInformationBackstage />}
          />
          <Route path="/BecomingNarad" element={<BecomingNarad />} />
          <Route path="/MalluAndPi" element={<MalluAndPi />} />
          <Route path="/Miniraja" element={<Miniraja />} />
          <Route path="/GuppyStoriesIp" element={<GuppyStoriesIp />} />
          <Route path="/OurStory" element={<OurStory />} />
          <Route path="/Transmedia" element={<Transmedia />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
