import { findByLabelText } from "@testing-library/react";
import React, { createRef, useEffect } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import MySubmission from "../../component/MySubmission";
import Images from '../../component/Images';
// import { useQuery } from 'react-query'
import { api } from "../../ApiService/Apicall";
import { useDispatch, useSelector } from "react-redux";
import { home_api } from "../../redux/homedata/home.actions";
import { getLocal, useMysubmit } from "../../otherexports";
import RagisterFirstModal from "../../component/RagisterFirstModal";
export default () => {

  const userinfo = getLocal("User");
  const Registermodal = createRef(null);
  const { isLoading, useringredient, userstory } = useMysubmit();
  useEffect(() => {
    if (userinfo.name == undefined) {
      Registermodal?.current?.show();
    }
  }, [userinfo])
  if (!isLoading)
    return (
      <div className={styles.body}>
        <Header />
        {userinfo?.name != undefined && <MySubmission data={[...(useringredient || []), ...(userstory || [])]} />}
        <RagisterFirstModal ref={Registermodal} />
      </div>
    );
  else
    return (
      <div className={styles.body}>
        <div className={styles.LoadingMain}
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{ width: '500px' }} src={Images.LoaderGIFweb} />
        </div>
      </div>
    );
};

// export default LandingPage;
