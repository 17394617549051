import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import Images from "./Images";
import { margin } from '@mui/system';

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    backgroundColor: '#E4E4E4'
  },
  topMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  mainText: {
    margin: '10px 5px 5px 5px',
    textAlign: 'center',
  }
});

const supportEmail = 'guppy@guppytheatre.com'

const ParentConsentFormNew = ({ userId, childName, childAge, relationshipWithChild, parentName, parentSign, date }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.topMain}>
          {userId!=undefined &&<Text> Guppy stories user ID: {userId}
          </Text>}
          <Image src={Images.logoNewGuppy} style={{width: '130px'}} />
      </View>
      <View style={styles.mainText}>
        <Text>Parent/Guardian Consent Form</Text>
      </View>
      <View style={styles.section}>
        <Text>[This Consent Form offers your express, verifiable consent, to collect and process your child's personal information. In order to give your consent, please print this form, fill the required information, sign it, scan and email the signed form to {supportEmail} ]</Text>
      </View>
      <View style={styles.section}>
       <Text> Child's full name: {childName}
        </Text>
      </View>
      <View style={styles.section}>
       <Text> {`Child's Age`}: {childAge}
        </Text>
      </View>
      <View style={styles.section}>
       <Text> Relationship: {relationshipWithChild}
        </Text>
      </View>

      <View style={styles.section}>
        <Text>I agree and acknowledge, that I had the opportunity to review the Direct
          Notice to Parents by Guppy Story. I further agree that I have read and
          understood the Privacy Policy and Terms of Use provided by Guppy Story,
          following which, I agree to the collection, use and/or disclosure of my child's
          personal information, as according to the mentioned Privacy Policy and Terms
          of Use.</Text>
      </View>
      <View style={styles.section}>
        <Text>I understand that I may withdraw my permission at any time, with written
        notice, as set forth in Privacy Policy.</Text>
      </View>
      <View style={styles.section}>
       <Text> Parent/Guardian's Name: {parentName}
        </Text>
      </View>
      <View style={styles.section}>
       <Text> Parent/Guardian's Signature: {parentSign}
        </Text>
      </View>
      <View style={styles.section}>
       <Text> Date: {date}
        </Text>
      </View>
    </Page>
  </Document>
);

// ReactPDF.render(<MyDocument />, `${Pdf}/example.pdf`);
// ReactPDF.renderToStream(<MyDocument />);

ParentConsentFormNew.defaultProps = {
  userId: '----------',
  childName: '---------------',
  childAge: '--',
  relationshipWithChild: '---------------',
  parentName: '---------------',
  parentSign: '---------------',
  date: '---------------',
}
export default ParentConsentFormNew;
