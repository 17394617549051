import React, { useImperativeHandle } from "react";
import { useState } from "react";
import Images from "./Images";
import Modal from "react-modal";
import { forwardRef } from "react";
import styles from "../pages/Home/landingpage.module.css";

import Modalstyle from "../pages/StoryPage/storypage.module.css";

const SubmittedSuccessfullyModal = forwardRef(({ text, Click }, ref) => {
  const [modal, setmodal] = useState(false);
  useImperativeHandle(ref, () => ({
    show: () => {
      setmodal(true);
      // return counts;
    },
    hide: () => {
      setmodal(false);
      // return counts;
    },
  }));

  return (
    <Modal
      style={{
        content: {
          display: "flex",
          backgroundColor: "#0000",
          borderWidth: 0,
          flexDirection: "column",
          flex: 1,
          position: "unset",
          overflow: "auto",
          height: "100%",
          zIndex: 999,
        },
      }}
      ariaHideApp={false}
      overlayClassName={Modalstyle.modal}
      isOpen={modal}
      onRequestClose={() => {
        setmodal(false);
      }}
      contentLabel="Example Modal"
    >
      <div className="container">
        <div className={Modalstyle.SubmittedSuccesModal}>
          <img
            className={Modalstyle.sharebtnimg}
            src={Images.CrossblueFrame}
            onClick={() => {
              setmodal(false);
            }}
          />
          {/* <div> */}
            <img src={Images.FrameConfirmedBlue} />
            <h2>{text==undefined?"Submitted \n successfully":text}</h2>
            <button style={{width: "150px"}}
              className={styles.btnFill}
              onClick={() => {
                if (Click != undefined) {
                  Click();
                } else {
                  setmodal(false);
                }
              }}
            >
              OK!
            </button>
          {/* </div> */}
        </div>
      </div>
    </Modal>
  );
});
export default SubmittedSuccessfullyModal;
