import React from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";
import { useEffect, useState, createRef } from "react";
import { signupapi } from "../redux/userdata/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../ApiService/Apicall";
import emailsend from "../ApiService/emailsend";
import NoDraftModal from "./NoDraftModal";
import SubmittedSuccessfullyModal from "./SubmittedSuccessfullyModal";
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import jwt_decode from "jwt-decode";

function Signup({ setloading }) {
  // const postEvent = () =>{
  //     const url = "https://manage.guppytheatre.com/api/"
  //     fetch(url)
  // }
  // const dispatch=useDispatch();
  const [modaltext, setmodaltext] = useState("");
  const modalopentwo = createRef(null);
  const modalopen = createRef(null);
  const navigate = useNavigate();
  const [uname, setUname] = useState("");
  const [Initials, setInitials] = useState("");
  const [relation, setrelation] = useState("");
  // const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function postUser() {
    setloading(true);
    var emailcheck = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (
      email.length == 0 ||
      password.length == 0 ||
      uname.length == 0 ||
      relation.length == 0 ||
      Initials.length == 0
    ) {
      let message =
        uname.length == 0
          ? "Parent/Guardian Name is a required field"
          : relation.length == 0
          ? "Relationship with the child is a required field"
          : email.length == 0
          ? "Email is a required field"
          : !emailcheck.test(email)
          ? "Invalid Email Id"
          : password.length == 0
          ? "Password is a required field"
          : Initials.length == 0
          ? "Parent/Guardian Initials is a required field"
          : "";
      setloading(false);
      setmodaltext(message);
      modalopen.current.show();
    } else {
      let data = {
        email: email,
        // profile:Avatarimg,
        user_media: "110",
        password: password,
        username: email.split("@")[0],
        ParentInitials: Initials,
        ParentName: uname,
        RelationwithChild: relation,
      };
      navigate("/SignUPChild", { state: { user: data, setloading } });
      // let alluser = await api('get',"users");
      // var user = alluser?.data?.filter((e) => e?.email == email);
      // if (user?.length > 0) {
      //   setmodaltext("This email is already linked to an existing account")
      //   modalopen.current.show()
      //   setloading(false);
      // }else{

      //   let sixnumber = 100000 + Math.floor(Math.random() * 900000);
      //   emailsend(
      //     {
      //       email: email,
      //       name: uname,
      //     },
      //     "psk",
      //     sixnumber
      //   );
      //   let data = {
      //     email: email,
      //     user_media: "110",
      //     password: password,
      //     username: email.split("@")[0],
      //     age: age,
      //     name: uname,
      //     penName: uname,
      //   };

      //   navigate("/Otp", {
      //     state: {
      //       data: data,
      //       otp: sixnumber,
      //     },
      //   });
      setloading(false);
    }
  }

  return (
    <div className={styles.signUpBg}>
      <div className={styles.arrowImgMain}>
        <div className={styles.signLogRightLine}>
          <img src={Images.signLogRightLine} />
        </div>
        <div className="container">
          <div className={`row ${styles.signUpInfo}`}>
            <div className={`col-sm-12 col-md-6 ${styles.leftInfo}`}>
              <img src={Images.MuniaMiddle} alt="" />
              <h5>Sign up</h5>
              <p>Sign up with one of the options below</p>
              <div className={styles.socialIcon}>
                {/* <a onClick={()=>{login()}}>
                  <img src={Images.googleSocial} />
                </a> */}
                <GoogleLogin
                  theme={"filled_blue"}
                  text={"continue_with"}
                  // type={"icon"}
                  // size={"large"}
                  shape={"circle"}
                  onSuccess={(credentialResponse) => {
                    var decoded = jwt_decode(credentialResponse.credential);
                    let userdetails = {
                      user_media: "110",
                      email: decoded.email,
                      password: decoded.email?.split("@")[0],
                      username: decoded.email?.split("@")[0],
                      ParentName: decoded?.name,
                    };
                    navigate("/SignUPChild", {
                      state: { user: userdetails, type: "google" },
                      setloading,
                    });
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </div>
            </div>
            <div className={`col-sm-12 col-md-6 ${styles.rightInfo}`}>
              <p className={styles.signupHeadline}>Parent/Guardian’s Details</p>
              <form action="#">
                <div className={styles.wrapdiv}>
                  <label for="uname">Parent/Guardian Name</label>
                  <input
                    type="text"
                    // name="uname"
                    value={uname}
                    onChange={(e) => {
                      setUname(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.wrapdiv}>
                  <label>Relationship with the child</label>
                  <input
                    type="text"
                    // id="quantity"
                    value={relation}
                    onChange={(e) => {
                      setrelation(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.wrapdiv}>
                  <label>Parent/Guardian Initials</label>
                  <input
                    type="text"
                    value={Initials}
                    onChange={(e) => {
                      setInitials(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.wrapdiv}>
                  <label for="email">Parent’s Email</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.wrapdiv}>
                  <label for="psw">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.btndiv}>
                  <button
                    style={{ width: "195px", padding: "7px 0" }}
                    type="submit"
                    className={styles.btnFill}
                    onClick={(e) => {
                      e.preventDefault();
                      // let data = {
                      //   email: email,
                      //   user_media: 6,
                      //   // contactNo: "",
                      //   password: password,
                      //   username: uname,
                      //   age: age,
                      //   name: uname,
                      //   penName: uname,
                      // };

                      postUser();
                    }}
                  >
                    Next
                  </button>

                  <div className={styles.againreg}>
                    <p
                      style={{
                        paddingTop: "30px",
                        fontSize: "18px",
                        lineHeight: "23px",
                      }}
                    >
                      Already have an account?{" "}
                      <a
                        style={{
                          color: "var(--black)",
                          display: "inline-block",
                          textDecoration: "underline",
                        }}
                        href="/login"
                      >
                        {" "}
                        Login
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.signBgLeft}>
          <img src={Images.signupbg} />
        </div>
      </div>
      <NoDraftModal ref={modalopen} text={modaltext} />
      <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
    </div>
  );
}

export default Signup;
