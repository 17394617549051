import React, { useState, createRef, useEffect } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import TheEnd from "./TheEnd";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { getLocal } from "../otherexports";
import put from "../ApiService/put";
import NoDraftModal from "./NoDraftModal";
import SubmittedSuccessfullyModal from "./SubmittedSuccessfullyModal";
import { api } from "../ApiService/Apicall";
function ShareStoryFlowNew() {
  const [insidedata, setinsidedata] = useState(null);
  const [modaltext, setmodaltext] = useState("");
  const modalopentwo = createRef(null);
  const modalopen = createRef(null);
  const navigate = useNavigate();
  const [selectname, setselectname] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [flowname, setflowname] = useState("");
  const [loading, setloading] = useState(true);
  const gettingAPi = async (id) => {
    const results = await api(
      "get",
      `guppy-story-flows/${id}?populate[0]=users_permissions_user`
    );
    setinsidedata(results?.data?.data);
    setloading(false);
  };

  useEffect(() => {
    if (window?.location?.href?.split("=")[1] != undefined) {
      gettingAPi(window?.location?.href?.split("=")[1]);
    }
  }, []);

  // const changetofull = (index) => {
  //   // setloading(true);
  //   let data = insidedata;
  //   data[index].fullview = !data[index].fullview;
  //   setinsidedata(data);
  //   // setloading(false);
  // };

  const common = (data) => {
    return (
      <>
        <img
          src={
            "https://manage.guppytheatre.com" +
            data?.StoryImage?.data?.attributes?.url
          }
        />
        <p>
          {data?.StoryText.replace(/\s+/g, " ").split("##")[0]}
          <span>
            {data?.StoryText?.replace(/\s+/g, " ").split("##")[1]}
          </span>{" "}
          {data?.StoryText?.split("##").map((e, i) => {
            if (i > 1) {
              return e.replace(/\s+/g, " ");
            }
          })}
        </p>
      </>
    );
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const filterkeywordfirts = (key) => {
    const str = key?.Description?.replace("\n", "");
    if (
      str?.split(key?.ingredient_type?.data?.attributes?.typekey)?.length > 1
    ) {
      return str?.split(key?.ingredient_type?.data?.attributes?.typekey)[0];
    } else {
      return str?.split(key?.ingredient_type?.data?.attributes?.TypeName)[0];
    }
  };

  const filterkeywordsecond = (key) => {
    const str = key?.Description?.replace("\n", "");
    if (
      str?.split(key?.ingredient_type?.data?.attributes?.typekey)?.length > 1
    ) {
      return str
        ?.split(key?.ingredient_type?.data?.attributes?.typekey)
        .map((e, i) => {
          if (i > 0 && i == 1) {
            return e;
          } else if (i > 1) {
            return key?.ingredient_type?.data?.attributes?.typekey + e;
          } else {
            return;
          }
        });
    } else {
      return str
        ?.split(key?.ingredient_type?.data?.attributes?.TypeName)
        .map((e, i) => {
          if (i > 0 && i == 1) {
            return e;
          } else if (i > 1) {
            return key?.ingredient_type?.data?.attributes?.TypeName + e;
          } else {
            return;
          }
        });
    }
  };
  if (!loading) {
    return (
      <div className={styles.StoryFlows}>
        <div className={styles.StoryFlows_top}>
          <div className="container">
            <div
              style={{ zIndex: "1" }}
              className={styles.backbtn}
              onClick={() => {
                navigate("/home");
              }}
            >
              <a
                onClick={() => {
                  navigate("/home");
                }}
              >
                <img src={Images.backbtn} />
              </a>
            </div>
            <div className={styles.StoryFlowTop}>
              <div className={styles.MyStoryFlowInfo}>
                <div className={styles.MyStoryFlowInner}>
                  <h5>
                    {selectname != ""
                      ? selectname
                      : insidedata?.attributes?.Story_Flow_Name == null ||
                        insidedata?.attributes?.Story_Flow_Name == ""
                      ? "Story Flow 1"
                      : insidedata?.attributes?.Story_Flow_Name}
                  </h5>
                  <p>
                    {insidedata?.attributes?.users_permissions_user?.data
                      ?.attributes?.username != undefined
                      ? "Author Name : " +
                        insidedata?.attributes?.users_permissions_user?.data
                          ?.attributes?.username
                      : "Author Name : Guest"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.StoryFlow_bgimg}>
            <img src={Images.BadalLeft} />
          </div>
        </div>
        <div className={styles.StoryFlow_bottom}>
          <div className="container">
            {insidedata?.attributes?.story_flow?.map((item, index) => {
              let singlekey = item?.flow_step.attributes?.Sections?.filter(
                (e) => {
                  if (
                    e.keyword.length != 0 &&
                    e?.keyword[0]?.keyword === item.type
                  ) {
                    return e.keyword;
                  }
                }
              )[0]?.keyword[0];
              let Sections = item?.flow_step?.attributes?.Sections;

              return (
                <div className={styles.StoryFlowInfo}>
                  {/* {!item.fullview &&
                    common(item.flow_step.attributes.Sections[0])}
                  {item.fullview &&
                    item.flow_step.attributes.Sections.map((e, i) => {
                      return common(e);
                    })} */}
                  {Sections?.map((e, i) => {
                    let filterindex =
                      item?.type == "continue" || item?.type == undefined
                        ? Sections.length - 1
                        : Sections?.findIndex((n) =>
                            n?.keyword?.some((l) => l?.keyword == item?.type)
                          ) == -1
                        ? Sections.length - 1
                        : Sections?.findIndex((n) =>
                            n?.keyword?.some((l) => l?.keyword == item?.type)
                          );
                    if (i <= filterindex) {
                      return <>{common(e)}</>;
                    }
                  })}
                  <div className={styles.Viewfullstory}>
                    {index !=
                      insidedata?.attributes?.story_flow?.length - 1 && (
                      <img
                        className={styles.storyTopImg}
                        src={Images.EnterArrow}
                      />
                    )}
                    <a
                      href="#"
                      onClick={() => {
                        navigate(`/StoryPage?id=${item?.flow_step?.id}`);
                      }}
                    >
                      {"View original story"}
                      <img src={Images.Eyeimg} />
                    </a>
                  </div>
                  {index != insidedata?.attributes?.story_flow?.length - 1 && (
                    <p>
                      {item.type != "continue" && (
                        <>
                          {filterkeywordfirts(singlekey)}
                          <span style={{ color: "#ED4C55" }}>
                            {
                              singlekey?.ingredient_type?.data?.attributes
                                ?.typekey
                            }
                          </span>
                          {filterkeywordsecond(singlekey)}
                        </>
                      )}
                      {item.type == "continue" && (
                        <>
                          {
                            item?.flow_step?.attributes?.continue_btn?.Description?.replace(
                              "\n",
                              ""
                            ).split(
                              item?.flow_step?.attributes?.continue_btn
                                ?.bold_text
                            )[0]
                          }
                          <span style={{ color: "#000" }}>
                            {
                              item?.flow_step?.attributes?.continue_btn
                                ?.bold_text
                            }
                          </span>
                          {item?.flow_step?.attributes?.continue_btn?.Description?.replace(
                            "\n",
                            ""
                          )
                            .split(
                              item?.flow_step?.attributes?.continue_btn
                                ?.bold_text
                            )
                            .map((e, i) => {
                              if (i > 1) {
                                return e;
                              }
                            })}
                        </>
                      )}
                    </p>
                  )}
                </div>
              );
            })}
            <div className={styles.backtotop}>
              <img src={Images.navBannerleft} />
            </div>
          </div>
        </div>
        <div className={styles.sharestorysecbg}>
          <TheEnd />
          <div className={styles.ShareStoryFlowNewbottom}>
            <img
              src={Images.BadalRight}
              style={{
                position: "absolute",
                bottom: "75px",
                left: "0",
              }}
            />
            <div className="container">
              <p>
                Create your own story flow with <br /> different customization
                options!
              </p>
              <div className={styles.createStoryBottomBtn}>
                <button className={styles.btnFill}>Download the app</button>
              </div>
              <div className={styles.createStoryBottomShare}>
                <span>Available on</span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <a href="https://play.google.com/store/apps/details?id=com.guppytheatre.app">
                    <img src={Images.googleIcon} alt="" />
                  </a>
                  <a href="https://apps.apple.com/in/app/guppy-stories/id6450250856">
                    <img src={Images.appledownload} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <img
              src={Images.BadalLeft}
              style={{
                position: "absolute",
                bottom: "75px",
                right: "0",
              }}
            />
          </div>
        </div>

        <Modal
          style={{
            content: {
              display: "flex",
              backgroundColor: "#0000",
              borderWidth: 0,
              flexDirection: "column",
              flex: 1,
              position: "unset",
              overflow: "auto",
              height: "100%",
              justifyContent: "space-around",
            },
          }}
          overlayClassName={Modalstyle.modal}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <div className="container">
            <div className={Modalstyle.modalstoryflow}>
              <h4>Edit Story Flow Name</h4>
              <p>
                You can edit/change the name of your <br /> story flow according
                to your choice.
              </p>
              <div className={Modalstyle.modalstoryflowSubdiv}>
                <p>
                  <strong>Story flow name</strong>
                </p>
                <input
                  type="text"
                  name=""
                  onChange={(e) => {
                    setflowname(e.target.value);
                  }}
                />
              </div>
              <button className={styles.btnUnfill} onClick={closeModal}>
                Cancel
              </button>
              <button
                style={{ padding: "0 25px" }}
                className={styles.btnFill}
                onClick={closeModal}
              >
                Save
              </button>

              {/* <button className={styles.btnFill} onClick={upadateflowname} >Save</button> */}
            </div>
          </div>
        </Modal>
        <NoDraftModal ref={modalopen} text={modaltext} />
        <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
      </div>
    );
  } else {
    return (
      <div className={styles.body}>
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{ width: "500px" }} src={Images.LoaderGIFweb} />
        </div>
      </div>
    );
  }
}

export default ShareStoryFlowNew;
