import React, { useEffect, useMemo, useRef,useState } from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";
import Modal from 'react-modal';
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import TinderCard from "react-tinder-card";

const StoryPageComp = ({data,setIsOpen2,item,Storydata,setCurrentIndex,setselectedstroy,setkey}) => {

let info = data.StoryImage?.data?.attributes?.url;
  const keypress=()=>{
    let singlekey = item?.attributes?.Sections?.filter((e) => {
      if (e?.keyword?.length != 0 && e?.keyword[0]?.keyword===data?.StoryText.split("##")[1]) {
        return e.keyword;
      }
    })[0];
    setkey(singlekey?.keyword[0]);
    setCurrentIndex(singlekey?.keyword[0]?.guppy_stories?.data.length - 1);
    setselectedstroy([...singlekey?.keyword[0]?.guppy_stories?.data]);
    setIsOpen2(true);
  }


  return (
    <div className={styles.storyPagecompMain}>
        <div className="container"> 
           {info!=undefined && <img src={"https://manage.guppytheatre.com" + info} />}
            <p>{data?.StoryText.split("##")[0]}
            <a href="#" onClick={()=>{keypress()}}>
            {data?.StoryText.split("##")[1]}
            {data?.StoryText.split("##")[1] != "" && data?.StoryText.split("##")[1] != undefined && (<img src={Images.StoryPageArrow} />)}
          </a>
            {data?.StoryText.split("##").map((e, i) => {
            if (i > 1) {
              return e;
            }
          })}</p>
        </div>
    </div>
  );
};

export default StoryPageComp;
