import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import styles from "../../pages/Home/landingpage.module.css";
import FooterBs from "../Component/FooterBs";
import BannerCommon from "../Component/BannerCommon";
import { useNavigate } from "react-router-dom";
import { useBackstagewhoweare } from "../../otherexports";
import MetaTags from 'react-meta-tags';
import Loader from "../Component/Loader";

function Transmedia() {
  const navigate = useNavigate();
  const {data,isLoading}=useBackstagewhoweare();
// console.log(JSON.stringify(data),"=======psk===")
  if(!isLoading){
    return (
      <section className={BsCustom.WrapperOurStory}>
        <MetaTags>
            <title>Transmedia and Guppyverse - Guppy Theater</title>
            <meta name="description" content="We produce participative story worlds in which the 21st century children are at the centre of the stage" />
            <meta name="keywords" content="Transmedia storytelling, metaverse, children" />
        </MetaTags>
        <HeaderBackstageNew />
  
        <section className={BsCustom.BannerCommon}>
            <img className={BsCustom.BannerCommonImg} src={Images.TransmediaBanner} />
            <img className={BsCustom.MobileCommonBannerNew} src={Images.TranmediaBannerMobileNew} />
            <img className={BsCustom.BackBtnBSCommonNew} src={Images.BackstageBackBtn} style={{cursor:"pointer"}}  onClick={() => {
            navigate(-1);
          }} />
        </section>
        <section className={`${BsCustom.WTGBSection} ${BsCustom.TeamLightBCGroung}`}>
          <div className="container-fluid">
              <div className={BsCustom.OurStoryMainTop}>
                  <h1 className={BsCustom.BsH1}>Transmedia</h1>
                  <div className={`row ${BsCustom.Row}`}>
                      <div className={`col-12 ${BsCustom.WhoWeAreRight}`}>
                      <p className={BsCustom.BsCommaonP}>
                      The best way to understand what kids are think and like, is not to analyse past data but to <br/> create with kids themselves. So, to predict the biggest kid's trends for 2023, we turned to the experts.
                      </p>
                      </div>
                  </div>
              </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <img src={Images.TranmediaTop} />
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={Images.TranmediaTop} 
                />
                <p className={BsCustom.BsCommaonP}>
                As our main mission is to place children at the centre of our story universe, we at Guppy Theatre develop and produce our IPs using the principles of transmedia storytelling.
                </p>
              </div>
            </div>
  
              <div className={`${BsCustom.OurStoryExtraParaNew}`}>
                  <p className={BsCustom.BsCommaonP} style={{marginBottom: "30px"}}>But what is Transmedia? In Transmedia storytelling the integral element of a story is retold, possibly repackaged and re-plotted, and dispersed across multi platforms to create an entertaining and variegated story world of that story. There is a seeming story divergence in order to converge into an experiential story world with the use of various formats and digital technologies. The idea is to add to the story not just replicate it in another medium, with each medium leading into the other. In the process of doing so multimedia is used to make the storyworld multi-dimensional, immersive, interactive, and participative.</p>
              </div>
          </div>
        </section>
          <div style={{width: "100%", marginTop: "-55px", position: "relative"}}>
              <img className={`${BsCustom.WhoWeAreSectionNewBottomCutImg}`} src={Images.TransmediaBreaker} />
              <img className={`${BsCustom.MobileIntroducingImgNew}`} src={Images.TranmediaBreakerImgNew} />
          </div>
        <section
          className={`${BsCustom.WhoWeAreSection} ${BsCustom.WhoWeAreSectionNewBottom}`}
        >
          
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <img src={Images.TransmediaBottom}  />
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                  <img className={BsCustom.MainWWANewExtraImg} src={Images.TransmediaBottom} />
                  <h3 className={BsCustom.BsH3}>Guppyverse</h3>
                  <p className={BsCustom.BsCommaonP}>
                  For the children to concretely participate, co-create and co-own our transmedia IPs we will be launching soon the GuppyVerse - a safe and decentralised media ecosystem for children from 6y to 13y. It is our gift to the next generation.
                  </p>
              </div>
            </div>
            <p className={`${BsCustom.BsCommaonP} ${BsCustom.paraMidiletranmedia}`}  style={{margin: "40px 0", display: "block"}}>
              The Roadmap of GuppyVerse has started
              </p>
              <p className={`${BsCustom.BsCommaonP} ${BsCustom.parabottomtranmedia}`} style={{textAlign: "center"}}>
              Phase 0 - GuppyStories APP : Launched. You can download it here. <a href="https://play.google.com/store/apps/details?id=com.guppytheatre.app"><img style={{marginLeft: "15px"}} src={Images.googleIcon} /></a>
              </p>
          </div>
        </section>
        <FooterBs />
      </section>
    );
  }
  else 
  {return (<Loader />)}
}

export default Transmedia;
