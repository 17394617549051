// import { createStore } from 'redux';

// import rootReducer from './rootReducer';


// const store = createStore(rootReducer);


// export default store;

import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import {persistStore, persistReducer} from 'redux-persist';
import {composeWithDevTools} from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
// import logger from 'redux-logger';
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk)),
  // composeWithDevTools(applyMiddleware(logger,thunk)),
);
export const persistor = persistStore(store);


export default store;