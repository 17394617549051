// import { useNavigate } from "react-router-dom";
import { sendWelcomeEmailAction } from "../../api/sendWelcomeEmail";
import api from "../../ApiService/Apicall";
import otherpost from "../../ApiService/otherpost";
import { setLocal } from "../../otherexports";
import { USER_SUCCESS, USER_FAILURE, USER_Request } from "./user.types";

export const User_reuest = () => {
  return {
    type: USER_Request,
  };
};
export const User_SUCCESS = (payload) => {
  return {
    type: USER_SUCCESS,
    payload,
  };
};
export const User_FAILURE = (error) => {
  return {
    type: USER_FAILURE,
    payload: error,
  };
};

//===========Login FUNCTION=========================
export const loginapi = ({ datasend, onSuccess, onFaliure }) => {
  return async (dispatch) => {
    setLocal("Token", null);
    dispatch(User_FAILURE());
    try {
      const response = await api("post", "auth/local?identifier=", datasend);
      setLocal("Token", response?.jwt);
      const responsetwo = await api("get", `users/me`, {}, "toke");
      setLocal("User", responsetwo?.data);
      dispatch(User_SUCCESS(response));
      onSuccess(response);
    } catch (error) {
      dispatch(User_FAILURE(error));
      onFaliure(error);
    }
  };
};

//===========Login FUNCTION=========================
export const logout = ({ onSuccess }) => {
  return async (dispatch) => {
    localStorage.clear();
    dispatch(User_SUCCESS({}));
    onSuccess({});
  };
};

//===========Signup FUNCTION=========================
export const signupapi = ({ datasend, onSuccess, onFaliure }) => {
  return async (dispatch) => {
    dispatch(User_FAILURE());
    try {
      // console.log(JSON.stringify(datasend),"=========JSON.stringify(datasend)");
      const avatarData = await api("get", "guppy-avtar-lists?populate=*");
      const response = await api("post", "auth/local/register", datasend);
      setLocal("Token", response.jwt);
      const defalutone = avatarData?.data?.data?.filter(
        (e) => e?.attributes?.default == true
      );
      // console.log(JSON.stringify(defalutone),"=======defalutone======defalutone====defalutone===defalutone");
      let otherdata = {
        data: {
          guppy_avtar: defalutone[0]?.id?.toString(),
          user: response?.user?.id?.toString(),
        },
      };
      const infortwo = await otherpost(
        "guppy-avtars",
        otherdata,
        "guppy-avtars",
        response.jwt
      );
      const responsetwo = await api("get", `users/me`, {}, "toke");
      setLocal("User", responsetwo.data);
      dispatch(User_SUCCESS(response));
      onSuccess(response);
      sendWelcomeEmailAction(responsetwo.data);
    } catch (error) {
      dispatch(User_FAILURE(error));
      onFaliure(error);
    }
  };
};

//===========User Edit FUNCTION=========================
export const userEditapi = ({ id, data, onSuccess, onFaliure }) => {
  return async (dispatch) => {
    dispatch(User_FAILURE());
    try {
      // console.log("users/" + id,"=========JSON.stringify(datasend)",JSON.stringify(data));
      const response = await api("put", "users/" + id, data, "psk");
      console.log(response, "==========");
      // setLocal("Token",response.jwt)
      const responsetwo = await api("get", `users/me`, {}, "toke");
      setLocal("User", responsetwo.data);
      dispatch(User_SUCCESS(response));
      onSuccess(response);
    } catch (error) {
      dispatch(User_FAILURE(error));
      onFaliure(error);
    }
  };
};
