import React from 'react'
import Images from './Images';
import styles from '../pages/Home/landingpage.module.css';


function TheEnd(){
    return (
        <div>
            <img style={{width: "100%"}} src={Images.dashedLine} />
            <div className={styles.theEndImg}>
                <img src={Images.TheEnd} />
            </div>
            <img style={{width: "100%", marginBottom: "30px"}}  src={Images.dashedLine} />
        </div>
    )
}


export default TheEnd;

