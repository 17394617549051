import React from "react";
import { useState } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import Modal from "react-modal";
import * as moment from 'moment'
// import SharebtnModal from '../../src/component/SharebtnMain';
import Modalstyle from "../pages/StoryPage/storypage.module.css";

function MyStories_stories({ Storys }) {
  // modal
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const singlestory = (data, index) => {
    return (
      <div className={styles.mystoriesInner}>
        {data?.attributes?.story_thumbnail==undefined&&<img
          // src={Images.StoryFlow1}
          src={
            data?.attributes?.story_media?.data != undefined &&
            (data?.attributes?.story_media?.data?.attributes.mime ==
              "image/png" ||
              data?.attributes?.story_media?.data?.attributes.mime ==
                "image/jpg")
              ? "https://manage.guppytheatre.com" +
                data?.attributes?.story_media?.data?.attributes?.url
              : Images.StoryFlow1
          }
        />}
         {data?.attributes?.story_thumbnail!=undefined&&<img
          // src={Images.StoryFlow1}
          src={"https://manage.guppytheatre.com"+data?.attributes?.story_thumbnail?.data?.attributes?.url}
        />}
        <div className={`${styles.mystoriesInnerInfo}`}>
          <div className={`${styles.mystoriesInnerLeft}`}>
            <h3>{data?.attributes?.Title || data?.attributes?.title}</h3>
            <p>{data?.attributes?.users_id?.data?.attributes?.username || data?.attributes?.user_ids?.data?.map((e)=>e?.attributes?.username)?.join(" , ") }</p>
          </div>
          <div className={`${styles.mystoriesInnerRight}`}>
            <div>
              <p>Published</p>
              <p>{moment(data?.attributes?.publishedAt).format("Do MMM ,YYYY")}</p>
            </div>
            <div>
              <img src={Images.Sharebtn} onClick={openModal} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {Storys?.map((e, i) => singlestory(e, i))}
      <Modal
        style={{
          content: {
            display: "flex",
            backgroundColor: "#0000",
            borderWidth: 0,
            flexDirection: "column",
            flex: 1,
            position: "unset",
            overflow: "auto",
            height: "100%"
          },
        }}
        overlayClassName={Modalstyle.modal}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className={Modalstyle.modalMyStories}>
            <div className={Modalstyle.sharebtnlink}>
              <img
                className={`${Modalstyle.sharebtnimg} ${styles.sharebtnimg}`}
                src={Images.backbtncross}
                onClick={closeModal}
              />
              <p>Share</p>
              <div className={Modalstyle.modalMyStoriesMobile}>
                <a href="#">
                  <img src={Images.DownloadShareNew} />
                  <span>Download</span>
                </a>
                <a href="#">
                  <img src={Images.Sharewhtspp} />
                  <span>Whatsapp</span>
                </a>
                <a href="#">
                  <img src={Images.Sharemessage} />
                  <span>Email</span>
                </a>
                <a href="#">
                  <img src={Images.facebookShareNew} />
                  <span>Facebook</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MyStories_stories;
