import { findByLabelText } from "@testing-library/react";
import React, { useEffect } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import MyStoryPackageIngredient from "../../component/PackageIngredient";
import Images from '../../component/Images';
// import { useQuery } from 'react-query'
// import { api } from "../../ApiService/Apicall";
// import { useDispatch, useSelector } from "react-redux";
// import { home_api } from "../../redux/homedata/home.actions";
// import { useQuery } from "@tanstack/react-query";
import { useInsidePack } from "../../otherexports";
export default () => {
  const { data, isLoading} = useInsidePack()
  // const dispatch = useDispatch();
  // const { data, loading } = useSelector((state) => state.home_data.Home);
//   console.log(JSON.stringify(data.filter((e) =>
//   e?.attributes?.ingredient_type_id?.data?.attributes
//     ?.TypeName==="Who"
// )),"==============")
  // useEffect(() => {
  //   fatch_data();
  // }, []);

  // const fatch_data = async () => {
  //   dispatch(home_api());
  // };

  if (!isLoading)
    return (
      <div className={styles.body}>
        <Header/>
        <MyStoryPackageIngredient data={data} />
      </div>
    );
  else
    return (
      <div className={styles.body}>
        <div className={styles.LoadingMain}
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
        </div>
      </div>
    );
};

// export default LandingPage;
