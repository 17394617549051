import React from 'react'
import Images from './Images';
import styles from '../pages/Home/landingpage.module.css';
import Header from './Header';
import { Link, useNavigate } from 'react-router-dom';


function PrivacyPolicy() {
    const navigate = useNavigate();
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleBackClick = () => {
        if (window.history && window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/home');
        }
    };
    return (
        <div>
            <Header />
            <section className={styles.TermsConditionsMain}>
                <div className='container'>
                    <div className={styles.TermsConditionsTop}>
                        <img src={Images.BackstageBackBtn} onClick={handleBackClick} style={{ cursor: "pointer" }} />
                        <h1>Privacy Policy</h1>
                    </div>
                    <div className={styles.TermsConditionsBottom}>
                        <div>
                            <p>Crossover Media and Design Private Limited having its registered address at St Helens Court Peddar Road Mumbai MH 400026 IN (hereinafter referred to as “we”, “us” and/or “Company”), and engaged in the business of audio visual and literary content production, has developed an English language, mobile application for children, titled, ‘Guppy Story’ (hereinafter referred to as “Application”/”App”). This privacy policy (“Policy”) governs the manner in which the Company collects, uses, maintains and discloses information collected from you, and outlines how you can limit the sharing of information on the Application. The users of the App are children of the age group between 6 years to 9 years (“User/s”), who are not legally competent to contract.  Only subject to verifiable consent provided by their legal guardians and/or parents (“you” or “your/s”), Users may be permitted to access the App. </p>
                            <p>We are committed to protecting the privacy of our Users, and follow the required and appropriate security, administrative measures and processes that are a must to take care of and guard your personal data from breach of privacy, loss, misuse, or alteration of the same. The Policy also describes the rights and options available to you with respect to the protection of your personal data. A User can browse the Application without revealing any information about himself/herself/itself, however, access to all our services shall be provided only after we have received verifiable consent from you. We collect only that information volunteered by or on behalf of the User. In case you/User does not provide basic personal information, we may not be able to serve User’s request on the Application.</p>
                            <h5>1. Consent</h5>
                            <p>We are committed to protecting the privacy of children (Users) who use our App, and do not permit them to create an account on the App, nor do we knowingly collect personal information from them, without the consent and express direction of a parent/guardian. On signing up on the App, you shall receive a verification email with directions to provide your verifiable consent, which is required to activate a User’s account. The email shall consist of a consent form, which you are expected to sign and mail us a scanned copy of the same. Upon mailing us the signed form, your User account will be activated. The signed form also implies that you give us your unconditional consent on behalf of the User, to be bound by the Terms and the Privacy Policy, and any other document interlinked to the App. Further, if at any time, you believe your child is participating in an activity on the App that collects personal information and you or another parent/guardian have not received an email providing notice or seeking your consent, please contact us at guppy@guppytheatre.com. We will not use your email address provided for parental consent, for marketing purposes, unless you as the parent have expressly opted for marketing mailers or have separately participated in an activity that allows for such communication.</p>
                            <p> In case you do not agree with the Policy or any part thereof, please do not let your child (User) proceed with the access of the App in any way. We urge you to always monitor the use of the App by the User.</p>
                            <h5>2. Data we collect </h5>
                            <p>We limit our collection of personal information from Users to no more than is reasonably necessary to enable a User’s participation on the App, in view of which, we may collect the following information:</p>
                            <p>
                                <ul>
                                    <li><p>a. <strong>Data that is necessary for us to provide you the service:</strong> When you sign up with us, we collect personal information like User’s first and last name, User’s pen name (which shall be visible to other users), your email address and phone number, etc. In the event, you sign up using a third-party account like Facebook and Google, they may share with us information that is necessary for your use of our service and to verify your account, such as your name, email address, gender and birthdate. We use this information only to create a User account, to identify Users, to communicate with you, to improve our services and App, and to develop new features.</p></li>
                                    <li><p>b. <strong>Data you may provide us:</strong>  We collect personal information like telephone number, physical address, which may not be necessary for providing services, but which you may provide us, to help us improve our services and to provide you with access to additional and exclusive features of the App. We may need additional device permissions to access the voice recorder, camera, photos gallery and other functions of your device, to enable you to submit audio recording files, pictures and video files on the App. Further, in order to make any purchases, or pay participation fee for contests, etc., you would be required to provide us with your financial data, including your account details, card details, currency, etc. We may also collect information from you through our surveys and newsletters, from which you can unsubscribe by clicking on the ‘unsubscribe’ option provided therein. However, you may still receive communications from us for up to 15 (fifteen) business days, till we process your request.</p></li>
                                    <li><p>c. <strong>Data we collect by your interaction with us and the use of the Application:</strong> We collect data based on our interaction and your use of the App, which includes geolocation information, IP address, web or mobile browser type, mobile carrier, operating system, hardware device type etc. We may need separate permissions from you in order to collect data via Cookies (defined below), through which we collect your usage trends. This information helps us personalize your user experience, improve our services an d provide you with appropriate security measures.</p></li>
                                </ul>
                            </p>
                            <p>Except for your email address, which is required to create your account on the App, you can always opt not to disclose any personal information to us, but then you may be unable to use certain features on the App. </p>
                            <h5>3. How we process your data</h5>
                            <p>We process the data collected by us, as per the procedure laid down in this Policy, and by using our content management system. The legal basis for processing your personal data under EU GDPR, is the express consent that you provide us on behalf of a User, when you sign up with us to avail our services. We shall follow and process your requests to protect your rights relating to your data, including its access and restrictions of processing. Any deletion or corrections to the data shall only be undertaken on your express request and subject to your consent only. For the purpose of processing the data, we may engage a third-party data processor, who shall process the data in compliance with the applicable laws. In case of any change in the engagement of the current third-party data-processor, we shall update the same on the Policy, at least 7 (seven) days prior to such appointment and engagement. </p>
                            <p>It is important to note that our App and its servers are operated, through servers located in various places in the world, and also on third party servers like Amazon Web Services. If you and the User are located outside of India, please be aware that the collected data will be transferred to, processed, and used in India or elsewhere. Therefore, User’s personal data may be processed outside the jurisdiction of your country of residence, including in countries and jurisdictions that are subject to different standards of data protection. We will take necessary steps to ensure that transfers of personal information are in accordance with applicable laws and are carefully managed to protect User’s privacy rights and interests, including through the use of standard contractual clauses. By allowing a User to use the App and by providing us your verifiable consent, you consent to such transfer, processing, and use of the data in accordance with this Policy. We reserve the right to change the third-party for operation of the App servers, you acknowledge that by allowing the use of the App, you are consenting to be bound by terms of use and privacy related provisions of such third-parties.You are requested to kindly check the Policy regularly. While processing the data, in event of discovery of a breach of personal data, we shall without undue delay notify you of the same. On the knowledge of any such breach, we shall make efforts to mitigate the breach and prevent its recurrence.</p>
                            <h5>4. How we store and secure your data</h5>
                            <p>We use industry standard and organizational measures to secure the data we store. We may in the future, store your data with ‘Skyflow’, a third-party service provider. Any third-party service provider appointed by us, is compliant with the applicable laws and in the event you wish to obtain more information on their practice, you may access their privacy policies on their respective websites like, Skyflow: <a href="https://www.skyflow.com/product/pii-data-privacy-vault">https://www.skyflow.com/product/pii-data-privacy-vault</a> </p>
                            <p>Unless you submit a deletion request, the data that we collect, is generally retained by us for a period necessary to provide quality services, which would be determined on the minimum period required to retain particular data. However, the duration for retaining the data shall also depend upon our legal obligations to you, and to defend any legal claims against us, as may become necessary. In the event you no longer wish for your child (User) to participate on the App and wish to remove User’s data from our servers, you may request the same by writing to us.  On any such requests by you, we will ordinarily remove your data within 45 (forty-five) days from the request. We may continue to retain the data as may be necessary to comply with our legal obligations.</p>
                            <h5>5. With who we share User’s data</h5>
                            <p>We maintain a high level of security standards for User’s personal data, and <strong>do not sell personal data or share it with third-parties,</strong> except in cases where it is being shared/transferred to our service-providers, who provide services on our behalf. We share the data collected from various sources, in the following cases:</p>
                            <p>
                                <ul>
                                    <li><p><span>a. Third-party service provider:</span>We may share your data with our third-party service providers, who may help us in the functioning and operation of the Application. However, their use of the data is limited to the purpose of providing us their services. By the terms of their contractual obligations to us, in no case they shall use the data for their own personal purpose or any other purpose not forming part of their services to us. </p></li>
                                    <li><p><span>b. Corporate restructuring:</span> In event of merger, acquisition or amalgamation with third-party entity, we reserve the right to transfer the data that we have collected from you. However, such new entity shall respect your personal data and shall use it in a manner consistent with our Policy and applicable laws. We may also share your personal data with our affiliated companies, group companies, or subsidiaries (as and when applicable) to process personalized contents and experience; and/or offer promotions and events for our products and services or third-party’s products and services.</p></li>
                                    <li><p><span>c. Third-party designated by you:</span>We may share User’s personal information with third parties, where you have provided your consent to do so. Please note that once we share your personal information with any third-party, upon your request, the information received by such third-party is controlled by it and becomes subject to the other third-party’s privacy practices.</p></li>
                                    <li><p><span>d. Authorities:</span>Only as may be legally required by competent authorities, we may share your information with them. We may share your personal data as we believe may be necessary or appropriate in the following cases: <br />
                                        (a) to comply with applicable laws; <br />
                                        (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities to meet national security or law enforcement requirements; <br />
                                        (c) to enforce our Policy; and  <br />
                                        (d) to protect our rights, privacy, safety or property, and/or that of you or others.
                                    </p>
                                    </li>
                                </ul>

                            </p>
                            <h5>6. Cookie Policy </h5>
                            <p>"Cookies" are small pieces of information that a server sends to your computer’s hard drive while you are viewing a webpage. We use cookies, local storage and other technologies, to recognize your device, to learn more about your preferences for improving security, and to provide you with essential features and services, to enhance and improve our services and App. We may use session Cookies (temporary cookies), persistent Cookies (which stay on your device until you delete them) and third- party Cookies (tracking cookies that track your online behavior) to provide you with a more personal and interactive experience on our App. Cookies allow you to take advantage of some of our essential features, available on the App. You can manage cookies through your device settings. Approved third parties, including search engines and social media networks, may also set cookies, in the process of performing services, on our behalf, when you interact with us. You may access their cookie policy on their respective websites.</p>
                            <h5>7. Opting out from our marketing communications:</h5>
                            <p>You may 'opt-out' from our marketing communications, by communicating the same to us, by sending an email to: guppy@guppytheatre.com. , Post your request we shall cease to process the information, required to contact you for marketing communications. However, the information which is necessary to provide you with the services will continue to be processed and used.</p>
                            <h5>8. Exclusion:</h5>
                            <p>This Policy does not apply to User Content and any material that any User posts or otherwise makes available on the App in a public way. Please do not post or allow the User to post or add any personal information to the App in a public way or which you would not want to be publicly available. . We do not control information that other Users of the App have copied out of the App.</p>
                            <h5>9. Parental Rights</h5>
                            <p>Depending on your and User’s location, you may also have certain additional rights by law, with respect to the personal information provided to us, such as: (i) data access and portability; (ii) data correction; (iii) data deletion; and (iv) withdrawal of consent or objection to processing data, including in limited circumstances, the right to ask us to stop processing your personal data, with some exceptions, by contacting us. </p>
                            <p>As parents/legal guardians of Users, you have certain additional rights in the User’s personal information that you share with us. These rights include; (i) the right to review your child’s personal information, (ii) direct us to delete your child’s personal information, (iii) modify your child’s personal information, and (iv) the right to refuse further use or future collection of your child’s personal information </p>
                            <p>To access, modify or delete the personal information that you have provided to us, or to exercise any above-mentioned rights, please contact us.</p>
                            <h5>10. International Use</h5>
                            <p>As further described in the Terms of Use, the Application is directed to, and is for use by Users in the India. Please be aware, if you use or allow your child to use the App outside of India, any information that we collect, may be transferred to, stored, and/or processed in India or may be sent back out of India to your country of residence or elsewhere in the normal course of the Company providing the services of the App. Notwithstanding the foregoing, we may transfer any information or data to any other country in which the Company chooses to operate in the future.</p>
                            <h5>11. Applicable Laws</h5>
                            <p>The App is based in India, and is compliant with all the laws of Indian jurisdiction. Additionally, this Policy is also compliant with international laws like EU General Data Protection Regulation (GDPR), California Consumer Privacy Act of 2018 (CCPA) and (United States Code) Children's Online Privacy Protection Act, 1998 (COPPA). We take all measures to provide you all rights as conferred upon you by the applicable laws. </p>
                            <h5>12. Amendment</h5>
                            <p>In adherence of the applicable laws, we timely update and annually amend our Policy. You can access the updated Policy on our Application. It is important that you read our Policy before you begin interacting with us so that you understand how and why we process your data.</p>
                            <h5>13. Contact us</h5>
                            <p>If you have any questions or concerns about your child's use of the Application, please contact us at guppy@guppytheatre.com.</p>
                        </div>
                        <div>
                            <h2>Notice To Parents</h2>
                            <h5>Greetings from the team,</h5>
                            <p>You have been identified as a parent/legal guardian of ______ (“User”). He/she would like to create an account on Guppy Story and be a part of our community. Your email address has been provided to us by the User, to obtain your consent for the purpose of activating the User’s Account on our Application. Our Application ‘Guppy Story’ is an interactive and participative storytelling application, which has been specially designed for kids between the ages of 6-9 years.</p>
                            <p>If you provide your consent to activate your child’s account, he/she would able to access the various features on our Application, including reading and submitting stories in the form of photos, texts, audio and video files. We may, at our sole discretion, publish such stories submitted by Users on our Application and elsewhere, with due credits at our discretion. Our Application helps to encourage children to write and nurture their creativity.</p>
                            <p>For maintaining User’s account, subject to your consent, we collect User’s personal information like children name, pen name, parent's email id, parent's phone number. Your child should be encouraged to create a pen name and the same should be informed to us. Kindly note that the pen name of the User shall be visible to other Users of the Application. We do not sell User’s information or share it with third-parties, in any manner not consistent with the Privacy Policy. We do not collect User’s information, without obtaining your consent.</p>
                            <p>In order to give your consent, you are required to sign the consent form, attached herein, and send the same back to us. If you want a detailed understanding of our practice, you may access our Terms of Use and Privacy Policy. By activating your child’s account, you consent to our practice of collection, use, or disclosure of User’s information, as prescribed in our Privacy Policy and our Terms of Use. </p>
                            <p>If you do not wish to activate your child’s account, you may email us your request at <a href='guppy@guppytheatre.com' >guppy@guppytheatre.com</a>. Kindly note that if you do not respond with the duly signed consent form, within 15 days, we will delete your online contact information from our database.</p>
                            <p>f you have any questions or concerns about your child's use of the application, please contact us at guppy@guppytheatre.com.</p>
                        </div>
                        <a className={styles.DownloadConsentFormText} href={require('../assets/Pdf/ConsentFormGuppyStory.pdf')} download >
                            <img className={styles.DownloadConsentFormimg} src={Images.DownloadConsentForm} /><a style={{
                                fontSize: '24px',
                                lineHeight: '31px',
                                fontWeight: '700',
                                textDecorationLine: 'underline',
                                color: '#000000',
                                marginLeft: '15px',
                            }}>Download Consent Form</a>

                        </a>
                    </div>
                    <div style={{ textAlign: 'right', cursor: "pointer" }} className={styles.BackToTop} onClick={() => { window.scrollTo(0, 0) }}>
                        <img onClick={handleScrollToTop} src={Images.navleft} style={{ transform: 'rotate(90deg)', width: '70px' }} className={styles.BackToTOpImg} /><span>Back to top</span>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default PrivacyPolicy;

