import React, { useImperativeHandle } from "react";
import { useState } from "react";
import Images from "./Images";
import Modal from 'react-modal';
import { forwardRef } from "react";
import styles from '../pages/Home/landingpage.module.css';
import Modalstyle from "../pages/StoryPage/storypage.module.css";

 const  MobileShareIcon= forwardRef(({text}, ref) =>{

    return(
        <div className={Modalstyle.modalIngredientsshareMobile}>
            <div className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img src={Images.Sharecopylink} />
                <span>CopyLink</span>
              </a>
              <a href="#">
                <img src={Images.Sharewhtspp} />
                <span>Whatsapp</span>
              </a>
            </div>
            <div className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img src={Images.Sharemessage} />
                <span>Email</span>
              </a>
            </div>
            <div className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img src={Images.ShareSnap} />
                <span>Snapchat</span>
              </a>
              <a href="#">
                <img src={Images.Insta} />
                <span>Instagram</span>
              </a>
            </div>
          </div>
       )
});
export default MobileShareIcon;