import React from "react";
import { useState } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getLocal } from "../otherexports";
import { useNavigate } from "react-router-dom";
import api from "../ApiService/Apicall";
import zIndex from "@mui/material/styles/zIndex";

function AvtarImg({ data }) {
  const navigate=useNavigate();
  const [selected, setselected] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal(e) {
    setselected(e)
    setIsOpen(true);
  }
  function closeModal() {
    setselected({})
    setIsOpen(false);
  }

  const {data:useravtarlist } = useQuery(["userGuppyAvtar"], async () => {
    const results = await api("get","guppy-avtars?populate[0]=guppy_avtar&populate[1]=guppy_avtar.avtar_media&populate[2]=user", {},"token")
    return results.data.data;
  });

 const updateavtar=async()=>{
    const token_data = getLocal('User');
    let user_id = token_data?.id?.toString();
    let otherdata={
      "data": {
        "guppy_avtar": selected.id.toString(),
        "user":user_id,
      }
    }
      let otherinfo=useravtarlist?.filter((e)=>e.attributes?.user?.data?.id?.toString()===user_id)
      console.log(JSON.stringify(otherinfo[0]))
      await api("PUT","guppy-avtars/"+otherinfo[0]?.id, otherdata,"info");
      setselected({})
      setIsOpen(false);
      navigate("/myAccount")
  }
 


  return (
    <div className={styles.avtarMain}>
      {data?.map((item, index) => {
        // console.log(JSON.stringify(item))
        return (
          <div className={styles.avtarmainInfo} key={index+""}>
            <img
              src={"https://manage.guppytheatre.com" + item?.attributes?.avtar_media?.data?.attributes?.url}
              onClick={()=>{openModal(item)}}
            ></img>
            <p>{item?.attributes?.avtar_name}</p>
            <Modal
              style={{
                content: {
                  display: "flex",
                  backgroundColor: "#0000",
                  borderWidth: 0,
                  flexDirection: "column",
                  flex: 1,
                  position: "unset",
                  justifyContent: "space-around",
                  height: "100%",
                  overflow: "auto"
                },
              }}
              overlayClassName={Modalstyle.modal}
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
            >
              <div className="container">
                <div className={Modalstyle.modalAvatar}>
                  <div style={{position: "relative"}}>
                    <img src={"https://manage.guppytheatre.com" + selected?.attributes?.avtar_media?.data?.attributes?.url} />
                    <img className={Modalstyle.modalAvatarLeftImg} style={{
                      position: "absolute",
                      top: "-42px",
                      left: "78px",
                      width: "35%",
                      zIndex: "-1"
                    }} src={Images.signLogRightLine} />
                    <img className={Modalstyle.modalAvatarRightImg}  style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "122px",
                      width: "20%",
                      zIndex: "-1",
                      transform: "rotate(180deg)",
                    }} src={Images.Ingredientstop} />
                  </div>
                  <p>
                    <strong>{selected?.attributes?.avtar_name}</strong>
                  </p>
                  <button style={{borderImageOutset: "2 0 8px 0"}} className={styles.btnUnfill} onClick={closeModal}>
                    Cancel
                  </button>
                  <button className={styles.btnFill} onClick={()=>{updateavtar()}}>Confirm</button>
                </div>
              </div>
            </Modal>
          </div>
        );
      })}
    </div>
  );
}

export default AvtarImg;
