import React, { useState } from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "../component/Images";

const Accordion = ({ title, content, videoUrl, item }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.accordionItem}>
      <div
        className={styles.accordionTitle}
        onClick={() => setIsActive(!isActive)}
      >
        <div>
          <span>{title}</span>
        </div>
        <div>
          <span>
            {isActive ? (
              <img src={Images.accordianArrow} />
            ) : (
              <img
                className={styles.accordianArrrow}
                src={Images.accordianArrow}
              />
            )}
          </span>
        </div>
      </div>
      {isActive && (
        <div className={styles.accordionContent}>
          <span>{content}</span>
          <div
            className={styles.accordianConImg}
            onClick={() => {
              if (!!item.attributes.youtube_url) {
                window.open(item?.attributes?.youtube_url, "_blank");
                console.log("pdgg");
              }
            }}
          >
            <img
              src={
                !!item.attributes.youtube_url
                  ? Images.youtube
                  : "https://manage.guppytheatre.com" + videoUrl
              }
              className={!!item.attributes.youtube_url ? styles.youtbe:styles.img}
            />
          </div>
          {!!item.attributes.youtube_url&&<a href="#">
            <sapn>Watch the video tutorial!</sapn>
          </a>}
        </div>
      )}
    </div>
  );
};

export default Accordion;
