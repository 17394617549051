import React, { useEffect } from "react";
import { useState } from "react";
import Images from "./Images";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import styles from "../pages/Home/landingpage.module.css";
import { useLocation, useNavigate } from "react-router-dom";

function PackageIngredient({ data }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [item, setitem] = useState(null);
  const [type, settype] = useState("Who");
  const [select, setselect] = useState([]);
  const [active, setactive] = useState(0);
  const [selected, setselected] = useState({});
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches)
  const fourWs = ["Who", "Where", "When", "What"];

  useEffect(() => {
    if (location?.state?.item) {
      setitem(location?.state?.item);
      let selectedinditwo =
        location?.state?.item?.attributes?.ingredients?.data?.map((e) => {
          return e?.id?.toString();
        });
      setselect(selectedinditwo);
    }

    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, [location?.state?.item]);



  //   const static_data = [
  //     { img: Images.MuniaPackage, title: "Munia" },
  //     { img: Images.MuniaPackage, title: "Munia" },
  //     { img: Images.MuniaPackage, title: "Munia" },
  //     { img: Images.MuniaPackage, title: "Munia" },
  //     { img: Images.MuniaPackage, title: "Munia" },
  //   ];
  // modal

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const changeval = (text) => {
    settype(text);
  };

  // Modal
  // console.log(JSON.stringify(location?.state?.item),"==location?.state?.item==location?.state?.item==location?.state?.item")

  return (
    <div className={`${styles.PackagesMainGredient} ${styles.PackagesMain}`}>
      <div className="container">
        <div
          className={styles.backbtn}
          onClick={() => {
            navigate(-1);
          }}
          style={{ cursor: "pointer" }}
        >
          <a
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={Images.backbtn} />
          </a>
        </div>
        <h4>My Story Packs</h4>
        <div className={`row ${styles.PackagesMain_top}`}>
          <div className={`col-md-12 col-lg-6 ${styles.PackagesMain_topLeft}`}>
            <img style={{ borderRadius: "12px" }}
              src={
                "https://manage.guppytheatre.com" +
                item?.attributes?.PackageImage?.data?.attributes?.url
              }
            />
          </div>
          <div className={`col-md-12 col-lg-6 ${styles.PackagesMain_topRight}`}>
            <h3>{item?.attributes?.Name}</h3>
            <p>
              You can select ingredients for your 4 W’s ( Who, What, When,
              Where) from this package
            </p>
          </div>
        </div>
        <div className={styles.PackagesMain_nav}>
          <ul>
            <a className={styles.PackagesMain_arrow}
              style={{ opacity: active == 0 ? 0.5 : 1, cursor: "pointer" }}
              onClick={() => {
                if (active != 0) {
                  changeval(fourWs[active - 1]);
                  setactive(active - 1);
                }
              }}>
              <img src={Images.BlueRightImgArrow} />
            </a>
            <li
              className={type == "Who" && styles.active}
              onClick={() => {
                changeval("Who");
              }}
              style={{ cursor: "pointer" }}
            ><span style={{ cursor: "pointer" }}>{!matches ? fourWs[active] : "Who"}</span>
            </li>
            <li
              className={type == "Where" && styles.active}
              onClick={() => {
                changeval("Where");
              }}
              style={{ cursor: "pointer" }}
            >
              <span style={{ cursor: "pointer" }}>Where</span>
            </li>
            <li
              className={type == "When" && styles.active}
              onClick={() => {
                changeval("When");
              }}
              style={{ cursor: "pointer" }}
            >
              <span style={{ cursor: "pointer" }}>When</span>
            </li>
            <li
              className={type == "What" && styles.active}
              onClick={() => {
                changeval("What");
              }}
              style={{ cursor: "pointer" }}
            >
              <span style={{ cursor: "pointer" }}>What</span>
            </li>
            <a
              className={styles.PackagesMain_arrow}

              style={{ opacity: active == 3 ? 0.5 : 1, cursor: "pointer" }}
              onClick={() => {
                if (active != 3) {
                  changeval(fourWs[active + 1]);
                  setactive(active + 1);
                }
              }}
            >
              <img src={Images.BlueRightImgArrow} />
            </a>
          </ul>
        </div>
        <div className={styles.PackagesMain_bottom}>
          <p>
            Please select an ingredient from <br /> the list below.
          </p>
          <div className={styles.PackagesInfo}>
            {data
              ?.filter(
                (e) =>
                  e?.attributes?.ingredient_type_id?.data?.attributes
                    ?.TypeName === type && select?.includes(e?.id?.toString())
              )
              .map((item, index) => {
                return (
                  <div className={styles.PackagesInfoInner}>
                    <div className={styles.PackagesInfoInnerImg}>
                      <img
                        src={"https://manage.guppytheatre.com" + item?.attributes?.IngredientImage?.data?.attributes?.url}
                        onClick={() => { setselected(item); openModal(); }}
                      />
                      <p>{item?.attributes?.IngredientName}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className={styles.pacakageNextcontent}>
        <div className="container">
          <p>
            Want more ingredients? Here’s a tip - the more stories you read, the more ingredients you earn! Psst… you can even upload your own ingredient!
          </p>
          <div
            className={styles.StoryFlowInfoBtn}
            onClick={() => {
              navigate("/home");
            }}
          >
            <button className={styles.btnFill}>
              <img src={Images.roadImg} />
              Start a new <br /> adventure!
            </button>
          </div>
        </div>
      </div>
      <Modal
        style={{
          content: {
            display: "flex",
            backgroundColor: "#0000",
            borderWidth: 0,
            flexDirection: "column",
            flex: 1,
            position: "unset",
            overflow: "auto",
            height: "100%",
            // justifyContent: "space-around"
          },
        }}
        overlayClassName={Modalstyle.modal}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className={`${Modalstyle.modalpackage}`}>
            <div className={styles.backbtn} onClick={closeModal}>
              <a href="#">
                <img src={Images.navleft} onClick={closeModal} />
              </a>
            </div>
            <div className={Modalstyle.modalIngredientsInner}>
              <img
                //  src={Images.MuniaPackage}
                src={"https://manage.guppytheatre.com" + selected?.attributes?.IngredientImage?.data?.attributes?.url}
              />
              <p>
                <strong>{selected?.attributes?.IngredientName}</strong>
              </p>
            </div>
          </div>
          <div className={Modalstyle.ModalpacakageNextcontent} onClick={() => { navigate("/GuppyAppDownload"); }}>
            <p>
              Record a new story <br /> using this ingredient.
            </p>
            <div
              className={`${styles.StoryFlowInfoBtn} ${Modalstyle.StoryFlowInfoBtn}`}
            >
              <button className={styles.btnFill}>
                <img src={Images.Recordimg} />
                Record a <br /> new story{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PackageIngredient;
