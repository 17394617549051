import React, { useEffect, useState, createRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";
import { useDispatch, useSelector } from "react-redux";
import { loginapi } from "../redux/userdata/user.actions";
import NoDraftModal from "./NoDraftModal";
import SubmittedSuccessfullyModal from "./SubmittedSuccessfullyModal";
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import jwt_decode from "jwt-decode";
const initial = {
  identifier: "",
  password: "",
};

function Login() {
  const [modaltext, setmodaltext] = useState("");
  const modalopentwo = createRef(null);
  const modalopen = createRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userlogin, setuserlogin] = useState(initial);

  useEffect(() => {});

  const loginbtn = (data) => {
    dispatch(
      loginapi({
        datasend: data,
        onSuccess: (res) => {
          navigate("/home");
        },
        onFaliure: (res) => {
          setmodaltext("Something went wrong!");
          modalopen.current.show();
        },
      })
    );
  };

  const socialloginbtn = (data, userdata) => {
    dispatch(
      loginapi({
        datasend: data,
        onSuccess: (res) => {
          navigate("/home");
        },
        onFaliure: (res) => {
          if (res?.response?.data?.error?.status == 400) {
            navigate("/SignUPChild", {
              state: { user: userdata, type: "google" },
            });
          } else {
            setmodaltext("Something went wrong!");
            modalopen.current.show();
          }
        },
      })
    );
  };

  return (
    <div className={styles.loginBg}>
      <div className={styles.arrowImgMain}>
        <div className={styles.signLogRightLine}>
          <img src={Images.signLogRightLine} />
        </div>
        <div className="container">
          <div className={`row ${styles.signUpInfo}`}>
            <div className={`col-sm-12 col-md-6 ${styles.leftInfo}`}>
              <img src={Images.MuniaMiddle} alt="" />
              <h5>Log in</h5>
              <p>Log in with one of the options below</p>
              <div className={styles.socialIcon}>
                {/* <a href="#" onClick={()=>{ }}>
                  <img src={Images.googleSocial} />
                </a> */}
                <GoogleLogin
                  theme={"filled_blue"}
                  text={"continue_with"}
                  // type={"icon"}
                  // size={"large"}
                  shape={"circle"}
                  onSuccess={(credentialResponse) => {
                    var decoded = jwt_decode(credentialResponse.credential);
                    let datasend = {
                      identifier: decoded.email?.split("@")[0],
                      password: decoded.email?.split("@")[0],
                    };
                    let userdetails = {
                      user_media: "110",
                      email: decoded.email,
                      password: decoded.email?.split("@")[0],
                      username: decoded.email?.split("@")[0],
                      ParentName: decoded?.name,
                    };
                    socialloginbtn(datasend, userdetails);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </div>
            </div>
            <div className={`col-sm-12 col-md-6 ${styles.rightInfo}`}>
              <form action="#">
                <div className={styles.wrapdiv}>
                  <label for="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    onChange={(event) => {
                      userlogin.identifier = event.target.value;
                      setuserlogin({ ...userlogin });
                    }}
                    required
                  />
                </div>
                <div className={styles.wrapdiv}>
                  <label for="psw">Password</label>
                  <input
                    type="password"
                    name="psw"
                    onChange={(event) => {
                      userlogin.password = event.target.value;
                      setuserlogin({ ...userlogin });
                    }}
                    required
                  />
                </div>
                <div className={styles.forgerPass}>
                  <a href="/ForgetPassword">Forgot Password?</a>
                </div>
                <div className={styles.btndiv}>
                  <button
                    type="submit"
                    className={styles.btnFill}
                    onClick={(e) => {
                      e.preventDefault();
                      var emailcheck =
                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                      if (
                        userlogin.identifier.length < 3 &&
                        userlogin.password.length < 6
                      ) {
                        setmodaltext("Please Enter details");
                        modalopen.current.show();
                      } else if (!emailcheck.test(userlogin.identifier)) {
                        setmodaltext("Invalid Email Id");
                        modalopen.current.show();
                      } else if (userlogin.password.length == 0) {
                        setmodaltext("Password is a required field");
                        modalopen.current.show();
                      } else {
                        loginbtn(userlogin);
                      }
                    }}
                  >
                    Log-In
                  </button>
                </div>
                <div className={styles.againreg}>
                  <p>Don’t have an account?</p> <a href="/signUp"> Register</a>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.signBgLeft}>
          <img src={Images.signupbg} />
        </div>
      </div>
      <NoDraftModal ref={modalopen} text={modaltext} />
      <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
    </div>
  );
}

export default Login;
