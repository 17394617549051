import React from 'react'
import Images from './Images';
import styles from '../pages/Home/landingpage.module.css';


function GoogleAds(){
    return (
       	<section className={styles.googleAdSection}>
        <div className='container-fluid'>
            <span><a href="#"><img src={Images.disablebtn} alt="" /></a></span>
            <img src={Images.googleAds} alt="" />
            <button>Get Started</button>
        </div>
    	</section>
    )
}


export default GoogleAds;