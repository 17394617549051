import React, { createRef } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import subscribeEmailAsync from "../api/subscripeEmailAsync";

function Footer({ userinfo, setmodaltext }) {
  // modal
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [email, setemail] = useState(userinfo?.email || "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const submitForm = async () => {
    setIsSubmitting(true);
    try {
      const response = await subscribeEmailAsync({ email: email });
      if (response.id) {
        setIsSubmitting(false);
        openModal();
        setemail("");
      } else {
        setIsSubmitting(false);
        setmodaltext(response.message);
        // modalopen.current.show();
      }
    } catch (e) {
      setmodaltext("Something went wrong!");
      // modalopen.current.show();
    }
  };

  // Modal
  return (
    <footer>
      <div className={styles.desktopFooter}>
        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-sm-12 col-md-12 col-lg-3 ${styles.footerLeft}`}
            >
              <a href="#" className={styles.footerLogo}>
                <img src={Images.footerLogo} alt="" />
              </a>
              <div className={styles.socialIcon}>
                <a
                  href="https://www.instagram.com/guppytheatre/"
                  target={"_blank"}
                >
                  <img src={Images.Insta} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/GuppyTheatre1"
                  target={"_blank"}
                >
                  <img src={Images.facebook} alt="" />
                </a>
                <a
                  href={`https://www.youtube.com/@guppytheatre5404`}
                  target={"_blank"}
                >
                  <img src={Images.youtube} alt="" />
                </a>
              </div>
            </div>
            <div
              className={`col-sm-12 col-md-12 col-lg-3 ${styles.footerMidLeft}`}
            >
              <p>Download App</p>
              <div
                className={`${styles.createStoryBottomShare} ${styles.qrdiv}`}
              >
                <div>
                  <a href="#">
                    <img src={Images.qrcode} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.guppytheatre.app"
                    target="_blank"
                  >
                    <img src={Images.googleIcon} className={styles.centerImg} />
                  </a>
                  <a href="https://apps.apple.com/in/app/guppy-stories/id6450250856">
                    <img src={Images.appledownload} />
                  </a>
                </div>
              </div>
            </div>
            <div className={`col-sm-12 col-md-12 col-lg-3 ${styles.footerMid}`}>
              <p>Get in touch</p>
              <div className={styles.contactIcon}>
                <a
                  href={"whatsapp://send?text=hello&phone=+918928110916"}
                  target={"_blank"}
                >
                  <img src={Images.whtspp} alt="" />
                </a>
                <a href="mailto:guppy@guppytheatre.com" target={"_blank"}>
                  <img src={Images.inbox} alt="" />
                </a>
              </div>
              <a
                href="/contact"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact us
              </a>
              <a
                href="/PrivacyPolicy"
                onClick={() => {
                  navigate("/PrivacyPolicy");
                }}
              >
                Privacy Policy
              </a>
              <a
                href="/TermsConditions"
                onClick={() => {
                  navigate("/TermsConditions");
                }}
              >
                Terms and Conditions
              </a>
            </div>
            <div
              className={`col-sm-12 col-md-12 col-lg-3 ${styles.footerRight}`}
            >
              <h4>
                Subscribe to <br /> our newsletter!
              </h4>
              <p>
                Stay updated with our latest release and events details. Get
                notified and receive all info.
              </p>
              <div className={styles.emailInput}>
                <input
                  type="text"
                  name="email"
                  placeholder="Email ID"
                  defaultValue={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  contentEditable={false}
                />
                <img
                  onClick={() => {
                    if (email.length != 0) {
                      var emailcheck =
                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                      if (!emailcheck.test(email)) {
                        setmodaltext("Invalid Email Id");
                      } else {
                        // openModal();
                        submitForm();
                      }
                    } else {
                      setmodaltext("Email is required !");
                    }
                  }}
                  src={Images.inputRightArrow}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobileFooter}>
        <div className="container-fluid">
          <div className={styles.footerRight}>
            <h4>Subscribe to our newsletter!</h4>
            <div className={styles.emailInput}>
              <input
                type="email"
                name="email"
                placeholder="Email ID"
                defaultValue={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                contentEditable={false}
              />
              <img
                onClick={() => {
                  if (email.length != 0) {
                    subscribeEmailAsync({ email }).then(() => {
                      openModal();
                    });
                  } else {
                    setmodaltext("Email is required !");
                  }
                }}
                src={Images.inputRightArrow}
                alt=""
              />
            </div>
            {isSubmitting && (
              <div className="">{"Subscribing Newsletter..."}</div>
            )}
          </div>
          <div className={styles.footerIconRow}>
            <div className={styles.footerMid}>
              <p>Get in touch</p>
              <div className={styles.contactIcon}>
                <a
                  href={"whatsapp://send?text=hello&phone=+918928110916"}
                  target={"_blank"}
                >
                  <img src={Images.whtspp} alt="" />
                </a>
                <a href="mailto:guppy@guppytheatre.com" target={"_blank"}>
                  <img src={Images.inbox} alt="" />
                </a>
              </div>
            </div>
            <div className={styles.footerLeft}>
              <p>Get in touch</p>
              <div className={styles.socialIcon}>
                <a
                  href="https://www.instagram.com/guppytheatre/"
                  target={"_blank"}
                >
                  <img src={Images.Insta} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/GuppyTheatre1"
                  target={"_blank"}
                >
                  <img src={Images.facebook} alt="" />
                </a>
                <a
                  href={`https://www.youtube.com/watch?v=oFD1Rs5Nx7Q&t=6s`}
                  target={"_blank"}
                >
                  <img src={Images.youtube} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.mobilelink}>
            <a href="/PrivacyPolicy">Privacy Policy</a>
            <a href="/TermsConditions">Terms and Conditions</a>
          </div>
        </div>
      </div>
      <Modal
        style={{
          content: {
            display: "flex",
            backgroundColor: "#0000",
            borderWidth: 0,
            flexDirection: "column",
            flex: 1,
            position: "unset",
            overflow: "auto",
            height: "100%",
            padding: "20px 20px 20px",
            justifyContent: "space-around",
          },
        }}
        overlayClassName={Modalstyle.modalconfirm}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className={Modalstyle.modalFooterMain}>
            <img
              className={Modalstyle.sharebtnimg}
              src={Images.backbtncross}
              onClick={closeModal}
            />
            <img src={Images.ConfirmedFrame} />
            <h2>
              Confirmed! <br /> You will get an email soon.
            </h2>
            <button onClick={closeModal} className={styles.btnFill}>
              OK!
            </button>
          </div>
        </div>
      </Modal>
    </footer>
  );
}

export default Footer;
