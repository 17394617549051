import React, { useState, createRef } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../ApiService/Apicall';
import { getLocal } from '../otherexports';
import styles from '../pages/Home/landingpage.module.css';
import Images from './Images';
import NoDraftModal from './NoDraftModal';
import SubmittedSuccessfullyModal from './SubmittedSuccessfullyModal';


function Contact() {
    const modalopentwo = createRef(null);
    const modalopen = createRef(null);
    const navigate = useNavigate();
    const Userdata = getLocal("User");
    const [comments, setComments] = useState('');
    const [modaltext, setmodaltext] = useState('');
    const postContact_us = async () => {
        let user_id = Userdata.id.toString();
        let dataSend = {
            users_permissions_user: user_id,
            Comments: comments,
        };
        let postData = await api('post', "guppy-contacts", { "data": dataSend }, "data");

        if (postData != undefined) {
            // alert("Message send Susessfully !");
            setmodaltext("Message send Susessfully !")
            modalopentwo.current.show()
            setComments("");
            // navigate(-1);
        } else {
            setmodaltext("Something went wrong!")
            modalopen.current.show()
            //   alert("Something went wrong!");
            setComments("");
            //   navigate(-1);
        }

    };

    //   useEffect(() => {setmodaltext("Something went wrong!")
    //   modalopen.current.show()} , [
    // ])

    return (
        <section className={styles.contactSection}>
            <div className={styles.ContactTop}>
                <div className='container'>
                    <div className={styles.backbtn} onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }} >
                        <a><img src={Images.backbtn} /></a>
                    </div>
                    <div className={styles.contactTopInfo}>
                        <h4>Contact us</h4>
                        <p>{"We'd love to hear \nfrom you!"}</p>
                        <div className={styles.contactinfoMain}>
                            <div className={styles.mainInfo}>
                                <span>Get in touch</span>
                                <div className={styles.socialIcon} onClick={() => { window.open('whatsapp://send?text=hello&phone=+918928110916', "_blank") }} style={{ cursor: "pointer" }} >
                                    <img src={Images.whtspp} />
                                    <span>8928110916</span>
                                </div>
                                <div className={styles.socialIcon} onClick={() => { window.open('mailto:guppy@guppytheatre.com', "_blank") }} style={{ cursor: "pointer" }} >
                                    <img src={Images.inbox} />
                                    <span>guppy@guppytheatre.com</span>
                                </div>
                            </div>
                            <div className={styles.mainInfo}>
                                <span>Social</span>
                                <div className={styles.socialIcon} onClick={() => { window.open('https://www.facebook.com/GuppyTheatre1', "_blank") }} style={{ cursor: "pointer" }}  >
                                    <img src={Images.facebook} />
                                    <span>theguppytheatre</span>
                                </div>
                                <div className={styles.socialIcon} onClick={() => { window.open('https://www.instagram.com/guppytheatre/', "_blank") }} style={{ cursor: "pointer" }} >
                                    <img src={Images.Insta} />
                                    <span>@theguppytheatre</span>
                                </div>
                                <div className={styles.socialIcon} onClick={() => { window.open('https://www.youtube.com/watch?v=oFD1Rs5Nx7Q&t=6s', "_blank") }} style={{ cursor: "pointer" }} >
                                    <img src={Images.youtube} />
                                    <span>theguppytheatre</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.ContactBottom}>
                <div className='container'>
                    <form action='#'>
                        <div className={styles.wrapdiv}>
                            <label for="uname">Name</label>
                            <input type="text" name="uname" value={Userdata?.name} disabled={true} />
                        </div>
                        <div className={styles.wrapdiv}>
                            <label for="email">Email ID*</label>
                            <input type="email" name="email" value={Userdata?.email} disabled={true} />
                        </div>
                        <div className={styles.wrapdiv}>
                            <label for="email">Submission ID</label>
                            <input type="email" name="email" value={Userdata?.username} disabled={true} />
                        </div>
                        <div className={styles.wrapdiv}>
                            <label for="email">User ID</label>
                            <input type="email" name="email" value={Userdata?.id} disabled={true} />
                        </div>
                        <div className={styles.wrapdiv}>
                            <label for="email">Comments</label>
                            <textarea rows="6" cols="2" name="comment" form="usrform" onChange={(event) => {
                                setComments(event.target.value)
                            }}></textarea>
                        </div>
                    </form>
                    <div className={styles.btndiv}>
                        <button className={styles.btnUnfill} onClick={() => { navigate(-1) }} >Cancel</button>
                        <button className={styles.btnFill} onClick={() => {
                            if (comments.length == 0) {
                                setmodaltext('Please add comments !')
                                modalopen.current.show()
                            } else { postContact_us() }
                        }}>Send</button>
                    </div>
                </div>
            </div>
            <NoDraftModal ref={modalopen} text={modaltext} />
            <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
        </section>
    )
}


export default Contact;

