import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import BannerBs from "../Component/BannerBs";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import CurrentIps from "../Component/CurrentIps";
import NewsLetterBS from "../Component/NewsLetterBS";
import GuppyStoriesBackstage from "../Component/GuppyStoriesBackstage";
import FooterBs from "../Component/FooterBs";
import BannerCommon from "../Component/BannerCommon";
import Team from "../Component/Team";
import { useNavigate } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import Loader from "../Component/Loader";
import { useBackstagewhoweare } from "../../otherexports";


function OurTeam(){

    const navigate=useNavigate();
    const {data,isLoading}=useBackstagewhoweare();
    if(!isLoading){
        return(
            <section className={BsCustom.WrapperOurTeam}>
                <MetaTags>
                    <title>Our Team - Guppy Theater</title>
                    <meta name="description" content="We produce participative story worlds in which the 21st century children are at the centre of the stage" />
                    <meta name="keywords" content="names of core team and advisory board" />
                </MetaTags>
                <HeaderBackstageNew />
                <section className={BsCustom.BannerCommon}>
                    <img className={BsCustom.BackBtnBSCommonNew} style={{cursor:"pointer"}} src={Images.BackstageBackBtn} onClick={()=>{navigate(-1)}} />
                    <div className={BsCustom.BannerCommonText}>
                        <h1 className={BsCustom.BsH1}>Our Team</h1>
                        <p className={BsCustom.BsCommaonP}>The Guppy Theatre team comprises recognised and passionate professionals from all over the world.They contribute to our story worlds as writers, producers and publishers of entertainment, created for and with the children.  </p>
                    </div>
                </section>
                <Team/>
                {/* <Team/> */}
                <FooterBs/>
            </section>
        ); 
    }
    else 
    {return (<Loader />)}
}

export default OurTeam;