import React from "react";
import Images from "./Images";
import styles from '../pages/Home/landingpage.module.css';
import MyAccount_com from './MyAccount_com';
import { useNavigate } from "react-router-dom";
import Story from "./Story";


const MyAccount = ({Userdata}) => {
  // const navigate=useNavigate();
  return (
    <div className={styles.myaccount_main}>
        {Object.keys(Userdata).length!=0?<div className={styles.accountmain_top}>
          <div className="container">
            <h4>My Account</h4>
            <div style={{display: "flex", flexWrap: "wrap", alignItems: "center"}}>
            <img 
            src={Images.Userimg} 
            // src={"https://manage.guppytheatre.com"+Userdata.user_media?.url}
            />
            <span><div className={styles.accountTitle} style={{maxWidth: "100%", display: "block", }}> <strong>Hi! {Userdata.username}, </strong> </div> {Userdata.age} years old | <span className={styles.Uid}>User ID: {Userdata.id}</span></span>
          </div>
          </div>
        </div>:<Story logindata={Userdata} hide={true} />}
        <div className={styles.account_bottom_main}>
        <MyAccount_com Userdata={Userdata} />
        </div>
    </div>
  );
};
    
  export default MyAccount;