import React from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";

const Palacecom = ({item}) => {
  // console.log(JSON.stringify(item?.attributes?.bottom_banner?.banner_image?.data?.attributes?.url),"======item?.attributes?.bottom_banner?.banner_image?.data?.attributes?.url=====")
  return (
    <div className={styles.PalacecomMain}>
        <a href={item?.attributes?.bottom_banner?.banner_link} target="_blank"><img style={{width: "100%"}} src={"https://manage.guppytheatre.com" +
                    item?.attributes?.bottom_banner?.banner_image
                      ?.data?.attributes?.url} /></a>
        {/* <div className={styles.PalacecomMainInner}>
            <h2>The Palace of Pranks now  available as an audio book.</h2>
            <div className={styles.PalacecomMainInnerinfo}>
                <img src={Images.Audible} />
                <button className={styles.btnFill}> Listen Now <img src={Images.Uppperarrowwhite} /></button>
            </div>
        </div> */}
    </div>
  );
};

export default Palacecom;
