import {
  Home_Data_Request,
  Home_Data_SUCCESS,
  Home_Data_FAILURE,
  Story_lits_Request,
  Story_lits_FAILURE,
  Story_lits_SUCCESS,
  Carousel_count
} from "./home.types";

const INITIAL_STATE = {
  Home: { loading: false, error: "", data: [] },
  Storylist: { loading: false, error: "", data: [] },
  // Carouselcount:"3/3"
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    //=================Home Data================
    case Home_Data_Request: {
      return {
        ...state,
        Home: {
          loading: true,
          data: [],
          error: "",
        },
      };
    }
    case Home_Data_SUCCESS: {
      return {
        ...state,
        Home: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case Home_Data_FAILURE: {
      return {
        Home: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
    //=================Home Data================

     //=================Story List================
    case Story_lits_Request: {
      return {
        ...state,
        Storylist: {
          loading: true,
          data: [],
          error: "",
        },
      };
    }
    case Story_lits_SUCCESS: {
      return {
        ...state,
        Storylist: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case Story_lits_FAILURE: {
      return {
        Storylist: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    }
   //=================Story List================
   

    default:
      return state;
  }
};

export default reducer;
