import React, { createRef, useRef, useState } from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
// import  'bootstrap/dist/css/bootstrap.min.css';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { async } from "q";
import api from "../../ApiService/Apicall";
import NoDraftModal from "../../component/NoDraftModal";
import SubmittedSuccessfullyModal from "../../component/SubmittedSuccessfullyModal";

function ContactBs() {
  const modalopentwo = createRef(null);
  const modalopen = createRef(null);
  const dropdownlist = [
    `Co-production/Distribution`,
    "Support",
    "Licensing",
    "Careers",
    "Creating IP",
    "Events",
  ];
  const [selectedlist, setselectedlist] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [comments, setComments] = useState("");
  const [modaltext, setmodaltext] = useState("");
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const [value, setValue] = useState(false);
  //   const handleSelect = (e) => {
  //     console.log(e);
  //     setValue(e);
  //   };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [Susessfulsend, setSusessfulsend] = useState(false);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (value) {
      if (
        name.length == 0 ||
        selectedlist.length == 0 ||
        message.length == 0 ||
        email.length == 0 ||
        phone.length == 0
      ) {
        let message =
          name.length == 0
            ? "Name"
            : selectedlist.length == 0
            ? "Subject"
            : message.length == 0
            ? "Message"
            : email.length == 0
            ? "Email"
            : "Phone Number";

            setmodaltext(`${message} field is required !`);
            modalopen.current.show();
      }
      const dataToSubmit = {
        name: name,
        Subject: selectedlist[0],
        message: message,
        email: email,
        Contactno: phone,
      };
      const postData = await api("post", "backstage-contact-uses", {
        data: dataToSubmit,
      });
      if (postData != undefined) {
        setSusessfulsend(true);
        setmodaltext("Message send Susessfully !");
        modalopentwo.current.show();
        setComments("");
      } else {
        setSusessfulsend(false);
        setmodaltext("Something went wrong!");
        modalopen.current.show();
        setComments("");
      }
    } else {
      setSusessfulsend(false);
      setmodaltext("Terms need to accepted !");
      modalopen.current.show();
    }
  };

  return (
    <div
      className={`${BsCustom.TeamLightBCGroung} ${BsCustom.ContactBsSection}`}
    >
      <div className="container-fluid">
        <div className={BsCustom.ContactBsSectionTop}>
          <h1 className={BsCustom.BsH1}>Contact Us</h1>
          <p className={BsCustom.BsCommaonP}> At <strong>Guppy Theatre</strong> we develop and produce IPs with a complete understanding of Transmedia <br/> storytelling. Connect with us if we can help develop your concept and/or if you have any questions.  <br/> We may take 2-3 days to respond but you will surely hear from us.</p>
        </div>
        <form action="#" method="post">
          <div className={BsCustom.ContactBsSectionBottom}>
            <p className={`ContactParaInputInner ${BsCustom.ContactParaNew}`}>
              Hello! My Name is <br/>
              <input
                className={BsCustom.TextNameInput}
                type="text"
                value={name}
                style={{ color: "#0D5268" }}
                onChange={(e) => setName(e.target.value)}
                name="name"
                placeholder="“Name here”"
              /> <br/>
              and I’d like to get in touch with
              you regarding  <br />
              {/* <span className={BsCustom.DropDownButtonValue}>{value}</span> */}
              <input
                className={BsCustom.DropDown}
                type="text"
                disabled={true}
                value={selectedlist[0]}
                style={{ color: "#0D5268" }}
                name="subject"
                // onChange={(e) => setSubject(e.target.value)}
                placeholder="Select subject from the list"
              /> 
              <img
                className={BsCustom.dropdownArrowImg}
                src={Images.BackstageMenuMainNav}
                style={{
                  transform: isShown ? `rotate(-90deg)` : `rotate(90deg)`,
                }}
                onClick={handleClick}
              />
              {isShown && (
                <div className={BsCustom.dropdownNewContact}>
                  <ul>
                    {dropdownlist.map((e) => {
                      return (
                        <li
                          onClick={() => {
                            if (selectedlist.length == 1) {
                              let info = [];
                              info.push(e);
                              setselectedlist([...info]);
                            } else {
                              selectedlist.push(e);
                              setselectedlist([...selectedlist]);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {selectedlist.includes(e) && (
                            <img src={Images.Checksign} />
                          )}
                          {!selectedlist.includes(e) && (
                            <img src={Images.Unchecksign} />
                          )}
                          {e}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </p>
            <p className={BsCustom.ContactParaNew}>
              I’d like to discuss more about <br/>
              <textarea rows="4"
                maxlength={"200"}
                minLength={"50"}
                className={BsCustom.TextCommentInput}
                type="text"
                name="message"
                value={message}
                style={{ color: "#0D5268"}}
                onChange={(e) => setMessage(e.target.value)}
                placeholder=" “Enter some text” "
              />
            </p>
            <p className={BsCustom.ContactParaNew}>
              You can contact me through the following email <br/>
              <input
                className={BsCustom.EmailUserInput}
                value={email}
                style={{ color: "#0D5268" }}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                placeholder="“e-mail here”"
              />{" "}
              <br />
              or the following phone number{" "} <br/>
              <input
                className={BsCustom.NumberInput}
                value={phone}
                style={{ color: "#0D5268" }}
                onChange={(e) => setPhone(e.target.value)}
                name="phone"
                type="tel"
                placeholder=" “+____________”"
              />
            </p>
            <div className={BsCustom.ContactApprovalBox}>
              <input
                style={{
                  width: "18px",
                  height: "18px",
                  verticalAlign: "sub",
                  marginRight: "5px",
                }}
                type="checkbox"
                value={value}
                onChange={() => {
                  setValue(!value);
                }}
              />
              <span>
                By checking, you confirm that Guppy team can use the information
                provided to contact you.
              </span>
            </div>
            <button className={BsCustom.BsComBtn} onClick={handleFormSubmit}>
              Send
            </button>
          </div>
          {Susessfulsend&&<div className={BsCustom.SuccessMessage}>
            <p className={BsCustom.BsCommaonP}>
              Thank you for reaching out to us. <br />
              Our team will get in touch with you shortly!
            </p>
          </div>}
        </form>
      </div>
      <NoDraftModal ref={modalopen} text={modaltext} />
      <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
    </div>
  );
}

export default ContactBs;
