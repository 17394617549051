import { findByLabelText } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import Faq from "../../component/Faq";
import Footer from "../../component/Footer";
import Images from '../../component/Images';
// import { useQuery } from 'react-query'
import { api } from "../../ApiService/Apicall";
import { useQuery } from "@tanstack/react-query";
import { getLocal } from "../../otherexports";
import NoDraftModal from "../../component/NoDraftModal";
import { createRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { home_api } from "../../redux/homedata/home.actions";
const FaqMain = () => {
  const { data,isLoading } = useQuery(["Faqapi"], async () => {
    const results = await api("get", "guppyt-faqs?populate=*");
    return results.data.data;
  });
  const modalopentwo=createRef(null);
  const userinfo = getLocal("User");
  const [modaltext, setmodaltext] = useState('');


  if (!isLoading)
    return (
      <div className={styles.body}>
        <Header />
        <Faq data={data} />
        <Footer userinfo={userinfo} setmodaltext={(e)=>{setmodaltext(e); modalopentwo.current.show()}} />
        <NoDraftModal ref={modalopentwo} text={modaltext} />
      </div>
    );
  else
    return (
      <div className={styles.body}>
      <div className={styles.LoadingMain}
        style={{
          alignItems: "center",
          backgroundColor: "#F2DD4E",
          top: 0,
          left: 0,
          bottom: 0,
          position: "absolute",
          justifyContent: "center",
          flex: 1,
          flexDirection: "column",
          display: "flex",
          right: 0,
        }}
      >
        <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
      </div>
    </div>
    );
};

export default FaqMain;
