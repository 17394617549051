import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";

function Team() {
  const static_data = [
    {
      backimg: Images.ThePalaceStory,
      MainText: "The Maharaja of Mastipur & The Palace of Pranks",
      NewImg: Images.Books,
      PlusBSSignImg: Images.ShareSignBS,
    },
  ];
  return (
    <div className={BsCustom.mainRel}>
      <div className={BsCustom.TeamDarkBCGroung}>
        <div className={BsCustom.OurTeamHederTop}>
          <h3 className={BsCustom.BsH3}>Core Team</h3>
          <p className={BsCustom.BsCommaonP}>
            {" "}
            Guppy Theatre’s core team is imaginative, vigilant and ahead of the
            times. They ensure that the framework they provide is so profoundly
            elastic yet safe that the onstage kids can freely express
            themselves.
          </p>
        </div>
        <div className="container-fluid">
          <div className={`${BsCustom.TeamInner} ${BsCustom.teamtopinner}`}>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <img
                  className={BsCustom.TeamInnercommonImg}
                  src={Images.NeeruNanda}
                />
                <p className={BsCustom.BsCommaonP}>Chairperson & Producer</p>
                <a
                  className={BsCustom.TeamHyperLink}
                  target="_blank"
                  href="mailto: neeru@guppytheatre.com"
                >
                  <img src={Images.inbox} />
                  neeru@guppytheatre.com
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={`${BsCustom.BsH4} ${BsCustom.mobileteamtitle}`}>
                  Neeru Nanda, Chairperson & Producer
                </h4>
                <h4 className={`${BsCustom.BsH4} ${BsCustom.desktopteamtitle}`}>
                  Neeru Nanda
                </h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.NeeruNanda}
                  />
                  <a
                    className={BsCustom.TeamHyperLink}
                    target="_blank"
                    href="mailto: neeru@guppytheatre.com"
                  >
                    <img src={Images.inbox} />
                    neeru@guppytheatre.com
                  </a>
                </div>
                <p className={BsCustom.BsCommaonP}>
                  My love affair with storytelling is deep and varied. Reading
                  gave me inspiration, interviewing for feature writing gave me
                  character insights, learning narrative form gave me structure
                  and the application of all resulted in a book of short stories
                  titled IF. And then I crossed over from the adult world into
                  the kids’ space to teach creative writing. To my delight I
                  discovered that every kid is a ‘guppy’ telling hilariously
                  crazy stories. I created a formless, shape shifting character
                  named Guppy, every child’s best friend, that took shape as a
                  transmedia platform called GUPPY THEATRE.
                </p>
              </div>
            </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={`${BsCustom.BsH4} ${BsCustom.mobileteamtitle}`}>
                  Cyril-Vincent Michaud, Chief Creative Officer
                </h4>
                <h4 className={`${BsCustom.BsH4} ${BsCustom.desktopteamtitle}`}>
                  Cyril-Vincent Michaud
                </h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.Cyril1}
                  />
                  <a
                    className={BsCustom.TeamHyperLink}
                    target="_blank"
                    href="mailto: cvm@guppytheatre.com"
                  >
                    <img src={Images.inbox} />
                    cvm@guppytheatre.com
                  </a>
                </div>
                <p className={BsCustom.BsCommaonP}>
                  My passion for 21st century innovations in media and
                  technology has carved my professional and geographical
                  trajectory from multinational media companies - founding a
                  digital art label, to a gaming startup,to founding a design
                  communication agency - to content creation for Europe and
                  Asia. <br />
                  Driven by the new forms of usage of mediums (Nouvelles
                  Écritures), for and with the children, I want to enable
                  children to evolve from passive consumers to ‘writing’ their
                  own future with a sense of authorship. As a parent I want to
                  contribute to the children’s development of their own
                  relationship with a fast-evolving analogue-digital
                  environment. I am shaping GUPPY THEATRE towards that goal.
                </p>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <img
                  className={BsCustom.TeamInnercommonImg}
                  src={Images.Cyril1}
                />
                <p className={BsCustom.BsCommaonP}>Chief Creative Officer</p>
                <a
                  className={BsCustom.TeamHyperLink}
                  target="_blank"
                  href="mailto: cvm@guppytheatre.com"
                >
                  <img src={Images.inbox} />
                  cvm@guppytheatre.com
                </a>
              </div>
            </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <img
                  className={BsCustom.TeamInnercommonImg}
                  src={Images.Anil_PIC2}
                />
                <p className={BsCustom.BsCommaonP}>
                  Head of Business & Production
                </p>
                <a
                  className={BsCustom.TeamHyperLink}
                  target="_blank"
                  href="mailto: anil@guppytheatre.com"
                >
                  <img src={Images.inbox} />
                  anil@guppytheatre.com
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={`${BsCustom.BsH4} ${BsCustom.mobileteamtitle}`}>
                  Anil Ramadhar, Head of Business & Production
                </h4>
                <h4 className={`${BsCustom.BsH4} ${BsCustom.desktopteamtitle}`}>
                  Anil Ramadhar
                </h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.Anil_PIC2}
                  />
                  <a
                    className={BsCustom.TeamHyperLink}
                    target="_blank"
                    href="mailto: anil@guppytheatre.com"
                  >
                    <img src={Images.inbox} />
                    anil@guppytheatre.com
                  </a>
                </div>
                <p className={BsCustom.BsCommaonP}>
                  My love for painting and sports led me to the Media &
                  Entertainment industry contrary to the norm of working in the
                  corporate sector since I have an MBA degree from IMS - BHU.
                  For 16 years I worked for India’s premier companies, DQ
                  Entertainment and Cosmos Maya, gaining in-depth knowledge and
                  experience in pitching, content distribution, licensing &
                  merchandising, co-productions and legal documentation. I enjoy
                  connecting with people and riding on the back of a healthy and
                  wide network of professional relationships. With GUPPY THEATRE
                  I’m living my dream of producing Indian IP’s for global
                  audiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={BsCustom.TeamLightBCGroung}>
        <div className={BsCustom.OurTeamHederTop}>
          <h3 className={BsCustom.BsH3}>Advisory board</h3>
          <p className={BsCustom.BsCommaonP}>
            Guppy Theatre’s advisory board comprises luminaries from the world
            of children’s media & entertainment. Their infallible reputation,
            experience and guidance provides impetus to Guppy Theatre’s goal of
            creating a transmedia platform for the children with the children.
          </p>
        </div>
        <div className="container-fluid">
          <div className={BsCustom.TeamInner}>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <a href="https://in.linkedin.com/in/hitenm">
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.HitendraMarchent}
                  />
                </a>
                <a
                  className={BsCustom.TeamHyperLink}
                  target="_blank"
                  href="https://in.linkedin.com/in/hitenm"
                >
                  <img src={Images.LinkedInLogoTeam} />
                  {/* https://in.linkedin.com/in/hitenm */}
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}>Hitendra Merchant</h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <a href="https://in.linkedin.com/in/hitenm">
                    <img
                      className={BsCustom.TeamInnercommonImg}
                      src={Images.HitendraMarchent}
                    />
                  </a>
                  <a
                    className={BsCustom.TeamHyperLink}
                    target="_blank"
                    href="https://in.linkedin.com/in/hitenm"
                  >
                    <img src={Images.LinkedInLogoTeam} />
                    {/* https://in.linkedin.com/in/hitenm */}
                  </a>
                </div>
                <p className={BsCustom.BsCommaonP}>
                  Hitendra Merchant founded YoBoHo in 2008 and is the Director &
                  CEO of YoBoHo New Media, a global digital media company, that
                  specialises in creating, distributing and licensing
                  digital-first original content in the kids and family
                  vertical. Hitendra has over 23 years of experience with
                  traditional media companies such as Times of India, Nimbus,
                  Saregama, and iDream Production, focusing on distribution
                  strategies, marketing, etc. YoBoHo also owns and operates
                  HooplaKidz which includes a sizable catalogue on Food and
                  Knowledge brands, catering to an older audience.
                </p>
              </div>
            </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}> Ishan Raina</h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <a href="https://www.linkedin.com/in/ishanraina">
                    <img
                      className={BsCustom.TeamInnercommonImg}
                      src={Images.IshanRaina}
                    />
                  </a>
                  <a
                    className={BsCustom.TeamHyperLink}
                    target="_blank"
                    href="https://www.linkedin.com/in/ishanraina"
                  >
                    <img src={Images.LinkedInLogoTeam} />
                    {/* https://www.linkedin.com/in/ishanraina */}
                  </a>
                </div>
                <p className={BsCustom.BsCommaonP}>
                  Ishan Raina is a Harvard University Advanced Leadership
                  Fellow, and an MBA from IIM Calcutta. He has spent 35 years in
                  advertising and media and digital media solutions. He has
                  founded several new-age companies like OOH Media. He was the
                  Founder-CEO & Joint-Venture Partner of Paris based Havas
                  Media. He is a member of various boards, particularly
                  Rainshine Entertainment.{" "}
                </p>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <a href="https://www.linkedin.com/in/ishanraina">
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.IshanRaina}
                  />
                </a>
                <a
                  className={BsCustom.TeamHyperLink}
                  target="_blank"
                  href="https://www.linkedin.com/in/ishanraina"
                >
                  <img src={Images.LinkedInLogoTeam} />
                  {/* https://www.linkedin.com/in/ishanraina */}
                </a>
              </div>
            </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <a href="https://www.linkedin.com/in/neilchakravarti/">
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.Neil_Chakravarti}
                  />
                </a>
                <a
                  className={BsCustom.TeamHyperLink}
                  target="_blank"
                  href="https://www.linkedin.com/in/neilchakravarti/"
                >
                  <img src={Images.LinkedInLogoTeam} />
                  {/* https://www.linkedin.com/in/neilchakravarti/ */}
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}>Neil Chakravarti</h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <a href="https://www.linkedin.com/in/neilchakravarti/">
                    <img
                      className={BsCustom.TeamInnercommonImg}
                      src={Images.Neil_Chakravarti}
                    />
                  </a>
                  <a
                    className={BsCustom.TeamHyperLink}
                    target="_blank"
                    href="https://www.linkedin.com/in/neilchakravarti/"
                  >
                    <img src={Images.LinkedInLogoTeam} />
                    {/* https://www.linkedin.com/in/neilchakravarti/ */}
                  </a>
                </div>
                <p className={BsCustom.BsCommaonP}>
                  Neil Chakravarti has worked across media, consumer tech,
                  strategy consulting and investment banking for over 25 years.
                  Most recently, he was co-founder, President and COO of WOW!
                  Unlimited Media and led the sale of the company to Genius
                  Brands in April 2022.
                </p>
              </div>
            </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}> Srividya Maliwal</h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <a href="https://www.linkedin.com/in/smaliwal/">
                    <img
                      className={BsCustom.TeamInnercommonImg}
                      src={Images.Srividya}
                    />
                  </a>
                  <a
                    className={BsCustom.TeamHyperLink}
                    target="_blank"
                    href="https://www.linkedin.com/in/smaliwal/"
                  >
                    <img src={Images.LinkedInLogoTeam} />
                    {/* https://www.linkedin.com/in/smaliwal */}
                  </a>
                </div>
                <p className={BsCustom.BsCommaonP}>
                  Srividya Maliwal, an MBA from the Indian Institute of
                  Management, Bangalore has over 30 years of experience with
                  several corporates and technology start-ups across Asia. She
                  was the Chief Producer and Co-Founder of Playware Studios – a
                  leading games-for-learning company in Asia. Presently she is
                  the Co-Founder and COO of The Added Company – an Asia-wide
                  Sports and Education start-up.
                </p>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <a href="https://www.linkedin.com/in/smaliwal/">
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.Srividya}
                  />
                </a>
                <a
                  className={BsCustom.TeamHyperLink}
                  target="_blank"
                  href="https://www.linkedin.com/in/smaliwal/"
                >
                  <img src={Images.LinkedInLogoTeam} />
                  {/* https://www.linkedin.com/in/smaliwal */}
                </a>
              </div>
            </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <a href="https://www.linkedin.com/in/tinsley-galyean-071190">
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.TinsleyAGalyean}
                  />
                </a>
                <a
                  className={BsCustom.TeamHyperLink}
                  target="_blank"
                  href="https://www.linkedin.com/in/tinsley-galyean-071190"
                >
                  <img src={Images.LinkedInLogoTeam} />
                  {/* https://www.linkedin.com/in/tinsley-galyean-071190 */}
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}>Tinsley A. Galyean</h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <a href="https://www.linkedin.com/in/tinsley-galyean-071190">
                    <img
                      className={BsCustom.TeamInnercommonImg}
                      src={Images.TinsleyAGalyean}
                    />
                  </a>
                  <a
                    className={BsCustom.TeamHyperLink}
                    target="_blank"
                    href="https://www.linkedin.com/in/tinsley-galyean-071190"
                  >
                    <img src={Images.LinkedInLogoTeam} />
                    {/* https://www.linkedin.com/in/tinsley-galyean-071190 */}
                  </a>
                </div>
                <p className={BsCustom.BsCommaonP}>
                  Tinsley Galyean is a Co-Founder and Executive Director of
                  Curious Learning which, with their partners, has curated
                  thousands of free and open source interactive literacy
                  learning experiences reaching over 3.5 million users. Tinsley
                  holds a PhD from the MIT Media Lab and a ScM in computer
                  graphics from Brown University. He has developed interactive
                  technology driven projects for museums around the world. He’s
                  also developed media experiences through Disney, Warner
                  Brothers, and an Emmy-nominated program for Discovery Kids.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={BsCustom.TeamDarkBCGroung}>
        <div className={BsCustom.OurTeamHederTop}>
          <h3 className={BsCustom.BsH3}>GLOBAL CREATOR VILLAGE</h3>
          <p className={BsCustom.BsCommaonP}>
            While our IPs are contextual to India, they are created for
            international audiences. To incorporate that, our team of writers,
            illustrators, designers, script writers, animators and consultants
            are spread all over the globe. Each of them adds a universally
            appealing perspective to our story worlds, many times collaborating
            with Indian talent to get that fine flavour of cross culture and
            crossmedia. Some highly esteemed contributors of our ‘Global Creator
            Village’ are:-
          </p>
        </div>
        <div className="container-fluid">
          <div className={BsCustom.TeamInner}>
            <div className={`row ${BsCustom.Row} ${BsCustom.globalCreaterNew}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <img
                  className={BsCustom.TeamInnercommonImg}
                  src={Images.WriterTeam}
                />
                {/* <a className={BsCustom.TeamHyperLink} href="mailto: support@guppytheatre.com"><img src={Images.inbox} />support@guppytheatre.com</a> */}
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}>Writers</h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.WriterTeam}
                  />
                </div>
                <p className={BsCustom.BsCommaonP}>
                  <a href="https://www.angelasalt">Angela Salt (UK)</a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="https://fr.linkedin.com/in/dennys-mccoy-62110828?trk=pulse-article_main-author-card">
                    Hickey & McCoy (USA & France)
                  </a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="thecreativegarden.co.uk">Sarah Baynes (UK)</a>
                </p>
              </div>
            </div>
            <div className={`row ${BsCustom.Row} ${BsCustom.globalCreaterNew}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}>Studio</h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.StudioTeam}
                  />
                </div>
                <p className={BsCustom.BsCommaonP}>
                  <a href=" https://anima.fi/">
                    Anima Point (Malaysia/ Finland){" "}
                  </a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="https://www.studioredfrog.com">
                    Studio Redfrog (France)
                  </a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="https://www.mondotvstudios.com/en/">
                    MondoTV Studio (Spain)
                  </a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="https://www.climbmedia.com/">Climb Media (India)</a>
                </p>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <img
                  className={BsCustom.TeamInnercommonImg}
                  src={Images.StudioTeam}
                />
                {/* <a className={BsCustom.TeamHyperLink} href="mailto: support@guppytheatre.com"><img src={Images.inbox} />support@guppytheatre.com</a> */}
              </div>
            </div>
            <div className={`row ${BsCustom.Row} ${BsCustom.globalCreaterNew}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <img
                  className={BsCustom.TeamInnercommonImg}
                  src={Images.TheaterTeam}
                />
                {/* <a className={BsCustom.TeamHyperLink} href="mailto: support@guppytheatre.com"><img src={Images.inbox} />support@guppytheatre.com</a> */}
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}>Theatre </h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.TheaterTeam}
                  />
                </div>
                <p className={BsCustom.BsCommaonP}>
                  <a href="#">Anurupa Roy (India)</a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="#">I Made Sidia (Bali) </a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="#">Peter Wilson (Australia/Bali) </a>
                </p>
              </div>
            </div>
            <div className={`row ${BsCustom.Row} ${BsCustom.globalCreaterNew}`}>
              <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                <h4 className={BsCustom.BsH4}>Agency</h4>
                <div className={BsCustom.TeamInnerMobileSectionImg}>
                  <img
                    className={BsCustom.TeamInnercommonImg}
                    src={Images.AgencyTeam}
                  />
                </div>
                <p className={BsCustom.BsCommaonP}>
                  <a href="https://www.bha.co.in/">
                    BHA Future Co (India): Design Communication, UX-UI{" "}
                  </a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="https://www.studiofiction.in/">Studio Fiction</a>
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <a href="https://www.thinkbar.in/">
                    Thinkbar (India) - Web and App development
                  </a>
                </p>
              </div>
              <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                <img
                  className={BsCustom.TeamInnercommonImg}
                  src={Images.AgencyTeam}
                />
                {/* <a className={BsCustom.TeamHyperLink} href="mailto: support@guppytheatre.com"><img src={Images.inbox} />support@guppytheatre.com</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
