import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';

function Credit({item}){
    return(
        <div className={BsCustom.CreditSectionTop}>
            <img style={{width: "100%"}} src={Images.BeforeMaharajacredits} />
            <div className={BsCustom.CreditSection}>
                <div className="container-fluid">
                    <h5>CREDITS</h5>
                    <p>This IP is fully owned by Crossover Media and Design Pvt Ltd</p>
                </div>
            </div>
        </div>
    );
}

export default Credit;