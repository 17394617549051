import React from 'react'
import Images from './Images';
import styles from '../pages/Home/landingpage.module.css';
import Header from './Header';
import { useNavigate } from 'react-router-dom';


function TermsConditions(){
    const navigate=useNavigate();
    const handleScrollToTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    return (
        <div>
            <Header/>
            <section className={styles.TermsConditionsMain}>
                <div className='container'>
                    <div className={styles.TermsConditionsTop}>
                        <img src={Images.BackstageBackBtn} onClick={()=>{navigate(-1);}} style={{cursor:"pointer"}}  />
                        <h1>Terms & Conditions</h1>
                    </div>
                    <div className={styles.TermsConditionsBottom}>
                        <p>Crossover Media And Design Private Limited having its registered address at St Helens Court Pedder Road Mumbai MH 400026 IN (hereinafter referred to as “we”, “us” and/or “Company”), engaged in the business of audio visual and literary content production, has developed an English language a mobile application for children, called, ‘Guppy Story’ (hereinafter referred to as “Application”/“App”). The Application, and its associated websites as may be developed by the Company, (hereinafter collectively referred to as “the Website/Website”), and any interlinked documents herein, are published in accordance with the provisions of Rule 3(1) of The Information Technology (Intermediaries Guidelines) Rules, 2011 under the Information Technology Act, 2000. The Application consists of terms of use and services (“Terms of Use”/“Terms”) that must be read carefully before using/accessing it or any information or content available therein. The following Terms, together with any documents and/or additional terms expressly incorporated herein by reference, govern your access to and use of the Application, including any content, functionality, services and products offered on or through it. </p>
                        <h5>1. ‍About us</h5>
                        <p>Our App, ‘Guppy Story’ is the latest initiative by Guppy Theater and provides the User an interactive and participative storytelling experience. We offer our Users an opportunity to be a part of the ‘Guppy Universe’ through storytelling tools. On the App, the Users can go on an adventure and create a ‘Storyflow’ by linking their favourite Guppy stories available on the App. The Users can also write their own stories, with the help of story ingredients (with reference to scenes, characters and themes of the story), which ingredients are provided to them by the App, and which they then can submit to us. We review these stories, modify them and subject to our discretion, may publish them on the App. </p>
                        <h5>2. User Eligibility</h5>
                        <p>The App is focussed on providing services to children of the age group between 6 years to 9 years (hereinafter referred to as “User/s”). Since, children are not legally competent to contract, their legal guardians and/or parents (“you” or “your/s”) will be required to log into the App on User’s behalf. Children can access our App subject to your guidance and supervision. Hence, you are required to give your consent to the User accessing our App, as per the procedure laid out in our Privacy Policy, only after which the User’s account shall be activated, and the User may be permitted to sign in on the App. </p>
                        <h5>3. Account Registration</h5>
                        <p>To provide Users access to all the features available on the App, you are required to create an account for the User, on the App. You may sign up on the App using any permitted social media platform, or sign up by providing basic information like your child’s name, age, your email address and a password. We reserve the right to recommend any social media platforms, which may be used to access the App at any time. After signing up, you shall receive a verification email with directions to provide your verifiable consent, required to activate User’s account. You agree that all information provided by you shall be accurate and true. Further, you agree to accept full- responsibility with respect to the activities, including but not limited to the purchases that are made on your child’s user account. You may edit your account details by clicking on the ‘Edit Profile’ section in the menu bar, and you may also delete your account, by sending us a written request for the same. You understand that upon deletion of your account, you will lose the right to access or use all or any part of the Application, and we shall not be liable to you for any refund, reimbursement or other liabilities. We reserve the right to permanently delete, disable, suspend, or terminate your account at any time, for any reasons whatsoever, in our sole and absolute discretion, including, without limitation, if you fail to comply with any provision of these Terms. You shall be responsible to maintain the confidentiality of your account credentials, and we shall not, under any circumstances, be held liable for any claims related to the use or misuse of your account due to the activities of any third party outside of your control or due to failure to maintain the confidentiality and security of your account.</p>
                        <h5>4. Permitted Use</h5>
                        <p>Subject to your compliance with these Terms and timely payment of the applicable fees, we grant you and the User a revocable, non-exclusive, non-transferable, non-sub licensable, limited license to use the App for your own, personal and non-commercial use only and only in the manner prescribed by us, for the purpose of viewing, posting and submitting User Content (defined below) on the App in accordance with these Terms. Except for the limited rights expressly set out in these Terms, we grant you and the User no other license or right in the App. Any rights not expressly granted to you herein are reserved to the Company. Your and User’s rights will end when these Terms are terminated. </p>
                        <h5>5. Prohibited Use</h5>
                        <p>You agree, acknowledge and guarantee that; 
                            <ul>
                                <li><p>a. You shall not use the App in any manner which could damage, disable, overburden, or impair the App or interfere with any other User’s use and enjoyment of the App.</p></li>
                                <li><p>b. You will not input or upload on the App any information that may contain viruses, Trojan horses, worms, time bombs or other computer programming routines that are intended to damage, interfere with, intercept or expropriate any system, of the App.</p></li>
                                <li><p>c. You shall not upload any content that infringes the intellectual property of another.</p></li>
                                <li><p>d. You shall not use tools designed for breaching security of the App (like password guessing programs, cracking tools or network probing tools)</p></li>
                                <li><p>e. You may not use or access the App in any way that in our judgment, adversely affects the performance or functioning of the App or interferes with the ability of authorised parties to access the App.</p></li>
                                <li><p>f. You shall not change, translate, reproduce, distribute or otherwise create derivative works of any User Content available on the App.</p></li>
                                <li><p>g. You shall not reverse engineer any aspect of the App in an effort to access things like source code, underlying ideas, or algorithms.</p></li>
                            </ul>
                        </p>
                        <p>We reserve the right to remove any User Content from the App, which we may determine at our own discretion, to be violative of these Terms or any law or statute in force at the time. In event, we find you/ User to be using the App in any manner restricted by us in the Terms, we reserve our right to terminate your access and also claim damages for such misconduct.</p>
                        <h5>6. Payment Terms</h5>
                        <p>The payment terms for accessing the services on the App shall be set forth in the payment schedule as may be notified to you from time to time. Till such payment terms are not notified, no charges shall be applicable. We reserve the right to determine the charges/fees and payment terms for the services on the App, and the right to revise/amend the same, and we will make reasonable efforts to keep such terms updated on the Application’s Terms of Use. You agree that you will be responsible for the payment of any fees, taxes or other charges to your account, as charged by us, and shall pay such fees, taxes and other changes in accordance with our billing terms, as set forth in the Terms. We also reserve the right to make available promotional offers for some Users, at our discretion.</p>
                        <h5>7. User Content:</h5>
                        <p>The App offers its Users an opportunity to create stories, by recording their own story and submitting it to us. We review the submitted stories and may publish some of these stories on the App.</p>
                        <p>7.1 <strong>User Content:</strong> Certain features of the App may permit Users to upload content, which includes, but is not limited to, your story, including texts, photos, audio, video and other types of works (“User Content”) on the App. The User Content submitted by the Users shall always comply with the conditions mentioned in the Terms. We reserve the right to modify any User Content, or any part thereof, before publishing on the App, or using elsewhere, for the purposes including but not limited to technical processing, distribution, and transmission, economical exploitation of User Content, and which may be required to meet the technical requirements of connecting to various networks, devices, platforms, sites, or otherwise. The right to decide upon the decision of publishing any submitted User Content shall solely and exclusively vest with us. </p>
                        <p>7.2 <strong> Standards for User Content:</strong> User to note that the User Content shall conform to the following general specifications;  
                            <ul>
                                <li><p>a. Text file: Any textual content shall not be more than 300 characters;</p></li>
                                <li><p>b. Photos: Any pictorial content shall not be more than 10Mb in size;</p></li>
                                <li><p>c. Audio/ Audio-visual file: Any audio/ audio-visual content shall not be longer than 2 minutes in duration;</p></li>
                            </ul>
                        </p>
                        <p>7.3 <strong>Prohibited User Content:</strong> We reserve the right to remove the User Content or account of any User, if the User Content contains;</p>
                        <p>a. Any material that is infringing, in whole or part, any intellectual property right of any third-party;</p>
                        <p>b. Any material that is duplicate of User Content that has already been released on the Application;</p>
                        <p>c. Any material that is invading any third-party’s privacy;</p>
                        <p>d. Any material that is offensive, hateful, is unlawful, or discriminates or incites violence against a particular group of people, including without limitation, any statement that is, or can reasonably be construed as, sexist or racist or in any way defamatory;</p>
                        <p>e. Any material that promotes, directly or indirectly, any illegal activity;</p>
                        <p>f. Any material that causes, directly or indirectly, another to engage in any self-harming behaviour, including, without limitation, encouraging or advocating suicide or an eating disorder;</p>
                        <p>g. Any material which is defamatory or disparaging to any third party;</p>
                        <p>h. Any material that contains pornographic content;</p>
                        <p>i. Any material that advocates terrorism; etc.</p>
                        <p>Without limiting any right available to us in the Terms, User Content found to be prohibited on any of the above grounds, may subject you to the termination or suspension of your account and/or the immediate removal of the User Content from the App, without notice to you. For the avoidance of doubt, all determinations regarding the availability of User Content on the App are subject to our sole discretion and we are under no obligation to reinstate any removed User Content. Re-uploading denied User Content in violation of the Terms, may result in immediate removal of the User Content from the App and the suspension or termination of your account. We further reserve the right to permanently delete, disable, suspend, or terminate your account and all User Content included therein, at any time, for any reason in our discretion, including, without limitation, if you fail to comply with any provision of the Terms.</p>
                        <h5>7.4 <strong>License</strong></h5>
                        <p>a. By submitting User Content on the App, you, on behalf of the User, grant us a worldwide, exclusive, royalty-free, fully paid right and license (with the right to sublicense) in perpetuity, and to exercise all rights comprised in User Content, including all intellectual property rights and copyrights in accordance with Section 14 of the Copyright Act, 1957 (as amended) and any other equivalent provision thereof.</p>
                        <p>b. We reserve the right to promote User Content, or any part thereof, with due credits to the User, , on social media platforms, on our App, and in other promotional material including our newsletters.</p>
                        <p>c. You agree and acknowledge that we shall have the right to host, store, transfer, copy, publicly display, publicly perform, create derivative works from and otherwise communicate and distribute, reproduce, modify, exploit, reformat, publish any User Content submitted by you, in all media, mode and format, whether published or unpublished on the App. Any User Content, submitted to us, shall be the sole and exclusive property of the Company.</p>
                        <p>d. The provisions of Section 19 (4) of the Copyright Act, 1957 (as amended) shall have no effect on the Terms. </p>
                        <p>e. The App shall credit the User for submitting User Content, if the User Content is published. However, the decision with respect to finalization of credit shall always vest with us.</p>
                        <h5>8. Intellectual Property</h5>
                        <p>a. Unless otherwise stated, any and all intellectual property rights (“IPR”) associated with the App, including all information, computer codes (including source code or object code), visual design elements, text, links, services, content, images, designs, concepts and layout, looks, arrangement, appearance and assembly of content, graphics, button icons, audio, video, audio-video, multimedia and all materials available on or through the App contained therein, belong exclusively to us or our affiliates from whom they have been licensed. The content is protected by Intellectual Property laws both of India and those countries with whom India has reciprocal arrangements.</p>
                        <p>b. You or any User, shall not copy, capture, reproduce, remove, perform, transfer, sell, license, modify, manipulate, create derivative works from or based upon the content available on the App; or republish, upload, edit, post, transmit, publicly display, frame, distribute, use, store, or exploit, in whole or in part, any IPR or content available on the App. </p>
                        <p>c. We reserve the right to terminate in appropriate circumstances, Users or account holders who repeatedly infringe or are believed to be repeatedly infringing our IPR or third-party holders’. In case of such termination and in case they have subscribed to our Services, they will not be entitled to any refund of any payments made to us. </p>
                        <h5>9. Take Down Procedure for Copyright Infringement</h5>  
                        <p>We respect the intellectual property rights of others and expect that our Users do the same. Thus, we have established a process for addressing claims of copyright infringement on our App, which permits Users who own a copyright or have authority to act on behalf of a copyright owner to report a claim against a third party infringing his/her IPR on the App, and may send us a written notice for the same. Such notice must contain the following details, based on which we will expeditiously take necessary and appropriate action: </p> 
                        <p>a. Description of the work being infringed, with adequate information of identity of the work; </p> 
                        <p>b. Details indicating the User’s ownership of the copyright in the work; </p> 
                        <p>c. Details of infringement; </p> 
                        <p>d. Location of the infringing work; and </p>
                        <p>e. Details of the person responsible, if known, for infringing the copyright. </p>
                        <p>In consideration of a complaint by any User, we may take down such allegedly infringing materials or ads, and in appropriate circumstances, we may disable or terminate the accounts of Users who may be repeat infringers. Any User affected by a takedown notice may respond to the notice, and when they do, we may re-upload their content on the App, within 10-14 days of the notice, unless the User (complainant) notifies us that he/she has initiated a legal action seeking to restrain the alleged infringing party from engaging in infringing activity.</p> 
                        <h5>10. Trademark Violation</h5>  
                        <p>All customised graphics, icons, including some of the characters, logos or other images or other items incorporated by us on this App are our registered or unregistered trademarks, service marks or trade names ("Marks"), or those of our affiliates or other entities, who have granted us the rights and licence to use such Marks through the App. The said Marks may not be used or interfered with in any manner without our express written consent. Any User who is a holder, user and/or adopter of any trademarks uploaded on our App, can report a trademark violation to us. While reporting any such violation, the User must mention his contact details, the details of use or adoption of the trademark, details to identify the infringing User, along with a description of confusion being caused by the acts of the infringing party. In keeping with our Terms, we may take down any material that is found to be infringing and/or objectionable of our IPR.</p> 
                        <h5>11. Contests </h5>  
                        <p>We may offer contests, special programs, or other promotions (“Contest/s”) on the App, which may include specific rules that are different from these Terms. We reserve the right to ascertain any amount as a participation fee for the Contest, and the rules and regulations for such Contests. We urge you to carefully review the rules and regulations of any such Contests, as participation constitutes full and unconditional acceptance of all applicable rules, conditions. If you do not agree to abide by and be bound by all such rules, you are not authorized to participate in the Contest. In case of any conflict between the rules associated with any such Contest and those Terms set forth herein, the rules associated with any such Contest will take priority over these Terms.</p> 
                        <h5>12. Third party Links</h5>
                        <p>The App may contain links to other third-party websites, which are governed by their own terms and conditions. By accessing these third-party websites, you agree to be bound to the terms and conditions of such third-party website. We have no control over and do not review the content of these third-party websites, and advise the Users to access them strictly under parental guidance. We may also provide third-party tools which enable the parents/users to export information to third-party platforms. By using such third-party tools, you agree to the transfer of information to such third-party service provider. We are not responsible for such third-party’s use of your exported information. In event you have any complaints, concerns or questions relating to materials provided by third-parties, the same should be addressed directly to the applicable third party. Under no circumstance, shall we be liable for any harm or damage caused to the User, in connection with his use of such third-party websites/services. </p>
                        <h5>13. Advertisements/ Ads</h5>
                        <p>To promote the products, and upcoming and existing business ventures of the Company, we may display advertisements of our products on the App. We may also display third party advertisements and promotions on the App.  In case of third-party advertisements on our App, we provide the same, based upon information provided by the third parties, and we are therefore not responsible or liable for any loss or damage of any sort incurred by you as a result of any such advertisements. Your interactions with advertisers found on or through the App, including, without limitation, all reliance upon such advertisements, all commercial transactions and legal obligations associated with such advertisements, are solely between you and the advertisers. In the event, you wish to find out more information about the practices of these third-party advertisers, as may be notified herein, you may visit their websites.</p>
                        <h5>14. Disclaimer of Warranties</h5>
                        <p>This App is made available “as is” and “as available for the stated purposes herein. Use of the Application is entirely at your own risk and peril, and you should use your best judgement and exercise caution while using or allowing the User to use the Application. We make no guarantee that use of the App, or functionalities associated with it, or delivery or display of the App, will be available, uninterrupted, interference free, or error free, or will be free from any viruses, worms, or other security intrusions. You are solely responsible for configuring your information technology, computer software, and other technology in order to access the App. We do not have any obligation to monitor the User Content and/or the use and activity of the User on the App. </p>
                        <p>We, to the fullest extent permissible by applicable law, disclaim all warranties, expressed or implied, written or oral, or arising from a course of dealing, performance, usage of trade, or otherwise; including, without limitation, warranties of merchantability, fitness for a particular purpose, title, or non-infringement. We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by any third-party through the App, any hyperlinked website, and we will not be a party to or in any way be responsible for monitoring any transaction between the User and any third-party providers of products. No information obtained by the User from the App shall create any warranty not expressly stated by us in these Terms of Use.</p>
                        <h5>15. Limitation of Liability</h5>
                        <p>‍In no event shall we be liable for any incidental, indirect, exemplary, punitive and/or consequential damages, lost profits, and/or damages on account of lost data resulting from the use of and/or inability to use the App or interruption of the same, or deficient services or inaccurate content, whether based on warranty, contract, tort, delict, or any other legal foundation, and whether or not we are advised of the possibility of such damages. Further, we take no responsibility for the User Content, provided to us by you, or for the advertisements displayed by us. To the extent permitted by applicable law and to the extent that we are otherwise found to be responsible for any damages, we shall be responsible for actual damages only and the remedies stated for you in these terms of use are exclusive and are limited to those expressly provided for herein.</p>
                        <h5>16. Indemnity</h5>
                        <p>To the fullest extent permitted by law, you are responsible for Users’ use of the Service, and you will defend and indemnify us and our officers, directors, employees, consultants, affiliates, subsidiaries and agents from and against every claim brought against us by a third party, and for any related liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or connected with: </p>
                        <p>a.  Users’ unauthorized use of or misuse of, the App; </p>
                        <p>b. Your violation of any portion of these Terms, or of any representation, warranty, or agreement referenced in these Terms, or of any applicable law or regulation; </p>
                        <p>c.  Users’ violation of any third party right, including any intellectual property right or publicity, confidentiality, or other property or privacy right; or </p>
                        <p>d.  Any dispute or issue between you and any third party. </p>
                        <p>We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of those claims.</p>
                        <h5>17. Privacy</h5>
                        <p>Our Privacy Policy sets out how we use the Users’ personal data on the App. To access the App, you, on behalf of Users, need to share with us their personal information. By using our App, you agree to the way in which we collect and process Users’ personal data in the manner set out in the Terms and our Privacy Policy.</p>
                        <h5>18. Entire Agreement</h5>
                        <p>These Terms, including any document incorporated by reference herein, are the whole agreement between us and you concerning the App, and these Terms supersede and replace any prior agreements between us and you.</p>
                        <h5>19. Assignment</h5>
                        <p>You may not assign these Terms or any of your interests, rights or obligations under these Terms to any other person.</p>
                        <h5>20. Miscellaneous</h5>
                        <p>If any provision of these Terms shall be found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. </p>
                        <p>Failure to strictly enforce any Terms or forbearance to exercise any right or remedy shall not be construed as a waiver by us of such right or remedy or preclude the further exercise of the right or remedy or any other thereof. </p>
                        <p>No waiver of any of these Terms shall be deemed a further or continuing waiver of such Terms or any other terms or condition. The parties (you and us) are neither partner, agent nor representative of the other and the relationship of the parties is that of independent contractors.</p>
                        <p>Headings are inserted for convenience only and are not intended to be part of or to affect the meaning or interpretation of the Terms. All provisions of these Terms, which by their nature should survive termination or expiration, shall survive termination or expiration, including, without limitation, provisions relating to intellectual property rights, warranty disclaimers, indemnity and limitations of liability.</p>
                        <h5>21. Amendment</h5>
                        <p>We reserve the right, in our sole discretion, to modify, alter or otherwise update these Terms or at any time for any reason, without providing you any notice thereof.  Users’ continued use of the App, after the modifications have been posted, shall constitute your acceptance of the modifications. If you do not agree to the updated Terms, you must cease your/Users’ use of the App. Any and all such modifications are effective immediately upon posting and apply to all Users access and use the App. You agree to periodically review the Terms in order to be aware of any such modifications.</p>
                        <h5>22. Governing Law</h5>
                        <p>The Terms shall be governed by and interpreted in accordance with the laws of India, and the Courts of Mumbai shall have exclusive jurisdiction over all disputes (whether contractual or non-contractual) arising out of or in connection with the Terms. You agree to submit to the exclusive jurisdiction of the Courts of Mumbai to resolve any dispute arising out of these Terms or the use of the Services.</p>
                        <h5>23. Compliance with applicable law</h5>
                        <p>The App is based in India, and conducts its business in a manner compliant with the laws of India and other international laws and regulations. However, you shall be solely responsible to use the App in strict compliance with all applicable laws, rulings, and regulations, and in a fashion that does not in our sole judgment, negatively impact our goodwill or reputation and you shall take no action, which might cause us to be in breach of any laws, rulings or regulations applicable to it. You agree to comply with all such applicable restrictions and not to export or re-export the services or Application’s content to countries or persons prohibited under Indian or other applicable export control laws or regulations. You agree that you will not use the App in any country in any manner prohibited by any applicable laws, restrictions or regulations. You are solely responsible for the compliance with the laws of your local jurisdiction and any other applicable laws regarding the export of the services and content. If you access the App from a location outside India, You are responsible for compliance with all local laws. We make no representation that materials or other content on the App are appropriate or available for use outside India, its territories, possessions and protectorates.</p>
                        <h5>24. Acceptance of the Terms</h5>
                        <p>You expressly acknowledge that by accepting the Terms;</p>
                        <p>a. You grant your consent to the Company, to collect and process your child’s data (as further explained in our   Privacy Policy attached herein). </p>
                        <p>b. You grant your consent for your child to access the Application, which shall include, but not be limited to browsing the Application, accessing various features available on the Application, taking part in Contests (defined below) conducted on the Application and all other features as may be made available on the Application in future. </p>
                        <p>c. You agree, acknowledge and warrant that you are responsible for the active monitoring of your child’s use of the Application and the content submitted by him on the Application.</p>
                        <h5>25. Contact us</h5>
                        <p>If you have any questions, concerns, or comments about our Terms, you may contact us at: guppy@guppytheatre.com or via WhatsApp, on the link which is available on the Home Page in the ‘Get in touch’ section. </p>
                    </div>
                    <div style={{textAlign: 'right',cursor:"pointer"}} classname={styles.BackToTop}  onClick={()=>{window.scrollTo(0, 0)}}>
                        <img onClick={handleScrollToTop} src={Images.navleft} style={{transform: 'rotate(90deg)', width: '70px'}} /><span>Back to top</span>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default TermsConditions;

