import React, { useState, useMemo, useRef,useEffect} from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import TinderCard from "react-tinder-card";
import { useNavigate } from "react-router-dom";
// import { useQuery } from "@tanstack/react-query";
// import api from "../ApiService/Apicall";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
const CardSwiper = ({ prevpress, nextpress,data,Storydata,otherisLoading,hide}) => {
  const navigate=useNavigate();
  const [currentIndex, setCurrentIndex] = useState(Storydata?.length - 1);
  const [slidecount, setslidecount] = useState(1);
  const currentIndexRef = useRef(currentIndex);
  const [showrandom, setshowrandom] = useState(false);
  const childRefs = useMemo(
    () =>
      Array(Storydata?.length)
        .fill(0)
        .map((i) => React.createRef()),
    [Storydata]
  );
  

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex >= 0;
  const swiped = async (direction, nameToDelete, index) => {
    updateCurrentIndex(index - 1);
  };

  const outOfFrame = (name, idx) => {
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    if (idx === 0) {
    for(let i=0;i<=Storydata.length;i++){
      childRefs[i].current.restoreCard();
      setCurrentIndex(Storydata?.length - 1)
    }
    }
  };

  const swipe = async (dir) => {
    if(currentIndex==4){
      setshowrandom(true);
    }
 
    if (canSwipe && currentIndex < Storydata?.length) {
      await childRefs[currentIndex].current.swipe(dir);
    }
  };
  
  const rotating = (i) => {
    if (i % 2 === 0 ) {
      return "10deg";
    }
    // else{
      // return "-10deg";
    // }
    if (i % 3 === 0) {
      return "-10deg";
    }
  };

  if(!!Storydata){
    return (
      <section className={styles.cardPickSection} style={{overflow:"hidden"}}>
        {otherisLoading?<CircularProgress />:<div className="container">
          {hide==undefined&&<div className={styles.swiperNavBanner}>
            <button
              className={styles.swiperPrev}
              onClick={() => {
                prevpress();
                if(slidecount <= 1){
                setslidecount(data?.length);
               } else {
                  setslidecount(slidecount - 1);
                }
              }}
              style={{outline:"none",cursor:"pointer"}}>
              <img src={Images.navBannerleft} alt="" />
            </button>
            <p className={styles.page_num}>{`${slidecount}/${data?.length}`}</p>
            <button
              className={styles.swiperNext}
              onClick={() => {
                nextpress();
                if(slidecount >= data?.length){
                  setslidecount(1);
                 } else {
                    setslidecount(slidecount + 1);
                  }
              }}
            style={{outline:"none",cursor:"pointer"}} >
              <img src={Images.navBannerleft} alt="" />
            </button>
          </div>}
        { hide==undefined&& <h2>
            Pick a card to start an adventure
          </h2>}
          <div
            className={styles.swiperSection}
            style={{ userSelect: "none" }}
          ><div className={styles.swiperSectionInfo_inner} style={{ display: "flex", height: "23em", justifyContent: "center" }}>
              {!!Storydata && Storydata?.map((cardData, index) => (
                <div className={styles.swiperSection_InfoDe}
                  style={{
                    zIndex: index,
                    position: "absolute",
                    rotate: rotating(index),
                  }}
                  key={index}
                ><TinderCard
                    ref={childRefs[index]}
                    className="swipe"
                    key={cardData?.attributes?.title}
                    onSwipe={(dir) => swiped(dir, cardData?.attributes?.title, index)}
                    onCardLeftScreen={() => outOfFrame(cardData?.attributes?.title, index)}
                    swipeThreshold={900}
                    swipeRequirementType={"velocity"}
                    preventSwipe={["up","down"]}
                  >
                    <div className={styles.swiperSlide}>
                      <div className={styles.cardNew} style={{overflow:"hidden"}}>
                        <div className={styles.cardHeader}>
                          {/* <img src={Images.Sample} alt="" /> */}
                          <img src={"https://manage.guppytheatre.com" + cardData?.attributes?.story_thumbnail?.data?.attributes?.url} alt="image" />
                        </div>
                        <div className={styles.cardFooter}>
                          <span className={styles.cardfooterName}  style={{whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden",display:"inherit"}}>{cardData?.attributes?.title}</span>
                        </div>
                      </div>
                    </div>
                  </TinderCard>
                </div>
              ))}
            </div>
            <div className={styles.swiperNav}>
              <button className={styles.swiperPrev} onClick={() => swipe("left")}   style={{outline:"none",cursor:"pointer"}}>
                <img src={Images.navleft} alt="" />
              </button>
              <button
                className={styles.swiperNext}
                onClick={() => swipe("right")}
                style={{outline:"none",cursor:"pointer"}}>
                <img src={Images.navright} alt="" />
              </button>
            </div>
            <div className={styles.swiperNavtwo}>
              <button className={styles.swiperPrev} onClick={() => swipe("left")}   style={{outline:"none",cursor:"pointer"}}>
                <img src={Images.navleft} alt="" />
              </button>
              <button
                className={styles.swiperNext}
                onClick={() => swipe("right")}
                style={{outline:"none",cursor:"pointer"}}>
                <img src={Images.navright} alt="" />
              </button>
            </div>
          </div>
          {hide==undefined&&<div className={styles.btnStory} onClick={()=>{
            //  navigate("/StoryPage",{ state: {item:Storydata[0]}})
            navigate("/StoryPage",{ state: {item:Storydata[currentIndex]}});
            }}>
            <button className={styles.btnFill}>Start a Story!</button>
          </div>}
        </div>}

           {showrandom&& <div className={styles.PickStoryHome}>
              <div className="container">
                <p>Unable to decide which story to <br/> choose? Let Guppy help.</p>
                <button className={styles.btnFill} onClick={()=>{
                   navigate("/StoryPage",{ state: {item:Storydata[_.random(0, Storydata?.length - 1)]}});
                }}>Guppy picks a Story </button>
              </div>
            </div>}

        {hide==undefined&&<div className={styles.bgImageOverflow}>
          <img src={Images.bludebg2} alt="" />
        </div>}
      </section>
    );
  }else{
    return(<CircularProgress />);
  }
};

export default CardSwiper;
