import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstage from "./HeaderBackstage";
import { useBackstagewhatwodo } from "../../otherexports";
import { useNavigate } from "react-router-dom";
import MetaTags from "react-meta-tags";

function BackstageMenu() {
  const { isLoading, data } = useBackstagewhatwodo();
  const navigate = useNavigate();
  // console.log(JSON.stringify(data),"=========data===data")
  window.scrollTo({ top: 0 });
  return (
    <div>
      <MetaTags>
        <title>Backstage Menu - Guppy Theater</title>
        {/* <meta name="description" content="Contact Guppy Theatre to receive IP bibles and Teaser Video" />
                <meta name="keywords" content="animation, games, IP development, story world, transmedia storytelling, children" /> */}
      </MetaTags>
      <HeaderBackstage />
      <div className={BsCustom.BackstageMenuMain}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul>
                <li className={BsCustom.liMainBs}>
                  <span
                    className={`${BsCustom.BackstageMenuLi} ${BsCustom.BackstageMenuSubLi}`}
                  >
                    <a href="/WhoWeAre">Who we are</a>
                  </span>
                  <img
                    className={BsCustom.BackstageMenuMainNavimg}
                    style={{ marginBottom: "20px", marginLeft: "15px" }}
                    src={Images.BackstageMenuMainNav}
                  />
                  <img
                    className={BsCustom.BackstageMenuMainNavHover}
                    style={{ marginBottom: "20px", marginLeft: "15px" }}
                    src={Images.BackstageMenuMainNavHover}
                  />
                  <ul className={BsCustom.BAckstageSubmenu}>
                    <li>
                      <a href="/OurStory">Our Story</a>
                    </li>
                    <li>
                      <a href="/OurTeam">Our Team</a>
                    </li>
                    {/* <li><a href="/GuppyIntroduction">Introducing Guppy</a></li> */}
                    <li>
                      <a href="/Transmedia">Transmedia and GuppyVerse</a>
                    </li>
                  </ul>
                </li>
                <li className={BsCustom.liMainBs}>
                  <span
                    className={`${BsCustom.BackstageMenuLi} ${BsCustom.BackstageMenuSubLi}`}
                  >
                    <a href="/WhatWeDo">What we do</a>
                  </span>
                  <img
                    className={BsCustom.BackstageMenuMainNavimg}
                    style={{ marginBottom: "20px", marginLeft: "15px" }}
                    src={Images.BackstageMenuMainNav}
                  />
                  <img
                    className={BsCustom.BackstageMenuMainNavHover}
                    style={{ marginBottom: "20px", marginLeft: "15px" }}
                    src={Images.BackstageMenuMainNavHover}
                  />
                  <ul className={BsCustom.BAckstageSubmenu}>
                    {/* <li><a href="/home">Guppy Theatre</a></li>
                                    {data?.attributes?.backstage_stories?.data?.map((item, index)=>{
                                        return(<li><a href="#"  onClick={() => {
                                            console.log(JSON.stringify(item))
                                            navigate("/IpInside",{ state: {item:item}});
                                          }}>{item?.attributes?.title}</a></li>)
                                    })} */}
                    <li>
                      <a href="/IpInside">Maharaja of Mastipur</a>
                    </li>
                    <li>
                      <a href="/MalluAndPi">Mallu and Pi</a>
                    </li>
                    <li>
                      <a href="/Miniraja">Miniraja</a>
                    </li>
                    <li>
                      <a href="/BecomingNarad">Becoming Narad</a>
                    </li>
                    <li>
                      <a href="/GuppyStoriesIp">Guppy Stories</a>
                    </li>
                  </ul>
                </li>
                {/* <li className={BsCustom.liMainBs}>
                                <span className={`${BsCustom.BackstageMenuLi} ${BsCustom.BackstageMenuSubLi}`}><a href="#">What we sell</a></span>
                                <img className={BsCustom.BackstageMenuMainNavimg} style={{marginBottom: '20px', marginLeft: '15px'}} src={Images.BackstageMenuMainNav} />
                                <img className={BsCustom.BackstageMenuMainNavHover} style={{marginBottom: '20px', marginLeft: '15px'}} src={Images.BackstageMenuMainNavHover} />
                                <ul className={BsCustom.BAckstageSubmenu}>
                                    <li><a href="#">Animation</a></li>
                                    <li><a href="#">Events</a></li>
                                    <li><a href="#">Publication</a></li>
                                    <li><a href="#">Games</a></li>
                                    <li><a href="#">Merchandising</a></li>
                                </ul>
                            </li> */}
                <li className={BsCustom.BackstageMenuLi}>
                  <a href="/ContactBackstage">Contact us</a>
                </li>
                {/* <li className={BsCustom.BackstageMenuLi}><a href="/News">News</a></li> */}
              </ul>
            </div>
          </div>
          <div className={`row ${BsCustom.BackstageMenuBottom}`}>
            {/* <div className={`col-sm-12 col-md-8`}>
                        <p>Guppy Storytelling Game</p>
                        <button className={BsCustom.BsComBtn} onClick={() => {
                            navigate("/GuppyStoriesIp");
                        }}>Guppy Stories <img src={Images.BsComBtnImg} /></button>
                    </div> */}
            <div className={`col-sm-12 col-md-4 ${BsCustom.SocialIconMenu}`}>
              <p>Follow Us</p>
              <a href="https://www.instagram.com/guppytheatre/">
                <img src={Images.Insta} />
              </a>
              <a href="https://www.facebook.com/GuppyTheatre1">
                <img src={Images.facebook} />
              </a>
              <a href="https://www.youtube.com/@guppytheatre5404">
                <img src={Images.youtube} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackstageMenu;
