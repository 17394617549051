// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.PackagesMain{
    padding: 50px 0;
    background: #D9EB41;
}
.PackagesInfo{
    display: flex;
    flex-wrap: wrap;
}
.PackagesInfo .PackagesInfoInner{
    flex: 0 0 30%;
    max-width: 30%;
}
.PackagesInfoInnerImg{
    position: relative;
}
.PackagesInfoInnerImg .arrowimgBottom{
    position: absolute;
    bottom: 0;
    right: 0;
}
.PackagesMain .PackagesInfo .PackagesInfoInner p{
    font-weight: 500;
}
.PackagesMain p{
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #000000;
}
.PackagesInfoInnerImg:hover{
    font-weight: 700;
}

/* PackageIngredient */
.PackagesMain_top{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/MyStoryKits/MyStoryPackages.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;AACA;IACI,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,eAAe;AACnB;AACA;IACI,aAAa;IACb,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;AACZ;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,gBAAgB;AACpB;;AAEA,sBAAsB;AACtB;IACI,kBAAkB;AACtB","sourcesContent":["*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n.PackagesMain{\n    padding: 50px 0;\n    background: #D9EB41;\n}\n.PackagesInfo{\n    display: flex;\n    flex-wrap: wrap;\n}\n.PackagesInfo .PackagesInfoInner{\n    flex: 0 0 30%;\n    max-width: 30%;\n}\n.PackagesInfoInnerImg{\n    position: relative;\n}\n.PackagesInfoInnerImg .arrowimgBottom{\n    position: absolute;\n    bottom: 0;\n    right: 0;\n}\n.PackagesMain .PackagesInfo .PackagesInfoInner p{\n    font-weight: 500;\n}\n.PackagesMain p{\n    text-align: center;\n    font-weight: 600;\n    font-size: 36px;\n    line-height: 47px;\n    text-align: center;\n    color: #000000;\n}\n.PackagesInfoInnerImg:hover{\n    font-weight: 700;\n}\n\n/* PackageIngredient */\n.PackagesMain_top{\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
