import React from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";

function DownoadGuppyApp() {
  return (
    <section className={styles.downoadGuppyApp}>
      <div className="container">
        <div className="row">
          <div className={`col-md-12 col-lg-6 ${styles.downoadGuppyApp_left}`}>
            <div className={styles.GuppyAppInner}>
              <p>
                Guppy Stories <br /> A Story Game
              </p>
              <img src={Images.DownloadApp} />
            </div>
          </div>
          <div className={`col-md-12 col-lg-6 ${styles.downoadGuppyApp_right}`}>
            <p>Download Guppy App</p>
            <span>
              Guppy Stories is a story game where kids can immerse themselves in
              a big adventure where they choose what happens next or create
              their own twists and turns.
            </span>
            <div className={styles.barcode_inner}>
              <p>Scan the QR Code.</p>
              <div className={styles.barcode_box}>
                <img src={Images.QRScanner} />
              </div>
              <p style={{ padding: "20px 0", margin: "0" }}>Or</p>
            </div>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.guppytheatre.app"
              className={styles.btnFill}
            >
              Get the link App
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DownoadGuppyApp;
