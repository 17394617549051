import React from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";
import { useEffect, useState, createRef } from "react";
import { signupapi } from "../redux/userdata/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../ApiService/Apicall";
import emailsend from "../ApiService/emailsend";
import NoDraftModal from './NoDraftModal';
import SubmittedSuccessfullyModal from './SubmittedSuccessfullyModal';
import Header from "./Header";
import { fontWeight } from "@mui/system";

function SignUpSuccessFully() {

  return (
    <div>
        <Header/>
        <div className={`${styles.signUpBg} ${styles.signUpOtp}`}>
        <div className={styles.arrowImgMain}>
            <div className={styles.signLogRightLine}>
            <img src={Images.signLogRightLine} />
            </div>
            <div className="container">
            <div className={`row ${styles.signUpInfo}`}>
                <div className={`col-sm-12 col-md-12 ${styles.rightInfo}`}>
                    <img src={Images.ConfirmedFrame} />
                    <p style={{
                        fontSize: '32px',
                        lineHeight: '45px',
                        fontWeight: '600',
                        marginBottom: '40px'
                    }}
                    >Congratulations! Your account <br/> has been successfully created, in <br/> the Guppy Story App. </p>
                 <button
                        type="submit"
                        className={styles.btnFill}>
                        Explore
                    </button>
                </div>
                   
            </div>
            </div>
            <div className={styles.signBgLeft}>
            <img src={Images.signupbg} />
            </div>
        </div>
        </div>
    </div>
  );
}

export default SignUpSuccessFully;
