import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import LatestNewsBS from "../Component/LatestNewsBS";
import BannerCommon from "../Component/BannerCommon";
import ContactBs from "../Component/ContactBs";
import BackStageContactFooter from "../Component/BackStageContactFooter";
import NewsLetterBs from "../Component/NewsLetterBS";
import { useNavigate } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";

function ContactBackstage(){
    const navigate = useNavigate();
    const {isLoading,home} =useBackstagehome();
    if(!isLoading){
        return(
            <section className={BsCustom.WrapperContactBackstage}>
                <MetaTags>
                    <title>Contact Us - Guppy Theater</title>
                    <meta name="description" content="Contact Guppy Theatre to receive IP bibles and Teaser Video" />
                    <meta name="keywords" content="animation, games, IP development, story world, transmedia storytelling, children" />
                </MetaTags>
                <HeaderBackstageNew />
                <section className={BsCustom.BannerCommon}>
                    <img className={BsCustom.BannerCommonImg} src={Images.BackstageBannerContact} />
                    <img className={BsCustom.MobileCommonBannerNew} src={Images.ContactBannerMobile} />
                    <img className={BsCustom.BackBtnBSCommonNew} src={Images.BackstageBackBtn} style={{cursor:"pointer"}}  onClick={() => {
                    navigate(-1);
                    }} />
                </section>
                <ContactBs  />
                <NewsLetterBs/>
                <BackStageContactFooter/>
            </section>
        ); 
    }
    else 
    {return (<Loader />)}
}

export default ContactBackstage;