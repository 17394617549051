//=================Home Data================

export const Home_Data_Request = 'Home_Data_Request';
export const Home_Data_SUCCESS = 'Home_Data_SUCCESS';
export const Home_Data_FAILURE = 'Home_Data_FAILURE';

//=================Home Data================

//=================Story lits ================

export const Story_lits_Request = 'Story_lits_Request';
export const Story_lits_SUCCESS = 'Story_lits_SUCCESS';
export const Story_lits_FAILURE = 'Story_lits_FAILURE';

//=================Story lits================
