import React from "react";
import { useState } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import Sharebtn from "./Sharebtn";
import { useNavigate } from "react-router-dom";

function MyStories_Ingredients({ hide, Ingridient, userinfo,matches }) {
  const [item, setitem] = useState(null);
  const navigate=useNavigate();
  // const static_data = [
  //     { img: Images.MuniaPackage, title: "Jungle", bottomImg: Images.CameraImg},
  //     { img: Images.MuniaPackage, title: "Jungle", bottomImg: Images.CameraImg},
  //     { img: Images.MuniaPackage, title: "Jungle", bottomImg: Images.CameraImg},
  // ];

  // modal
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal(e) {
    setitem(e);
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // Modal

  return (
    <div>
      {hide == undefined && (
        <>
          <div className={styles.backbtn}>
            <a href="#">
              <img src={Images.navBannerleft} />
            </a>
          </div>
          <h4>My Stories</h4>
          <div className={styles.storybtn}>
            <button className={styles.btnFill}>Stories</button>
            <button className={styles.btnUnfill}>Ingredients</button>
          </div>
        </>
      )}
      <h3>Here is a list of your published Ingredients</h3>
      <p>
        lorem ipsum dolor sit amet consectetur <br /> adipiscing elit
      </p>
      <div className={styles.PackagesInfo}>
        {Ingridient.map((item, index) => {
          return (
            <div className={styles.PackagesInfoInner}>
              <div
                className={styles.PackagesInfoInnerImg}
                onClick={() => {
                  if(!matches){
                    navigate('/ModalStoryIngrediaentsNew',{state:{
                      item:item
                    }
                    })
                  }else{
                    openModal(item);
                  }
                
                }}
              >
                <img
                  src={
                    "https://manage.guppytheatre.com" +
                    item?.attributes?.IngredientImage?.data?.attributes?.url
                  }
                />
                {/* <img  className={styles.arrowimgBottom} src={e.bottomImg} onClick={openModal} /> */}
              </div>
              <p>{item?.attributes?.IngredientName}</p>
            </div>
          );
        })}
      </div>
      <Modal
        style={{
          content: {
            display: "flex",
            backgroundColor: "#0000",
            borderWidth: 0,
            flexDirection: "column",
            flex: 1,
            position: "unset",
            height: "100%",
            overflow: "auto",
          },
        }}
        overlayClassName={Modalstyle.modal}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className={Modalstyle.modalIngredients}>
            <div className={styles.backbtn} onClick={closeModal}>
              <a href="#">
                <img src={Images.navleft} onClick={closeModal} />
              </a>
            </div>
            <p>{item?.attributes?.IngredientName}</p>
            <div className={Modalstyle.modalIngredientsInner}>
              <img
                src={
                  "https://manage.guppytheatre.com" +
                  item?.attributes?.IngredientImage?.data?.attributes?.url
                }
              />
              <p>
                <strong>{item?.attributes?.IngredientName}</strong>
              </p>
              <div className={Modalstyle.modalIngredientsInnerInfo}>
                <div>
                  <img src={Images.account_6} />
                  <span>{userinfo.username}</span>
                </div>
                <div className={Modalstyle.modalIngredientsInnerInfoimg}>
                  <img src={Images.logoHeader} />
                </div>
              </div>
            </div>
            <div className={Modalstyle.HeartYellowbtn} onClick={closeModal}>
              <a href="#">
                <img src={Images.HeartYellow} />
              </a>
            </div>
            <div className={Modalstyle.sharebtnMobile}>
                <button style={{position: "relative"}} className={styles.btnFill}><img style={{position: "absolute", top: "-30px", left: "-30px", width: "77px"}} src={Images.YellowShareBtnStory} />Share this Ingredient</button>
            </div>
          </div>
          <Sharebtn />
          <div className={Modalstyle.modalIngredientsshareMobile}>
            <div className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img src={Images.Sharecopylink} />
                <span>CopyLink</span>
              </a>
              <a href="#">
                <img src={Images.Sharewhtspp} />
                <span>Whatsapp</span>
              </a>
            </div>
            <div className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img src={Images.Sharemessage} />
                <span>Email</span>
              </a>
            </div>
            <div className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img src={Images.ShareSnap} />
                <span>Snapchat</span>
              </a>
              <a href="#">
                <img src={Images.Insta} />
                <span>Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MyStories_Ingredients;
