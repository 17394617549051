import React, { useState } from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import BackStageMaharaja from "../Component/BackStageMaharaja";
import BSHyperLinkPriviousNext from "../Component/BSHyperLinkPriviousNext";
import StoryInformationBackstage from "../Component/StoryInformationBackstage";
import BshyperLinkStoryTitle from "../Component/BshyperLinkStoryTitle";
import Credit from "../Component/Credit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBackstagewhatwodo, useSettergetter } from "../../otherexports";
import MetaTags from "react-meta-tags";
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";

function BecomingNarad() {
  const location = useLocation();
  const { Singleitem } = useSettergetter(location?.state?.item);
  const [item, setitem] = useState(Singleitem);
  window.scrollTo({ top: 0 });
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { isLoading, home } = useBackstagehome();
  if (!isLoading) {
    return (
      <section className={BsCustom.WrapperIpInside}>
        <MetaTags>
          <title>Becoming Narad - Guppy Theater</title>
          <meta
            name="description"
            content="A TRAVEL ADVENTURE BETWEEN MYTHOLOGY AND SCIENCE"
          />
          <meta
            name="keywords"
            content="adventure, Narad, animation, game, children, streaming"
          />
        </MetaTags>
        <HeaderBackstageNew />
        <div className={BsCustom.BshyperLinkStoryTitleMain}>
          <div className="container">
            <div className={BsCustom.BSHyperLinkPriviousNextMain}>
              <a href="/Miniraja" className="ReadMoreCmn">
                <img
                  style={{ marginRight: "15px" }}
                  src={Images.CurveArrowLeft}
                />
                <span>Previous IP</span>
              </a>
              <a href="#">
                <h1 className={BsCustom.BsH1}>Becoming Narad </h1>
              </a>
              <a href="/GuppyStoriesIp" className="ReadMoreCmn">
                <span>Next IP</span>
                <img
                  style={{ marginLeft: "15px" }}
                  src={Images.CurveArrowRight}
                />
              </a>
            </div>
          </div>
        </div>

        <div className={BsCustom.StoryInformationBackstageSection}>
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div
                className={`col-sm-12 col-md-8 ${BsCustom.StoryInformationBackstageLeft}`}
              >
                <div className={BsCustom.CopyrightTextNew}>
                  <img
                    style={{ height: "800px" }}
                    src={Images.IPInsidebannerBN}
                  />
                  <span>
                    &#169; 2024 Crossover Media and Design Pvt. Ltd. , all
                    rights reserved
                  </span>
                </div>
                <div
                  className={BsCustom.StoryInformationMobileBottom}
                  style={{ textAlign: "center" }}
                >
                  <span className={BsCustom.ImageBottomInfoSpan}>
                    Narad interacting with his audience in his ‘live streaming’
                    show
                  </span>
                </div>
              </div>
              <div
                className={`col-sm-12 col-md-4 ${BsCustom.StoryInformationBackstageRight}`}
              >
                <img
                  src={Images.IPInsideLOGOBN}
                  style={{ margin: "0 auto 55px" }}
                />
                {/* <h4 className={BsCustom.BsH4}>IP Information</h4> */}
                <p className={BsCustom.BsCommaonP}>
                  <strong>Format: </strong>
                  <br />
                  26 x 22 minutes - Animated TV series{" "}
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Genre: </strong>
                  <br />A fantasy travel adventure, action and comic debate
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Written by: </strong>
                  <br />
                  Pamela Hickey and Dennys McCoy
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Target Audience: </strong>
                  <br />
                  8+ and family{" "}
                </p>
                {/* <p className={BsCustom.BsCommaonP}><strong>Production Status: </strong><br/> Promo in development, pilot script ready</p> */}
                {/* <p className={BsCustom.BsCommaonP}><strong>GAME: </strong><br/>Game pitch and pilot script ready </p> */}
                {/* <p className={BsCustom.BsCommaonP}><strong>Status: </strong><br/>Seeking primary broadcasters and co-production partners</p> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`container-fluid ${BsCustom.StoryInformationDesktopBottom}`}
        >
          <span className={BsCustom.ImageBottomInfoSpan}>
            Narad interacting with his audience in his ‘live streaming’ show
          </span>
        </div>

        <section
          className={`${BsCustom.WTGBSection} ${BsCustom.IPInsideFirstMAinPara}`}
        >
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <div className={BsCustom.CopyrightTextNew}>
                  <a href="#">
                    <img src={Images.SynopsisBN} />
                  </a>
                  <span>
                    &#169; 2024 Crossover Media and Design Pvt. Ltd. , all
                    rights reserved
                  </span>
                </div>
                <span className={BsCustom.ImageBottomInfoSpan}>
                  Bendonner challenging Finn McCool (two giants) at the Giant's
                  Causeway, Ireland
                </span>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <h3 class={BsCustom.BsH3}>Synopsis</h3>
                <div className={BsCustom.CopyrightTextNew}>
                  <img
                    className={BsCustom.MainWWANewExtraImg}
                    src={Images.SynopsisBN}
                  />
                  <span className={BsCustom.CopyrightTextNewMobile}>
                    &#169; 2024 Crossover Media and Design Pvt. Ltd. , all
                    rights reserved
                  </span>
                </div>
                <span className={BsCustom.ImageBottomInfoSpan}>
                  Bendonner challenging Finn McCool (two giants) at the Giant's
                  Causeway, Ireland
                </span>

                <p
                  className={BsCustom.BsCommaonP}
                  style={{ marginBottom: "15px" }}
                >
                  BECOMING NARAD is a ‘streaming show’ of Narad through which
                  his audience accompanies him and his team on fun and humorous
                  adventures to discover the{" "}
                  <strong>science and mythology</strong> of some of the
                  fantastic natural wonders of the world. His companions are a
                  motley team of real and fantastical characters, each of whom
                  represent one of the powers of RISHI NARAD from Indian
                  mythology.
                </p>
                <p
                  className={BsCustom.BsCommaonP}
                  style={{ marginBottom: "15px" }}
                >
                  A call to action through fantastic storytelling, discovery
                  through travel and witty debate!!
                </p>
                <p className={BsCustom.BsCommaonP}>
                  The show encourages the children to act now to protect their
                  own future and inspires them to ‘Become Narad’ themselves.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className={BsCustom.TopSectionbreaker1BN}>
          <img
            style={{ width: "100%" }}
            className={BsCustom.desktopBreakerImg}
            src={Images.Sectionbreaker1BN}
          />
          <img
            style={{ width: "100%" }}
            className={BsCustom.MobileBreakerImg}
            src={Images.TopSectionbreaker1BN}
          />

          <div className="container-fluid">
            {/* <span className={BsCustom.ImageBottomInfoSpan}> Narad and Superdrone at a natural wonder - Giant Causeway of Ireland </span> */}
            <p
              className={BsCustom.BsCommaonP}
              style={{ padding: "64px 0", textAlign: "center" }}
            >
              The show inspires the children to act now to protect their own
              future and encourages them to ‘Become Narad’ themselves.
            </p>
          </div>
        </div>

        <div className={BsCustom.NewGuppyIPStoryPAra}>
          <img
            style={{ width: "100%" }}
            className={BsCustom.desktopBreakerImg}
            src={Images.Sectionbreaker2BN}
          />
          <img
            style={{ width: "100%" }}
            className={BsCustom.MobileBreakerImg}
            src={Images.MobileBreakerBecomingNarad}
          />

          <div className="container-fluid">
            <span
              style={{ marginBottom: "60px" }}
              className={BsCustom.ImageBottomInfoSpan}
            >
              Holographic projection through Superdrone Camera
            </span>
          </div>
          <div className={BsCustom.CreditSection}>
            <div className="container-fluid">
              <h5>CREDITS</h5>
              <p>Creative Consultant - Sarah Baynes (Creative Garden UK)</p>
              <p>Writers - Pamela Hickey & Dennys McCoy (USA & France)</p>
              <p>Teaser Production - Prismart Studios (INDIA)</p>
              <p>Game Writer - Priyadarshini Gogoi (India & USA)</p>
              <p>Concept Art & Design - Anima Point (Malaysia/ Finland) </p>
              <p>Music & Voices - Scoredraw Music (Ireland) </p>
            </div>
          </div>
        </div>

        <div className={BsCustom.ExtraParaIpinside}>
          <div className="container-fluid">
            <h5>STATUS / Enquiries</h5>
            <p>
              Status - Seeking primary buyers (Broadcasters/OTT) and
              co-production partners <br />
              For co-productions, pre-sales, pitch bible, screening materials
              and other business enquiries <br />
              contact :{" "}
              <a
                href="mailto:anil@guppytheatre.com"
                style={{ textDecoration: "underline", color: "#000" }}
              >
                {" "}
                anil@guppytheatre.com{" "}
              </a>
            </p>
          </div>
          <div className="container-fluid" style={{ marginTop: "30px" }}>
            <h5>Production Status:</h5>
            <p>
              Teaser in development, pilot episode script & pitch bible ready{" "}
              <br /> Game: Game pitch and game script ready
            </p>
          </div>
        </div>
        <div className={BsCustom.BSHyperLinkPriviousNextWrapper}>
          <div className="container">
            <div className={BsCustom.BSHyperLinkPriviousNextMain}>
              <a href="/Miniraja" className="ReadMoreCmn">
                <img
                  style={{ marginRight: "15px" }}
                  src={Images.CurveArrowLeft}
                />
                <span>Previous IP</span>
              </a>
              <button
                href="#"
                onClick={handleScrollToTop}
                className="ReadMoreCmn"
                style={{
                  color: "#0D5268",
                  textDecorationLine: "underline",
                  border: "0",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                Back to Top
              </button>
              <a href="/GuppyStoriesIp" className="ReadMoreCmn">
                <span>Next IP</span>
                <img
                  style={{ marginLeft: "15px" }}
                  src={Images.CurveArrowRight}
                />
              </a>
            </div>
          </div>
        </div>
        <FooterBs />
      </section>
    );
  } else {
    return <Loader />;
  }
}

export default BecomingNarad;
