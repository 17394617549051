import React, { createRef, useEffect, useMemo, useState } from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import Modal from 'react-modal';
import Modalstyle from "../../pages/StoryPage/storypage.module.css";
import styles from '../../pages/Home/landingpage.module.css';
import NoDraftModal from "../../component/NoDraftModal";
import subscribeEmailAsync from "../../api/subscripeEmailAsync";

const isValidEmail = email => {
    return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
}
function NewsLetterBS() {
    const [email, setEmail] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [modaltext, setmodaltext] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);

    const modalopen = createRef(null);

    const submitForm = async () => {
        setErrorMsg(null);
        var emailcheck = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if(email.length==0){
            setmodaltext("Email is invalid !");
            modalopen.current.show();
            setIsSubmitting(false)
        }
        else if(!emailcheck.test(email)){
            setmodaltext("Invalid Email Id");
            modalopen.current.show();
            setIsSubmitting(false)
          }
        else{
            setIsSubmitting(true)
            try {
                const response = await subscribeEmailAsync({email:email })
                if (response.id) {
                    setIsSubmitting(false)
                    openModal()
                    setEmail("")
                } else{
                    setIsSubmitting(false)
                    setmodaltext(response.message);
                    modalopen.current.show();
                }
              
            } catch(e) {
                console.log(e)
                setmodaltext("Email is invalid !");
                modalopen.current.show();
            }  finally {
                setIsSubmitting(false)
            }
        }
    }


    useEffect(() => {
        if (!email) {
            setErrorMsg(null)
        } else {
            if (isValidEmail(email)) {
                setErrorMsg(null)
            } else {
                setErrorMsg("Email is invalid !")
            }
        }
    }, [ email ])


    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div className={BsCustom.NewsletterSection}>
            <div className="container-fluid">
                <div className={BsCustom.NewsletterInner}>
                    <h3 className={BsCustom.BsH3}>Subscribe to Our Newsletter!</h3>
                    <p>Stay updated with our latest release and event details. Get notified and receive all info.</p>
                    <div className={BsCustom.emailInput}>
                        <input placeholder="Email ID" onChange={(e) => { setEmail(e.target.value) }} value={email} />
                        <img src={Images.inputRightArrow} alt="" onClick={submitForm} />
                    </div>
                   {isSubmitting && (
                        <div className="">{'Subscribing Newsletter...'}</div>
                    )}
                    {errorMsg && (
                        <div className="text-danger">{errorMsg}</div>
                    )} 
                </div>
            </div>
            <Modal style={{ content: { display: "flex", backgroundColor: "#0000", borderWidth: 0, flexDirection: "column", flex: 1, position: "unset", overflow: 'auto', height: '100%', padding: '20px 20px 20px', justifyContent: "space-around" } }} overlayClassName={Modalstyle.modalconfirm} isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
                <div className="container">
                    <div className={Modalstyle.modalFooterMain}>
                        <img className={Modalstyle.sharebtnimg} src={Images.backbtncross} onClick={closeModal} />
                        <img src={Images.ConfirmedFrame} />
                        <h2>Confirmed! <br /> You will get an email soon.</h2>
                        <button onClick={closeModal} className={styles.btnFill}>OK!</button>
                    </div>
                </div>
            </Modal>
            <NoDraftModal ref={modalopen} text={modaltext} />
        </div>
    );
}

export default NewsLetterBS;