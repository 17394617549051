import React from "react";
import { useState } from "react";
import Images from "./Images";
import styles from '../pages/Home/landingpage.module.css';
import { useNavigate } from "react-router-dom";

function MyStoryPackages({ Packages }) {
  // const static_data = [
  //   { img: Images.StoryPackage1, title: "Jungle", bottomImg: Images.BlueRightImgArrow},
  //   { img: Images.StoryPackage1, title: "Jungle", bottomImg: Images.BlueRightImgArrow},
  //   { img: Images.StoryPackage1, title: "Jungle", bottomImg: Images.BlueRightImgArrow},
  //   { img: Images.StoryPackage2, title: "Animals", bottomImg: Images.BlueRightImgArrow},
  //   { img: Images.StoryPackage2, title: "Animals", bottomImg: Images.BlueRightImgArrow},
  //   { img: Images.StoryPackage2, title: "Animals", bottomImg: Images.BlueRightImgArrow},
  // ];

  const navigate = useNavigate();

  return (
    <div className={styles.PackagesMain}>
      <div className="container">
        <div className={styles.backbtn} onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }}>
          <a onClick={() => { navigate(-1) }}><img src={Images.backbtn} /></a>
        </div>
        <h4>My Story Packs</h4>
        <p className={styles.borderBottom}>List of packages available to use</p>
        <div className={styles.PackagesInfo}>
          {Packages.map((item, index) => {
            return (
              <div style={{ cursor: "pointer" }} className={styles.PackagesInfoInner} onClick={() => {
                navigate('/MyStoryKits/MyStoryPackageIngredient', {
                  state: {
                    item: item,
                    index: index,
                  },
                })
              }}>
                <div className={styles.PackagesInfoInnerImg}>
                  <img style={{ borderRadius: "12px" }} src={"https://manage.guppytheatre.com" +
                    item?.attributes?.PackageImage?.data?.attributes?.url} />
                  <a ><img className={styles.arrowimgBottom} src={Images.BlueRightImgArrow} /></a>
                </div>
                <p>{item?.attributes?.Name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MyStoryPackages;
