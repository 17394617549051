import React, { useState, useRef, useEffect } from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import BackStageMaharaja from "../Component/BackStageMaharaja";
import BSHyperLinkPriviousNext from "../Component/BSHyperLinkPriviousNext";
import StoryInformationBackstage from "../Component/StoryInformationBackstage";
import BshyperLinkStoryTitle from "../Component/BshyperLinkStoryTitle";
import Credit from "../Component/Credit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBackstagewhatwodo, useSettergetter } from "../../otherexports";
import styles from "../../pages/Home/landingpage.module.css";
import MetaTags from "react-meta-tags";
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";

function GuppyStoriesIp() {
  const location = useLocation();
  const iframeRef = useRef(null);

  const [iframeLoaded, setIframeLoaded] = useState(false);
  const { Singleitem } = useSettergetter(location?.state?.item);
  const [item, setitem] = useState(Singleitem);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    function calculateIframeHeight() {
      const iframe = iframeRef.current;
      if (iframe) {
        const width = iframe.offsetWidth; // Get the current width of the iframe
        const aspectRatio = 315 / 560; // Adjust this value according to your iframe's aspect ratio

        const height = width * aspectRatio; // Calculate the height based on the width and aspect ratio
        iframe.style.height = height + "px"; // Set the calculated height to the iframe element
      }
    }
    calculateIframeHeight();
    // Recalculate the iframe height on window resize
    window.addEventListener("resize", calculateIframeHeight);

    return () => {
      window.removeEventListener("resize", calculateIframeHeight);
    };
  }, [iframeLoaded]);

  const handleIframeLoad = () => {
    console.log("logging iframe loaded");
    setIframeLoaded(true);
  };

  const { isLoading, home } = useBackstagehome();
  if (!isLoading) {
    return (
      <section className={BsCustom.WrapperIpInside}>
        <MetaTags>
          <title>Guppy Stories - Guppy Theater</title>
          <meta
            name="description"
            content="Interactive and partipative storytelling game for children"
          />
          <meta name="keywords" content="free online game, free online story" />
        </MetaTags>
        <HeaderBackstageNew />
        <div className={BsCustom.BshyperLinkStoryTitleMain}>
          <div className="container">
            <div className={BsCustom.BSHyperLinkPriviousNextMain}>
              <a href="/BecomingNarad" className="ReadMoreCmn">
                <img
                  style={{ marginRight: "15px" }}
                  src={Images.CurveArrowLeft}
                />
                <span>Previous IP</span>
              </a>
              <a href="#">
                <h1 className={BsCustom.BsH1}>Guppy Stories </h1>
              </a>
              <a href="/LandingPageBs" className="ReadMoreCmn">
                <span>Next IP</span>
                <img
                  style={{ marginLeft: "15px" }}
                  src={Images.CurveArrowRight}
                />
              </a>
            </div>
          </div>
        </div>

        <div className={BsCustom.StoryInformationBackstageSection}>
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div
                className={`col-sm-12 col-md-8 ${BsCustom.StoryInformationBackstageLeft}`}
              >
                <img src={Images.GuppyPlaystore_BannersFinal} />
              </div>
              <div
                className={`col-sm-12 col-md-4 ${BsCustom.StoryInformationBackstageRight}`}
              >
                <img src={Images.GuppystorieslogoIp} />
                {/* <h4 className={BsCustom.BsH4}>IP Information</h4> */}
                <p className={BsCustom.BsCommaonP}>
                  <strong>Genre: </strong>
                  <br />
                  Stream of consciousness story worlds{" "}
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Medium: </strong>
                  <br />
                  interactive participatory storytelling game - APP and WEB
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Release: </strong>
                  <br />
                  Beta version 2023 on Android OS and Web
                </p>
              </div>
            </div>
          </div>
        </div>

        <section
          className={`${BsCustom.WTGBSection} ${BsCustom.IPInsideFirstMAinPara}`}
        >
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={Images.GuppyFinalBannersTop}
                />
                <p
                  className={BsCustom.BsCommaonP}
                  style={{ marginBottom: "15px" }}
                >
                  A free mind is what a child is born with. It should be allowed
                  to roam free, nurture the surge of creativity and be allowed
                  to arrange the world as it wishes, resulting in a happier
                  adult who is better able to cope, find solutions and think
                  innovatively.
                </p>
                <p className={BsCustom.BsCommaonP}>
                  Storytelling is the building block of creativity. It lurks in
                  every corner of the world from the depths of the oceans to the
                  galaxies of outer space. It’s the spicy ingredient savoured by
                  every child, educator, grandparent and great grandparent.
                </p>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <a href="#">
                  <img src={Images.GuppyFinalBannersTop} />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section
          className={`${BsCustom.WTGBSection} ${BsCustom.IPInsideFirstMAinPara}`}
        >
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <a href="#">
                  <img src={Images.GuppyFinalBannersBottom} />
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={Images.GuppyFinalBannersBottom}
                />
                <p
                  className={BsCustom.BsCommaonP}
                  style={{ marginBottom: "15px" }}
                >
                  We want to give kids their own space so that they become
                  co-creators, not just consumers.
                </p>
                <p className={BsCustom.BsCommaonP}>
                  We leave them in that space for them to think things out their
                  way - make choices, make mistakes without ever being afraid of
                  being judged. Just let them be. Let them expand, let them
                  touch the stars, talk to the moon, create their own wormholes,
                  spaceships, or dig a tunnel under Mount Everest - just let
                  them be in the centre of their own world.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className={BsCustom.NewGuppyIPStoryPAra}>
          <div style={{ margin: "60px" }}>
            <iframe
              width="100%"
              ref={iframeRef}
              onLoad={handleIframeLoad}
              src="https://www.youtube.com/embed/--T1DypMQNs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allo
            ></iframe>
          </div>
          {/* <img style={{ width: "100%" }} className={BsCustom.desktopBreakerImg} src={Images.GuppyFinalBannersMidileCut} /> */}
          {/* <img style={{ width: "100%" }} className={BsCustom.MobileBreakerImg} src={Images.MobileBreakerGuppyIPStroies} /> */}
          <div className="container-fluid">
            <p
              style={{
                margin: "60px 0",
                textAlign: "center",
                lineHeight: "70px",
              }}
              className={BsCustom.BsCommaonP}
            >
              We want to celebrate these unfettered minds and in turn take them
              to higher flights of fancy. <br />
              In brief we want them to be our driving force. Our
              jump-out-of-the-box co-creators.
            </p>
          </div>
        </div>

        <section
          className={`${BsCustom.WTGBSection} ${BsCustom.IPInsideFirstMAinPara}`}
        >
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <a href="#">
                  <img src={Images.GuppyFinalBannersBottom} />
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={Images.GuppyFinalBannersBottom}
                />
                <p
                  className={BsCustom.BsCommaonP}
                  style={{ marginBottom: "15px" }}
                >
                  In this APP we give the children ingredients just like you
                  would if you were playing ‘chef chef’. And they have to use
                  their wit & imagination to cook-up a delicious story that
                  everyone would like to read or continue as a game. We may know
                  the starting point of the story but the end-point may have us
                  biting our nails or rolling off our chairs.
                </p>
                <div
                  className={` ${styles.storeIconRight} ${BsCustom.storeIconRight}`}
                >
                  <span
                    className={BsCustom.BsCommaonP}
                    style={{
                      display: "block",
                      fontWeight: "600",
                      marginBottom: "20px",
                    }}
                  >
                    Available on
                  </span>
                  <a href="https://play.google.com/store/apps/details?id=com.guppytheatre.app">
                    <img src={Images.googleIcon} alt="" />
                  </a>
                  <a href="https://apps.apple.com/in/app/guppy-stories/id6450250856">
                    <img
                      style={{ marginLeft: "15px" }}
                      src={Images.appledownload}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <p
            style={{ marginBottom: "50px", textAlign: "center" }}
            className={BsCustom.BsCommaonP}
          >
            Because the stream of consciousness or a free-wheeling imagination
            has no limits.
          </p>
          <p
            className={BsCustom.BsCommaonP}
            style={{ textAlign: "center", marginBottom: "50px" }}
          >
            The App is just one end of the elastic. The child can pull it as far
            as it likes, in any direction, towards any <br /> resolution.
          </p>
        </div>
        <div className={BsCustom.BSHyperLinkPriviousNextWrapper}>
          <div className="container">
            <div className={BsCustom.BSHyperLinkPriviousNextMain}>
              <a href="/BecomingNarad" className="ReadMoreCmn">
                <img
                  style={{ marginRight: "15px" }}
                  src={Images.CurveArrowLeft}
                />
                <span>Previous IP</span>
              </a>
              <button
                href="#"
                onClick={handleScrollToTop}
                className="ReadMoreCmn"
                style={{
                  color: "#0D5268",
                  textDecorationLine: "underline",
                  border: "0",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                Back to Top
              </button>
              <a href="/LandingPageBs" className="ReadMoreCmn">
                <span>Next IP</span>
                <img
                  style={{ marginLeft: "15px" }}
                  src={Images.CurveArrowRight}
                />
              </a>
            </div>
          </div>
        </div>
        <FooterBs />
      </section>
    );
  } else {
    return <Loader />;
  }
}

export default GuppyStoriesIp;
