import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
// import CurrentIps from "../Component/CurrentIps";
// import NewsLetterBS from "../Component/NewsLetterBS";
// import GuppyStoriesBackstage from "../Component/GuppyStoriesBackstage";
import FooterBs from "../Component/FooterBs";
import BannerCommon from "../Component/BannerCommon";
import { useNavigate } from "react-router-dom";
import { useBackstagewhoweare } from "../../otherexports";
import MetaTags from 'react-meta-tags';
import Loader from "../Component/Loader";


function WhoWeAre() {
  const navigate = useNavigate();
  const {data,isLoading}=useBackstagewhoweare();
// console.log(JSON.stringify(data),"=======psk===")
  if(!isLoading){
    return (
      <section className={BsCustom.WrapperWhoWeAre}>
        <MetaTags>
          <title>Who We Are - Guppy Theater</title>
          <meta name="description" content="Guppy Theatre is a Transmedia storytelling company that develops IPs with children that entertain as well as foster creativity and collaboration" />
          <meta name="keywords" content="animation, games, IP development, story world, transmedia story telling, children" />
        </MetaTags>
        <HeaderBackstageNew />
  
        <section className={BsCustom.BannerCommon}>
            <img className={BsCustom.BannerCommonImg} src={Images.WhoweareheaderBanner} />
            <img className={BsCustom.MobileCommonBannerNew} src={Images.WhoWeAreBannewMobile} />
            <img className={BsCustom.BackBtnBSCommonNew} src={Images.BackstageBackBtn} style={{cursor:"pointer"}}  onClick={() => {
            navigate(-1);
          }} />
        </section>
        {/* <BannerCommon navigate={navigate} /> */}
        <section
          className={BsCustom.WhoWeAreSection}
          style={{ textAlign: "center" }}
        >
          <div className="container-fluid">
            <h1 className={BsCustom.BsH1}>Who We Are</h1>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-12 ${BsCustom.WhoWeAreRight}`}>
                <p className={BsCustom.BsCommaonP}>
                  {data?.attributes?.description_text}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={BsCustom.WTGBSection}>
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <img src={"https://manage.guppytheatre.com" + data?.attributes?.our_story_media?.data?.attributes?.formats?.small?.url} />
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <h3 className={BsCustom.BsH3}>Our Story</h3>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={"https://manage.guppytheatre.com" + data?.attributes?.our_story_media?.data?.attributes?.formats?.small?.url} 
                />
                <p className={BsCustom.BsCommaonP}>
                  {data?.attributes?.our_story_text}
                </p>
                <a className={BsCustom.ReadMoreCmn} href="/OurStory">
                  <span>Read More</span> <img src={Images.ReadMoreImg} />
                </a>
              </div>
            </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <h3 className={BsCustom.BsH3}>Our Team</h3>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={"https://manage.guppytheatre.com" + data?.attributes?.the_team_media?.data?.attributes?.formats?.small?.url} 
                />
                <p className={BsCustom.BsCommaonP}>
                  {data?.attributes?.the_team_text}
                </p>
                <a className={BsCustom.ReadMoreCmn} href="/OurTeam">
                  <span>Read More</span> <img src={Images.ReadMoreImg} />
                </a>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <img src={"https://manage.guppytheatre.com" + data?.attributes?.the_team_media?.data?.attributes?.formats?.small?.url}  />
              </div>
            </div>
          </div>
        </section>
        <section
          className={`${BsCustom.WhoWeAreSection} ${BsCustom.WhoWeAreSectionNewBottom}`}
        >
          <img
            className={`${BsCustom.WhoWeAreSectionNewBottomCutImg}`}
            src={Images.WWAWhoisguppy}
          />
          <img
            className={`${BsCustom.MobileIntroducingImgNew}`}
            src={Images.WhoWeAreBreakerNewMobile}
          />
          <div className="container-fluid">
            <h2 className={BsCustom.BsH2}>Who is Guppy !?!</h2>
            <div className={`row ${BsCustom.Row}`}>
              <div
                className={`col-12 ${BsCustom.WhoWeAreRight} ${BsCustom.WhoWeAreRightNew}`}
              >
                <p className={`${BsCustom.BsCommaonP} ${BsCustom.IntoductionParanew}`}>
                  {data?.attributes?.Introducing_guppy}
                </p>
                {/* <button className={BsCustom.BsComBtn} onClick={()=>{navigate("/GuppyIntroduction")}} >Read More</button> */}
              </div>
            </div>
            <div className={`row ${BsCustom.Row} ${BsCustom.bottomNewWhoWeare}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <img src={"https://manage.guppytheatre.com" + data?.attributes?.transmedia_media?.data?.attributes?.formats?.small?.url}  />
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <h3 className={BsCustom.BsH3}>Transmedia and GuppyVerse</h3>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={"https://manage.guppytheatre.com" + data?.attributes?.transmedia_media?.data?.attributes?.formats?.small?.url} 
                />
                <p className={BsCustom.BsCommaonP}>
                  {data?.attributes?.transmedia_text}
                </p>
                <a className={BsCustom.ReadMoreCmn} href="/Transmedia">
                  <span>Read More</span> <img src={Images.ReadMoreImg} />
                </a>
              </div>
            </div>
          </div>
        </section>
        <FooterBs />
      </section>
    );
  }
  else 
    {return (<Loader />)}
}

export default WhoWeAre;
