const SEND_MAIL_URL = 'https://api.sendinblue.com/v3/contacts'

const mailHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_SIB_API_KEY
}

export default async function subscribeEmailAsync({ email }) {
    try {
        console.log({ mailHeaders })
        let response = await fetch(SEND_MAIL_URL, {
            method:"post",
            headers: mailHeaders,
            body: JSON.stringify({
                email,
                // updateEnabled: true
            }),
        })
        response = await response.json()
        return response;
    } catch (error) {
        throw error
    }
}