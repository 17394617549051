import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import { useNavigate, useHistory } from "react-router-dom";

function CurrentIps(){
    const static_data = [
        { backimg: Images.MalluPibg, LogoImg: Images.MalluandPi, NewImg: Images.New, NewPara:"8+"},
        { backimg: Images.BecomingNaradbg, LogoImg: Images.BecomingNarad, NewImg: Images.New, NewPara:"8+"},
        { backimg: Images.LilMaharajabg, LogoImg: Images.IPInsideLOGOMiniraja, NewImg: Images.New, NewPara:"6+"},
    ];
    const navigate = useNavigate();
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return(
        <div className={BsCustom.CurrentIpsSection}>
            <div className="container-fluid">
                <h2 className={BsCustom.BsH2}>Current IPs</h2>
                <div className="row">
                    {static_data.map((e, index) => {
                        return (
                            <div className={`col-sm-12 col-md-4 ${BsCustom.CurrentIpsInner}`}>
                                <img className={BsCustom.ipsBackImg} src={e.backimg} />
                                <div>
                                    <img className={BsCustom.currentIpsLogo} src={e.LogoImg} />
                                    <div className={BsCustom.currentIpsNew} >
                                        {/* <img src={e.NewImg} /> */}
                                        <p>{e.NewPara}</p>
                                    </div>
                                    <div className={BsCustom.SeeMoreBtn}>
                                        <button onClick={() =>{ 
                                            switch(index) {
                                                case 0:
                                                    navigate("/MalluAndPi");
                                                break;
                                                case 1:
                                                    navigate("/BecomingNarad");
                                                break;
                                                case 2:
                                                    navigate("/Miniraja");
                                                break;
                                                default:
                                                    navigate("/IpInside");
                                            }
                                        }}>See More</button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default CurrentIps;