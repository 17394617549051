import React, { useEffect } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import Myaccount from "../../component/MyAccount";
import Images from '../../component/Images';
import { useDispatch, useSelector } from "react-redux";
import { home_api } from "../../redux/homedata/home.actions";
import { getLocal } from "../../otherexports";
const MyAccount = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.home_data.Home);
  const Userdata=getLocal("User");
// console.log(JSON.stringify(data),"==============")
  useEffect(() => {
    fatch_data();
  }, []);

  const fatch_data = async () => {
    dispatch(home_api());
  };

  if (!loading)
    return (
      <div className={styles.body} >
        <Header />
        <Myaccount Userdata={Userdata} />
      </div>
    );
  else
    return (
      <div className={styles.body}>
        <div className={styles.LoadingMain}
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
        </div>
      </div>
    );
};

export default MyAccount;
