import React, { useState, createRef } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import TheEnd from "../component/TheEnd";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { getLocal } from "../otherexports";
import put from "../ApiService/put";
import NoDraftModal from "./NoDraftModal";
import SubmittedSuccessfullyModal from "./SubmittedSuccessfullyModal";

function StoryFlows({
  stroyitem,
  stroynum,
  insidedata,
  setinsidedata,
  setloading,
}) {
  const [modaltext, setmodaltext] = useState("");
  const modalopentwo = createRef(null);
  const modalopen = createRef(null);
  const navigate = useNavigate();
  const [selectname, setselectname] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen3, setIsOpen3] = useState(false);
  const [flowname, setflowname] = useState("");
  const changetofull = (index) => {
    setloading(true);
    let data = insidedata;
    data[index].fullview = !data[index].fullview;
    setinsidedata(data);
    setloading(false);
  };

  const common = (data) => {
    return (
      <>
        <img
          src={
            "https://manage.guppytheatre.com" +
            data?.StoryImage?.data?.attributes?.url
          }
        />
        <p>
          {data?.StoryText.replace(/\s+/g, " ").split("##")[0]}
          <span>
            {data?.StoryText?.replace(/\s+/g, " ").split("##")[1]}
          </span>{" "}
          {data?.StoryText?.split("##").map((e, i) => {
            if (i > 1) {
              return e.replace(/\s+/g, " ");
            }
          })}
        </p>
      </>
    );
  };

  const upadateflowname = async () => {
    const userinfo = getLocal("User");
    if (userinfo != undefined && userinfo != null) {
      let dataSend = {
        data: {
          story_flow: insidedata,
          users_permissions_user: userinfo?.id.toString(),
          Story_Flow_Name: flowname,
          createdBy: "",
          updatedBy: "",
        },
      };
      let postData = await put(
        "guppy-story-flows/" + stroyitem.id,
        dataSend,
        undefined,
        "psk"
      );
      if (postData.data != {}) {
        setselectname(flowname);
        setflowname("");
        closeModal();
      } else {
        setselectname(flowname);
        setmodaltext("Something went wrong..!");
        modalopen.current.show();
        closeModal();
      }
    }
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen3(false);
    setIsOpen(false);
  }
  // Modal

  const filterkeywordfirts = (key) => {
    const str = key?.Description?.replace("\n", "");

    if (
      str?.split(key?.ingredient_type?.data?.attributes?.typekey)?.length > 1
    ) {
      // console.log("condition 1=======",str?.split(key?.ingredient_type?.data?.attributes?.typekey))
      return str?.split(key?.ingredient_type?.data?.attributes?.typekey)[0];
    } else {
      // console.log("condition 2=====",str?.split(key?.ingredient_type?.data?.attributes?.TypeName))
      return str?.split(key?.ingredient_type?.data?.attributes?.TypeName)[0];
    }
  };

  const filterkeywordsecond = (key) => {
    const str = key?.Description?.replace("\n", "");
    if (
      str?.split(key?.ingredient_type?.data?.attributes?.typekey)?.length > 1
    ) {
      return str
        ?.split(key?.ingredient_type?.data?.attributes?.typekey)
        .map((e, i) => {
          if (i > 0 && i == 1) {
            return e;
          } else if (i > 1) {
            return key?.ingredient_type?.data?.attributes?.typekey + e;
          } else {
            return;
          }
        });
    } else {
      return str
        ?.split(key?.ingredient_type?.data?.attributes?.TypeName)
        .map((e, i) => {
          if (i > 0 && i == 1) {
            return e;
          } else if (i > 1) {
            return key?.ingredient_type?.data?.attributes?.TypeName + e;
          } else {
            return;
          }
        });
    }
  };

  // // mobilematch
  // const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);
  // useEffect(()=>{
  //     window
  //     .matchMedia("(min-width: 768px)")
  //     .addEventListener('change', e => setMatches( e.matches ));
  // },[])

  return (
    <div className={styles.StoryFlows}>
      <div className={styles.StoryFlows_top}>
        <div className="container">
          <div style={{ zIndex: "1" }} className={styles.backbtn}>
            <a
              href="/MyStoryFlows"
              // onClick={() => {
              //   navigate('/MyStoryFlows');
              // }}
            >
              <img src={Images.backbtn} />
            </a>
          </div>
          <div className={styles.StoryFlowTop}>
            <div className={styles.MyStoryFlowInfo}>
              <img
                className={styles.MyStoryFlowInfoeditpencil}
                src={Images.editpencil}
                onClick={openModal}
              />
              <div className={styles.MyStoryFlowInner}>
                <h5>
                  {selectname != ""
                    ? selectname
                    : stroyitem?.attributes?.Story_Flow_Name == null ||
                      stroyitem?.attributes?.Story_Flow_Name == ""
                    ? "Story Adventure " + stroynum
                    : stroyitem?.attributes?.Story_Flow_Name}
                </h5>
                <p>
                  {stroyitem?.attributes?.users_permissions_user?.data
                    ?.attributes?.username != undefined
                    ? "by " +
                      stroyitem?.attributes?.users_permissions_user?.data
                        ?.attributes?.username
                    : "by Guest"}
                </p>
              </div>
            </div>
            <div className={styles.Storysharebtn}>
              <a href="#">
                <img
                  src={Images.YellowShareBtnStory}
                  onClick={() => {
                    setIsOpen3(true);
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.StoryFlow_bgimg}>
          <img src={Images.BadalLeft} />
        </div>
      </div>
      <div className={styles.StoryFlow_bottom}>
        <div className="container">
          {insidedata.map((item, index) => {
            let Sections = item?.flow_step?.attributes?.Sections;
            let singlekey = item?.flow_step.attributes?.Sections?.filter(
              (e) => {
                if (
                  e.keyword.length != 0 &&
                  e?.keyword[0]?.keyword === item.type
                ) {
                  return e.keyword;
                }
              }
            )[0]?.keyword[0];

            return (
              <div className={styles.StoryFlowInfo}>
                {/* <img src={item.img} />
                <p>{item.title}</p> */}
                {/* {!item.fullview &&
                  common(item.flow_step.attributes.Sections[0])} */}
                {Sections?.map((e, i) => {
                  let filterindex =
                    item?.type == "continue" || item?.type == undefined
                      ? Sections.length - 1
                      : Sections?.findIndex((n) =>
                          n?.keyword?.some((l) => l?.keyword == item?.type)
                        ) == -1
                      ? Sections.length - 1
                      : Sections?.findIndex((n) =>
                          n?.keyword?.some((l) => l?.keyword == item?.type)
                        );
                  if (i <= filterindex) {
                    return <>{common(e)}</>;
                  }
                })}
                {/* {item.fullview &&
                  item.flow_step.attributes.Sections.map((e, i) => {
                    return common(e);
                  })} */}
                <div className={styles.Viewfullstory}>
                  {index != insidedata?.length - 1 && (
                    <img
                      className={styles.storyTopImg}
                      src={Images.EnterArrow}
                    />
                  )}
                  {/* <a
                    href="#"
                    onClick={() => {
                      changetofull(index);
                    }}
                  >
                    {!item.fullview ? "View full story" : "View half story"}{" "}
                    <img src={Images.Eyeimg} />
                  </a> */}
                </div>
                {index != insidedata?.length - 1 && (
                  <p>
                    {item.type != "continue" && (
                      <>
                        {filterkeywordfirts(singlekey)}
                        <span style={{ color: "#ED4C55" }}>
                          {
                            singlekey?.ingredient_type?.data?.attributes
                              ?.typekey
                          }
                        </span>
                        {filterkeywordsecond(singlekey)}
                      </>
                    )}
                    {item.type == "continue" && (
                      <>
                        {
                          item?.flow_step?.attributes?.continue_btn?.Description?.replace(
                            "\n",
                            ""
                          ).split(
                            item?.flow_step?.attributes?.continue_btn?.bold_text
                          )[0]
                        }
                        <span style={{ color: "#000" }}>
                          {item?.flow_step?.attributes?.continue_btn?.bold_text}
                        </span>
                        {item?.flow_step?.attributes?.continue_btn?.Description?.replace(
                          "\n",
                          ""
                        )
                          .split(
                            item?.flow_step?.attributes?.continue_btn?.bold_text
                          )
                          .map((e, i) => {
                            if (i > 1) {
                              return e;
                            }
                          })}
                      </>
                    )}
                  </p>
                )}
              </div>
            );
          })}
          <div className={styles.backtotop}>
            <img src={Images.navBannerleft} />
          </div>
        </div>
      </div>
      <div className={styles.sharestorysecbg}>
        <TheEnd />
        <div className="container">
          <div className={styles.sharestorysec}>
            <button className={styles.btnFill}>
              <img src={Images.YellowShareBtnStory} />
              Share this Story flow
            </button>
          </div>
        </div>
      </div>
      <div className={styles.StoryFlow_bottomBtn}>
        <div className="container">
          <div
            className={styles.StoryFlowInfoBtn}
            onClick={() => {
              navigate("/home");
            }}
          >
            <button
              className={styles.btnFill}
              onClick={() => {
                navigate("/home");
              }}
            >
              <img src={Images.roadImg} />
              Start a new <br /> adventure!
            </button>
          </div>
        </div>
        <div
          className={styles.StoryFlow_bgimg}
          onClick={() => {
            navigate("/home");
          }}
        >
          <img src={Images.BadalLeft} />
        </div>
      </div>
      <Modal
        style={{
          content: {
            display: "flex",
            backgroundColor: "#0000",
            borderWidth: 0,
            flexDirection: "column",
            flex: 1,
            position: "unset",
            overflow: "auto",
            height: "100%",
            justifyContent: "space-around",
          },
        }}
        overlayClassName={Modalstyle.modal}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className={Modalstyle.modalstoryflow}>
            <h4>Edit Story Flow Name</h4>
            <p>
              You can edit/change the name of your <br /> story flow according
              to your choice.
            </p>
            <div className={Modalstyle.modalstoryflowSubdiv}>
              <p>
                <strong>Story flow name</strong>
              </p>
              <input
                type="text"
                name=""
                onChange={(e) => {
                  setflowname(e.target.value);
                }}
              />
            </div>
            <button className={styles.btnUnfill} onClick={closeModal}>
              Cancel
            </button>
            <button
              style={{ padding: "0 25px" }}
              className={styles.btnFill}
              onClick={upadateflowname}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        style={{
          content: {
            display: "flex",
            backgroundColor: "#0000",
            borderWidth: 0,
            flexDirection: "column",
            flex: 1,
            position: "unset",
            overflow: "auto",
            height: "100%",
          },
        }}
        overlayClassName={Modalstyle.modal}
        isOpen={modalIsOpen3}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className={Modalstyle.modalMyStories}>
            <div className={Modalstyle.sharebtnlink}>
              <img
                className={`${Modalstyle.sharebtnimg}`}
                src={Images.backbtncross}
                onClick={closeModal}
              />
              <p>Share</p>
              <div className={Modalstyle.modalMyStoriesMobile}>
                <a href="#">
                  <img src={Images.DownloadShareNew} />
                  <span>Download</span>
                </a>
                <a href="#">
                  <img src={Images.Sharewhtspp} />
                  <span>Whatsapp</span>
                </a>
                <a href="#">
                  <img src={Images.Sharemessage} />
                  <span>Email</span>
                </a>
                <a href="#">
                  <img src={Images.facebookShareNew} />
                  <span>Facebook</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <NoDraftModal ref={modalopen} text={modaltext} />
      <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
    </div>
  );
}

export default StoryFlows;
