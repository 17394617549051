import {
  USER_SUCCESS,
  USER_FAILURE,
  USER_Request,
} from "./user.types";

const INITIAL_STATE = {
  User: { loading: false, error: "", data:{} },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    //=================User Data================
    case USER_Request: {
      return {
        ...state,
        User: {
          loading: true,
          data: {},
          error: "",
        },
      };
    }
    case USER_SUCCESS: {
      return {
        ...state,
        User: {
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case USER_FAILURE: {
      return {
        User: {
          loading: false,
          data:{},
          error: action.payload,
        },
      };
    }
    //=================User Data================


    default:
      return state;
  }
};

export default reducer;
