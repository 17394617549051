import React from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import { useNavigate } from "react-router-dom";

function Theater() {
  const navigate = useNavigate();

  return (
    <section className={styles.btsSection}>
      <div className="container">
        <div className={styles.btsSectionInfo}>
          <button
            className={styles.btnUnfill}
            onClick={() => {
              navigate("/LandingPageBs");
            }}
          >
            Access to Backstage
          </button>
        </div>
        <div className={`row ${styles.theaterInfo}`}>
          <div className={`col-sm-8 ${styles.theaterLeftInfo}`}>
            <h4>Guppy Stories App</h4>
            <p>
              The App offers kids a world of fun and fanciful <br /> stories to
              enjoy and, more importantly, the <br /> tools to make up their
              own!
            </p>
          </div>
          <div className={`col-sm-4 ${styles.storeIconRight}`}>
            <span>Available on</span>
            <a href="https://play.google.com/store/apps/details?id=com.guppytheatre.app">
              <img src={Images.googleIcon} alt="" />
            </a>
            <a href="https://apps.apple.com/in/app/guppy-stories/id6450250856">
              <img src={Images.appledownload} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.dashedLine}>
        <img src={Images.dashedLine} alt="" />
      </div>
    </section>
  );
}

export default Theater;
