import React, { createRef, useEffect } from 'react'
import styles from '../pages/Home/landingpage.module.css';
import Images from './Images';
import AvtarImg from './AvtarImg';
import { useQuery } from '@tanstack/react-query';
import api from '../ApiService/Apicall';
import { useNavigate } from 'react-router-dom';
import RagisterFirstModal from './RagisterFirstModal';
import { getLocal } from '../otherexports';


function Myavtar(){
    const Registermodal= createRef(null);
    const navigate=useNavigate();
    const userinfo = getLocal("User");
    const {data,isLoading } = useQuery(["GuppyAvtar"], async () => {
        const results = await api("get", "guppy-avtar-lists?populate=*");
        return results.data.data;
      });

      useEffect(()=>{
        if(userinfo.name==undefined){
            Registermodal.current.show();
          }
      },[userinfo])
    //   console.log(data)
    return (
        <section className={styles.myavtarsection}>
            <div className='container'>
                <div className={styles.backbtn} onClick={()=>{navigate(-1)}}>
                    <a onClick={()=>{navigate(-1)}}><img src={Images.backbtn} /></a>
                </div>
                <div className={styles.avtarBody}>
                    <h4>Shift me into any <br/> shape you want ! </h4>
                    <img src={Images.myavtar} />
                    {!isLoading&&<AvtarImg data={data} />}
                </div>
            </div>
            <RagisterFirstModal ref={Registermodal} />
        </section>
    )
}


export default Myavtar;

