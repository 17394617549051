import React from "react";
import Images from "./Images";
import Modalstyle from "../pages/StoryPage/storypage.module.css";

export default function Sharebtn(){
    return(<div className={Modalstyle.modalIngredientsshare}>
        <p>Share this Ingredient</p>
        <div className={Modalstyle.modalIngredientsshareInner}>
          <a href="#">
            <img src={Images.Sharecopylink} />
            <span>CopyLink</span>
          </a>
          <a href="#">
            <img src={Images.Sharewhtspp} />
            <span>Whatsapp</span>
          </a>
          <a href="#">
            <img src={Images.Sharemessage} />
            <span>Email</span>
          </a>
          <a href="#">
            <img src={Images.ShareSnap} />
            <span>Snapchat</span>
          </a>
          <a href="#">
            <img src={Images.Insta} />
            <span>Instagram</span>
          </a>
        </div>
      </div>)
}
