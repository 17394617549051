import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import { useBackstagenews } from "../../otherexports";


function LatestNewsBS({data}){

    console.log(JSON.stringify(data), "==========Hello====Hello");

    return(
        <div className={BsCustom.LatestNewsSection}>
                {data?.map((item, index)  => {
                    let image_url = "https://manage.guppytheatre.com" +  item?.attributes?.News?.news_media?.data?.attributes?.url      
                    return (
                        <div className={`${BsCustom.WhatWeDoInner} ${BsCustom.TeamDarkBCGroung}`}>
                            <div className="container-fluid">
                                <div className={`row ${BsCustom.Row}`}>
                                    <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                                        <img src={image_url}/>
                                    </div>
                                    <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                                        <h4 className={BsCustom.BsH4}>{item?.attributes?.News?.title}</h4>
                                        <p className={BsCustom.BsCommaonP}>{item?.attributes?.News?.news_description}</p>
                                        <a href="#" className={BsCustom.ReadMoreCmn}><span>{item?.attributes?.News?.link_label}</span> <img src={Images.ReadMoreImg} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    })}
            </div>
    );
}

export default LatestNewsBS;