import { findByLabelText } from "@testing-library/react";
import React, { createRef, useEffect, useRef, useState } from "react";
import styles from "./landingpage.module.css";
import Header from "../../component/Header";
import Banner from "../../component/Banner";
import Theater from "../../component/Theater";
import GoogleAds from "../../component/GoogleAds";
import CardSwiper from "../../component/CardSwiper";
import Story from "../../component/Story";
import Footer from "../../component/Footer";
// import { useQuery } from 'react-query'
import { api } from "../../ApiService/Apicall";
import { useDispatch, useSelector } from "react-redux";
import { home_api } from "../../redux/homedata/home.actions";
import { getLocal } from "../../otherexports";
import { useQuery } from "@tanstack/react-query";
import { useAvtar, useSettergetter } from "../../otherexports";
import MuniaModal from "../../component/MuniaModal";
import NoDraftModal from "../../component/NoDraftModal";
import Images from '../../component/Images';
import MetaTags from 'react-meta-tags';


const LandingPage = () => {
  const modalIsOpen = createRef(null);
  const modalopentwo=createRef(null);
  const [birdshow, setbirdshow] = useState(true);
  const [modaltext, setmodaltext] = useState('');
  const userinfo = getLocal("User");
  const { isLoading: otherloading, data: Avtardata } = useAvtar();
  const logindata = getLocal("User");
  const carouselref = createRef(null);
  const [submitstory, setsubmitstory] = useState([]);
  const { data, isLoading } = useQuery(["HomeApi"], async () => {
    const results = await api("get", "Home-apis?populate=*");
    return results.data.data;
  });
  const { data: Storydata, isLoading: otherisLoading } = useQuery(["Stroydata"],
    async () => {
      const results = await api(
        "get",
        "guppy-stories?populate[0]=Sections&populate[1]=Sections.StoryImage&populate[2]=Sections.keyword&populate[3]=Sections.keyword.guppy_stories&populate[4]=Sections.keyword.guppy_stories.Sections.StoryImage&populate[5]=Sections.keyword.ingredient_type&populate[6]=story_ingredients&populate[7]=story_ingredients.IngredientImage&populate[8]=story_ingredients.ingredient_type_id&populate[9]=story_ingredients.packages&populate[10]=story_ingredients.users_permissions_users&populate[11]=continue_btn&populate[12]=continue_btn.guppy_stories&populate[13]=continue_btn.guppy_stories.Sections.StoryImage&populate[14]=bottom_banner&populate[15]=bottom_banner.banner_image&populate[16]=user_ids&populate[17]=story_thumbnail&populate[18]=*"
      );
      return results?.data?.data?.filter((e) => e?.attributes?.show_landing_screen);
    }
  );
  const nextpress = () => {
    carouselref.current.next();
  };
  const prevpress = () => {
    carouselref.current.Prev();
  };

  const getApi = async () => {
    const results = await api(
      "get",
      "story-submissions?populate[0]=users_id&populate[1]=what_ingredient&populate[2]=what_ingredient.ingredient_type_id&populate[3]=what_ingredient.IngredientImage&populate[4]=who_ingredient&populate[5]=who_ingredient.ingredient_type_id&populate[6]=who_ingredient.IngredientImage&populate[7]=when_ingredient&populate[8]=when_ingredient.ingredient_type_id&populate[9]=when_ingredient.IngredientImage&populate[10]=where_ingredient&populate[11]=where_ingredient.ingredient_type_id&populate[12]=where_ingredient.IngredientImage&populate[13]=packages&populate[14]=packages.PackageImage&populate[15]=packages.ingredients&populate[16]=story_media",
      undefined,
      "psk"
    );
    let current_userdata = results?.data?.data?.filter(
      (e) =>
        e?.attributes?.users_id?.data?.id?.toString() ==
        logindata?.id?.toString()
    );
    setsubmitstory(current_userdata);
  };

  useEffect(() => {
    if (Object.keys(logindata).length != 0) {
      getApi();
    }
    if (!birdshow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, []);

  if (!isLoading && !!Storydata) {
    return (
      <div className={styles.homemain}>
        <MetaTags>
          <title>Home - Guppy Theater</title>
          <meta name="description" content="Guppy Stories is an interactive and participative storytelling game for children. Create your story adventure, create your own story and record it with the App." />
          <meta name="keywords" content="Free online story, story games for children, Online games" />
        </MetaTags>
        <Header />
        <Banner info={data} carouselref={carouselref} />
        {!!Storydata && (
          <CardSwiper
            nextpress={nextpress}
            data={data}
            prevpress={prevpress}
            Storydata={Storydata}
            otherisLoading={otherisLoading}
          />
        )}
        {birdshow && (
          <div className={styles.birdTopText}>
          <img
            className={styles.MuniaMiddle}
            style={{ zIndex: 90 }}
            src={
              !!userinfo?.name
                ? "https://manage.guppytheatre.com" +
                  Avtardata?.attributes?.guppy_avtar?.data?.attributes
                    ?.avtar_media?.data?.attributes?.url : "https://manage.guppytheatre.com" +
                  Avtardata?.attributes?.avtar_media?.data?.attributes?.url
            }
          />
          <span><img src={Images.Askguppyborder}  onClick={() => {
              setbirdshow(false);
              modalIsOpen.current.show();
            }} /></span>
          </div>
        )}
        <Story logindata={logindata} Storydata={submitstory} />
        {/* <GoogleAds /> */}
        <Theater />
        <Footer userinfo={userinfo} setmodaltext={(e)=>{setmodaltext(e); modalopentwo.current.show()}} />
        <MuniaModal
          ref={modalIsOpen}
          birdshow={() => {
            setbirdshow(true);
          }}
          data={Avtardata}
        />
         <NoDraftModal ref={modalopentwo} text={modaltext} />
      </div>
    )
  } else{
    return (
      <div className={styles.body}>
        <div className={styles.LoadingMain}
          style={{
            alignItems: "center",
             backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
        </div>
      </div>
    )}
}

export default LandingPage;
