// import React from "react";
import React, { useState, useMemo, useRef, useEffect } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import { useNavigate } from "react-router-dom";
import TinderCard from "react-tinder-card";
import moment from "moment";

function Story({ logindata, hide, Storydata }) {
  // const [Storydata, setStorydata] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [currentIndex, setCurrentIndex] = useState(Storydata?.length - 1);
  const [slidecount, setslidecount] = useState(1);
  const currentIndexRef = useRef(currentIndex);
  const [showrandom, setshowrandom] = useState(false);
  const childRefs = useMemo(
    () =>
      Array(Storydata?.length)
        .fill(0)
        .map((i) => React.createRef()),
    [Storydata]
  );
  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex >= 0;
  const swiped = async (direction, nameToDelete, index) => {
    updateCurrentIndex(index - 1);
  };

  const outOfFrame = (name, idx) => {
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    if (idx === 0) {
      for (let i = 0; i <= Storydata.length; i++) {
        childRefs[i].current.restoreCard();
        setCurrentIndex(Storydata?.length - 1);
      }
    }
  };

  const swipe = async (dir) => {
    if (currentIndex == 4) {
      setshowrandom(true);
    }

    if (canSwipe && currentIndex < Storydata?.length) {
      await childRefs[currentIndex].current.swipe(dir);
    }
  };

  const rotating = (i) => {
    if (i % 2 === 0) {
      return "-6.11deg";
    }
    // else{
    // return "-10deg";
    // }
    if (i % 3 === 0) {
      return "6.11deg";
    }
  };

  const navigate = useNavigate();

  return (
    <section
      className={
        hide == undefined ? styles.storySection : styles.storySectiontwo
      }
    >
      <div className={styles.dashedLine}>
        <img src={Images.dashedLine} alt="" />
      </div>
      <div className={styles.bgImageStoryNewLeft}>
        <img src={Images.redCir1} alt="" />
      </div>
      <div className="container">
        {(hide == undefined || Storydata?.length!=0) && <h2>My Stories</h2>}
        {Object.keys(logindata).length == 0 && (
          <>
            <div className={styles.storySectionTwoInnerInfo}>
              <h4 style={{ display: "none" }}>My Account</h4>
              <div className={styles.storySectionTwoInner}>
                <p>Please register or log-in to view your published stories.</p>
                <div className={styles.btnStory}>
                  <button
                    className={styles.btnUnfill}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log-In
                  </button>
                  <button
                    className={styles.btnUnfill}
                    onClick={() => {
                      navigate("/signUp");
                    }}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {/* new part */}
        {Object.keys(logindata).length != 0 && Storydata?.length!=0 && (
          <div className={styles.ViewCardStory}>
            <div
              className={styles.swiperSection}
              style={{ userSelect: "none" }}
            >
              <div
                className={styles.swiperSectionInfo_inner}
                style={{
                  marginTop: "100px",
                  height: "36em",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!!Storydata &&
                  Storydata?.map((cardData, index) => (
                    <div
                      className={styles.swiperSection_InfoDe}
                      style={{
                        zIndex: index,
                        position: "absolute",
                        rotate: rotating(index),
                      }}
                      key={index}
                    >
                      <TinderCard
                        ref={childRefs[index]}
                        className="swipe"
                        key={cardData?.attributes?.title}
                        onSwipe={(dir) =>
                          swiped(dir, cardData?.attributes?.title, index)
                        }
                        onCardLeftScreen={() =>
                          outOfFrame(cardData?.attributes?.title, index)
                        }
                        swipeThreshold={900}
                        swipeRequirementType={"velocity"}
                        preventSwipe={["up", "down"]}
                      >
                        <div className={styles.swiperSlide}>
                          <div className={styles.cardNew}>
                            <div className={styles.cardHeader}>
                              {cardData?.attributes?.story_media?.data
                                ?.attributes?.url != undefined && (
                                <img
                                  src={
                                    "https://manage.guppytheatre.com" +
                                    cardData?.attributes?.story_media?.data
                                      ?.attributes?.url
                                  }
                                  alt=""
                                  style={{width:543,height:341,objectFit:"cover"}}
                                />
                              )}
                              {cardData?.attributes?.story_media?.data
                                ?.attributes?.url == undefined && (
                                <img src={Images.Sample} alt="" />
                              )}
                            </div>
                            <div
                              className={styles.cardFooterNew}
                              style={{ background: "#F2DD4E" }}
                            >
                              <div className={`${styles.mystoriesInnerInfo}`}>
                                <div className={`${styles.mystoriesInnerLeft}`} style={{width:"50%"}}>
                                  <h3 style={{whiteSpace:"nowrap",overflow:"hidden",display:"block",textOverflow:"ellipsis"}}>
                                    {cardData?.attributes?.Title ||
                                      cardData?.attributes?.title}
                                  </h3>
                                  <p>
                                    {cardData?.attributes?.users_id?.data
                                      ?.attributes?.username ||
                                      cardData?.attributes?.user_ids?.data
                                        ?.map((e) => e?.attributes?.username)
                                        ?.join(" , ")}
                                  </p>
                                </div>
                                <div
                                  className={`${styles.mystoriesInnerRight}`}
                                >
                                  <div>
                                    <p>Published</p>
                                    <p>
                                      {moment(
                                        cardData?.attributes?.publishedAt
                                      ).format("Do MMM ,YYYY")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TinderCard>
                    </div>
                  ))}
              </div>
            </div>
            <div style={{position: 'relative'}}><button className={styles.btnFill}>View this story</button></div>
          </div>
        )}

        {/* new part  End */}

        <div className={styles.ZidZagDesktopStory}>
          <img
            style={{ marginBottom: "-115px" }}
            src={Images.ZidZagDesktopStory}
            alt=""
          />
        </div>

        {hide == undefined && (
          <div
            style={{ marginTop: "60px" }}
            className={styles.recordBg}
            onClick={() => {
              navigate("/CreateStory");
            }}
          >
            <img
              src={Images.RedZigZagHomeStory}
              className={styles.RedZigZagHomeStory}
              style={{
                position: "absolute",
                left: "-92px",
                top: "-22px",
                zIndex: "-1",
              }}
            />
            <a href="#">
              <img src={Images.rans} alt="" />
            </a>
          </div>
        )}
      </div>
      {hide == undefined && (
        <div className={styles.bgImageOverflow}>
          <img src={Images.redCir} alt="" />
        </div>
      )}
      {hide != undefined && (
        <div className={styles.dashedLinebottom}>
          <img style={{ width: "100%" }} src={Images.dashedLine} alt="" />
        </div>
      )}
    </section>
  );
}

export default Story;
