import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';

import { useNavigate } from "react-router-dom";
function HeaderBackstage(){
    const navigate=useNavigate();
    return(
        <div className={BsCustom.navheaderBackstage}>
            <img className={BsCustom.navheaderBgImg} src={Images.MenuBSBGMain} />
            <div className="container">
                <nav className={`navbar ${BsCustom.navbarInnerInfo}`}>
                    <a href="#"><h1 className={BsCustom.MainHeadingHead}>Guppy Backstage!</h1></a>
                    <a style={{cursor:"pointer"}} onClick={()=>{navigate(-1)}}><img src={Images.BackstageBackBtn} /></a>
                </nav>
            </div>
        </div>
    );
}

export default HeaderBackstage;