// import { useNavigate } from "react-router-dom";
import api from "../../ApiService/Apicall";
import {
  mystorydata_SUCCESS,
  mystorydata_FAILURE,
  mystorydata_Request,
} from "./mystorydata.types";

export const User_reuest = () => {
  return {
    type: mystorydata_Request,
  };
};
export const User_SUCCESS = (payload) => {
  return {
    type: mystorydata_SUCCESS,
    payload,
  };
};
export const User_FAILURE = (error) => {
  return {
    type: mystorydata_FAILURE,
    payload: error,
  };
};



//===========mystorydata FUNCTION=========================
export const mystoryflow = () => {
  return async (dispatch) => {
    dispatch(User_reuest());
    try {
      const response = await api("get","guppy-story-flows?populate[0]=users_permissions_user",{},'toke');
      dispatch(User_SUCCESS(response));
    } catch (error) {
      dispatch(User_FAILURE(error));
    }
  };
};



