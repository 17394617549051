import React from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import { useNavigate } from "react-router-dom";
function Nav({ hide, setIsOpen2, modalIsOpen2 }) {
  const navigate = useNavigate();

  return (
    <div
      className={styles.navheader}
      style={{ visibility: modalIsOpen2 ? "hidden" : "visible" }}
    >
      <img className={styles.navheaderBgImg} src={Images.CurveBgheader} />
      <div className="container-fluid">
        <nav className={`navbar ${styles.NewTopMainHeader}`}>
          {hide == undefined && (
            <button
              className={styles.navbarToggler}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setIsOpen2(true);
                //  navigate('/MenuMain')
              }}
              style={{ cursor: "pointer" }}
            >
              <span className={styles.navbarTogglerIcon}>
                <img src={Images.hamburger} alt="hamburger" />
              </span>
            </button>
          )}
          {hide != undefined && <div className={styles.headerGs}></div>}
          {/* <div className={`${styles.headerGs} ${styles.headerGsNew}`}><a href="/home"><img src={Images.HeaderGsStoriesActive} /></a></div> */}
          <a className={styles.navbarBrand} href="/home">
            <img width="auto" src={Images.logoBackstageStories} alt="LOGO" />
          </a>
          {/* <div className={styles.headerGb}><a href="/LandingPageBs"><img src={Images.BackstageHeader} /></a></div> */}
          <div />
          {/* <div className={styles.headerContact}>
            <a
              onClick={() => {
                navigate("/myAccount");
              }}
            >
              <img src={Images.contact} alt="contact" />
            </a>
          </div> */}
        </nav>
      </div>
    </div>
  );
}

export default Nav;
