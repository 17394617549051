import React from "react";
import { useEffect, useState } from "react";
import Nav from "./Nav";
import styles from "../pages/Home/landingpage.module.css";
import Menu from "../component/Menu";
import { getLocal } from "../otherexports";

const Header = ({ hide }) => {
  const Userdata = getLocal("User");
  const [sticky, setSticky] = useState("");
  const [modalIsOpen2, setIsOpen2] = useState(false);
  // useEffect(() => {
  //     // window.addEventListener('scroll', isSticky);
  //     // return () => {
  //     //     window.removeEventListener('scroll', isSticky);
  //     // };
  // },[]);
  const scrollTop = window.scrollY;
  // const isSticky = (e) => {
  //     const scrollTop = window.scrollY;
  //     const stickyClass = scrollTop >= 250 ? "is_sticky" : "";
  //     setSticky(stickyClass);
  // };

  useEffect(() => {
    if (modalIsOpen2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalIsOpen2]);

  return (
    <div
      className={styles.header_section}
      // className={scrollTop <= 250 ?  styles.header_section : [styles.is_sticky]}
    >
      {" "}
      <div
        style={{
          position: "absolute",
          overflow: "hidden",
          transform: modalIsOpen2 ? `translateX(0px)` : `translateX(-100%)`,
          width: "100%",
        }}
      >
        <Menu Userdata={Userdata} setIsOpen2={setIsOpen2} />
      </div>
      <div style={{ zIndex: 20 }}>
        <Nav hide={hide} setIsOpen2={setIsOpen2} modalIsOpen2={modalIsOpen2} />
      </div>
    </div>
  );
};

export default Header;

// function header(){
//     return (
//         <Nav />
//     )
// }

// export default header;

{
  /* <header className={styles.main_header}>
                <Nav />
            </header> */
}
