import axios from "axios";
import { getLocal } from "../otherexports";
export const baseURL = "https://manage.guppytheatre.com/api/";
// export const baseURL = "https://guppy.thinkbar.tech/api/";
export const api = (method, path, data, token) => {
  if (method != "get") {
    const Token = getLocal("Token");
    let url = `${baseURL + path}`;
    // console.log('post url',url)
    return new Promise((resolve, reject) => {
      console.log(`${method} url------------->>>>>`, url);
      axios({
        method: method,

        url: url,

        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${Token}` : "",
          Accept: "application/json",
        },
        data: data,
        responseType: "json",
      })
        .then(
          (res) => {
            // console.log(`${url} url------------->>>>>`,JSON.stringify(res),"==========",data);
            resolve(res.data);
          },
          (err) => {
            // console.log(`${method} url------------->>>>>`,err,"==========",data);
            reject(err);
          }
        )
        .catch((err) => {
          // console.log(`${method} url------------->>>>>`,err,"==========",data);
          reject(err);
        });
    });
  } else if (method === "get") {
    return new Promise((resolve, reject) => {
      const Token = getLocal("Token");
      let url = `${baseURL + path}`;
      console.log("Get url------------->>>>>", url);
      axios({
        method: "GET",

        url: url,

        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${Token}` : "",
          Accept: "application/json",
        },
      })
        .then(
          (res) => {
            // console.log(`${url} url------------->>>>>`,JSON.stringify(res),"==========");
            resolve(res);
          },
          (err) => {
            // console.log("Get url------------->>>>>",err)
            reject(err);
          }
        )
        .catch((err) => {
          // console.log("Get url------------->>>>>",err)
          reject(err);
        });
    });
  }
};

export default api;
