import React from "react";
import Images from './Images';
import styles from '../pages/Home/landingpage.module.css';
import Modalstyle from "../pages/StoryPage/storypage.module.css";

const NoDataFile = ({ text }) => {
  return (
    <div className="container">
      <div className={Modalstyle.NoDraftModal} style={{ border: 0, boxShadow: "none", borderRadius: 0 }}>
        <div>
          <img className={Modalstyle.NoDraftFrame} src={Images.NoDraftFrame} />
          <h2>{text != undefined ? text : "Oops… Nothing to show yet!"}</h2>
        </div>
      </div>
    </div>
  );
}

export default NoDataFile;