import React from 'react';
import Owldemo1 from './Owldemo1'
import styles from '../pages/Home/landingpage.module.css';


function Banner({info,carouselref}){
    return(
        <section className={styles.banner}>
        <Owldemo1 otherdata={info} ref={carouselref} />
        </section>
    );
}

export default Banner;
