import React, { useState } from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import BackStageMaharaja from "../Component/BackStageMaharaja";
import BSHyperLinkPriviousNext from "../Component/BSHyperLinkPriviousNext";
import StoryInformationBackstage from "../Component/StoryInformationBackstage";
import BshyperLinkStoryTitle from "../Component/BshyperLinkStoryTitle";
import Credit from "../Component/Credit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBackstagewhatwodo, useSettergetter } from "../../otherexports";
import MetaTags from "react-meta-tags";
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";

function MalluAndPi() {
  const location = useLocation();
  const { Singleitem } = useSettergetter(location?.state?.item);
  const [item, setitem] = useState(Singleitem);
  window.scrollTo({ top: 0 });
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { isLoading, home } = useBackstagehome();
  if (!isLoading) {
    return (
      <section className={BsCustom.WrapperIpInside}>
        <MetaTags>
          <title>Mallu & Pi - Guppy Theater</title>
          <meta
            name="description"
            content="It wasn't Mallu's plan to save the future until he met Pi"
          />
          <meta
            name="keywords"
            content="adventure, mallu, Pi, animation, children, game"
          />
        </MetaTags>
        <HeaderBackstageNew />
        <div className={BsCustom.BshyperLinkStoryTitleMain}>
          <div className="container">
            <div className={BsCustom.BSHyperLinkPriviousNextMain}>
              <a href="/IpInside" className="ReadMoreCmn">
                <img
                  style={{ marginRight: "15px" }}
                  src={Images.CurveArrowLeft}
                />
                <span>Previous IP</span>
              </a>
              <a href="#">
                <h1 className={BsCustom.BsH1}>Mallu & Pi </h1>
              </a>
              <a href="/Miniraja" className="ReadMoreCmn">
                <span>Next IP</span>
                <img
                  style={{ marginLeft: "15px" }}
                  src={Images.CurveArrowRight}
                />
              </a>
            </div>
          </div>
        </div>

        <div className={BsCustom.StoryInformationBackstageSection}>
          <div className="container-fluid">
            <div className={`row`}>
              <div
                className={`col-sm-12 col-md-8 ${BsCustom.StoryInformationBackstageLeft}`}
              >
                <div className={BsCustom.CopyrightTextNew}>
                  <img
                    style={{ height: "815px" }}
                    src={Images.IPInsidebannerMalluPi}
                  />
                  <span>
                    &#169; 2024 Crossover Media and Design Pvt. Ltd. , all
                    rights reserved
                  </span>
                </div>
                <div
                  className={BsCustom.StoryInformationMobileBottom}
                  style={{ textAlign: "center" }}
                >
                  <span className={BsCustom.ImageBottomInfoSpan}>
                    Mallu & Pi
                  </span>
                </div>
              </div>
              <div
                className={`col-sm-12 col-md-4 ${BsCustom.StoryInformationBackstageRight}`}
              >
                <img src={Images.IPInsideLOGOMalluPi} />
                {/* <h4 className={BsCustom.BsH4}>IP Information</h4> */}
                <p className={BsCustom.BsCommaonP}>
                  <strong>Format: </strong>
                  <br />
                  Animated feature film and graphic novel
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Genre: </strong>
                  <br />
                  Sci-fi fantasy adventure
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Written by: </strong>
                  <br />
                  Neeru Nanda
                </p>
                <p className={BsCustom.BsCommaonP}>
                  <strong>Target Audience: </strong>
                  <br />
                  8+ and family
                </p>
                {/* <p className={BsCustom.BsCommaonP}><strong>Production Status: </strong><br/> Feature film script in development, pre-bible available.</p> */}
                {/* <p className={BsCustom.BsCommaonP}><strong>Graphic Novel/Motion Comic: </strong><br/>Script and artwork in development.</p> */}
                {/* <p className={BsCustom.BsCommaonP}><strong>Status: </strong><br/>Seeking primary co-production partners/broadcasters/ commissioners/buyers and  distributors.</p> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`container-fluid ${BsCustom.StoryInformationDesktopBottom}`}
        >
          <span className={BsCustom.ImageBottomInfoSpan}>Mallu & Pi</span>
        </div>

        <section
          className={`${BsCustom.WTGBSection} ${BsCustom.IPInsideFirstMAinPara}`}
        >
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <a href="#">
                  <img src={Images.SynopsisMalluPi} />
                </a>
                <span className={BsCustom.ImageBottomInfoSpan}>
                  Kooka - personifies evolution - our ancestor
                </span>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <h3 class={BsCustom.BsH3}>Synopsis</h3>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={Images.SynopsisMalluPi}
                />
                <span className={BsCustom.ImageBottomInfoSpan}>
                  Kooka - personifies evolution - our ancestor
                </span>

                <p className={BsCustom.BsCommaonP}>
                  In an astounding adventure that weaves through time and space,
                  two children and their ‘non-human’ companions team-up to try
                  and find the key to balanced life – The Needle Of Light.
                </p>
                <p className={BsCustom.BsCommaonP}>
                  The Needle of Light is a life-kindling force to reverse the
                  deadly crystallisation of all life forms on Threa with
                  Advanced Crystal Technology (ACT). It is a secret hidden in
                  the centre of Earth and the quest to find it is a treacherous
                  journey made light with wit and humour. A surprising twist in
                  the end reveals the start of another adventure - part 2 of the
                  trilogy.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className={BsCustom.NewGuppyIPStoryPAra}>
          <img
            style={{ width: "100%" }}
            className={BsCustom.desktopBreakerImg}
            src={Images.SectionbreakerMalluPi}
          />
          <img
            style={{ width: "100%" }}
            className={BsCustom.MobileBreakerImg}
            src={Images.MobileBreakerMalliandPiIP}
          />

          <div className="container-fluid">
            <span
              style={{ marginBottom: "60px" }}
              className={BsCustom.ImageBottomInfoSpan}
            >
              Backwaters of Kerala{" "}
            </span>
          </div>
          <div className={BsCustom.CreditSection}>
            <div className="container-fluid">
              <h5>CREDITS</h5>
              <p>Concept and script - Neeru Nanda</p>
              <p>Concept art and design - Anima Point (Malaysia/ Finland)</p>
            </div>
          </div>
        </div>

        <div className={BsCustom.ExtraParaIpinside}>
          <div className="container-fluid">
            <h5>STATUS / Enquiries</h5>
            <p>
              Status - Seeking primary co-production
              partners/broadcasters/commissioners/ buyers and distributors.{" "}
              <br />
              For co-productions, pre-sales, pitch bible, screening materials
              and other business enquiries <br />
              contact :{" "}
              <a
                href="mailto:anil@guppytheatre.com"
                style={{ textDecoration: "underline", color: "#000" }}
              >
                {" "}
                anil@guppytheatre.com
              </a>
            </p>
          </div>
          <div className="container-fluid" style={{ marginTop: "30px" }}>
            <h5>Production Status:</h5>
            <p>
              Feature Film Script in development, Pre-Bible available <br />{" "}
              Graphic Novel/Motion Comic: Script & Artwork in development
            </p>
          </div>
        </div>
        <div className={BsCustom.BSHyperLinkPriviousNextWrapper}>
          <div className="container">
            <div className={BsCustom.BSHyperLinkPriviousNextMain}>
              <a href="/IpInside" className="ReadMoreCmn">
                <img
                  style={{ marginRight: "15px" }}
                  src={Images.CurveArrowLeft}
                />
                <span>Previous IP</span>
              </a>
              <button
                href="#"
                onClick={handleScrollToTop}
                className="ReadMoreCmn"
                style={{
                  color: "#0D5268",
                  textDecorationLine: "underline",
                  border: "0",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                Back to Top
              </button>
              <a href="/Miniraja" className="ReadMoreCmn">
                <span>Next IP</span>
                <img
                  style={{ marginLeft: "15px" }}
                  src={Images.CurveArrowRight}
                />
              </a>
            </div>
          </div>
        </div>
        <FooterBs />
      </section>
    );
  } else {
    return <Loader />;
  }
}

export default MalluAndPi;
