import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';

function StoryInformationBackstage({item}){
    return(
        <div className={BsCustom.StoryInformationBackstageSection}>
            <div className="container-fluid">
                <div className={`row ${BsCustom.Row}`}>
                    <div className={`col-sm-12 col-md-8 ${BsCustom.StoryInformationBackstageLeft}`}>
                        <img style={{height: '630px'}} src={Images.IPinsidebannerMaharaja} />
                    </div>
                    <div className={`col-sm-12 col-md-4 ${BsCustom.StoryInformationBackstageRight}`}>
                        <img src={Images.IPInsidelogo} />
                        {/* <h4 className={BsCustom.BsH4}>IP Information</h4> */}
                        <p className={BsCustom.BsCommaonP}>A Transmedia IP experienced differently across the following mediums: book series and audiobooks, theatre play, animation series, mobile game, card game, NFT’s and more
                        </p>
                        <p className={BsCustom.BsCommaonP}><strong>Released: </strong><br/>books and audiobooks, theatre play, mobile game</p>
                        <p className={BsCustom.BsCommaonP}><strong>Target Audience: </strong><br/>Young children and family</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoryInformationBackstage;