import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import BannerBs from "../Component/BannerBs";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import CurrentIps from "../Component/CurrentIps";
import NewsLetterBS from "../Component/NewsLetterBS";
import GuppyStoriesBackstage from "../Component/GuppyStoriesBackstage";
import FooterBs from "../Component/FooterBs";
import BannerCommon from "../Component/BannerCommon";
import BackStageMaharaja from "../Component/BackStageMaharaja";
import BSHyperLinkPriviousNext from "../Component/BSHyperLinkPriviousNext";
import { useNavigate } from "react-router-dom";
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";

function TemplatePage(){
    const navigate = useNavigate();
    const {isLoading,home} =useBackstagehome();
    if(!isLoading){
        return(
            <section className={BsCustom.WrapperTemplatePage}>
                <HeaderBackstageNew />
                <BannerCommon/>
                <section className={ `${BsCustom.WhoWeAreSection} ${BsCustom.WhoWeAreSectionNewTop}`} style={{textAlign: 'center'}}>
                    <div className="container-fluid">
                        <h1 className={BsCustom.BsH1}>Lorem ipsum dolor sit</h1>
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-12 ${BsCustom.WhoWeAreRight}`}>
                                <p className={BsCustom.BsCommaonP}>Subtitle: Lorem ipsum dolor sit amet, consectetur <br/> adipiscing elit, sed do eiusmod tempor.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={BsCustom.WTGBSection}>
                    <div className="container-fluid">
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <img src={Images.CommonBackstageImg}/>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 className={BsCustom.BsH3}>Title</h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.CommonBackstageImg}/>
                                <p className={BsCustom.BsCommaonP}>The Maharaja’s “little brother” returns from his
                                world tour with shipping crates full of
                                “sophisticated” souvenirs of his travels. Not all
                                the shipping crates arrive at once. No one knows
                                when one could show up and it could contain a
                                beautiful new Dusenberg, or the latest
                                impressionistic (i.e. “incomprehensible”) painting
                                from Paris.</p>
                            </div>
                        </div>
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h3 className={BsCustom.BsH3}>Our Team</h3>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.CommonBackstageImg}/>
                                <p className={BsCustom.BsCommaonP}>In this season Narad travels to the strange, natural phenomena of the
                                world and discovers that SCIENCE AND MYTHOLOGY offer different
                                viewpoints for the same phenomena.
                                With the help of his TEAM - a ROBOTIC LAB, a grumpy old CAT and
                                gadgets.</p>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <img src={Images.CommonBackstageImg}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`${BsCustom.WhoWeAreSection} ${BsCustom.WhoWeAreSectionNewBottom}`}>
                    <img className={`${BsCustom.WhoWeAreSectionNewBottomCutImg}`} src={Images.GuppyBSCutImage} />
                    <img className={`${BsCustom.MobileIntroducingImgNew}`} src={Images.MobileIntroducingImg} />
                    <div className="container-fluid">
                        <h2 className={BsCustom.BsH2}>Introducing Guppy</h2>
                        <div className={`row ${BsCustom.Row}`}>
                            <div className={`col-12 ${BsCustom.WhoWeAreRight} ${BsCustom.WhoWeAreRightNew}`}>
                                <p className={BsCustom.BsCommaonP}>In this season Narad travels to the strange, natural phenomena of the
                                world and discovers <br/> that SCIENCE AND MYTHOLOGY offer different
                                viewpoints for the same phenomena.
                                </p>
                                <button className={BsCustom.BsComBtn}  onClick={()=>{navigate("/GuppyIntroduction")}} >Read More</button>
                            </div>
                        </div>
                        <div className={`row ${BsCustom.Row} ${BsCustom.TPBottom}`}>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                                <img src={Images.MalluNewLeftImg}/>
                            </div>
                            <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                                <h4 className={BsCustom.BsH4}>Mallu and Pi</h4>
                                <img className={BsCustom.MainWWANewExtraImg} src={Images.CommonBackstageImg}/>
                                <p className={BsCustom.BsCommaonP}>In this season Narad travels to the strange, natural phenomena of the
                                    world and discovers that SCIENCE AND MYTHOLOGY offer different
                                    viewpoints for the same phenomena.
                                    With the help of his TEAM - a ROBOTIC LAB, a grumpy old CAT and
                                    gadgets - a DRONE and a PHONE WATCH.</p>
                                <a className={BsCustom.ReadMoreCmn} href="#"><span>View</span> <img src={Images.Eyeimg} /></a>
                            </div>
                        </div>
                    </div>
                </section>
                <BackStageMaharaja/>
                <BSHyperLinkPriviousNext/>
                <NewsLetterBS/>
                <GuppyStoriesBackstage/>
                <FooterBs/>
            </section>
        ); 
    }
    else 
    {return (<Loader />)}
}

export default TemplatePage;