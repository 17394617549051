import React from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";
import { useEffect, useState, createRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../ApiService/Apicall";
import emailsend from "../ApiService/emailsend";
import NoDraftModal from "./NoDraftModal";
import SubmittedSuccessfullyModal from "./SubmittedSuccessfullyModal";
import moment from "moment";
import { getLocal, setLocal } from "../otherexports";

function SignUPChild() {
  const [modaltext, setmodaltext] = useState("");
  const modalopentwo = createRef(null);
  const modalopen = createRef(null);
  const navigate = useNavigate();
  const [uname, setUname] = useState("");
  const [bod, setbod] = useState("");
  const [penName, setpenName] = useState("");
  const [Initials, setInitials] = useState("");
  const [relation, setrelation] = useState("");
  const location = useLocation();
  const [termacept, settermacept] = useState(false);
  const [conditonaceept, setconditonaceept] = useState(false);
  const [user, setuser] = useState(location?.state?.user);
  const { setloading = () => {} } = location?.state;
  // const [password, setPassword] = useState("");

  useEffect(() => {
    // console.log("psk data====>")
    const userdata = getLocal("Signupdata");
    if (!!userdata) {
      setUname(userdata.name);
      setbod(userdata.Birthday);
      setpenName(userdata.penName);
      setInitials(userdata.ParentInitials);
      setrelation(userdata.RelationwithChild);
      // settype(userdata.type);
    }
  }, [location?.state?.type]);

  async function postUser() {
    if (uname.length == 0 || bod.length == 0 || penName.length == 0) {
      let message =
        uname.length == 0
          ? "Child’s Name is a required field"
          : bod.length == 0
          ? "Child’s Birth Date is a required field"
          : penName.length == 0
          ? "Pen Name is a required field"
          : "";
      setmodaltext(message);
      modalopen.current.show();
    } else {
      // const user = location?.state?.user;
      let alluser = await api("get", "users");
      var userdetail = alluser?.data?.filter((e) => e?.email == user.email);
      if (userdetail?.length > 0) {
        setmodaltext("This email is already linked to an existing account");
        modalopen.current.show();
        setloading(false);
      } else {
        let sixnumber = 100000 + Math.floor(Math.random() * 900000);
        emailsend(
          {
            email: user.email,
            name: user.username,
          },
          "psk",
          sixnumber
        );
        let age = new Date().getFullYear() - moment(bod).format("YYYY");
        let data = {
          email: user.email,
          user_media: "110",
          password: user.password,
          username: user.username,
          ParentInitials: user.ParentInitials,
          RelationwithChild: user.RelationwithChild,
          ParentName: user.ParentName,
          name: uname,
          Birthday: bod,
          penName: penName,
          age: age?.toString(),
        };

        navigate("/Otp", {
          state: {
            data: data,
            otp: sixnumber,
          },
        });
        setloading(false);
      }
    }
  }

  async function socialpost(user) {
    if (
      uname.length == 0 ||
      bod.length == 0 ||
      penName.length == 0 ||
      Initials.length == 0 ||
      relation.length == 0
    ) {
      let message =
        uname.length == 0
          ? "Child’s Name is a required field"
          : bod.length == 0
          ? "Child’s Birth Date is a required field"
          : penName.length == 0
          ? "Pen Name is a required field"
          : Initials.length == 0
          ? "Parent/Guardian Initials is a required field"
          : relation.length == 0
          ? "Relationship with the child is a required field"
          : "";
      setmodaltext(message);
      modalopen.current.show();
    } else {
      // const user = location?.state?.user;
      let alluser = await api("get", "users");
      var userdetail = alluser?.data?.filter((e) => e?.email == user.email);
      if (userdetail?.length > 0) {
        setmodaltext("This email is already linked to an existing account");
        modalopen.current.show();
        setloading(false);
      } else {
        let sixnumber = 100000 + Math.floor(Math.random() * 900000);
        emailsend(
          {
            email: user.email,
            name: user.username,
          },
          "psk",
          sixnumber
        );
        let age = new Date().getFullYear() - moment(bod).format("YYYY");
        let data = {
          email: user.email,
          user_media: "110",
          password: user.password,
          username: user.username,
          ParentInitials: Initials,
          RelationwithChild: relation,
          ParentName: user.ParentName,
          name: uname,
          Birthday: bod,
          penName: penName,
          age: age?.toString(),
        };

        navigate("/Otp", {
          state: {
            data: data,
            otp: sixnumber,
          },
        });
        setloading(false);
      }
    }
  }

  const openInNewTab = (url) => {
    //  let newWindow =
    window.open(url, "_blank");
    // if (newWindow) newWindow.opener = null
  };

  return (
    <div className={styles.signUpBg}>
      <div className={styles.arrowImgMain}>
        <div className={styles.signLogRightLine}>
          <img src={Images.signLogRightLine} />
        </div>
        <div className="container">
          <div className={`row ${styles.signUpInfo}`}>
            <div className={`col-sm-12 col-md-6 ${styles.leftInfo}`}>
              <img src={Images.MuniaMiddle} alt="" />
              <h5>Sign up</h5>
              <p>Sign up with one of the options below</p>
              <div className={styles.socialIcon}>
                <a href="#">
                  <img src={Images.googleSocial} />
                </a>
              </div>
            </div>
            <div className={`col-sm-12 col-md-6 ${styles.rightInfo}`}>
              <p className={styles.signupHeadline}>Child’s Details</p>
              <form action="#">
                {location?.state?.type == undefined ? (
                  <>
                    <div className={styles.wrapdiv}>
                      <label>Child’s Name</label>
                      <input
                        type="text"
                        name="uname"
                        value={uname}
                        onChange={(e) => {
                          setUname(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.wrapdiv}>
                      <label>Pen Name</label>
                      <input
                        type="text"
                        value={penName}
                        onChange={(e) => {
                          setpenName(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.wrapdiv}>
                      <label>Child’s Birth Date</label>
                      <input
                        type="date"
                        value={bod}
                        onChange={(e) => {
                          setbod(e.target.value);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.wrapdiv}>
                      <label>Child’s Name</label>
                      <input
                        type="text"
                        name="uname"
                        value={uname}
                        onChange={(e) => {
                          setUname(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.wrapdiv}>
                      <label>Pen Name</label>
                      <input
                        type="text"
                        value={penName}
                        onChange={(e) => {
                          setpenName(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.wrapdiv}>
                      <label>Child’s Birth Date</label>
                      <input
                        type="date"
                        value={bod}
                        onChange={(e) => {
                          setbod(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.wrapdiv}>
                      <label>Relationship with the child</label>
                      <input
                        type="text"
                        // id="quantity"
                        value={relation}
                        onChange={(e) => {
                          setrelation(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.wrapdiv}>
                      <label>Parent/Guardian Initials</label>
                      <input
                        type="text"
                        value={Initials}
                        onChange={(e) => {
                          setInitials(e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}
                <div className={styles.wrapdiv}>
                  <div className={styles.checkboxdiv}>
                    <input
                      type="checkbox"
                      defaultChecked={termacept}
                      onChange={() => {
                        settermacept(!termacept);
                      }}
                    />
                    <span>
                      I accept the Guppy Story <a href="#">Terms of Use.</a>
                    </span>
                  </div>
                  <div className={styles.checkboxdiv}>
                    <input
                      type="checkbox"
                      defaultChecked={conditonaceept}
                      onChange={() => {
                        setconditonaceept(!conditonaceept);
                      }}
                    />
                    <span>
                      I have read the Guppy Story{" "}
                      <a href="#">Privacy Policies</a> and accept both of them.{" "}
                    </span>
                  </div>
                  <div className={styles.checkboxdiv}>
                    <button
                      className={styles.btnFill}
                      disabled={
                        conditonaceept == true && termacept == true
                          ? false
                          : true
                      }
                      style={{
                        opacity:
                          conditonaceept == true && termacept == true ? 1 : 0.5,
                      }}
                      onClick={() => {
                        if (conditonaceept == true && termacept == true) {
                          if (location?.state?.type == undefined) {
                            const user = location?.state?.user;
                            let age =
                              new Date().getFullYear() -
                              moment(bod).format("YYYY");
                            if (
                              uname.length == 0 ||
                              bod.length == 0 ||
                              penName.length == 0
                            ) {
                              let message =
                                uname.length == 0
                                  ? "Child’s Name is a required field"
                                  : bod.length == 0
                                  ? "Child’s Birth Date is a required field"
                                  : penName.length == 0
                                  ? "Pen Name is a required field"
                                  : "";
                              setmodaltext(message);
                              modalopen.current.show();
                            } else {
                              let data = {
                                email: user.email,
                                user_media: "110",
                                password: user.password,
                                username: user.username,
                                ParentInitials: user.ParentInitials,
                                RelationwithChild: user.RelationwithChild,
                                ParentName: user.ParentName,
                                name: uname,
                                Birthday: bod,
                                penName: penName,
                                age: age?.toString(),
                              };
                              setLocal("Signupdata", data);
                              const userdata = getLocal("Signupdata");
                              setuser(userdata);
                              openInNewTab(
                                `https://guppytheatre.com/ParentConsentForm?ParentName=${
                                  user.ParentName
                                },ChildName=${uname},Age=${age?.toString()},RelationwithChild=${
                                  user.RelationwithChild
                                }`
                              );
                              // navigate(`/ParentConsentForm?ParentName=${user.ParentName},ChildName=${uname},Age=${age?.toString()},RelationwithChild=${user.RelationwithChild}`, { state: {signupdata:data} });
                            }
                          } else {
                            if (
                              uname.length == 0 ||
                              bod.length == 0 ||
                              penName.length == 0 ||
                              Initials.length == 0 ||
                              relation.length == 0
                            ) {
                              let message =
                                uname.length == 0
                                  ? "Child’s Name is a required field"
                                  : bod.length == 0
                                  ? "Child’s Birth Date is a required field"
                                  : penName.length == 0
                                  ? "Pen Name is a required field"
                                  : Initials.length == 0
                                  ? "Parent/Guardian Initials is a required field"
                                  : relation.length == 0
                                  ? "Relationship with the child is a required field"
                                  : "";
                              setmodaltext(message);
                              modalopen.current.show();
                            } else {
                              let age =
                                new Date().getFullYear() -
                                moment(bod).format("YYYY");
                              const user = location?.state?.user;
                              let data = {
                                email: user.email,
                                user_media: "110",
                                password: user.password,
                                username: user.username,
                                ParentInitials: Initials,
                                RelationwithChild: relation,
                                ParentName: user.ParentName,
                                name: uname,
                                Birthday: bod,
                                penName: penName,
                                age: age?.toString(),
                                type: location?.state?.type,
                              };
                              setLocal("Signupdata", data);
                              const userdata = getLocal("Signupdata");
                              setuser(userdata);
                              openInNewTab(
                                `https://guppytheatre.com/ParentConsentForm?ParentName=${
                                  user.ParentName
                                }=ChildName=${uname}=Age=${age?.toString()}=RelationwithChild=${relation}`
                              );
                              // navigate(`/ParentConsentForm?ParentName=${user.ParentName}=ChildName=${uname}=Age=${age?.toString()}=RelationwithChild=${relation}`, { state: {signupdata:data} });
                            }
                          }
                        }
                      }}
                    >
                      View Consent Form
                    </button>
                  </div>
                </div>
                <div className={styles.btndiv}>
                  <button
                    style={{ width: "195px", padding: "7px 0" }}
                    type="submit"
                    className={styles.btnFill}
                    onClick={(e) => {
                      e.preventDefault();
                      if (conditonaceept == true && termacept == true) {
                        const userdata = getLocal("Signupdata");
                        console.log(userdata, "=====userdata===userdata");
                        if (userdata) {
                          if (userdata.type == undefined) {
                            postUser();
                          } else {
                            socialpost(userdata);
                          }
                        } else {
                          if (location?.state?.type == undefined) {
                            postUser();
                          } else {
                            socialpost(location?.state?.user);
                          }
                        }
                      } else {
                        let message =
                          termacept == false
                            ? "Guppy Story Terms is a required field"
                            : conditonaceept == false
                            ? "Guppy Story Terms is a required field"
                            : "";
                        setmodaltext(message);
                        modalopen.current.show();
                      }
                    }}
                  >
                    Save
                  </button>

                  <div className={styles.againreg}>
                    <p
                      style={{
                        paddingTop: "30px",
                        fontSize: "18px",
                        lineHeight: "23px",
                      }}
                    >
                      Already have an account?{" "}
                      <a
                        style={{
                          color: "var(--black)",
                          display: "inline-block",
                          textDecoration: "underline",
                        }}
                        href="/login"
                      >
                        {" "}
                        Login
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.signBgLeft}>
          <img src={Images.signupbg} />
        </div>
      </div>
      <NoDraftModal ref={modalopen} text={modaltext} />
      <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
    </div>
  );
}

export default SignUPChild;
