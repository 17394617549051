import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import FooterBs from "../Component/FooterBs";
import Team from "../Component/Team";
import BannerCommon from "../Component/BannerCommon";
import { useBackstagewhatwodo } from "../../otherexports";
import { useNavigate, useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import Loader from "../Component/Loader";


function WhatWeDo() {
  const navigate = useNavigate();
  const { isLoading, data } = useBackstagewhatwodo();

  // console.log(JSON.stringify(data?.attributes?.backstage_stories?.data), "==========data====data");
  if(!isLoading){
    return (
      <section className={BsCustom.WrapperWhatWeDo}>
        <MetaTags>
            <title>What We Do - Guppy Theater</title>
            <meta name="description" content="Guppy Theatre create and produce Transmedia IP for Children and Family" />
            <meta name="keywords" content="animation, games, IP development, story world, transmedia storytelling, children, metaverse" />
        </MetaTags>
        <HeaderBackstageNew />
        <section className={BsCustom.BannerCommon}>
            <img className={BsCustom.BannerCommonImg} src={Images.WhatwedoheaderBanner} />
            <img className={BsCustom.MobileCommonBannerNew} src={Images.WhatWeDoBannerNewMobile} />
            <img className={BsCustom.BackBtnBSCommonNew} src={Images.BackstageBackBtn} style={{cursor:"pointer"}}  onClick={() => {
            navigate(-1);
          }} />
        </section>
        {/* <BannerCommon /> */}
        <div className={`${BsCustom.TeamLightBCGroung} ${BsCustom.WrapperWhatWeDoTop}`}>
          <div className="container-fluid">
            <h1 className={BsCustom.BsH1}>What We Do</h1>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-12 ${BsCustom.WhoWeAreRight}`}>
                <p className={`${BsCustom.BsCommaonP} ${BsCustom.IntoductionParanew}`} >
                  {data?.attributes?.description}
                </p>
              </div>
            </div>
            {/* <div className={BsCustom.WhatWeDoInner}>
              <div className={`row ${BsCustom.Row}`}>
                <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                  <img src={Images.CommonBackstageImg} />
                </div>
                <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                  <h4 className={BsCustom.BsH4}>Guppy theatre</h4>
                  <p className={BsCustom.BsCommaonP}>
                    {data?.attributes?.description}
                  </p>
                  <a href="#" className={BsCustom.ReadMoreCmn}>
                   <span> Know More</span> <img src={Images.ReadMoreImg} />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
  
        {data?.attributes?.backstage_stories?.data.map((item, index) => {
  
          // console.log("item-------->", item)
  
           let image_url = "https://manage.guppytheatre.com" + item?.attributes?.banner_media?.data?.attributes?.url     
           
          return (
            <div
              className={`${BsCustom.WhatWeDoInner} ${BsCustom.TeamDarkBCGroung}`}
            >
              <div className={`row ${BsCustom.Row}`}>
                <div className={`col-md-6 ${BsCustom.TeamInnerImgSec}`}>
                  <img src={image_url} />
                </div>
                <div className={`col-md-6 ${BsCustom.TeamInnerTextSec}`}>
                  <h4 className={BsCustom.BsH4}>{item?.attributes?.title}</h4>
                  <p className={BsCustom.BsCommaonP}>{item?.attributes.bottom_text}</p>
                  <button onClick={() => {
                    if(index==0){
                      navigate("/IpInside");
                    }
                    else if(index==1){
                      navigate("/MalluAndPi");
                    }
                    else if(index==2){
                      navigate("/Miniraja");
                    }
                    else if(index==3){
                      navigate("/BecomingNarad");
                    }
                    else if(index==5){
                      navigate("/GuppyStoriesIp");
                    }
                    else{
                      navigate("/IpInside");
                    }
                      }} href="#"  className={BsCustom.ReadMoreCmn}>
                        { (index != 4 && index !=6) &&  <> <span>View</span> <img src={Images.EyeimgNewWhat} /></>}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        <FooterBs />
      </section>
    );
  }
  else 
    {return (<Loader />)}
}

export default WhatWeDo;
