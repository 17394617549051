import React from "react";
import Images from "../../component/Images";
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import styles from "../../pages/Home/landingpage.module.css";
import FooterBs from "../Component/FooterBs";
import BannerCommon from "../Component/BannerCommon";
import { useNavigate } from "react-router-dom";
import { useBackstagewhoweare } from "../../otherexports";
import MetaTags from "react-meta-tags";
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";

function OurStory() {
  const navigate = useNavigate();
  const { data, isLoading } = useBackstagewhoweare();
  // console.log(JSON.stringify(data),"=======psk===")
  if (!isLoading) {
    return (
      <section className={BsCustom.WrapperOurStory}>
        <MetaTags>
          <title>Our Story - Guppy Theater</title>
          <meta
            name="description"
            content="We produce participative story worlds in which the 21st century children are at the centre of the stage"
          />
          <meta
            name="keywords"
            content="publishing, book, children, transmedia, mumbai, animation"
          />
        </MetaTags>
        <HeaderBackstageNew />

        <section className={BsCustom.BannerCommon}>
          <img
            className={BsCustom.BannerCommonImg}
            src={Images.OurStoryBanner}
          />
          <img
            className={BsCustom.MobileCommonBannerNew}
            src={Images.OurStoryNewMobileBanner}
          />
          <img
            className={BsCustom.BackBtnBSCommonNew}
            src={Images.BackstageBackBtn}
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          />
        </section>
        <section
          className={`${BsCustom.WTGBSection} ${BsCustom.TeamLightBCGroung}`}
        >
          <div className="container-fluid">
            <div className={BsCustom.OurStoryMainTop}>
              <h1 className={BsCustom.BsH1}>Our Story</h1>
              <div className={`row ${BsCustom.Row}`}>
                <div className={`col-12 ${BsCustom.WhoWeAreRight}`}>
                  <p className={BsCustom.BsCommaonP}>
                    Crossover Media & Design Pvt Ltd started as a publishing
                    company for coffee table books at a <br /> time when these
                    visually rich books were the centrepieces of living rooms
                    the world over. Soon <br /> enough the internet turned the
                    world upside down, relegating ink on paper to niche
                    audiences.
                  </p>
                </div>
              </div>
            </div>
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <img src={Images.OurStoryTop} />
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={Images.OurStoryTop}
                />
                <p className={BsCustom.BsCommaonP}>
                  In about 2008-2010 we noticed a vacuum in the area of
                  children’s contemporary Indian stories. Children’s fiction was
                  based upon mythology, tradition and culture leading to the
                  import of older editions of visually appealing story books. As
                  a result the children were identifying with foreign cities,
                  foreign names and foreign cultures. That’s when we created our
                  first character - the Maharaja - an Indian character
                  universally known. Beneath the bravery, history and fanfare of
                  these maharajas we discovered stories about the rollicking fun
                  they used to have because of their resources and
                  opportunities. In short a totally madcap, whimsical Maharaja
                  full of fun and pranks.
                </p>
              </div>
            </div>

            <div className={`${BsCustom.OurStoryExtraParaNew}`}>
              <p
                className={BsCustom.BsCommaonP}
                style={{ marginBottom: "30px" }}
              >
                With the Maharaja came his magnificent palace, his large kingdom
                and his quirky family - a complete storyworld. Using new
                technologies we started creating transmedia content and to take
                the example of our Maharaja and his family, this story world
                experience spreads across books, stage, screens and all other
                digital mediums - existing or in the future.
              </p>
              <p
                className={BsCustom.BsCommaonP}
                style={{ marginBottom: "30px" }}
              >
                Creating the Maharaja of Mastipur was the beginning of our
                transmedia venture. During the confines of the pandemic we
                created other unique IPs that are ready for co-production and
                for deployment in a variety of different mediums like TV shows,
                gaming, merchandising, App’s etc.
              </p>
            </div>
          </div>
        </section>
        <div
          style={{ width: "100%", marginTop: "-55px", position: "relative" }}
        >
          <img
            className={`${BsCustom.WhoWeAreSectionNewBottomCutImg}`}
            src={Images.OurStoryMiddileBreaker}
          />
          <img
            className={`${BsCustom.MobileIntroducingImgNew}`}
            src={Images.OurStoryBreakerNewMobile}
          />
        </div>
        <section
          className={`${BsCustom.WhoWeAreSection} ${BsCustom.WhoWeAreSectionNewBottom}`}
        >
          <div className="container-fluid">
            <div className={`row ${BsCustom.Row}`}>
              <div className={`col-md-6 ${BsCustom.WTGBSectionLeft}`}>
                <img
                  className={BsCustom.MainWWANewExtraImg}
                  src={Images.OurStoryBottom}
                />
                <p className={BsCustom.BsCommaonP}>
                  Moving quickly to the need of the hour we are engaging the
                  children in participative/interactive storytelling thereby
                  inspiring them and encouraging them to be at the centrestage
                  of their own world. Our one-of-a-kind storytelling App GUPPY
                  STORIES is the perfect example wherein we have used the ‘5W’s
                  and a How’ model to prompt and guide the children to make
                  creative choices to build their own story adventures. This App
                  will further facilitate school teachers to instill creative
                  writing in children by incentivising them to get published on
                  the App, enabling them to shine in their own real world.
                </p>
                <div
                  className={` ${styles.storeIconRight} ${BsCustom.storeIconRight}`}
                >
                  <span
                    className={BsCustom.BsCommaonP}
                    style={{
                      display: "block",
                      fontWeight: "600",
                      marginBottom: "20px",
                    }}
                  >
                    Available on
                  </span>
                  <a href="https://play.google.com/store/apps/details?id=com.guppytheatre.app">
                    <img src={Images.googleIcon} alt="" />
                  </a>
                  <a href="https://apps.apple.com/in/app/guppy-stories/id6450250856">
                    <img
                      style={{ marginLeft: "15px" }}
                      src={Images.appledownload}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className={`col-md-6 ${BsCustom.WTGBSectionRight}`}>
                <img src={Images.OurStoryBottom} />
              </div>
            </div>
          </div>
        </section>
        <FooterBs />
      </section>
    );
  } else {
    return <Loader />;
  }
}

export default OurStory;
