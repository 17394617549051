import { useCallback, useRef, useState, createRef } from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { useNavigate } from "react-router-dom";
import { getLocal } from "../otherexports";
import { useDispatch, useSelector } from "react-redux";
import { userEditapi } from "../redux/userdata/user.actions";
import Webcam from "react-webcam";
import Modal from "react-modal";
import imagepost from "../ApiService/imagepost";
import NoDraftModal from './NoDraftModal';
import SubmittedSuccessfullyModal from './SubmittedSuccessfullyModal';

function EditProfile({ Userdata }) {
  const [modaltext, setmodaltext] = useState('');
  const modalopentwo=createRef(null);
  const modalopen=createRef(null);
  const webcamRef = useRef(null);
  const [inputs, setInputs] = useState(Userdata);
  const [captureimg, setcaptureimg] = useState(null);
  const [uploadingimg, setuploadingimg] = useState(null);
  const [picid, setpicid] = useState(Userdata?.user_media?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileshare = useRef(null);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  // const videoConstraints = {
  //   facingMode: "user",
  // };

  // const capture = useCallback(async () => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   console.log(imageSrc);
  //   // const imagedata = await imagepost({
  //   //   uri: imageSrc,
  //   //   name: "Profilepic",
  //   //   type:"image/jpeg",
  //   // },
  //   //   "======posting data",
  //   //   "toke"
  //   // );
  // }, [webcamRef]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    if(inputs.name.length==0 || inputs.penName?.length==0 || inputs.penName==undefined || inputs.contactNo?.length==0 || inputs.name==undefined || inputs.contactNo==undefined || inputs.age?.length==0 || inputs.age==undefined ){
      let message=inputs.name.length==0 || inputs.name==undefined?"Name":inputs.penName?.length==0 || inputs.penName==undefined?"PenName":inputs.contactNo?.length==0 || inputs.contactNo==undefined? "Contact No":"Age"
      setmodaltext(`${message} is required field !`);
      modalopen.current.show()
    }else{
      let token_data = getLocal("User");
      let user_id = token_data.id;
      let dataSend = {
        user_media: picid,
        name: inputs.name,
        penName: inputs.penName,
        age: inputs.age,
        username: inputs.username,
        contactNo: inputs.contactNo,
        email: inputs.emailId,
      };
  
      dispatch(
        userEditapi({
          id: user_id,
          data: dataSend,
          onSuccess: (res) => {
            setmodaltext("Submitted successfully!")
              modalopentwo.current.show()
          },
          onFaliure: (res) => {
            setmodaltext("Some thing Went Wrong!")
              modalopen.current.show()
          },
        })
      );
    }
  
  };

  // modal
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    // if (captureimg) {
    //   setcaptureimg(false);
    // } else {
    //   setIsOpen(false);
    // }
    if (captureimg == null) {
      setIsOpen(false);
    } else {
      setcaptureimg(null);
    }
  }
  // Modal
  return (
    <section className={styles.edit_profile_section}>
      <div className={styles.profile_top}>
        <div className="container">
          <div
            className={styles.backbtn}
            onClick={() => {
              if (captureimg == null) {
                navigate(-1);
              } else {
                setcaptureimg(null);
                setuploadingimg(null);
              }
            }}
          >
            <a href="#">
              <img
                src={Images.backbtn}
                onClick={() => {
                  if (captureimg == null) {
                    navigate(-1);
                  } else {
                    setcaptureimg(null);
                    setuploadingimg(null);
                  }
                }}
              />
            </a>
          </div>
          <h4>Edit My Profile</h4>
          <div className={styles.profile_top_img}>
            <img
              onClick={openModal}
              src={(uploadingimg == null || picid=="110" ) ? Images.editprofile : uploadingimg}
              style={{
                // width: 150,
                // height: 150,
                // borderRadius: 100,
                // objectFit: "cover",
                // objectPosition: "center",
              }}
            />
          </div>
          {/* modal html */}
          <Modal
            style={{
              content: {
                display: "flex",
                backgroundColor: "#0000",
                borderWidth: 0,
                flexDirection: "column",
                flex: 1,
                position: "unset",
                height: "100%",
                justifyContent: "space-around"
              },
            }}
            overlayClassName={
              captureimg == null ? Modalstyle.modal : Modalstyle.modaltwo
            }
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
          >
            <div className="container">
              <div className={Modalstyle.modalEditProfile}>
                <div className={styles.backbtn} onClick={closeModal}>
                  <a href="#">
                    <img src={Images.navleft} onClick={closeModal} />
                  </a>
                </div>
                {captureimg == null && (
                  <div className={Modalstyle.profile_top_img}>
                    <img src={Images.editprofile} />
                  </div>
                )}
                {captureimg != null && <h4>Edit profile picture</h4>}
                <p
                  style={{
                    paddingRight: captureimg != null ? "10%" : 0,
                    paddingLeft: captureimg != null ? "20%" : 0,
                  }}
                >
                  {captureimg == null
                    ? "Change your profile picture by capturing a new picture or by uploading picture of your choice."
                    : "Edit/Crop your picture to make it a perfect fit for your profile picture."}
                </p>
                {captureimg != null && (
                  <img
                    src={captureimg}
                    style={{ width: "100%", height: "100%" }}
                  />
                  // <Webcam
                  //   audio={false}
                  //   height={"60%"}
                  //   ref={webcamRef}
                  //   screenshotFormat="image/jpg"
                  //   width={"60%"}
                  //   forceScreenshotSourceSize={true}
                  //   videoConstraints={videoConstraints}
                  // />
                )}
                {captureimg == null && (
                  <>
                    <button className={styles.btnFill} onClick={()=>{setpicid("110");setIsOpen(false);}}>
                      Remove Profile Picture
                    </button>
                    {/* <button
                      className={styles.btnUnfill}
                      onClick={() => {
                        setcaptureimg(true);
                      }}
                    >
                      Capture Profile Picture
                    </button> */}
                    <input
                      type="file"
                      ref={fileshare}
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg"
                      onChange={async (event) => {
                        let url = URL.createObjectURL(event.target.files[0]);
                        const imagedata = await imagepost(
                          event.target.files,
                          "======posting data",
                          "toke"
                        );
                        setpicid(imagedata?.data[0]?.id?.toString());
                        setuploadingimg(url);
                        setcaptureimg(url);
                      }}
                    />
                    <button
                      className={styles.btnUnfill}
                      onClick={() => {
                        fileshare.current.click();
                        // setcaptureimg(true);
                      }}
                    >
                      Upload Profile Picture
                    </button>
                  </>
                )}
                {captureimg != null && (
                  <div className={styles.profile_bottom}>
                    <div className={Modalstyle.Catputure}>
                      <button
                        className={Modalstyle.btnUnfill}
                        onClick={() => {
                          setcaptureimg(null);
                          setuploadingimg(null);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className={Modalstyle.btnFill}
                        onClick={() => {
                          closeModal();
                          setIsOpen(false);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>

          <form>
            <div className={styles.profileInfo}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={inputs.name || ""}
                onChange={handleChange}
              />
            </div>
            <div className={styles.profileInfo}>
              <label>Pen Name</label>
              <input
                type="text"
                name="penName"
                value={inputs.penName || ""}
                onChange={handleChange}
              />
            </div>
            <div className={styles.profileInfo}>
              <label>Age</label>
              <input
                type="number"
                name="age"
                value={inputs.age || ""}
                onChange={handleChange}
              />
            </div>
            <div className={styles.profileInfo}>
              <label>Unique ID</label>
              <input
                type="text"
                name="id"
                value={inputs.id || ""}
                onChange={handleChange}
                disabled
              />
            </div>
            <div className={styles.profileInfo}>
              <label>Contact No.</label>
              <input
                type="tel"
                name="contactNo"
                value={inputs.contactNo || ""}
                onChange={handleChange}
              />
            </div>
            <div className={styles.profileInfo}>
              <label>Email ID</label>
              <input
                type="email"
                name="email"
                value={inputs.email || ""}
                onChange={handleChange}
                disabled
              />
            </div>
          </form>
        </div>
      </div>
      <div className={styles.profile_bottom}>
      <div style={{ position: "absolute", top: 0, left: 0, right: 0}}>
            <img style={{width: "100%"}} src={Images.dashedLine} alt="" />
          </div>
        <div className="container">
          
          <button
            className={styles.btnUnfill}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </button>
          <button className={styles.btnFill} onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
      <NoDraftModal ref={modalopen} text={modaltext} />
      <SubmittedSuccessfullyModal ref={modalopentwo} text={modaltext} />
    </section>
  );
}

export default EditProfile;

// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(<MyForm />)
