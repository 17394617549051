import React, { useImperativeHandle } from "react";
import { useState } from "react";
import Images from "./Images";
import Modal from 'react-modal';
import { forwardRef } from "react";
import styles from '../pages/Home/landingpage.module.css';
import Modalstyle from "../pages/StoryPage/storypage.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocal } from "../otherexports";
import Header from "../component/Header"


 const  ModalStoryIngrediaentsNew =() =>{
  const userinfo = getLocal("User");
  const navigate=useNavigate();
  const location = useLocation();
  // modal
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal(e) {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // Modal

    return(
        <div className={Modalstyle.newScreenModal}>
        <Header/>
        <div style={{backgroundColor: "#56CDD5", padding: "25px 0", marginTop: "-25px"}}>
            <div className={styles.backbtn}  onClick={()=>{navigate(-1)}} >
              <a  onClick={()=>{navigate(-1)}}>
                <img src={Images.backbtn}  onClick={()=>{navigate(-1)}}/>
              </a>
            </div>
            <p style={{fontWeight: "600", fontSize: "36px", lineHeight: "47px", color: "#000000", textAlign: "center"}}>{location?.state?.item?.attributes?.IngredientName}</p>
            {/* <p style={{fontWeight: "600", fontSize: "36px", lineHeight: "47px", color: "#000000", textAlign: "center"}}>{"Cow"}</p> */}
        </div>
        <div className={Modalstyle.modalIngredients} >
            <img src={Images.dashedLine} />
            <div className={Modalstyle.modalIngredientsInner}>
              <img
              //  src={Images.MuniaPackage}
                src={
                  "https://manage.guppytheatre.com" +location?.state?.item?.attributes?.IngredientImage?.data?.attributes?.url
                }
              />
              <p>
              {/* <strong style={{fontWeight: "600", fontSize: "36px", lineHeight: "47px", color: "#000000", textAlign: "center"}}>{"psk"}</strong> */}
                <strong style={{fontWeight: "600", fontSize: "36px", lineHeight: "47px", color: "#000000", textAlign: "center"}}>{location?.state?.item?.attributes?.IngredientName}</strong>
              </p>
              <div className={Modalstyle.modalIngredientsInnerInfo}>
                <div>
                  <img src={Images.account_6} />
                  <span>{userinfo.username}</span>
                </div>
                <div className={Modalstyle.modalIngredientsInnerInfoimg}>
                  <img src={Images.logoHeader} />
                </div>
              </div>
            </div>
            <div className={Modalstyle.HeartYellowbtn} onClick={()=>{}} >
              <a href="#">
                <img src={Images.HeartYellow} />
              </a>
            </div>
            <div className={Modalstyle.sharebtnMobile}>
                <button onClick={openModal} className={styles.btnFill} style={{position: "relative"}}><img style={{position: "absolute", top: "-55px", left: "-30px", width: "77px"}} src={Images.YellowShareBtnStory} />Share this Ingredient</button>
            </div>
          </div>

            {/* Modal */}
            <Modal
        style={{
          content: {
            display: "flex",
            backgroundColor: "#0000",
            borderWidth: 0,
            flexDirection: "column",
            flex: 1,
            position: "unset",
            height: "100%",
            overflow: "auto",
            justifyContent: "flex-end",
            padding: "0",
          },
        }}
        overlayClassName={Modalstyle.modal}
        isOpen={modalIsOpen}
        // shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        // onAfterOpen
        ariaHideApp={true}
        shouldCloseOnEsc={true}
        contentLabel="Example Modal">
        <div>
          <div className={Modalstyle.modalIngredientsshareMobile} style={{border: "0"}}>
            <img
                className={`${Modalstyle.sharebtnimg} ${styles.sharebtnimg}`}
                src={Images.backbtncross}
                onClick={closeModal}
                style={{
                  position: 'absolute',
                  top: '-11px',
                  right: 0,
                  width: '45px'
                }}
              />
            <div style={{margin: "15px 0" }} className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img style={{width: "95px"}} src={Images.Sharecopylink} />
                <span>CopyLink</span>
              </a>
              <a href="#">
                <img style={{width: "95px"}} src={Images.Sharewhtspp} />
                <span>Whatsapp</span>
              </a>
            </div>
            <div style={{margin: "15px 0" }} className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img style={{width: "95px"}} src={Images.Sharemessage} />
                <span>Email</span>
              </a>
            </div>
            <div style={{margin: "15px 0" }} className={Modalstyle.modalIngredientsshareInner}>
              <a href="#">
                <img style={{width: "95px"}} src={Images.ShareSnap} />
                <span>Snapchat</span>
              </a>
              <a href="#">
                <img style={{width: "95px"}} src={Images.Insta} />
                <span>Instagram</span>
              </a>
            </div>
          </div>
          </div>
      </Modal>

          </div>
       )
};
export default ModalStoryIngrediaentsNew;