import api from '../ApiService/Apicall'
import { APP_SERVER_URL, APP_URL } from '../config'

const fetch = require('node-fetch')

const senderConfig = {
    "email": "natasha@guppytheatre.com",
    "name": "Guppy"
}

const SEND_MAIL_URL = 'https://api.sendinblue.com/v3/smtp/email'
const SMTP_API_KEY = "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"

const mailHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json",
}

const sendMailAsync = (payload) => new Promise(async (resolve, reject) => {
    try {
        let response = await fetch(SEND_MAIL_URL, {
            method:"post",
            headers: {
                ...mailHeaders,
                'api-key': SMTP_API_KEY
            },
            body: JSON.stringify(payload),
        })
        response = await response.json()
        resolve(response)
        return response;
    } catch (error) {
        reject(error)
    }
})

let defaultSubject = "Welcome to Guppy"

export const sendWelcomeEmail = ({ email, name, userId, subject = defaultSubject, body, payload = {} }) => {
    let mailContent = `<div>
    Dear user,<br />
    Welcome aboard on Guppy<br />
    <a href="${APP_URL}/user/consent-pdf?email=${email}">View Consent PDF</a>
    </div>`

    let data = {
        "sender": senderConfig,
        "subject": subject,
        "htmlContent": mailContent,
        "messageVersions": [
            {
                "to": [
                    { email, name }
                ]
            }
        ],
        // attachment: [
        //     {
        //         url: `${APP_URL}/user/consent-pdf?email=${email}`,
        //         name: 'User-Signup-Consent.pdf'
        //     }
        // ]
    }
    sendMailAsync(data).then(res => {
        console.debug('Mail response:', { res })
    }).catch((e) => {
        console.warn('Error in mail send: ', e)
    })
}

export const sendWelcomeEmailAction = async (user) => {
    try {
        // const user = await fetch('')
        // const { data: user } = api("get",`users/me`,{},'toke')
        // console.info({ user })
        sendWelcomeEmail({
            userId: user.id,
            name: user.name,
            email: user.email,
            age: user.age,
            birthDate: user.Birthday,
            parentName: user.ParentName,
            relationWithChild: user.RelationwithChild,
        })
    } catch (error) {
        console.warn(error)
    }
}
