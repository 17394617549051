import React, { useEffect, useState } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import Logining from "../../component/Login";
import Images from "../../component/Images";
import api from "../../ApiService/Apicall";
import { useNavigate } from "react-router-dom";


const PasswordReset = () => {
  const navigate=useNavigate();
const [loading,setloading]=useState(true);
const [Code,setCode]=useState('');
const [password,setpassword]=useState('');
const [confirmpassword,setconfirmpassword]=useState('');
  useEffect(()=>{
    // console.log(window?.location?.href?.split("=")[1],"--Previous url---");
    if(!!window?.location?.href?.split("=")[1]){
      setCode(window.location.href.split("=")[1]);
      setloading(false);
    }
  },[])

  const sendforgetPassowrd=async()=>{
    setloading(true);
    await api('post','auth/reset-password',{'code':Code,password:password,passwordConfirmation:confirmpassword}).then(res=>{
      navigate('/PasswordUpdatedScreen');
    },err=>{
      console.log(err)
      alert('Something went wrong..!');
    })
  }

  if (!loading)
  return (
    <div className={styles.body}>
      <Header />
      <div className={styles.loginBg}>
      <div className={styles.arrowImgMain}>
        <div className={styles.signLogRightLine}>
          <img src={Images.signLogRightLine} />
        </div>
        <div className="container">
          <div className={`row ${styles.signUpInfo}`}>
            <div className={`col-sm-12 col-md-6 ${styles.leftInfo}`}>
              <img src={Images.MuniaMiddle} alt="" />
              <h5>Forget Password</h5>
              <p>Log in with one of the options below</p>
              <div className={styles.socialIcon}>
                <a href="#">
                  <img src={Images.googleSocial} />
                </a>
                {/* <a href="#">
                  <img src={Images.facebook} />
                </a> */}
              </div>
            </div>
            <div className={`col-sm-12 col-md-6 ${styles.rightInfo}`}>
              <form action="#">
                <div className={styles.wrapdiv}>
                  <label for="email">Password</label>
                  <input
                    type="email"
                    name="email"
                    onChange={(event) => {
                      console.log(event.target.value);
                      setpassword(event.target.value)
                    }}
                    required
                  />
                </div>
                <div className={styles.wrapdiv}>
                  <label for="psw">Confirm Password</label>
                  <input
                    type="password"
                    name="psw"
                    onChange={(event) => {
                      setconfirmpassword(event.target.value)
                    }}
                    required
                  />
                </div>
                {/* <div className={styles.forgerPass}>
                  <a href="#">Forgot Password?</a>
                </div> */}
                <div className={styles.btndiv}>
                  <button
                    type="submit"
                    className={styles.btnFill}
                    onClick={(e) => {
                      e.preventDefault();
                      if(password===confirmpassword){
                        sendforgetPassowrd();
                      }else{
                        alert((confirmpassword.length==0 || password.length==0)?"Password is a required field":"Error! Password Do not match")
                      }
                    
                    }}
                  >Send</button>
                </div>
                {/* <div className={styles.againreg}>
                  <p>Don’t have an account?</p> <a href="/signUp"> Register</a>
                </div> */}
              </form>
            </div>
          </div>
        </div>
        <div className={styles.signBgLeft}>
          <img src={Images.signupbg} />
        </div>
      </div>
    </div>
    </div>
  );
  else
  return (
    <div className={styles.body}>
    <div className={styles.LoadingMain}
      style={{
        alignItems: "center",
        backgroundColor: "#F2DD4E",
        top: 0,
        left: 0,
        bottom: 0,
        position: "absolute",
        justifyContent: "center",
        flex: 1,
        flexDirection: "column",
        display: "flex",
        right: 0,
      }}
    >
      <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
    </div>
  </div>
  );
};

export default PasswordReset;
