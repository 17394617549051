// export const baseURL = "https://guppy.thinkbar.tech/api/";
export const baseURL = "https://manage.guppytheatre.com/api/";

export default async (uri, dataSend, comment, token) => {
  let header;
  if (token != undefined) {
    header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  } else {
    header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }
  return new Promise((resolve, reject) => {
    // console.log( baseURL + uri)
    fetch(baseURL + uri, {
      method: "post",
      headers: header,
      body: JSON.stringify(dataSend),
    })
      .then((response) => response.json())
      .then((res) => {
        // if(comment!=undefined){
        //   console.log(uri,"=================",JSON.stringify(dataSend), "=================" + comment + "=================");
        //   console.log(res, "=================" + comment + "=================");
        // }

        resolve(res);
      })
      .catch((error) => {
        // console.log(error, "=================" + comment + "================="+JSON.stringify(dataSend));
        reject(error);
      });
  });
};
