import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';

function BSHyperLinkPriviousNext(){
    const handleScrollToTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    return(
        <div className={BsCustom.BSHyperLinkPriviousNextWrapper}>
            <div className="container">
                <div className={BsCustom.BSHyperLinkPriviousNextMain}>
                    <a href="/LandingPageBs" className="ReadMoreCmn"><img style={{marginRight: "15px"}} src={Images.CurveArrowLeft} /><span>Previous IP</span></a>
                    <a href="#" onClick={handleScrollToTop}  className="ReadMoreCmn" style={{color: '#0D5268', textDecorationLine: 'underline'}}>Back to Top</a>
                    <a href="/MalluAndPi" className="ReadMoreCmn"><span>Next IP</span><img style={{marginLeft: "15px"}} src={Images.CurveArrowRight} /></a>
                </div>
            </div>
        </div>
    );
}

export default BSHyperLinkPriviousNext;