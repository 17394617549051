import React from "react";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
import * as moment from "moment";

function Submission_Submitted({ item, index }) {
  return (
    <div className={styles.MySubmissionInfo}>
        
      {item.type != "ingredient" && item.attributes.Status == "Submitted" && (
        <div
          className={`${styles.MySubmissiondetail_story} ${styles.MySubmissiondetail_publish} ${styles.MySubmissiondetail} ${index%2!=0&&styles.leftalign}`}
        >
          <a href="#" className={styles.MySubmissionInfoInner}>
            <a href="#" className={styles.tick}>
              <img src={Images.blueSingleTick} />
            </a>
            <h3
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                marginLeft: "10%",
              }}
            >
              {item.attributes?.Title}
            </h3>
            <p>{item.attributes?.users_id?.data?.attributes?.username}</p>
            <h4>SUBMITTED...</h4>
            <p>{moment(item.attributes.createdAt).format("Do MMMM,YYYY")}</p>
          </a>
        </div>
      )}

      {item.type != "ingredient" && item.attributes.Status == "Published" && (
        <div
          className={`${styles.MySubmissiondetail_story} ${styles.MySubmissiondetail_publish} ${styles.MySubmissiondetail} ${index%2!=0&&styles.leftalign}`}
        >
          <a href="#" className={styles.MySubmissionInfoInner}>
            <a href="#" className={styles.tick}>
              <img src={Images.yellowSingleTick} />
            </a>
            <h3
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                marginLeft: "10%",
              }}
            >
              {item.attributes?.Title}
            </h3>
            <p>{item.attributes?.users_id?.data?.attributes?.username}</p>
            <h4 className={styles.MySubmissionInfo_publish}>PUBLISHED</h4>
            <p>{moment(item.attributes.createdAt).format("Do MMMM,YYYY")}</p>
          </a>
        </div>
      )}

       {item.type == "ingredient" &&
        item.attributes.ingredient_status == "Published" && (
          <div
            className={`${styles.MySubmissiondetail_Ingredients} ${styles.MySubmissiondetail_submit} ${styles.MySubmissiondetail} ${index%2!=0&&styles.leftalign}`}
          >
            <a href="#" className={styles.MySubmissionInfoInner}>
              <a href="#" className={styles.tick}>
                <img src={Images.yellowSingleTick} />
              </a>
              <h3 style={{textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap",marginLeft:"10%",
              marginRight:"10%",marginTop:"4%",
              marginRight:"10%",marginTop:"4%"}}>{item.attributes?.ingredient_keyword_short_description}</h3>
                <p>{item.attributes?.users_permissions_user?.data?.attributes?.username}</p>
              <h4 className={styles.MySubmissionInfo_publish}>PUBLISHED</h4>
              <p>{moment(item.attributes.createdAt).format("Do MMMM,YYYY")}</p>
            </a>
          </div>
        )}

      {item.type == "ingredient" &&
        item.attributes.ingredient_status == "Read" && (
          <div
            className={`${styles.MySubmissiondetail_Ingredients} ${styles.MySubmissiondetail_read} ${styles.MySubmissiondetail} ${index%2!=0&&styles.leftalign}`}
          >
            <a href="#" className={styles.MySubmissionInfoInner}>
              <a href="#" className={styles.tick}>
                <img src={Images.blueDoubleTick} />
              </a>
              <h3 style={{textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap",marginLeft:"10%",
              marginRight:"10%",marginTop:"4%",
              marginRight:"10%",marginTop:"4%"}}>{item.attributes?.ingredient_keyword_short_description}</h3>
                <p>{item.attributes?.users_permissions_user?.data?.attributes?.username}</p>
              <h4>READ...</h4>
              <p>{moment(item.attributes.createdAt).format("Do MMMM,YYYY")}</p>
            </a>
          </div>
        )}

      {item.type == "ingredient" &&
        item.attributes.ingredient_status == "Submitted" && (
          <div
            className={`${styles.MySubmissiondetail_Ingredients} ${styles.MySubmissiondetail_read} ${styles.MySubmissiondetail} ${index%2!=0&&styles.leftalign}`}
          >
            <a href="#" className={styles.MySubmissionInfoInner}>
              <a href="#" className={styles.tick}>
                <img src={Images.blueSingleTick} />
              </a>
              <h3 style={{textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap",marginLeft:"10%",
              marginRight:"10%",marginTop:"4%"}}>{item.attributes?.ingredient_keyword_short_description}</h3>
                <p>{item.attributes?.users_permissions_user?.data?.attributes?.username}</p>
              <h4>SUBMITTED...</h4>
              <p>{moment(item.attributes.createdAt).format("Do MMMM,YYYY")}</p>
            </a>
          </div>
        )}

      {item.type != "ingredient" && item.attributes.Status == "Read" && (
        <div
          className={`${styles.MySubmissiondetail_story} ${styles.MySubmissiondetail_submit} ${styles.MySubmissiondetail} ${index%2!=0&&styles.leftalign}`}
        >
          <a href="#" className={styles.MySubmissionInfoInner}>
            <a href="#" className={styles.tick}>
              <img src={Images.blueDoubleTick} />
            </a>
            <h3
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                marginLeft: "10%",
              }}
            >
              {item.attributes?.Title}
            </h3>
            <p>{item.attributes?.users_id?.data?.attributes?.username}</p>
            <h4>Read...</h4>
            <p>{moment(item.attributes.createdAt).format("Do MMMM,YYYY")}</p>
          </a>
        </div>
      )}

    </div>
  );
}

export default Submission_Submitted;
