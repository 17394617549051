import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';

function SellProductBs(){
    const static_data = [
        { 
            backimg: Images.ThePalaceStory, 
            MainText: 'The Maharaja of Mastipur & The Palace of Pranks', 
            NewImg: Images.Books,
            PlusBSSignImg: Images.ShareSignBS
        },
        { 
            backimg: Images.ThePetTiger, 
            MainText: 'The Maharaja of Mastipur & The Pet Tiger', 
            NewImg: Images.Books,
            PlusBSSignImg: Images.PlusBSSign
        },
        { 
            backimg: Images.AllStoryOneFame, 
            MainText: 'Card Game Title', 
            NewImg: Images.Books,
            PlusBSSignImg: Images.ShareSignBS
        },
        { 
            backimg: Images.ThePetTiger, 
            MainText: 'The Maharaja of Mastipur & The Pet Tiger', 
            NewImg: Images.Books,
            PlusBSSignImg: Images.PlusBSSign
        },
        { 
            backimg: Images.AllStoryOneFame, 
            MainText: 'Card Game Title', 
            NewImg: Images.Games,
            PlusBSSignImg: Images.ShareSignBS
        },
        { 
            backimg: Images.ThePalaceStory, 
            MainText: 'The Maharaja of Mastipur & The Palace of Pranks', 
            NewImg: Images.Books,
            PlusBSSignImg: Images.PlusBSSign
        },

    ];
    return(
        <div className={BsCustom.SellProductMain}>
        <div className={BsCustom.CurrentIpsSection}>
            <div className="container-fluid">
                <div className="row">
                    {static_data.map((e) => {
                        return (
                            <div className={`col-sm-12 col-md-4 ${BsCustom.CurrentIpsInner}`}>
                                <img className={BsCustom.ipsBackImg} src={e.backimg} />
                                <img className={BsCustom.currentIpsNew} src={e.NewImg} />
                                <div className={BsCustom.SellProductPara}>
                                    <p className={BsCustom.NewparaSell}>{e.MainText}</p>
                                    <img className={BsCustom.SharePlusSign} src={e.PlusBSSignImg} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
        </div>
    );
}

export default SellProductBs;