import React, {
  Component,
  useImperativeHandle,
  useRef,
  forwardRef,
  useState,
  useEffect,
} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Images from "./Images";
import styles from "../pages/Home/landingpage.module.css";
// import { useSettergetter } from "../otherexports";
// export className Owldemo1 extends Component {
const Owldemo1 = forwardRef(({ otherdata}, ref) => {
  const owlcrosel = useRef(null);
  // const [indexing,setindexing]=useState(1)
  // const [val,setval]=useState(1)



  useImperativeHandle(ref, () => ({
    next: () => {
      owlcrosel.current.next();
      // return counts;
    },
    Prev: () => {
      owlcrosel.current.prev();
      // return counts;
    }
  }));

  // const changeing=(e)=>{
  //   setindexing(e.item.index);
  //   var num =e.item.index+1;
  //   if(num!=undefined){
  //     setval((prevVal)=>{
  //       console.log(val,"==========eeee",num)
  //       if(val!=num){
  //         return num;
  //       }
  //     })
  //   }
  
  // }

  return (
    <div className={`container-fluid ${styles.banner_container}`}>
      <OwlCarousel
        items={1}
        className="owl-theme"
        loop
        center={true}
        // startPosition={indexing}
        dots={false}
        ref={owlcrosel}
        // onTranslated={changeing}
       >
        {otherdata?.map((e, i) => {
          return (
            <div  className={`container-fluid ${styles.banner_container}`} key={i} >
              <div className={styles.bannerSlideCar}>
                <div className={`row ${styles.owlSlideText}`}>
                  <div
                    className={`col-sm-12 col-md-12 col-lg-12 ${styles.bannerLeft}`}
                  >
                   {e?.attributes?.Website_banner?.data?.attributes?.url!=undefined &&<img src={"https://manage.guppytheatre.com" + e?.attributes?.Website_banner?.data?.attributes?.url} alt="image"/>}
                    {/* <img src={Images.BannerBg} /> */}
                    {/* <div className={styles.whtsNew}>
                      <img src={Images.whtsNew} />
                    </div> */}
                  </div>
                  {/* <div
                    className={`col-sm-12 col-md-12 col-lg-4 ${styles.bannerRightText}`}
                  >
                    <h1>{e.attributes.Title}</h1>
                    <p>{e.attributes.description}</p>
                    <button className={styles.btnFill}>{"CTA button here"}</button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </div>
  );
});

export default Owldemo1;
