import React, { createRef, useState } from "react";
import Images from "../../component/Images";
import BsCustom from "../BackStageCss/Backstagelandingpage.module.css";
import styles from "../../pages/Home/landingpage.module.css";
import Modal from "react-modal";
import Modalstyle from "../../pages/StoryPage/storypage.module.css";
import { useNavigate } from "react-router-dom";
import NoDraftModal from "../../component/NoDraftModal";
import subscribeEmailAsync from "../../api/subscripeEmailAsync";

function Footer({ userinfo }) {
  const navigate = useNavigate();
  const [email, setemail] = useState(userinfo?.email || "");
  const [modalIsOpen, setIsOpen] = useState(false);
  const modalopen = createRef(null);
  const [modaltext, setmodaltext] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const submitForm = async () => {
    setIsSubmitting(true);
    try {
      const response = await subscribeEmailAsync({ email: email });
      if (response.id) {
        setIsSubmitting(false);
        openModal();
        setemail("");
      } else {
        setIsSubmitting(false);
        setmodaltext(response.message);
        modalopen.current.show();
      }
    } catch (e) {
      setmodaltext("Something went wrong!");
      modalopen.current.show();
    }
  };

  return (
    <div className={BsCustom.FooterBackstage}>
      <footer style={{ backgroundColor: "transparent" }}>
        <div className={styles.desktopFooter}>
          <div className="container-fluid">
            <div className="row">
              <div
                className={`col-sm-12 col-md-12 col-lg-3 ${styles.footerLeft}`}
              >
                <a href="/Home" className={styles.footerLogo}>
                  <img src={Images.footerLogo} alt="" />
                </a>
                <div className={styles.socialIcon}>
                  <a
                    href="https://www.instagram.com/guppytheatre/"
                    target={"_blank"}
                  >
                    <img src={Images.BsInstagram} alt="" />
                  </a>
                  <a
                    href="https://www.facebook.com/GuppyTheatre1"
                    target={"_blank"}
                  >
                    <img src={Images.BsFacebook} alt="" />
                  </a>
                  <a
                    href={`https://www.youtube.com/@guppytheatre5404`}
                    target={"_blank"}
                  >
                    <img src={Images.BsYoutube} alt="" />
                  </a>
                </div>
              </div>
              <div
                className={`col-sm-12 col-md-12 col-lg-3 ${styles.footerMidLeft}`}
              >
                <p>Download App</p>
                <div
                  className={`${styles.createStoryBottomShare} ${styles.qrdiv}`}
                >
                  <div>
                    <a href="#">
                      <img src={Images.qrcode} />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.guppytheatre.app"
                      target="_blank"
                    >
                      <img
                        src={Images.googleIcon}
                        className={styles.centerImg}
                      />
                    </a>
                    <a href="https://apps.apple.com/in/app/guppy-stories/id6450250856">
                      <img src={Images.appledownload} />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={`col-sm-12 col-md-12 col-lg-3 ${styles.footerMid}`}
              >
                <p>Get in touch</p>
                <div className={styles.contactIcon}>
                  <a
                    href={"whatsapp://send?text=hello&phone=+918928110916"}
                    target={"_blank"}
                  >
                    <img src={Images.BsWhatsapp} alt="" />
                  </a>
                  <a href="mailto:guppy@guppytheatre.com" target={"_blank"}>
                    <img src={Images.BsMessage} alt="" />
                  </a>
                </div>
                <a
                  href="/ContactBackstage"
                  onClick={() => {
                    navigate("/ContactBackstage");
                  }}
                >
                  Contact us
                </a>
                <a
                  href="/PrivacyPolicy"
                  onClick={() => {
                    navigate("/PrivacyPolicy");
                  }}
                >
                  Privacy Policy
                </a>
                <a
                  href="/TermsConditions"
                  onClick={() => {
                    navigate("/TermsConditions");
                  }}
                >
                  Terms and Conditions
                </a>
              </div>
              <div
                className={`col-sm-12 col-md-12 col-lg-3 ${styles.footerRight}`}
              >
                <h4
                  style={{
                    fontFamily: "Kodchasan , sans-serif",
                  }}
                >
                  Subscribe to <br /> our Newsletter
                </h4>
                <p>
                  Stay updated with our latest <br /> release and new
                  developments <br /> once every month.
                </p>
                <div className={`${BsCustom.emailInputBackstage}`}>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email ID"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                  <img
                    src={Images.inputRightArrow}
                    alt=""
                    onClick={() => {
                      if (email.length != 0) {
                        // openModal();
                        var emailcheck =
                          /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                        if (!emailcheck.test(email)) {
                          setmodaltext("Invalid Email Id");
                          modalopen.current.show();
                        } else {
                          submitForm();
                        }
                      } else {
                        setmodaltext("Email is required !");
                        modalopen.current.show();
                      }
                    }}
                  />
                </div>
                {isSubmitting && (
                  <div className="">{"Subscribing Newsletter..."}</div>
                )}
              </div>
            </div>
            <p className={BsCustom.copyrightTextNew}>
              {" "}
              &#169; 2024 Crossover Media and Design Pvt. Ltd. , all rights
              reserved.
            </p>
          </div>
        </div>
        <div className={styles.mobileFooter}>
          <div className="container-fluid">
            <div className="row">
              <div className={`col-6 ${styles.footerLeft}`}>
                <a href="/Home" className={styles.footerLogo}>
                  <img src={Images.footerLogo} alt="" />
                </a>
                <div className={styles.socialIcon}>
                  <a
                    href="https://www.instagram.com/guppytheatre/"
                    target={"_blank"}
                  >
                    <img
                      style={{ width: "25px" }}
                      src={Images.BsInstagram}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/GuppyTheatre1"
                    target={"_blank"}
                  >
                    <img
                      style={{ width: "25px" }}
                      src={Images.BsFacebook}
                      alt=""
                    />
                  </a>
                  <a
                    href={`https://www.youtube.com/@guppytheatre5404`}
                    target={"_blank"}
                  >
                    <img
                      style={{ width: "25px" }}
                      src={Images.BsYoutube}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div
                className={`col-6 ${styles.footerMid} ${BsCustom.footerMid}`}
              >
                <p>Get in touch</p>
                <div className={styles.contactIcon}>
                  <a
                    href={"whatsapp://send?text=hello&phone=+918928110916"}
                    target={"_blank"}
                  >
                    <img
                      style={{ width: "25px" }}
                      src={Images.BsWhatsapp}
                      alt=""
                    />
                  </a>
                  <a href="mailto:guppy@guppytheatre.com" target={"_blank"}>
                    <img
                      style={{ width: "25px" }}
                      src={Images.BsMessage}
                      alt=""
                    />
                  </a>
                </div>
                <a
                  href="/ContactBackstage"
                  style={{ fontSize: "14px", lineHeight: "18px" }}
                >
                  Contact us
                </a>
                <a
                  href="/PrivacyPolicy"
                  style={{ fontSize: "14px", lineHeight: "18px" }}
                >
                  Privacy Policy
                </a>
                <a
                  href="/TermsConditions"
                  style={{ fontSize: "14px", lineHeight: "18px" }}
                >
                  Terms and Conditions
                </a>
              </div>
            </div>
            <div className="row">
              <div
                className={`col-12 ${styles.footerRight}`}
                style={{ textAlign: "center" }}
              >
                <h4
                  style={{
                    fontFamily: "Kodchasan , sans-serif",
                    fontSize: "20px",
                    lineHeight: "26px",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Subscribe to our Newsletter
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  Stay updated with our latest release and new developments once
                  every month.
                </p>
                <div className={`${BsCustom.emailInputBackstage}`}>
                  <input
                    style={{ width: "75%", padding: "0 10px" }}
                    type="text"
                    name="email"
                    placeholder="Email ID"
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                  <img
                    style={{ width: "40px" }}
                    src={Images.inputRightArrow}
                    alt=""
                    onClick={() => {
                      if (email.length != 0) {
                        openModal();
                      } else {
                        setmodaltext("Email is required !");
                        modalopen.current.show();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <p className={BsCustom.copyrightTextNew}>
              {" "}
              &#169; 2020 Crossover Media and Design Pvt. Ltd. , all rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
      <Modal
        style={{
          content: {
            display: "flex",
            backgroundColor: "#0000",
            borderWidth: 0,
            flexDirection: "column",
            flex: 1,
            position: "unset",
            overflow: "auto",
            height: "100%",
            padding: "20px 20px 20px",
            justifyContent: "space-around",
          },
        }}
        overlayClassName={Modalstyle.modalconfirm}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="container">
          <div className={Modalstyle.modalFooterMain}>
            <img
              className={Modalstyle.sharebtnimg}
              src={Images.backbtncross}
              onClick={closeModal}
            />
            <img src={Images.ConfirmedFrame} />
            <h2>
              Confirmed! <br /> You will get an email soon.
            </h2>
            <button onClick={closeModal} className={styles.btnFill}>
              OK!
            </button>
          </div>
        </div>
      </Modal>
      <NoDraftModal ref={modalopen} text={modaltext} />
    </div>
  );
}

export default Footer;
