import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import api from "../ApiService/Apicall";
export const setLocal = (key, val) => {
  localStorage.setItem(key, JSON.stringify(val));
};

export const getLocal = (key) => {
  const data = localStorage.getItem(key) || "{}";
  const info = data ? JSON.parse(data) : "";
  return info;
};

export const setsession = (key, val) => {
  sessionStorage.setItem(key, JSON.stringify(val));
};

export const getsession = (key) => {
  const data = sessionStorage.getItem(key);
  const info = !!data ? JSON.parse(data) : "";
  return info;
};

export const useSettergetter = (initial) => {
  const [value, setValue] = useState(initial);
  useEffect(() => {
    setValue(initial);
  }, [value]);

  return { Singleitem: value };
};

export const useMystory = () => {
  const { data: mystorydata, isLoading } = useQuery(["MyStory"], async () => {
    const response = await api(
      "get",
      "story-submissions?populate[0]=users_id&populate[1]=what_ingredient&populate[2]=what_ingredient.ingredient_type_id&populate[3]=what_ingredient.IngredientImage&populate[4]=who_ingredient&populate[5]=who_ingredient.ingredient_type_id&populate[6]=who_ingredient.IngredientImage&populate[7]=when_ingredient&populate[8]=when_ingredient.ingredient_type_id&populate[9]=when_ingredient.IngredientImage&populate[10]=where_ingredient&populate[11]=where_ingredient.ingredient_type_id&populate[12]=where_ingredient.IngredientImage&populate[13]=packages&populate[14]=packages.PackageImage&populate[15]=packages.ingredients&populate[16]=story_media",
      {},
      "psk"
    );
    return response?.data?.data;
  });

  const { data: Storylistdata, isLoading: Storylistloading } = useQuery(
    ["Storylist"],
    async () => {
      const response = await api(
        "get",
        "guppy-stories?populate[0]=Sections&populate[1]=Sections.StoryImage&populate[2]=Sections.keyword&populate[3]=Sections.keyword.guppy_stories&populate[4]=Sections.keyword.guppy_stories.Sections.StoryImage&populate[5]=Sections.keyword.ingredient_type&populate[6]=story_ingredients&populate[7]=story_ingredients.IngredientImage&populate[8]=story_ingredients.ingredient_type_id&populate[9]=story_ingredients.packages&populate[10]=story_ingredients.users_permissions_users&populate[11]=continue_btn&populate[12]=continue_btn.guppy_stories&populate[13]=continue_btn.guppy_stories.Sections.StoryImage&populate[14]=bottom_banner&populate[15]=bottom_banner.banner_image&populate[16]=user_ids&populate[17]=story_thumbnail&populate[18]=*"
      );
      return response?.data?.data;
    }
  );

  const { data: Ingridient, isLoading: IngridientisLoading } = useQuery(
    ["Ingridientdata"],
    async () => {
      const response = await api("get", "ingredients?populate=*", {}, "psk");
      return response?.data?.data;
    }
  );

  const userinfo = getLocal("User");
  const user_id = userinfo?.id.toString();

  let current_userdata = mystorydata?.filter(
    (e) =>
      e?.attributes?.users_id?.data?.id.toString() == user_id &&
      e?.attributes?.Status == "Published"
  );

  let filterStorys = Storylistdata?.filter((e) =>
    e?.attributes?.user_ids?.data?.some((e) => e?.id?.toString() == user_id)
  );

  return {
    isLoading:
      !IngridientisLoading && !isLoading && !Storylistloading ? false : true,
    data: current_userdata,
    filterStorys: filterStorys,
    Ingridient: Ingridient,
    userinfo: userinfo,
  };
};

export const useMysubmit = () => {
  const { data: mystorydata, isLoading } = useQuery(
    ["MysubmissionsStory"],
    async () => {
      const response = await api(
        "get",
        "story-submissions?populate=users_id&populate[1]=what_ingredient&populate[2]=what_ingredient.ingredient_type_id&populate[3]=what_ingredient.IngredientImage&populate[4]=who_ingredient&populate[5]=who_ingredient.ingredient_type_id&populate[6]=who_ingredient.IngredientImage&populate[7]=when_ingredient&populate[8]=when_ingredient.ingredient_type_id&populate[9]=when_ingredient.IngredientImage&populate[10]=where_ingredient&populate[11]=where_ingredient.ingredient_type_id&populate[12]=where_ingredient.IngredientImage&populate[13]=packages&populate[14]=packages.PackageImage&populate[15]=packages.ingredients&populate[16]=story_media",
        {},
        "psk"
      );
      return response?.data?.data;
    }
  );

  const { data: Ingridient, isLoading: IngridientisLoading } = useQuery(
    ["Ingridientsubmissionsdata"],
    async () => {
      const response = await api(
        "get",
        "ingredient-submissions?populate[0]=ingredient_media&populate[1]=ingredient_type&populate[2]=users_permissions_user",
        {},
        "psk"
      );
      return response?.data?.data;
    }
  );

  const userinfo = getLocal("User");
  const user_id = userinfo?.id?.toString();

  let userstory = mystorydata
    ?.filter((e) => e?.attributes?.users_id?.data?.id.toString() == user_id)
    .map((n) => {
      return { ...n, type: "Story" };
    });
  let useringredient = Ingridient?.filter(
    (e) => e?.attributes?.users_permissions_user?.data?.id.toString() == user_id
  ).map((n) => {
    return { ...n, type: "ingredient" };
  });

  return userinfo.name != undefined
    ? {
        isLoading: !IngridientisLoading && !isLoading ? false : true,
        useringredient: useringredient,
        userstory: userstory,
      }
    : {
        isLoading: false,
        useringredient: [],
        userstory: [],
      };
};

export const useInsidePack = () => {
  const { data, isLoading } = useQuery(["MystoryPackinside"], async () => {
    const response = await api("get", "ingredients?populate=*", {}, "psk");
    return response?.data?.data;
  });
  const userinfo = getLocal("User");
  // const user_id = userinfo?.id.toString();
  let ingredientids = userinfo?.ingredients?.map((e) => {
    return e?.id;
  });
  let current_userdata = data?.filter(
    (e) =>
      ingredientids?.includes(e.id) || e.attributes.default_ingredient == true
  );

  return {
    isLoading,
    data: current_userdata,
  };
};

export const useAvtar = () => {
  const userinfo = getLocal("User");
  const { data, isLoading } = useQuery(["MyAvtar"], async () => {
    if (userinfo?.name != undefined) {
      const otherresponse = await api(
        "get",
        "guppy-avtars?populate[0]=guppy_avtar&populate[1]=guppy_avtar.avtar_media&populate[2]=user",
        undefined,
        "psk"
      );
      let otherinfo = otherresponse?.data?.data?.filter(
        (e) =>
          e?.attributes?.user?.data?.id.toString() == userinfo?.id?.toString()
      );
      return otherinfo[0];
    } else {
      const response = await api("get", "guppy-avtar-lists?populate=*");
      let defalutone = response?.data?.data?.filter(
        (e) => e?.attributes?.default == true
      );
      return defalutone[0];
    }
  });
  return {
    isLoading,
    data: data,
  };
};

export const useGetdata = () => {
  const userinfo = getLocal("User");
  const { data, isLoading } = useQuery(["packageslist"], async () => {
    const response = await api("get", "packages?populate=*");
    return response.data.data;
  });

  const { data: ingridentdata, isLoading: ingridentloading } = useQuery(
    ["ingredientslist"],
    async () => {
      let ingredientids = userinfo?.ingredients?.map((e) => {
        return e?.id;
      });
      const response = await api("get", "ingredients?populate=*");
      return response.data.data.filter(
        (e) =>
          ingredientids?.includes(e.id) ||
          e.attributes.default_ingredient == true
      );
    }
  );

  if (data != undefined && ingridentdata != undefined) {
    return {
      isLoading: !ingridentloading && !isLoading ? false : true,
      Packagelist: data,
      ingridentlist: ingridentdata,
    };
  } else {
    return {
      isLoading: true,
      Packagelist: [],
      ingridentlist: [],
    };
  }
};

export const useBackstagehome = () => {
  const { data, isLoading } = useQuery(["Backstagehome"], async () => {
    const response = await api(
      "get",
      "home-backstages?populate[banner][populate][0]=banner_img&populate[who_we_are_media][populate][1]=data&populate[welcome_media][populate][2]=data&populate[banner][populate][3]=data&populate[guppy_stories_media][populate][3]=data",
      undefined
    );
    return response?.data?.data[0];
  });
  return {
    isLoading,
    home: data,
  };
};

export const useBackstagewhatwodo = () => {
  const { data, isLoading } = useQuery(["Backstagewhatwodo"], async () => {
    const response = await api(
      "get",
      "backstage-what-wo-dos?populate[0]=backstage_stories&populate[2]=backstage_stories.banner_media",
      undefined
    );
    return response?.data?.data[0];
  });
  return {
    isLoading,
    data: data,
  };
};

export const useBackstagewhoweare = () => {
  const { data, isLoading } = useQuery(["useBackstagewhoweare"], async () => {
    const response = await api(
      "get",
      "backstage-who-we-ares?populate=*",
      undefined
    );
    return response?.data?.data[0];
  });
  return {
    isLoading,
    data: data,
  };
};

export const useBackstagewhatwesell = () => {
  const { data, isLoading } = useQuery(["useBackstagewhatwesell"], async () => {
    const response = await api("get", "what-we-sells?populate=*", undefined);
    return response?.data?.data[0];
  });
  return {
    isLoading,
    data: data,
  };
};

export const useBackstagenews = () => {
  const { data, isLoading } = useQuery(["Backstagenews"], async () => {
    const response = await api(
      "get",
      "Backstage-news?populate[0]=News.news_media&populate[1]=*",
      undefined
    );
    return response?.data?.data;
  });
  return {
    isLoading,
    data: data,
  };
};

export const useGetStoryFlow = () => {
  const { data, isLoading } = useQuery(["GetStoryFlow"], async () => {
    const response = await api(
      "get",
      "guppy-story-flows?populate[0]=users_permissions_user",
      undefined,
      "psk"
    );
    const userinfo = getLocal("User");
    // console.log(JSON.stringify(response?.data?.data?.filter((e)=>e?.attributes?.users_permissions_user?.data?.id?.toString()==userinfo?.id?.toString())),"===psk==change")
    return response?.data?.data?.filter(
      (e) =>
        e?.attributes?.users_permissions_user?.data?.id?.toString() ==
        userinfo?.id?.toString()
    );
  });
  return {
    isLoading,
    data: data,
  };
};
