import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import BannerBs from "../Component/BannerBs";
import HeaderBackstageNew from "../../component/HeaderBackstageNew";
import CurrentIps from "../Component/CurrentIps";
import NewsLetterBS from "../Component/NewsLetterBS";
import GuppyStoriesBackstage from "../Component/GuppyStoriesBackstage";
import FooterBs from "../Component/FooterBs";
import BannerCommon from "../Component/BannerCommon";
import Loader from "../Component/Loader";
import { useBackstagehome } from "../../otherexports";


function GuppyIntroduction(){
    const {isLoading,home} =useBackstagehome();
    if(!isLoading){
        return(
            <section className={BsCustom.WrapperGuppyIntro}>
                <HeaderBackstageNew />
                <BannerCommon/>
                <section className={`${BsCustom.GIMain} ${BsCustom.TeamDarkBCGroung}`}>
                    <h1 className={BsCustom.BsH1}>Introducing Guppy</h1>
                    <p className={`${BsCustom.BsCommaonP} ${BsCustom.paraTop}`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,  <br/> sed do eiusmod tempor. Lorem ipsum dolor sit amet, <br/> consectetur adipiscing elit,  sed do eiusmod tempor.</p>
                    <img src={Images.GuppyBSCutImage} />
                    <p className={`${BsCustom.BsCommaonP} ${BsCustom.paraBottom}`}>In this season Narad travels to the strange, natural phenomena of the
                    world and discovers <br/>that SCIENCE AND MYTHOLOGY offer different
                    viewpoints for the same phenomena.
                    In this <br/> season Narad travels to the strange, natural phenomena of the
                    world and discovers that <br/> SCIENCE AND MYTHOLOGY offer different
                    viewpoints for the same phenomena.
                    In this season <br/> Narad travels to the strange, natural phenomena of the
                    world and discovers that SCIENCE <br/> AND MYTHOLOGY offer different
                    viewpoints for the same phenomena.
                    </p>
                </section>
                <FooterBs/>
            </section>
        ); 
    }
    else 
    {return (<Loader />)}
}

export default GuppyIntroduction;