import React from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";

// function StoryPageNavigation() {
//   const static_data = [
//     { img1: Images.StorypageNavFill, img2: Images.StorypageNavline, img3: Images.StorypageNavUnFill, img4: Images.StorypageNavHover},
//     { img1: Images.StorypageNavFill, img2: Images.StorypageNavline, img3: Images.StorypageNavUnFill, img4: Images.StorypageNavHover},
//     { img1: Images.StorypageNavFill, img2: Images.StorypageNavline, img3: Images.StorypageNavUnFill, img4: Images.StorypageNavHover},
//     { img1: Images.StorypageNavFill, img2: Images.StorypageNavline, img3: Images.StorypageNavUnFill, img4: Images.StorypageNavHover},
//     { img1: Images.StorypageNavFill, img2: Images.StorypageNavline, img3: Images.StorypageNavUnFill, img4: Images.StorypageNavHover},
//   ];

//   return (
//     <div className={styles.storynavigatemain}>
//       <div className="container"> 
//         {static_data.map((e) => {
//             return (
//               <div className={styles.storynavigatemainInner}>
//                 <img src={e.img1} />
//                 <img src={e.img2} />
//                 <img src={e.img3} />
//                 <img src={e.img4} />
//               </div>
//             );
//         })}
//         <p>1/5</p>
//       </div>
//     </div>
//   );
// }


const StoryPageNavigation = ({data, index, last, current}) => {
  return (
    <div className={styles.storynavigatemain} >
        {/* <div className="container">  */}
          <div className={styles.storynavigatemainInner}>
            {data.visible && index != current &&<img src={Images.StorypageNavFill} />}
            {data.visible && index == current &&<div className={`${styles.active}`}><img className={`${styles.StorypageNavFill}`} src={Images.StorypageNavActive} /></div>}
           {!data.visible && <img src={Images.StorypageNavUnFill} />}
           {index != last &&<img src={Images.StorypageNavline} />}
          </div>
            {/* <p>1/5</p> */}
        {/* </div> */}
    </div>
  );
};

export default StoryPageNavigation;
