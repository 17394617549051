import React, { useEffect, useState, createRef } from "react";
import styles from "../Home/landingpage.module.css";
import Header from "../../component/Header";
import Logining from "../../component/Login";
import Images from "../../component/Images";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signupapi } from "../../redux/userdata/user.actions";
import SubmittedSuccessfullyModal from "../../component/SubmittedSuccessfullyModal";
import NoDraftModal from "../../component/NoDraftModal";
import { sendWelcomeEmail } from "../../api/sendWelcomeEmail";


const Otp = () => {
const [modaltext, setmodaltext] = useState('');
const modalopentwo=createRef(null);
const modalopen=createRef(null);
const [loading,setloading]=useState(true);
const [verifycode,setverifycode]=useState("")
const location = useLocation();
const navigate=useNavigate();
const dispatch=useDispatch();
  useEffect(()=>{setloading(false)},[])


const registring=()=>{
  if(verifycode.length==0){
    // alert("Otp field is required !");
    setmodaltext("Otp field is required !")
    modalopen.current.show()
  }else{
  if(location.state.otp == verifycode){
    const data = location.state.data
    dispatch(
      signupapi({
        datasend: data,
        onSuccess: (res) => {
          navigate("/home");
          sendWelcomeEmail({
            email: data.email,
            name: data.name,
            payload: {
              childName: data.name,
              parentName: data.parentName,
              birthDate: data.Birthday,
              date: (new Date).toLocaleDateString(),
            }
          })
        },
        onFaliure: (res) => {
          // alert(res?.error?.name);
          setmodaltext(res?.error?.name)
            modalopen.current.show()
        },
      }))
  }

  }
}


  if (!loading)
  return (
    <div className={styles.body}>
      <Header />
      <div className={styles.loginBg}>
      <div className={styles.arrowImgMain}>
        <div className={styles.signLogRightLine}>
          <img src={Images.signLogRightLine} />
        </div>
        <div className="container">
          <div className={`row ${styles.signUpInfo}`}>
            <div className={`col-sm-12 col-md-6 ${styles.leftInfo}`}>
              <img src={Images.MuniaMiddle} alt="" />
              <h5>Sign Up</h5>
              <p>Log in with one of the options below</p>
              <div className={styles.socialIcon}>
                <a href="#">
                  <img src={Images.googleSocial} />
                </a>
                {/* <a href="#">
                  <img src={Images.facebook} />
                </a> */}
              </div>
            </div>
            <div className={`col-sm-12 col-md-6 ${styles.rightInfo}`}>
              <form action="#">
                <div className={styles.wrapdiv}>
                  <label for="otp">OTP</label>
                  <input
                    type="top"
                    name="otp"
                    onChange={(event) => {
                      // console.log(event.target.value);
                      setverifycode(event.target.value)
                    }}
                    required
                  />
                </div>
                {/* <div className={styles.wrapdiv}>
                  <label for="psw">Password</label>
                  <input
                    type="password"
                    name="psw"
                    onChange={(event) => {
                      userlogin.password = event.target.value;
                      setuserlogin({ ...userlogin });
                    }}
                    required
                  />
                </div> */}
                {/* <div className={styles.forgerPass}>
                  <a href="#">Forgot Password?</a>
                </div> */}
                <div className={styles.btndiv}>
                  <button
                    type="submit"
                    className={styles.btnFill}
                    onClick={(e) => {
                      e.preventDefault();
                      registring()
                    }}
                  >Verify</button>
                </div>
                {/* <div className={styles.againreg}>
                  <p>Don’t have an account?</p> <a href="/signUp"> Register</a>
                </div> */}
              </form>
            </div>
          </div>
        </div>
        <div className={styles.signBgLeft}>
          <img src={Images.signupbg} />
        </div>
      </div>
    </div>
    </div>
  );
  else
  return (
    <div className={styles.body}>
        <div className={styles.LoadingMain}
          style={{
            alignItems: "center",
            backgroundColor: "#F2DD4E",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            display: "flex",
            right: 0,
          }}
        >
          <img style={{width: '500px'}} src={Images.LoaderGIFweb} />
        </div>
      </div>
  );
};

export default Otp;
