import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';

function BackStageMaharaja(){
    return(
        <div className={BsCustom.BackStageMaharajaMain}>
            <div className={BsCustom.BackStageMaharajaMainTop}>
                <img className={BsCustom.MaharajaBannerImg} src={Images.MaharajaImage} />
                <img className={BsCustom.MobileMaharajaImageNew} src={Images.MobileMaharajaImage} />
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="container-fluid">
                <div className={BsCustom.BackStageMaharajaMainBottom}>
                    <p>In this season Narad travels to the strange, natural phenomena of the
                    world and discovers that SCIENCE AND MYTHOLOGY offer different
                    viewpoints for the same phenomena. No one knows
                    when one could show up and it could contain a
                    beautiful new Dusenberg.</p>
                    <div className={`row ${BsCustom.BackStageMaharajaRightRow}`}>
                        <div className={`col-md-6 ${BsCustom.BackStageMaharajaLeft}`}>
                            <h3>The Maharaja’s “little brother”  <br/>  returns from his
                            world tour with  <br/> shipping crates full of  <br/> “sophisticated” souvenirs of his  <br/> travels. Not all
                            the shipping crates  <br/> arrive at once. No one knows
                            when <br/>  one could show up.</h3>
                        </div>
                        <div className={`col-md-6 ${BsCustom.BackStageMaharajaRight}`}>
                            <img src={Images.CommonBackstageImg}/>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.dolor sit amet, <br/>  consectetur adipiscing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BackStageMaharaja;