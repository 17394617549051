import { combineReducers } from 'redux';


// import Testing from './Testing/tester.reducer';
import Homedata from './homedata/home.reducer';
import Userdata from './userdata/user.reducer';
import MyStoryflow from './mystorydata/mystorydata.reducer';
const rootReducer = combineReducers({
    // testing: Testing,
    home_data:Homedata,
    user_data:Userdata,
    MyStoryflow:MyStoryflow
});

export default rootReducer;