import React from "react";
import Images from '../../component/Images';
import BsCustom from '../../Backstage/BackStageCss/Backstagelandingpage.module.css';
import { useBackstagewhatwodo } from "../../otherexports";


function BshyperLinkStoryTitle({item}){
    return(
        <div className={BsCustom.BshyperLinkStoryTitleMain}>
            <div className="container">
                <div className={BsCustom.BSHyperLinkPriviousNextMain}>
                    <a href="#" className="ReadMoreCmn"><img src={Images.CurveArrowLeft} /><span>Previous <br/> article title</span></a>
                    <a href="#"><h1 className={BsCustom.BsH1}>{item?.attributes?.title}</h1></a>
                    <a href="#" className="ReadMoreCmn"><span>Next <br/> Article title</span><img src={Images.CurveArrowRight} /></a>
                </div>
            </div>
        </div>
    );
}

export default BshyperLinkStoryTitle;