import React, {
  Component,
  useImperativeHandle,
  useRef,
  forwardRef,
  useState,
  useEffect,
} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import Images from '../../component/Images';
import BsCustom from "../../Backstage/BackStageCss/Backstagelandingpage.module.css";
import { url } from "../../utils";


function BannerBs({ data }) {
  return (
    <div className={`${BsCustom.bannerSlider}`}>
      <OwlCarousel
        items={1}
        className="owl-theme"
        loop={true}
        center={true}
        dots={true}
        autoplay={true}
      >
        {data.map(({ banner_img: { data: { attributes } }, link }) => {
          return (
            <div className={`row`}>
              <div className={`col-sm-12 col-md-12 col-lg-12`}>
                <img
                  className={BsCustom.SliderImg}
                  src={url(attributes?.formats?.large?.url ?? attributes?.url)}
                  alt={attributes.alternativeText}
                  onClick={() => {
                    window.open(link, "_self");
                  }}
                />
                <img
                  className={BsCustom.MobileBannerBackstageImg}
                  src={url(attributes?.formats?.small?.url)}
                  alt={attributes.alternativeText}
                  onClick={() => {
                    window.open(link, "_self");
                  }}
                />
                {/* {JSON.stringify({ e })} */}
                {/* <div className={`${BsCustom.bannerSliderBtn}`}>
                    <button className={BsCustom.BsComYellowBtn}>Read The Story</button>
                </div>  */}
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </div>
  );
}

export default BannerBs;
