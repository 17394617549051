// import React from "react";
// import styles from "../pages/Home/landingpage.module.css";
import { useEffect, useMemo, useState } from "react";
// import { signupapi } from "../redux/userdata/user.actions";
// import { useDispatch, useSelector } from "react-redux";
// import api from "../ApiService/Apicall";
// import NoDraftModal from "./NoDraftModal";
// import SubmittedSuccessfullyModal from "./SubmittedSuccessfullyModal";
import { APP_SERVER_URL } from '../config'

import React from 'react';
import { getLocal } from "../otherexports";
import axios from "axios";
import { PDFViewer } from "@react-pdf/renderer";
import ParentConsentFormNew from "./ParentConsentFormNew";
import { useLocation } from "react-router-dom";


const ParentConsentFormMain = () => {
  const [ error, setError ] = useState(null)
  const [ data, setData ] = useState(null)
  const { search } = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(search), [ search ])
  const Userdata = getLocal("User");
  // const qParams = useRout
  useEffect(() => {
    let userEmail = queryParams.get('email')
    if (Userdata) {
      userEmail = Userdata.email
    }
    if(userEmail) {
      axios.get(APP_SERVER_URL + `/api/app/users/${userEmail}`).then(res => {
        if (res.data?.ok) {
          setData(res.data?.data)
        } else {
          alert("Invalid user")
          setError(res.data)
        }
      })
    }
  }, [ queryParams ])
  if (!data) {
    return (
      <>
      Loading...
      </>
    )
  }
  return (
    <>
      {/* {JSON.stringify({ queryParams: queryParams.get('email') })} */}
    <PDFViewer style={{width: '100%', height: '100vh'}}>
      <ParentConsentFormNew 
        userId={data.id}
        childName={data.name}
        childAge={data.age}
        relationshipWithChild={data.RelationwithChild}
        parentName={data.ParentName}
        parentSign={data.ParentName}
        date={(new Date(data.createdAt)).toDateString()}
      />
    </PDFViewer>
    </>
  )
}

  
export default ParentConsentFormMain
