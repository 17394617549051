import React, { createRef, useEffect, useRef, useState } from "react";
import Images from "../../component/Images";
import styles from "../../pages/Home/landingpage.module.css";
const Loader = () => {return(<div className={styles.body}>
<div className={styles.LoadingMain}
  style={{
    alignItems: "center",
    backgroundColor: "#F2DD4E",
    top: 0,
    left: 0,
    bottom: 0,
    position: "absolute",
    justifyContent: "center",
    flex: 1,
    flexDirection: "column",
    display: "flex",
    right: 0,
  }}
><img style={{width: '500px'}} src={Images.LoaderGIFweb} />
  {/* <div className={styles.LoadingMainChild} style={{ color: "red", fontSize: 24, alignSelf: "center" }}>
    ...Loading <span></span> */}
  {/* </div> */}
</div>
</div>    );
}

export default Loader;