
export const baseURL = "https://manage.guppytheatre.com/api/";

export default async (data,comment,otp) => {
    let dataSend={
        "sender":{
           "email":"natasha@guppytheatre.com",
           "name":"Guppy"
        },
        "subject":"Guppy OTP",
        "htmlContent":`<!DOCTYPE html><html><body><h1>Your otp is :`+otp+`</h1></body></html>`,
        "messageVersions":[
           {
              "to":[
                data
            ]
           }
        ]
     }

  return new Promise((resolve, reject) => {
    fetch('https://api.sendinblue.com/v3/smtp/email', {
        method:"post",
        headers: {Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": "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"
      },
        body: JSON.stringify(dataSend),
      })
        .then((response) => response.json())
        .then((res) => {
          // if(comment!=undefined){
          //   console.log("=================",JSON.stringify(dataSend), "=================" + comment + "=================");
          //   console.log(res, "=================" + comment + "=================");
          // }
          
            resolve(res);
        })
        .catch((error) => {
            // console.log(error, "=================" + comment + "================="+JSON.stringify(dataSend));
            reject(error);
        });
  });
};
