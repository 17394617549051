import React, { useImperativeHandle } from "react";
import { useState } from "react";
import Images from "./Images";
import Modal from 'react-modal';
import { forwardRef } from "react";

import Modalstyle from "../pages/StoryPage/storypage.module.css";

 const  NoDraftModal= forwardRef(({text}, ref) =>{


    const [modal, setmodal] = useState(false);
    useImperativeHandle(ref, () => ({
      show: () => {
        setmodal(true);
        // return counts;
      },
      hide: () => {
        setmodal(false);
        // return counts;
      },
    }));

    return(<Modal style={{
        content: {
          display: "flex",
          backgroundColor: "#0000",
          borderWidth: 0,
          flexDirection: "column",
          flex: 1,
          position: "unset",
          overflow: "auto",
          height: "100%",
          zIndex:90,
        },
      }}
      ariaHideApp={false}
      overlayClassName={Modalstyle.modal}
      isOpen={modal}
      onRequestClose={() => {
        setmodal(false);
      }}
      contentLabel="Example Modal"
      >
        <div className="container">
          <div className={Modalstyle.NoDraftModal}>
            <img className={Modalstyle.sharebtnimg} src={Images.backbtncross}  onClick={()=>{setmodal(false);}}  />
            <div>
              <img className={Modalstyle.NoDraftFrame} src={Images.NoDraftFrame}/>
              <h2>{text!=undefined?text:"NO DRAFTS"}</h2>
            </div>
          </div>
        </div>
        </Modal>)
});
export default NoDraftModal;