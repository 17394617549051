import React from 'react'
import Images from './Images';
import styles from '../pages/Home/landingpage.module.css';


function MenuMobileNotLogin({navigate}){
    return (
        <div style={{textAlign: 'center', marginTop: '25px'}} className='NewMobileLogin'>
       
        <div style={{backgroundColor:"#56CDD5",width:"114%",marginLeft:-15,}}>
            <img style={{margin: '13px 0'}} src={Images.dashedLine} />
            <div className={styles.storySectionTwoInnerInfo}>
                <div className={styles.storySectionTwoInner}>
                    <p style={{
                        fontWeight: '700',
                        fontSize: '18px',
                        lineHeight: '23px',
                        color: '#0D5268',
                        margin: "0 auto 15px"
                    }}>Please log in/ register to continue your adventure</p>
                    <div className={styles.btnStory}>
                    <button className={styles.btnUnfill} onClick={()=>{navigate("/login");}}>
                        Log-In
                    </button>
                    <button
                        className={styles.btnUnfill} onClick={()=>{navigate("/signUp");}}>
                        Register
                    </button>
                    </div>
                </div>
            </div>
            <img style={{margin: '13px 0'}} src={Images.dashedLine} />
        </div>
        
      </div>
    )
}


export default MenuMobileNotLogin;

