import React from "react";
import styles from "../pages/Home/landingpage.module.css";
import Images from "./Images";
import { useEffect, useState, createRef } from "react";
import { signupapi } from "../redux/userdata/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../ApiService/Apicall";
import emailsend from "../ApiService/emailsend";
import NoDraftModal from "./NoDraftModal";
import * as moment from 'moment'
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { PDFViewer } from "@react-pdf/renderer";
import ParentConsentFormNew from "./ParentConsentFormNew";
function ParentConsentForm() {

  const navigate=useNavigate();
  const location = useLocation();
  const [user, setuser] = useState([]);

  useEffect(() => {
    console.log(
      window?.location?.href.split("?")[1].split("="),
      "--Previous url---"
    );

    if (!!window?.location?.href?.split("?")[1]) {
      setuser(window?.location?.href.split("?")[1].split("="));
    }
  }, []);

  const generatepdf=()=>{

var doc=new jsPDF('l','px','a4');
doc.html(document.querySelector('#container'),{callback:function(pdf){
  var pageCount = doc.internal.getNumberOfPages();
  pdf.deletePage(pageCount);
  // console.log(pdf.output("dataurlnewwindow"),"====== pdf.output(dataurlnewwindow)==");
  pdf.output("dataurlnewwindow")
  // pdf.output("ConsentForm.pdf");
  // pdf.save("ConsentForm.pdf");
},
x: 10,
y: 10,windowWidth:100})
  }

  const sigup=async()=>{
    let sixnumber = 100000 + Math.floor(Math.random() * 900000);
    const user = location?.state?.signupdata;
    emailsend(
      {
        email: user.email,
        name: user.username,
      },
      "psk",
      sixnumber
    );
    navigate("/Otp", {
      state: {
        data: user,
        otp: sixnumber,
      },
    });
  }

  return( <PDFViewer style={{width: '100%', height: '100vh'}}>
  <ParentConsentFormNew 
    userId={undefined}
    childName={user[3]}
    childAge={user[5]}
    relationshipWithChild={user[7]}
    parentName={user[1]?.replace("%20", " ")}
    parentSign={user[1]?.split("%20")[0]}
    date={moment().format("DD/MM/YYYY")}
  />
</PDFViewer>)

  // return (
  //   <div className="container" >
  //     <div className={styles.ParentConsentFormSection} id="container">
  //       <h4>Parent/Guardian Consent Form</h4>
  //       <div className={styles.ParentConsentInner}>
  //         <p>
  //           This Consent Form offers your express, verifiable consent, to
  //           collect and process your child’s personal information. In order to
  //           give your consent, please print this form, fill the required
  //           information, sign it, scan and email the signed form to
  //           guppy@guppytheatre.com.
  //         </p>
  //         <br />
  //         <br />
  //         <br />

  //         <p>
  //           Child’s full name: <strong>{user[3]}</strong>
  //         </p>
  //         <p>
  //           Child’s Age: <strong>{user[5]}</strong>
  //         </p>
  //         <p>
  //           Relationship: <strong>{user[7]}</strong>
  //         </p>

  //         <br />
  //         <br />
  //         <br />

  //         <p>
  //           I agree and acknowledge, that I had the opportunity to review the
  //           Direct Notice to Parents by Guppy Story. I further agree that I have
  //           read and understood the Privacy Policy and Terms of Use provided by
  //           Guppy Story, following which, I agree to the collection, use and/or
  //           disclosure of my child’s personal information, as according to the
  //           mentioned Privacy Policy and Terms of Use.
  //         </p>
  //         <br />
  //         <br />
  //         <p>
  //           <strong>
  //             I understand that I may withdraw my permission at any time with a
  //             written notice as set forth in the Guppy Privacy Policy.
  //           </strong>
  //         </p>
  //         <br />
  //         <br />
  //         <p>
  //           Parent/Guardian’s Name:{" "}
  //           <strong>{user[1]?.replace("%20", " ")}</strong>
  //         </p>
  //         <br />
  //         <p>
  //           Parent/Guardian’s Signature:{" "}
  //           <strong>{user[1]?.split("%20")[0]}</strong>
  //         </p>
  //         <p>
  //           Date: <strong>{moment().format("DD/MM/YYYY")}</strong>
  //         </p>
  //       </div>
      
  //     </div>
  //     <div className={styles.btndiv} style={{display:"flex",marginLeft:40,marginRight:40,justifyContent:"space-between",alignItems:"center"}}>
  //         <button
  //           style={{ width: "250px", padding: "7px 0" }}
  //           type="submit"
  //           className={styles.btnFill}
  //           onClick={generatepdf}
  //         >Download</button>
  //         <button
  //           style={{ width: "250px", padding: "7px 0" }}
  //           type="submit"
  //           className={styles.btnFill}
  //           onClick={(e) => {
  //             e.preventDefault();
  //             sigup()
  //           }}
  //         >Sign Up</button>
  //       </div>
  //   </div>
  // );
  
}

export default ParentConsentForm;
