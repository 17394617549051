import { getLocal } from '../otherexports';

export const baseURL = "https://manage.guppytheatre.com/api/";

export default async (uri,dataSend, comment,token) => {
    const Token=getLocal("Token");
  let header;
  if(token!=undefined){
header={
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: 'bearer ' + Token,}
  }else{
    header={
      Accept: "application/json",
      "Content-Type": "application/json",}
  }
  return new Promise((resolve, reject) => {
    // console.log( baseURL + uri)
    fetch(baseURL + uri, {
        method: "PUT",
        headers: header,
        body: JSON.stringify(dataSend),
      })
        .then((response) => response.json())
        .then((res) => {
          // if(comment!=undefined){
          //   console.log(uri,"=================",dataSend, "=================" + comment + "=================");
          //   console.log(res, "=================" + comment + "=================");
          // }
          
            resolve(res);
        })
        .catch((error) => {
            // console.log(error, "=================" + comment + "=================");
            reject(error);
        });
  });
};
